import { history } from '../history';
import { LOCALSTORAGE_KEY } from "../constants";
import { userService } from "../services/userService";

/**
 * 
 * @param {string} username 
 * @param {string} password 
 */
export function login(username, password) {
    return dispatch => {
        dispatch({ type: "LOGIN_REQUEST" })
        userService.login(username, password)
        .then(response => {
            const user = response.data
            const { destination_url } = user;
            const message = user.message || "Login success";
            dispatch({ type: "LOGIN_SUCCESS", payload: user })
            dispatch({ type: "ALERTS_ADD", payload: { message: message, variant: "success" } })
            history.push(destination_url)
            setTimeout(() => {
                dispatch({ type: "ALERTS_DISMISS_ALL" })
            }, 3000);
        })
        .catch(error => {
            const { message } = error.response.data
            dispatch({ type: "LOGIN_FAILURE", payload: error.response })
            dispatch({ type: "ALERTS_ADD", payload: { message, variant: "danger" } })
            setTimeout(() => dispatch({ type: "ALERTS_DISMISS_ALL" }), 3000);
        })
    }
}

export function logout() {
    localStorage.removeItem(LOCALSTORAGE_KEY)
    return {
        type: "LOGOUT"
    }
}