import objectToFormData from "object-to-formdata";
import { axios } from "../axios";

export const subOrgsService = {
    getCreate, postCreate
}

const SUBORGS_CREATE_RESPONSE = {
    states: [
        { value: 'vic', label: 'Victoria' },
        { value: 'tas', label: 'Tasmania' },
        { value: 'nsw', label: 'New South Wales' },
        { value: 'act', label: 'Australian Capital Territory' },
        { value: 'qld', label: 'Queensland' },
        { value: 'wa', label: 'Western Australia' },
        { value: 'sa', label: 'South Australia' },
        { value: 'nt', label: 'Northern Territory' },
    ],
    addressCategories: [
        { value: 'example', label: 'Sample address type' },
    ],
    orgRequirements: [
        { "label": "Driver Required for All Shifts", "value": "1", "checked": true },
        { "label": "Full VIC Car Licence Required", "value": "2", "checked": true },
        { "label": "Medication Training", "value": "5", "checked": true },
        { "label": "Home Based Care", "value": "6", "checked": true },
        { "label": "Email Shift Changes to LC Manager", "value": "7", "checked": true },
        { "label": "Compulsory Lunch Breaks for Shifts > 5hrs", "value": "8", "checked": true },
        { "label": "Welfare Confidentiality Forms NOT REQUIRED", "value": "9", "checked": true },
        { "label": "Attach Timesheet to Invoices", "value": "10", "checked": true },
        { "label": "Smoking Ban", "value": "11", "checked": true },
        { "label": "Bushfire Area", "value": "12", "checked": true },
    ]
}

/** @returns {Promise<{data: typeof SUBORGS_CREATE_RESPONSE}>} */
function getCreate() {
    return axios.get("/suborgs/create");
    //return new Promise((resolve, reject) => setTimeout(() => resolve({data: SUBORGS_CREATE_RESPONSE}), 2000))
}

const SUBORGS_CREATE_POST_RESPONSE_FAIL = {
    errors: {
        "OrgLegalDetails[sub_org_legal_name]": "Field is required",
        "OrgLegalDetails[abn]": "Field is required",
        
        // ContactDetails: {
        //     sub_org_address: '',
        //     state: '',
        //     postcode: '',
        //     address_category: '',
        //     sub_orgs_phones: [],
        //     sub_orgs_emails: [],
        //     sub_orgs_website: ''
        // },
        // KeyContactDetails: {
        //     first_name: '',
        //     last_name: '',
        //     position: '',
        //     department: '',
        //     phone_contact: [],
        //     email_contact: [],
        //     is_billing_same_as_key_contact: false
        // },
        // BillingContactDetails: {
        //     who_you_want_to_bill: '',
        //     first_name: '',
        //     last_name: '',
        //     position: '',
        //     department: '',
        //     primary_phone_contacts: [],
        //     primary_email_contacts: [],
        //     pay_roll_tax: '',
        //     gst: '',
        // },
        // SubOrgRequirements: {
        //     driver_license_for_all_shifts: false,
        //     welfare_confidentiality_forms_not_required: false,
        //     full_vic_drivers_license_required: false,
        //     attach_timesheet_to_invoice: false,
        //     medication_training: false,
        //     smoking_ban: false,
        //     home_based_care: false,
        //     bushfire_area: false,
        //     email_shift_change_to_lc_manager: false,
        //     compulsory_lunch_breaks_to_shifts_greater_than_5_hours: false
        // },
    }
}

/** 
 * @returns {Promise<{data: Record<string, string>}>} 
 */
function postCreate(values) {
    return axios.post("/suborgs/create", objectToFormData({
        values: JSON.stringify(values),
        logo: values.logo
    }));
    // return new Promise((resolve, reject) => setTimeout(() => reject({ response: {data: SUBORGS_CREATE_POST_RESPONSE_FAIL }}), 500));
}