import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import "../stylesheets/pages/DashboardPage.scss";

// import AddIconBlack from "../images/Add-Icon-Black.svg";
// import BackIconBlack from "../images/Back-Icon-Black.svg";
// import BackIconWhite from "../images/Back-Icon-White.svg";
// import ClosedArrowIconBlack from "../images/ClosedArrow-Icon-White.svg";
// import GoogleIcon from "../images/Google-Icon.svg";

import AddIconWhite from "../images/Add-Icon-White.svg";
import ViewIconBlack from "../images/View-Icon-Black.svg";
import ViewIconWhite from "../images/View-Icon-White.svg";
import { organisationService } from '../services/organisationService';
import { Button, Modal } from 'react-bootstrap'

class DashboardPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            invoices: {},
            latest_updates: {},
            fms_feedback: {},
            _links: {},
            _loading: false,
            selectedUpdate: null,
            selectedInvoice: null,
        }
    }

    async componentDidMount() {
        this.setState({ _loading: true })
        const response = await organisationService.dashboard()
        const data = response.data;
        this.setState({
            invoices: data.invoices,
            latest_updates: data.latest_updates,
            fms_feedback: data.fms_feedback,
            _links: data._links,
            _loading: false,
        }) 
    }

    renderNotification() {
        return (
            <div className="fms-feedback">
                <div className="d-inline-block bg-primary text-white fms-badge">
                    <b>FMS-Feedback</b>
                </div>
                <div className="d-inline-block fms-feedback-body">
                    <div className="d-inline-block fms-feedback-line">
                        “[Feedback by a Participant here]” - Lorna Marsh, 01/01/01
                    </div>
                    <div className="d-inline-block fms-feedback-line">
                        “[Feedback by a Participant here, Feedback by a Participant here, Feedbac
                    </div>
                </div>
            </div>
        )
    }

    renderActionButtons() {
        /** @type {React.CSSProperties} */
        const plusIconStyles = {
            display: "inline-block",
            width: 20,
            marginLeft: 15
        }

        return (
            <div className="action-buttons text-right">
                <Link to="/sites/create" className="btn btn-large btn-primary">
                    <b>Create new Site</b>
                    <img src={AddIconWhite} alt="Create new site" style={plusIconStyles}/>
                </Link>
                <Link to="/sub-orgs/create" className="btn btn-large btn-primary">
                    <b>Create new Sub-org</b>
                    <img src={AddIconWhite} alt="Create new sub-org" style={plusIconStyles}/>
                </Link>
            </div>
        )
    }

    renderDivider() {
        return (
            <div className="divider bg-primary"></div>
        )
    }

    renderLatestUpdates() {

        const viewAll = (this.state.latest_updates._links || {})['view_all'];
        const { _loading: loading } = this.state
        const latestUpdates = (this.state.latest_updates.data || []).map((latest_update, i) => ({
			desc: latest_update.desc,
            update: latest_update.update,
            date: latest_update.date,
            url: latest_update._links.self,
		}))

        return (
            <div className="latest-updates">
                <table className="table">
                    <thead>
                        <tr>
                            <td colSpan="3">
                                <h4>Latest updates</h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Update</b>
                            </td>
                            <td>
                                <b>Date</b>
                            </td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading && (
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        Loading...
                                    </td>
                                </tr>
                            )
                        }
                        {
                            !loading && latestUpdates.length === 0 ? 
                            (
                                <tr className="text-center">
                                    <td colSpan="3">No results found</td>
                                </tr>
                            ) 
                            : latestUpdates.map((latestUpdate, i) => (
                                <tr key={i}>
                                    <td>{latestUpdate.update}</td>
                                    <td>{latestUpdate.date}</td>
                                    <td>
                                        <span title="View update" style={{cursor: "pointer"}} onClick={() => this.setState({selectedUpdate: latestUpdate})}>
                                            <img src={ViewIconBlack} alt="View" className="view-icon"/>
                                        </span>   
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {
                    viewAll && (
                        <div className="text-right">
                            <Link to="/notifications" className="btn btn-md btn-dark view-all-btn" title="View all updates">
                                {viewAll.text || "View All"}
                                <img src={ViewIconWhite} alt="View" className="view-icon"/>
                            </Link>
                        </div>
                    )
                }
            </div>
        )
    }


    renderInvoices() {

        const { _loading: loading } = this.state
        const viewAll = (this.state.invoices._links || {})['view_all'];
        const invoices = (this.state.invoices.data || []).map((invoice, i) => ({
            invoiceName: invoice.invoiceName,
            dueDate: invoice.dueDate,
            url: invoice._links.self,
        }))

        return (
            <div className="invoices">
                <table className="table">
                    <thead>
                        <tr>
                            <td colSpan="3">
                                <h4>Invoices</h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Invoice name</b>
                            </td>
                            <td>
                                <b>Due Date</b>
                            </td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading && (
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        Loading...
                                    </td>
                                </tr>
                            )
                        }
                        {
                            
                            !loading && invoices.length === 0 ? 
                            (
                                <tr className="text-center">
                                    <td colSpan="3">No results found</td>
                                </tr>
                            ) 
                            : invoices.map((invoice, i) => (
                                <tr key={i}>
                                    <td>{invoice.invoiceName}</td>
                                    <td>{invoice.dueDate}</td>
                                    <td>
                                        <Link to={invoice.url || "/invoices"} title="View invoice">
                                            <img src={ViewIconBlack} alt="View" className="view-icon"/>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {
                    viewAll && (
                        <div className="text-right">
                            <Link to={viewAll.url || "/invoices"} className="btn btn-md btn-dark view-all-btn" title="View all updates">
                                {viewAll.text || "View All"}
                                <img src={ViewIconWhite} alt="View" className="view-icon"/>
                            </Link>
                        </div>
                    )
                }
            </div>
        )
    }


    renderLatestUpdatesAndInvoices() {
        return (
            <div className="row">
                <div className="col-12 col-lg-6">
                    {this.renderLatestUpdates()}
                </div>
                <div className="col-12 col-lg-6">
                    {this.renderInvoices()}
                </div>
            </div>
        )
    }

    renderSingleUpdateModal() {
		const update = this.state.selectedUpdate

        return (
            <>
                <Modal show={!!this.state.selectedUpdate} onHide={() => this.setState({ selectedUpdate: null })} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update</Modal.Title>
                    </Modal.Header>
                    {
                        update && (
                            <Modal.Body>
								<p>{update.update}</p>
                                <p>{update.desc}</p>
                                <p>{update.date}</p>
                            </Modal.Body>
                        )
                    }
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ selectedUpdate: null })}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }


    render() {
        return (
            <div className="container-fluid">
                <div className="content-block">
                    {this.renderActionButtons()}
                </div>
                <div className="content-block">
                    {this.renderDivider()}
                </div>
                <div className="content-block">
                    {this.renderLatestUpdatesAndInvoices()}
                </div>
                {this.renderSingleUpdateModal()}
            </div>
        )
    }
}

export default connect()(DashboardPage)