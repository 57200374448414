import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { LOGIN_URL } from '../constants';
import { logout } from '../actions/userActions';

class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.dispatch(logout());
    }

    render() {
        const { props } = this

        return <Redirect to={{pathname: LOGIN_URL, state: {from: props.location}}} />
    }
}

export default connect()(LogoutPage)