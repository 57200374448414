import React from 'react';
import { css } from '../helpers/common';

// styling for <CustomCheckbox /> is located from "/src/layouts/general.scss"

/** 
 * Custom-styled checkbox
 * 
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { label: JSX.Element }} props  
 */
const CustomCheckbox = (props) => {
    const styles = css({
        root: {
            opacity: !!props.disabled ? 0.5 : undefined,
        }
    })

    return (
        <div className="custom-checkbox" style={styles.root}>
            <label htmlFor={props.id}>
                <input {...props} type="checkbox" />
                <span className="box"></span>
                {props.children || props.label}
            </label>
        </div>
    )
}

export default CustomCheckbox;