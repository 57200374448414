export const prestineContactState = {
    contact_type: {
        label: '',
        value: ''
    },
    first_name: '',
    last_name: '',
    position: '',
    department: '',
    phone_primary: '',
    phone_secondary_1: null,
    phone_secondary_2: null,
    email_primary: '',
    email_secondary_1: null,
    email_secondary_2: null,
    contactDetails: null
}