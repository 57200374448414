import createReducer from '../helpers/createReducer'

/** 
 * @typedef {object} State
 * @property {boolean} loading
 * @property {any[]} items
 * @property {string[]} errors
 * @property {number|null} fetched_at
 * 
 * @typedef {keyof types} ActionTypes
 */

/** @type {State} */
const initialState = {
    loading: false,
    items: [],
    errors: [],
    fetched_at: null,
}

export const types = {
    AUSTRALIAN_STATES_LOADING: 'AUSTRALIAN_STATES_LOADING',
    AUSTRALIAN_STATES_SUCCESS: 'AUSTRALIAN_STATES_SUCCESS',
    AUSTRALIAN_STATES_FAILED: 'AUSTRALIAN_STATES_FAILED',
}


export const australianStates = createReducer(initialState, {
    [types.AUSTRALIAN_STATES_LOADING]: (state, action) => ({
        ...state,
        loading: true,
    }),
    [types.AUSTRALIAN_STATES_SUCCESS]: (state, action) => ({
        ...state,
        loading: false,
        items: (action.response.data['data'] || []).map(o => ({label: o.label, value: o.value})),
        fetched_at: Date.now(),
        errors: [],
    }),
    [types.AUSTRALIAN_STATES_FAILED]: (state, action) => ({
        ...state,
        loading: false,
        fetched_at: null,
        errors: ['Sorry something went wrong while fetching data']
    })
});
