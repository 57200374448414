import React from 'react'
import { useField } from 'formik'

/** 
 * Custom-styled radio button
 * 
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { label: JSX.Element }} props  
 */
const CustomRadioButton = ({ children, ...props }) => {
    const [field] = useField({ ...props, type: 'radio' })
    return (
        <label htmlFor={props.id} className="custom-radio-button">
            <input type="radio" {...field} {...props} />
            {props.children || props.label}
        </label>
    )
}

export default CustomRadioButton