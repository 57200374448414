import React from "react";

import "../stylesheets/components/LoginFooter.scss";

export default function LoginFooter() {
    /** @type {React.CSSProperties} */
    const sponsorIconStyle = {
        width: '100%',
        height: 22,
        backgroundColor: 'black',
        verticalAlign: 'middle',
    }

    /** @type {React.CSSProperties} */
    const siteFooterStyle = {
        color: "#FFFFFF",
    }

    return (
        <div className="site-footer bg-primary" style={siteFooterStyle}>
            <div className="container">
                <ul className="footer-links d-flex justify-content-center">
                    {/* <li>
                        <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="#">Terms &amp; Conditions</a>
                    </li> */}
                    <li>
                        <span className="d-inline-block">
                            <span className="copyight">
                                &copy; Healthcare Manager <span className="copyright-year">2019</span> - All Rights Reserved
                            </span>
                        </span>
                    </li>
                    {/* <li>
                        <a href="#">About My ONCALL</a>
                    </li>
                    <li>
                        <a href="#">Staff Policies</a>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}