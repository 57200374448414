import React from 'react'
import { connect } from 'react-redux'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import jwt from "jsonwebtoken"
import _ from "lodash"
import { Formik, ErrorMessage, Field, Form} from 'formik'
import GooglePlacesAutocomplete from 'react-google-autocomplete'
import objectToFormData from 'object-to-formdata'
import DatePicker from 'react-datepicker';
import moment from 'moment'

import OrgInfoSummary from '../../components/OrgInfoSummary'
import RequiredLabel from '../../components/RequiredLabel'
import { CustomSelect, CustomAsyncSelect } from "../../components/CustomSelect"
import { findAddressComponent } from '../../helpers/google-places'
import participants from '../../services/participants'
import common from '../../services/common'
import {axios} from "../../axios"

import "react-datepicker/dist/react-datepicker.css";
import "../../stylesheets/pages/fms/CreatePage.scss"
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs'
import SiteInfoSummary from '../../components/site/SiteInfoSummary'
import { ensureOnlyNumericKeypressAllowed } from '../../helpers/common'

/**
 * @typedef {{label: string, value: string, selected?: boolean, disabled:? boolean}} Option
 * @typedef {ReturnType<typeof mapStateToProps>} CreatePageProps
 * @typedef {CreatePageProps & import('react-router-dom').RouteComponentProps & import('react-redux').DispatchProp} Props
 * 
 * @typedef {object} State
 */


/**
 * Page that renders FMS case form. 
 * 
 * Used by these routes
 * - `/fms/create`
 * - `/sub-orgs/:id/fms/create`
 * - `/sites/:id/fms/create`
 * 
 * @extends {React.Component<Props, State>}
 */
class CreatePage extends React.Component {

    state = {
        
    }

    /**
     * @param {any} values
     * @param {import('formik').FormikHelpers<any>} formikHelpers
     */
    handleSubmit = async (values, formikHelpers) => {

        // convert value-label objects to primitives before submitting
        // they have to be value-label objects because React.Async dropdown will not work if their value prop is not a primitive
        let newValues = {
            ...values,
            meta: this.computeFMSCaseMeta(),
            FMSCase: {
                ...values.FMSCase,
                initiated_by: parseInt((values.FMSCase.initiated_by && ("value" in values.FMSCase.initiated_by)) 
                    ? values.FMSCase.initiated_by.value 
                    : values.FMSCase.initiated_by),
                againsts: (values.FMSCase.againsts || []).map(against => ({
                    ...against,
                    against_by: (against.against_by && ("value" in against.against_by)) 
                        ? against.against_by.value 
                        : against.against_by
                }))
            }
        }


        const formData = objectToFormData({
            request: JSON.stringify(newValues, null, 4)
        })

        let response = null
        try {
            response = await axios.post('/fms/create_case', formData)
        } catch (e) {
            const { data } = e.response
            const { errors } = data
            if (errors) {
                for (let k in errors) {
                    formikHelpers.setFieldError(k, errors[k])
                }
            }
        }
    }

    /**
     * Options to be fed into `options` prop of 'Initiator Details' dropdown
     * @param {number} initiated_type 
     */
    initiatedTypeOptions(initiated_type) {
        
        // suborg
        if (initiated_type == 8) {
            const { suborgs } = this.props
            const { items, loading } = suborgs
            return [
                { label: !!loading ? 'Loading...' : 'Select...', value: null, selected: true, disabled: true, hidden: true },
                ...(items || []).map(s => ({ value: s.id, label: s.name })).filter(opt => !!opt.value)
            ]
        }
        // site/house
        else if (initiated_type == 4) {
            const { sites } = this.props
            const { items: siteItems, loading: siteLoading } = sites
            return [
                { label: !!siteLoading ? 'Loading...' : 'Select...', value: null, selected: true, disabled: true },
                ...(siteItems || []).map(s => ({ value: s.site_id, label: s.title })).filter(opt => !!opt.value)
            ]
        } 
        // for org, allow 1 option only (w/c is parent org)
        else if (initiated_type == 3) {
            const { orgDetails } = this.props
            const { basic_detail } = orgDetails.value || {}

            // just in case...
            if (!basic_detail) {
                return [{ label: 'Select...', value: null, selected: true, disabled: true }]
            }

            const { name: orgName, ocs_id } = basic_detail || {}
            return [{ label: orgName, value: ocs_id, selected: true, title: 'You can only select your parent organisation'}]
        }

        return [
            { label: 'Select...', value: null, selected: true, disabled: true }
        ]
    }

    /**
     * Displays customized validation error message
     * 
     * @param {string} errorMessage
     */
    renderValidationError = errorMessage => {
        return (
            <span className="text-danger">
                <small>{errorMessage}</small>
            </span>
        )
    }


    /**
     * Render breadcrumbs
     */
    renderBreadcrumbs() {
        return <CustomBreadcrumbs />
    }

    /**
     * Render org details
     */
    renderOrgSummary() {
        return (
            <div className="site-information">
                <OrgInfoSummary />
            </div>
        )
    }


    /**
     * Render site details
     */
    renderSiteSummary() {
        return (
            <div className="site-information">
                <SiteInfoSummary />
            </div>
        )
    }


    /**
     * Render page title
     */
    renderEntryHeader() {
        return (
            <div className="entry-header">
                <h2 style={{margin: 0}}>
                    <b>Create new FMS Case</b>
                </h2>
            </div>
        )
    }

    computeFMSCaseMeta() {
        const { params, path } = this.props.match
        if (!params) {
            return { type: 'ORGANISATION' }
        }
        
        const { id } = this.props.match.params
        if (id && path === '/sub-orgs/:id/fms') {
            return { type: 'SUB-ORGANISATION', id: id }
        }
        
        if (id && path === '/sites/:id/fms') {
            return { type: 'SITES', id: id }
        }

        return null;
    }



    renderForm() {
        const { location, match, entityType, currentSubOrg, currentSite, orgDetails, suborgs, sites } = this.props
        const { pathname } = location
        const { params } = match || {}
        const { id } = params || {}
        const { basic_detail } = orgDetails.value || {}

        // pre-select initiators based on ORG, SUBORG or SITE
        let initiatedBy = basic_detail ? { label: basic_detail.name, value: basic_detail.ocs_id } : null
        let initiatedType = 3

        if (entityType === 'SUBORG') {
            initiatedType = 8
            initiatedBy = currentSubOrg ? { value: currentSubOrg.id, label: currentSubOrg.name } : null
        } else if (entityType === 'SITE') {
            initiatedType = 4 // House, assuming 'house' is same as 'site'
            initiatedBy = currentSite ? { value: currentSite.ocs_id, label: currentSite.title } : null
        }

        const isOrgDetailsSuborgsAndSiteLoading = orgDetails.loading || suborgs.loading || sites.loading

        return (
            <Formik
                initialValues={{
                    FMSCase: {
                        // the first three are required
                        event_date: '',
                        initiated_by: initiatedBy, // typeof { value: any, label: string }
                        initiated_type: initiatedType,

                        // required if initiated type is 5
                        Initiator_first_name: '',
                        Initiator_last_name: '',
                        Initiator_email: '',
                        Initiator_phone: '',

                        // required
                        category: {
                            categoryId: '',
                        },
                        locations: [
                            {
                                // caseId: '',
                                address: '',
                                suburb: '',
                                postal: '',
                                state: '',
                                categoryId: '',
                            }
                        ],
                        againsts: [
                            {
                                // caseId: '',
                                against_category: '',
                                against_first_name: '',
                                against_last_name: '',
                                against_email: '',
                                against_phone: '',
                                against_by: null,
                                // created: '',
                            }
                        ],
                        // required
                        reason: {
                            title: '',
                            description: '',
                        },

                        // optional
                        note: {
                            title: '',
                            description: '',
                        },
                    },
                    request_url: pathname, // will be used by the backend to check where 
                    entityType: entityType,
                    siteOrSuborgId: id,
                }}
                enableReinitialize={true}
                onSubmit={this.handleSubmit}
            >
                {({values, errors, setFieldValue, handleChange, isSubmitting}) => {

                    return (
                        <Form id="CreateFMSCaseForm" method="POST">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>
                                            <b>Case initiator details</b>
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-2" style={{ minWidth: 182 }}>
                                        <RequiredLabel htmlFor="FMSCase-event_date">Event date</RequiredLabel><br/>
                                        <DatePicker
                                            autoComplete={'off'} 
                                            placeholderText={"DD-MM-YYYY"} 
                                            name="FMSCase[event_date]"
                                            id="FMSCase-event_date"
                                            className="form-control border-dark" 
                                            selected={values.FMSCase.event_date ? moment(values.FMSCase.event_date, 'DD-MM-YYYY') : null} 
                                            maxDate={moment()}
                                            dateFormat="DD-MM-YYYY" 
                                            onChange={/** @param {moment.Moment} momentInstance */ momentInstance => {
                                                let event_date = momentInstance ? momentInstance.format("DD-MM-YYYY") : null
                                                setFieldValue("FMSCase[event_date]", event_date)
                                            }}
                                        /><br/>
                                        <ErrorMessage name="FMSCase[event_date]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4" style={{ minWidth: 228 }}>
                                        <RequiredLabel htmlFor="FMSCase-category-categoryId">Case Category</RequiredLabel>
                                        <CustomSelect 
                                            variant="dark"
                                            name="FMSCase[category][categoryId]"
                                            id="FMSCase-category-categoryId"
                                            className="form-control"
                                            value={values.FMSCase.category.categoryId}
                                            options={[
                                                { label: "Select...", value: "", selected: true, disabled: true },
                                                { label: "Grievence", value: "1" },
                                                { label: "Staff Performance", value: "2" },
                                                { label: "Serious Misconduct", value: "3" },
                                                { label: "Investigation", value: "4" },
                                                { label: "Cat 1 Investigation", value: "5" },
                                                { label: "Own Alert", value: "6" },
                                                { label: "Client Mismatch", value: "7" },
                                                { label: "Client Survey Response", value: "8" },
                                                { label: "Now Works For Organisation", value: "9" },
                                                { label: "Cat 1 Incident", value: "10" }
                                            ]}
                                            clearable={false}
                                            onChange={(newValue) => newValue && setFieldValue("FMSCase[category][categoryId]", newValue.value)}
                                        />
                                        <ErrorMessage name="FMSCase[category][categoryId]" render={this.renderValidationError}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-2" style={{minWidth: 230}}>
                                        <RequiredLabel htmlFor="FMSCase-initiated_type">Initiator Category</RequiredLabel>
                                        <CustomSelect 
                                            variant="dark"
                                            name="FMSCase[initiated_type]"
                                            id="FMSCase-initiated_type"
                                            className="form-control"
                                            value={isOrgDetailsSuborgsAndSiteLoading ? null : values.FMSCase.initiated_type}
                                            placeholder={isOrgDetailsSuborgsAndSiteLoading ? "Loading..." : 'Select...'}
                                            options={[
                                                { value: null, label: 'Select...', selected: true, disabled: true, hidden: true },
                                                { value: 3, label: 'HCM Organisation' },
                                                { value: 8, label: 'HCM Sub-organisation' },
                                                { value: 4, label: 'HCM Site' },
                                            ]}
                                            clearable={false}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    setFieldValue("FMSCase[initiated_type]", newValue.value)
                                                }
                                                if (newValue.value !== values.FMSCase.initiated_type) {
                                                    setFieldValue("FMSCase[initiated_by]", null)
                                                }

                                                // when selecting 'HCM Organisation' as initiator category, 
                                                // autoselect first option (usually will only have 1 avail option, w/c is the parent org)
                                                if (newValue.value === 3) {
                                                    const orgOption = this.initiatedTypeOptions(3).find(kv => !!kv.value)
                                                    if (orgOption) {
                                                        setFieldValue("FMSCase[initiated_by]", orgOption)
                                                    }
                                                } 
                                                else if (newValue.value == 4) {
                                                    if (entityType === "SITE") {
                                                        const useSiteOption = this.initiatedTypeOptions(4).find(kv => !!kv.value)
                                                        setFieldValue("FMSCase[initiated_by]", useSiteOption)
                                                    }
                                                } else if (newValue.value == 8) {
                                                    if (entityType === "SUBORG") {
                                                        //setFieldValue("FMSCase[initiated_by]", initiatedBy)
                                                        const useSuborgOption = this.initiatedTypeOptions(8).find(kv => !!kv.value)
                                                        setFieldValue("FMSCase[initiated_by]", useSuborgOption)
                                                    }
                                                }

                                            }}
                                            isLoading={isOrgDetailsSuborgsAndSiteLoading}
                                            disabled={isOrgDetailsSuborgsAndSiteLoading}
                                        />
                                        <ErrorMessage name="FMSCase[initiated_type]" render={this.renderValidationError}/>
                                    </div>
                                    {
                                        ([5, 6].indexOf(values.FMSCase.initiated_type) >= 0) && (
                                            <>
                                                <div className="col-12 col-sm-2" style={{ minWidth: 230 }}>
                                                    <RequiredLabel htmlFor="FMSCase-Initiator_first_name">Initiator First name</RequiredLabel>
                                                    <Field type="text" className="form-control border-dark" name="FMSCase[Initiator_first_name]" id="FMSCase-Initiator_first_name"/>
                                                    <ErrorMessage name="FMSCase[Initiator_first_name]" render={this.renderValidationError}/>
                                                </div>
                                                <div className="col-12 col-sm-2" style={{ minWidth: 230 }}>
                                                    <RequiredLabel htmlFor="FMSCase-Initiator_last_name">Initiator last name</RequiredLabel>
                                                    <Field type="text" className="form-control border-dark" name="FMSCase[Initiator_last_name]" id="FMSCase-Initiator_last_name"/>
                                                    <ErrorMessage name="FMSCase[Initiator_last_name]" render={this.renderValidationError}/>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        ([5].indexOf(values.FMSCase.initiated_type) >= 0) && (
                                            <>
                                                <div className="col-12 col-sm-2" style={{ minWidth: 230 }}>
                                                    <label htmlFor="FMSCase-Initiator_email">Initiator email</label>
                                                    <Field type="text" className="form-control border-dark" name="FMSCase[Initiator_email]" id="FMSCase-Initiator_email"/>
                                                    <ErrorMessage name="FMSCase[Initiator_email]" render={this.renderValidationError}/>
                                                </div>
                                                <div className="col-12 col-sm-2" style={{ minWidth: 230 }}>
                                                    <label htmlFor="FMSCase-Initiator_phone">Initiator phone</label>
                                                    <Field type="tel" className="form-control border-dark" name="FMSCase[Initiator_phone]" id="FMSCase-Initiator_phone" maxlength="10"  onKeyPress={ensureOnlyNumericKeypressAllowed}/>
                                                    <ErrorMessage name="FMSCase[Initiator_phone]" render={this.renderValidationError}/>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        ([3,8,4].indexOf(values.FMSCase.initiated_type) >= 0) && (
                                            <div className="col-12 col-sm-4 col-md-4">
                                                <RequiredLabel htmlFor="FMSCase-initiated_by">Initiator details</RequiredLabel>
                                                <CustomSelect
                                                    clearable={false}
                                                    cache={false}
                                                    name={`FMSCase[initiated_by]`}
                                                    id={`FMSCase-initiated_by`}
                                                    placeholder={(() => {
                                                        switch (values.FMSCase.initiated_type) {
                                                            case 3:
                                                                return "Select organisation"
                                                            case 8:
                                                                return "Select sub-organisations"
                                                            case 4:
                                                                return "Select sites"
                                                            default:
                                                                return 'Select'
                                                        }
                                                    })()}
                                                    clearable={false}
                                                    cache={false}
                                                    name={`FMSCase[initiated_by]`}
                                                    id={`FMSCase-initiated_by`}
                                                    options={this.initiatedTypeOptions(values.FMSCase.initiated_type)}
                                                    value={values.FMSCase.initiated_by}
                                                    onChange={(newValue) => {
                                                        if (newValue) {
                                                            setFieldValue(`FMSCase[initiated_by]`, newValue)
                                                        }
                                                    }}
                                                    isLoading={isOrgDetailsSuborgsAndSiteLoading}
                                                    disabled={isOrgDetailsSuborgsAndSiteLoading}
                                                />

                                                <ErrorMessage name="FMSCase[initiated_by]" render={this.renderValidationError}/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>
                                            <b>Parties &amp; location details</b>
                                        </h4>
                                    </div>
                                </div>

                                {
                                    values.FMSCase.againsts.map((against, i) => {
                                        return (
                                            <div className="row" key={i}>
                                                <div className="col-12 col-sm-2" style={{ minWidth: 230 }}>
                                                    <RequiredLabel htmlFor={`FMSCase-againsts-${i}-against_category`}>'Against' Category</RequiredLabel>
                                                    <CustomSelect 
                                                        variant="dark"
                                                        name={`FMSCase[againsts][${i}][against_category]`}
                                                        id={`FMSCase-againsts-${i}-against_category`}
                                                        className="form-control"
                                                        value={against.against_category}
                                                        options={[
                                                            { value: null, label: 'Select...', selected: true, disabled: true },
                                                            { value: 1, label: 'Member of Public' },
                                                            { value: 2, label: 'HCM Member' },
                                                            { value: 3, label: 'HCM Participant' },
                                                            { value: 4, label: 'HCM (General)' },
                                                            { value: 5, label: 'HCM User/Admin' },
                                                            { value: 6, label: 'HCM Organisation' }
                                                        ]}
                                                        clearable={false}
                                                        onChange={(newValue) => {
                                                            if (newValue) {
                                                                setFieldValue(`FMSCase[againsts][${i}][against_category]`, newValue.value)
                                                            }
                                                        }}
                                                    />
                                                    <ErrorMessage name={`FMSCase[againsts][${i}][against_category]`} render={this.renderValidationError}/>
                                                </div>
                                                {
                                                    ([1, 4].indexOf(against.against_category) >= 0) && ( // Member of public, HCM (General)
                                                        <>
                                                            <div className="col-12 col-sm-2">
                                                                <RequiredLabel style={{whiteSpace: 'nowrap'}}>'Against' details</RequiredLabel>
                                                                <Field type="text" className="form-control border-dark" name={`FMSCase[againsts][${i}][against_first_name]`} id={`FMSCase-againsts-${i}-against_first_name`} placeholder="First name"/>
                                                                <ErrorMessage name={`FMSCase[againsts][${i}][against_first_name]`} render={this.renderValidationError}/>
                                                            </div>
                                                            <div className="col-12 col-sm-2">
                                                                <label></label>
                                                                <Field type="text" className="form-control border-dark mt-auto" name={`FMSCase[againsts][${i}][against_last_name]`} id={`FMSCase-againsts-${i}-against_last_name`} placeholder="Last name" style={{ marginBottom: 1 }}/>
                                                                <ErrorMessage name={`FMSCase[againsts][${i}][against_last_name]`} render={this.renderValidationError}/>
                                                            </div>
                                                            <div className="col-12 col-sm-2">
                                                                <label></label>
                                                                <Field type="text" className="form-control border-dark mt-auto" name={`FMSCase[againsts][${i}][against_email]`} id={`FMSCase-againsts-${i}-against_email`} placeholder="Email" style={{ marginBottom: 1 }}/>
                                                                <ErrorMessage name={`FMSCase[againsts][${i}][against_email]`} render={this.renderValidationError}/>
                                                            </div>
                                                            <div className="col-12 col-sm-2">
                                                                <label></label>
                                                                <Field type="text" className="form-control border-dark mt-auto" name={`FMSCase[againsts][${i}][against_phone]`} id={`FMSCase-againsts-${i}-against_phone`} placeholder="Phone" style={{ marginBottom: 1 }} maxlength="10" onKeyPress={ensureOnlyNumericKeypressAllowed}/>
                                                                <ErrorMessage name={`FMSCase[againsts][${i}][against_phone]`} render={this.renderValidationError}/>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                {
                                                    ([2, 3, 5, 6].indexOf(against.against_category) >= 0) && (
                                                        <div className="col-12 col-sm-3">
                                                            <label htmlFor={`FMSCase-againsts-${i}-against_by`}>Against details</label>
                                                            <CustomAsyncSelect
                                                                clearable={false}
                                                                cache={false}
                                                                name={`FMSCase[againsts][${i}][against_by]`} 
                                                                id={`FMSCase-againsts-${i}-against_by`}
                                                                value={against.against_by}
                                                                placeholder={(() => {
                                                                    switch (against.against_category) {
                                                                        case 2:
                                                                            return "Search member"
                                                                        case 3:
                                                                            return "Search participant"
                                                                        case 5:
                                                                            return "Search user/admin"
                                                                        case 6:
                                                                            return "Search organisation"
                                                                        default:
                                                                            return 'Search'
                                                                    }
                                                                })()}
                                                                // TODO: Warning! Debounce this search functionality
                                                                loadOptions={async inputValue => {
                                                                    if (!inputValue) {
                                                                        return Promise.resolve([]);
                                                                    }

                                                                    switch (against.against_category) {
                                                                        case 3:
                                                                            let { data: participantsData } = await participants.search(inputValue);
                                                                            return { options: participantsData.data }
                                                                        case 2:
                                                                            let { data: membersData } = await common.search_members(inputValue);
                                                                            return { options: membersData.data }
                                                                        case 5:
                                                                            let { data: userAdminData } = await common.search_admins(inputValue);
                                                                            return { options: userAdminData.data }
                                                                        case 6:
                                                                            let { data: organisationData } = await common.search_organisations(inputValue) 
                                                                            return { options: organisationData.data }
                                                                        default:
                                                                            console.warn("Could not find appropriate datasource to fetch options with initiator category of " + inputValue);
                                                                            return Promise.resolve([]);
                                                                    }
                                                                }}
                                                                onChange={(newValue) => {
                                                                    if (newValue) {
                                                                        setFieldValue(`FMSCase[againsts][${i}][against_by]`, newValue)
                                                                    }
                                                                }}
                                                            />
                                                            <ErrorMessage name={`FMSCase[againsts][${i}][against_by]`} render={this.renderValidationError}/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                                {
                                    values.FMSCase.locations.map((location, i) => {
                                        return (
                                            <div className="row" key={i}>
                                                <div className="col-12 col-sm-3" style={{minWidth: 344}}>
                                                    <RequiredLabel htmlFor={`FMSCase-locations-${i}-address`}>Event location</RequiredLabel>
                                                    <GooglePlacesAutocomplete 
                                                        name={`FMSCase[locations][${i}][address]`}
                                                        id={`FMSCase-locations-${i}-address`} 
                                                        className="form-control border-dark"
                                                        value={location.address}
                                                        onPlaceSelected={/** @param {google.maps.places.PlaceResult} place */ (place) => {
                                                            const address = {
                                                                state: findAddressComponent(place, "administrative_area_level_1"),
                                                                suburb: findAddressComponent(place, "locality"),
                                                                postcode: findAddressComponent(place, "postal_code"),
                                                                city: findAddressComponent(place, "locality"),
                                                                street_number: findAddressComponent(place, "street_number"),
                                                                route: findAddressComponent(place, "route", true),
                                                                address: document.getElementById(`FMSCase-locations-${i}-address`).value
                                                            }

                                                            const state = (address.state || "").toLowerCase();
                                                            const foundState = (this.props.australianStates.items || []).find(s => s.label.toLowerCase() == state)
                                                            
                                                            setFieldValue(`FMSCase[locations][${i}][state]`, (foundState || {}).value)
                                                            setFieldValue(`FMSCase[locations][${i}][address]`, [address.street_number, address.route].join(" "))
                                                            setFieldValue(`FMSCase[locations][${i}][postal]`, address.postcode)
                                                            setFieldValue(`FMSCase[locations][${i}][suburb]`, address.suburb)
                                                            setFieldValue(`FMSCase[locations][${i}][city]`, address.city)
                                                        }}
                                                        types={['address']}
                                                        componentRestrictions={{country: "au"}}
                                                        onChange={handleChange}
                                                    />
                                                    <ErrorMessage name={`FMSCase[locations][${i}][address]`} render={this.renderValidationError}/>
                                                </div>
                                                <div className="col-12 col-sm-2" style={{minWidth: 230}}>
                                                    <RequiredLabel htmlFor={`FMSCase-locations-${i}-state`}>State</RequiredLabel>
                                                    <CustomSelect
                                                        variant="dark"
                                                        name={`FMSCase[locations][${i}][state]`}
                                                        id={`FMSCase-locations-${i}-state`}
                                                        className="form-control"
                                                        value={location.state}
                                                        options={this.props.australianStates.items}
                                                        clearable={false}
                                                        onChange={(newValue) => newValue && setFieldValue(`FMSCase[locations][${i}][state]`, newValue.value)}
                                                    />
                                                    <ErrorMessage name={`FMSCase[locations][${i}][state]`} render={this.renderValidationError}/>
                                                </div>
                                                <div className="col-12 col-sm-2" style={{minWidth: 230}}>
                                                    <RequiredLabel htmlFor={`FMSCase-locations-${i}-suburb`}>Suburb</RequiredLabel>
                                                    <Field type="text" className="form-control border-dark" name={`FMSCase[locations][${i}][suburb]`} id={`FMSCase-locations-${i}-suburb`}/>
                                                    <ErrorMessage name={`FMSCase[locations][${i}][suburb]`} render={this.renderValidationError}/>
                                                </div>
                                                <div className="col-12 col-sm-1" style={{minWidth: 115}}>
                                                    <RequiredLabel htmlFor={`FMSCase-locations-${i}-postal`} style={{whiteSpace: 'nowrap'}}>Postcode</RequiredLabel>
                                                    <Field type="number" className="form-control border-dark" name={`FMSCase[locations][${i}][postal]`} id={`FMSCase-locations-${i}-postal`}/>
                                                    <ErrorMessage name={`FMSCase[locations][${i}][postal]`} render={this.renderValidationError}/>
                                                </div>
                                                <div className="col-12 col-sm-2">
                                                    <RequiredLabel htmlFor={`FMSCase-locations-${i}-categoryId`} style={{ whiteSpace: 'nowrap' }}>Category</RequiredLabel>
                                                    <CustomSelect
                                                        variant="dark"
                                                        name={`FMSCase[locations][${i}][categoryId]`}
                                                        id={`FMSCase-locations-${i}-categoryId`}
                                                        className="form-control"
                                                        value={location.categoryId}
                                                        options={[
                                                            {value: '', label: 'Select...', selected: true, disabled: true},
                                                            {value: 1, label: 'Own Home'},
                                                            {value: 2, label: 'Family Home'},
                                                            {value: 3, label: "Mum's House"},
                                                            {value: 4, label: "Dad's House"},
                                                            {value: 5, label: "Relative's House"},
                                                            {value: 6, label: "Friend's House"},
                                                            {value: 7, label: "HCM House"}
                                                        ]}
                                                        clearable={false}
                                                        onChange={(newValue) => newValue && setFieldValue(`FMSCase[locations][${i}][categoryId]`, newValue.value)}
                                                    />
                                                    <ErrorMessage name={`FMSCase[locations][${i}][categoryId]`} render={this.renderValidationError}/>
                                                </div>
                                            </div> 
                                        )
                                    })
                                }
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>
                                            <b>Case details</b>
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-5">
                                        <RequiredLabel htmlFor="FMSCase-reason-title">Case reason</RequiredLabel>
                                        <div className="title-description-field border border-dark bg-white w-100" 
                                            style={{
                                                borderRadius: 8,
                                                padding: 16,
                                            }}
                                        >
                                            <div style={{ marginBottom: 5 }}>
                                                <Field 
                                                    id="FMSCase-reason-title"
                                                    name="FMSCase[reason][title]"
                                                    placeholder="Please type the Case title here"
                                                    className="form-control w-100"
                                                    type="text" 
                                                    style={{
                                                        fontSize: 14,
                                                        border: "none",
                                                        borderRadius: 0,
                                                        borderBottom: "1px solid #cacaca",
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <Field 
                                                    component="textarea"
                                                    id="FMSCase-reason-description"
                                                    name="FMSCase[reason][description]"
                                                    className="form-control w-100"
                                                    placeholder="A short description of the case goes here..."
                                                    rows={3}
                                                    style={{
                                                        border: "none",
                                                        borderRadius: 0,
                                                        fontSize: 15,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <ErrorMessage name={`FMSCase[reason][title]`} render={this.renderValidationError}/><br/>
                                        <ErrorMessage name={`FMSCase[reason][description]`} render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-sm-5">
                                        <label htmlFor="FMSCase-note-title">Case notes</label>
                                        <div className="title-description-field border border-dark bg-white w-100" 
                                            style={{
                                                borderRadius: 8,
                                                padding: 16,
                                            }}
                                        >
                                            <div style={{ marginBottom: 5 }}>
                                                <Field 
                                                    id="FMSCase-note-title"
                                                    name="FMSCase[note][title]"
                                                    placeholder="Please type the Case title here"
                                                    className="form-control w-100"
                                                    type="text" 
                                                    style={{
                                                        fontSize: 14,
                                                        border: "none",
                                                        borderRadius: 0,
                                                        borderBottom: "1px solid #cacaca",
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <Field 
                                                    component="textarea"
                                                    id="FMSCase-note-description"
                                                    name="FMSCase[note][description]"
                                                    className="form-control w-100"
                                                    placeholder="A short description of the case goes here..."
                                                    rows={3}
                                                    style={{
                                                        border: "none",
                                                        borderRadius: 0,
                                                        fontSize: 15,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <ErrorMessage name={`FMSCase[note][title]`} render={this.renderValidationError}/>
                                        <ErrorMessage name={`FMSCase[note][description]`} render={this.renderValidationError}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-10 text-right">
                                        <Link to="/fms" className="btn border-dark">Cancel FMS case</Link>
                                        &nbsp;
                                        <button type="submit" className="btn btn-dark" disabled={isSubmitting}>
                                            {isSubmitting ? `Submitting...` : 'Submit FMS Case'}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </Form>
                    )
                }}
            </Formik>
        )
    }




    render() {
        const { entityType } = this.props

        return (
            <div className="container-fluid">
                <div className="content-block">
                    {this.renderBreadcrumbs()}
                </div>
                <div className="content-block">
                    {entityType === 'SITE' ? this.renderSiteSummary() : this.renderOrgSummary()}
                </div>
                <div className="content-block">
                    {this.renderEntryHeader()}
                </div>
                <div className="content-block">
                    {this.renderForm()}
                </div>
            </div>
        )
    }
}

/**
 * 
 * @param {import('../../reducers').RootState} state 
 * @param {import('react-router-dom').RouteComponentProps} ownProps 
 */
const mapStateToProps = (state, ownProps) => {
    const { auth, orgDetails, suborgs, sites } = state
    const {token} = auth || {}
    const { type: entityType } = state.entityTypes
    const { params } = ownProps.match

    let claims = null;
    if (token) {
        claims = jwt.decode(token)
    }

    return {
        entityType,
        orgDetails,
        suborgs,
        sites,
        organisationName: (claims || {})['name'],
        currentSubOrg: entityType === 'SUBORG' ? (suborgs.items || []).find(s => s.id == params.id) : null,
        currentSite: entityType === 'SITE' ? (sites.items || []).find(s => s.site_id == params.id) : null,
        australianStates: {
            items: [ 
                state.australianStates.loading ? 
                    { label: "Loading...", value: "", selected: true, disabled: false } : 
                    { label: "Select...", value: "", selected: true, disabled: false },
                ...state.australianStates.items
            ],
            loading: state.australianStates.loading
        }
    }
}


export default connect(mapStateToProps)(CreatePage)