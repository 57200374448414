const initialState = {
    isVerifyingPasswordResetToken: false,
    isResettingPassword: false
}

export function passwordReset(state = initialState, action = {}) {
    switch (action.type) {
        
        case "RESET_PASSWORD_REQUESTED":
            return { 
                ...state, 
                isResettingPassword: true 
            }
        case "RESET_PASSWORD_SUCCESS":
            return { 
                ...state, 
                isResettingPassword: false 
            }
        case "RESET_PASSWORD_FAILED":
            return { 
                ...state, 
                isResettingPassword: false 
            }


        case "VERIFY_PASSWORD_RESET_TOKEN_REQUESTED":
            return {
                ...state,
                isVerifyingPasswordResetToken: true,
                passwordResetTokenErrors: []
            }
        case "VERIFY_PASSWORD_RESET_TOKEN_SUCCESS":
            return {
                ...state,
                isVerifyingPasswordResetToken: false,
                passwordResetTokenErrors: []
            }
        case "VERIFY_PASSWORD_RESET_TOKEN_FAILURE":
            return {
                ...state,
                isVerifyingPasswordResetToken: false,
                passwordResetTokenErrors: action.payload.errors
            }
        default:
            return state
    }
}