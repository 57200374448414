import React from "react"
import Select from "react-select-plus"
import 'react-select-plus/dist/react-select-plus.css';

// styling for CustomSelect and CustomAsyncSelect moved into layouts/general.scss

/**
 * @typedef {{ options: {label: string, options: import('react-select').Options }[]} OptionGroup
 */

/** 
 * Custom-styled select dropdown. Uses `react-select-plus` to add support for option groups.
 * 
 * @param {import("react-select").ReactSelectProps & { options: OptionGroup[], variant?: 'dark' | 'light' }} props 
 */
export const CustomSelect = props => <Select 
    {...props}
    className={['custom-select', props.className, props.variant ? `custom-select-${props.variant}`: false].filter(c => !!c).join(' ')} 
/>

/** 
 * Custom-styled select dropdown. Uses `react-select-plus` to add support for option groups.
 * Use this if options are coming from remote source
 * 
 * @param {import("react-select").ReactAsyncSelectProps & { options: OptionGroup[], variant?: 'dark' | 'light' }} props 
 */
export const CustomAsyncSelect = props => <Select.Async 
    {...props}
    className={['custom-select', props.className, props.variant ? `custom-select-${props.variant}`: false].filter(c => !!c).join(' ')} 
/>

export default CustomSelect