import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap'
import CustomSelect from "./CustomSelect"

/**
 * @typedef {{show: boolean, value?: import('react-select').OptionValues, onAdd?: (selectedOption: import('react-select').Option) => void, onCancel?: () => void}} AddSitesModalProps
 * @typedef {AddSitesModalProps & ReturnType<typeof mapStateToProps> & import('react-redux').DispatchProp} Props
 * 
 * @param {Props} props 
 */
const AddSitesModal = (props) => {
    const { value = null, show = false, onAdd = () => {}, onCancel = () => {} } = props

    const [selectedOption, setSelectedOption] = useState(value);
    const { sites } = props
    const { items, loading } = sites
    const options = (items || []).map(s => ({
        label: s['title'],
        value: s['site_id'],
    }))

    return (
        <Modal show={show} onHide={() => onCancel()}>
            <Modal.Header closeButton>
                <Modal.Title>Add sites</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomSelect
                    onChange={(newValue) => setSelectedOption(newValue)}
                    value={selectedOption}
                    cache={false}
                    clearable={true}
                    isLoading={loading}
                    disabled={loading}
                    options={options}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onAdd(selectedOption)} disabled={!selectedOption}>
                    Add
                </Button>
                <Button variant="secondary" onClick={() => onCancel()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { sites } = state

    return {
        sites
    }
}


export default connect(mapStateToProps)(AddSitesModal)