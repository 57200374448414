import React, { useState, useEffect } from 'react'
import BlockUi from 'react-block-ui'
import { css } from '../helpers/common'
import { axios } from '../axios'



/**
 * Component to render FMS case details. 
 * 
 * Used exclusively by `fms/IndexPage.jsx`
 * 
 * @param {{[key:string]: any, original: {[key: string]: any, id: number|string}}} props 
 */
const FMSCaseDetails = props => {
    const { original } = props
    const { details: fmsDetails } = original

    const styles = css({
        root: {

        },
        table: {
            maxWidth: 1074,
        },
        cellLabel: {
            width: 300,
        },
        cellValue: {
            borderBottom: "1px solid black", 
            borderTop: "none"
        },
        historyLog: {
            fontSize: 20, 
            color: "#006166"
        },
        mapMarker: {
            fontSize: 20, 
            color: "#006166"
        }
    })

    /** @type {{label: string, value: React.ReactChild, icon?: React.ReactChild }[]} */
    const details = [
        { 
            label: 'FMS Against', 
            value: fmsDetails.first_againsts_name 
        },
        { 
            label: 'Initiator', 
            value: fmsDetails.initiated_by_name
        },
        { 
            label: '', 
            value: fmsDetails.initiated_by_email, 
            icon: <span className="icon icon-pending-icons" style={styles.historyLog} title="History log"></span> 
        },
        { 
            label: '', 
            value: fmsDetails.initiated_by_phone, 
            icon: <span className="icon icon-pending-icons" style={styles.historyLog} title="History log"></span> 
        },
        {
            label: 'Location',
            value: fmsDetails.location,
            icon: <span className="icon icon-pending-icons" style={styles.mapMarker} title="Address"></span> 
        },
        {
            label: 'Case Details',
            value: fmsDetails.reason_title || <>&nbsp;</>,
        }
    ]

    return (
        <div className="row fms-details" style={styles.root}>
            <table className="table" style={styles.table}>
                <tbody>
                    {
                        details.map((detail, i) => (
                            <tr key={i}>
                                <td className="border-0 text-right" style={styles.cellLabel}>
                                    <b>{detail.label}</b>
                                </td>
                                <td className="border-0">
                                    <div className="w-100 d-flex justify-content-between" style={styles.cellValue}>
                                        {
                                            detail.value ? <span className="text-left">{detail.value}</span> : <span>&nbsp;</span>
                                        }
                                        {
                                            detail.icon && (detail.icon)
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default FMSCaseDetails