import React from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { determineEntityType } from "../helpers/common";

import DefaultSubOrgLogo from '../images/Org-icon-trimmed.png'

/**
 * @typedef {{}} OrgInfoSummaryProps
 * @typedef {OrgInfoSummaryProps & ReturnType<typeof mapStateToProps> & import('react-redux').DispatchProp} Props
 */


/**
 * Component to display summary information of the organization
 * 
 * @extends {React.Component<Props, {}>}
 */
class OrgInfoSummary extends React.Component {
    constructor(props) {
        super(props);
    }


    /**
     * Display parent org details
     */
    renderOrgInfo() {
        const { value: orgDetail } = this.props.orgDetails
        const { basic_detail, org_contacts } = orgDetail || {}
        const { ocs_id, primary_address, logo_file, status } = basic_detail || {}

        const supportCoordinator = (org_contacts || []).find(c => (c.contact_type || {}).value === '3')
        const { first_name, last_name } = supportCoordinator || {}
        const logosrc = process.env.REACT_APP_ADMIN_URL + logo_file

        return (
            <div className="d-flex org-summary flex-row">
                {
                    logo_file && (
                        <div className="org-logo">
                            <img src={logosrc.replace('\\', '/')} alt="" width="100" height="100" />
                        </div>
                    )
                }
                <div className="org-info">
                    <ul>
                        <li>
                            <span className="font-weight-bold">OCS ID:</span>&nbsp;{ocs_id}
                        </li>
                        <li>
                            <span className="font-weight-bold">Address:</span>&nbsp;{primary_address}
                        </li>
                        <li>
                            <span className="font-weight-bold">Key contact:</span>&nbsp;{[first_name, last_name].filter(f => f).join(' ')}
                        </li>
                        <li>
                            <span className="font-weight-bold">Status:</span>&nbsp;{status === "1" ? 'Active' : 'Inactive'}
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    /**
     * Find state by id
     * 
     * @param {number} id 
     */
    determineAusStateById(id) {
        const { items: states } = this.props.australianStates
        const foundStateOption = (states || []).find(s => s.value == id) || {}
        const state = foundStateOption.label
        return state
    }


    /**
     * Determine suborg's addresses by selecting the first primary address and joining street, city, postal and state
     * @param {object} currentSubOrg 
     */
    determineSuborgAddress(currentSubOrg = {}) {
        const primaryAddresses = (currentSubOrg.addresses || []).filter(a => a.primary_address)
        const firstPrimaryAddress = primaryAddresses.find(a => a) || {}

        const { street, city, postal, state } = firstPrimaryAddress
        const ausState = this.determineAusStateById(state)
        const address = [street, city, postal, ausState].filter(k => k).join(' ')
        return address
    }
    
    /**
     * Determine support coordinator based on the full name of the first key contact of the given suborg
     * @param {object} currentSubOrg 
     */
    determineSuborgSupportCoordinator(currentSubOrg = {}) {
        const key_contacts = currentSubOrg.key_contacts || []
        const { name, lastname } = key_contacts.find(k => k) || {}

        const supportCoordinator = [name, lastname].filter(n => n).join(' ')
        return supportCoordinator
    }

    /**
     * Determines what logo to display for suborg
     * @param {object} currentSubOrg 
     */
    determineSuborgLogoSrc(currentSubOrg = {}) {
        const { logo_file } = currentSubOrg

        return DefaultSubOrgLogo
    }


    /**
     * Display sub org details
     */
    renderSuborgInfo() {
        const { currentSubOrg } = this.props
        const { id, status } = currentSubOrg || {}

        const address = this.determineSuborgAddress(currentSubOrg)
        const supportCoordinator = this.determineSuborgSupportCoordinator(currentSubOrg)
        const suborgLogo = this.determineSuborgLogoSrc(currentSubOrg)
        const curentStatus = status == '1' ? 'Active' : 'Inactive'

        return (
            <div className="d-flex org-summary flex-row">
                <div className="org-logo" style={{ width: 100, height: 100 }}>
                    <img src={suborgLogo} alt="Logo" width="100" height="100" />
                </div>
                <div className="org-info">
                    <ul>
                        <li>
                            <span className="font-weight-bold">OCS ID:</span>&nbsp;{id}
                        </li>
                        <li>
                            <span className="font-weight-bold">Address:</span>&nbsp; {address}
                        </li>
                        <li>
                            <span className="font-weight-bold">Key contact:</span>&nbsp; {supportCoordinator}
                        </li>
                        <li>
                            <span className="font-weight-bold">Status:</span>&nbsp; {curentStatus}
                        </li>
                    </ul>
                </div>
            </div>
        )
    }



    render() {
        const { entityType } = this.props

        if (entityType === 'SUBORG') {
            return this.renderSuborgInfo()
        }

        return this.renderOrgInfo()
    }
}


const mapStateToProps = (state, ownProps) => {
    const { orgDetails, australianStates } = state
    const { match } = ownProps
    const { path, params } = match

    const entityType = determineEntityType(path)
    const currentSubOrg = entityType === 'SUBORG' ? (state.suborgs.items || []).find(s => s.id == params.id) : null

    return {
        orgDetails,
        entityType,
        currentSubOrg,
        australianStates
    }
}


export default withRouter(connect(mapStateToProps)(OrgInfoSummary))

