import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
// import $ from 'jquery';

/**
 * Component to render secondary phone/email
 */
class TextInputContactComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: ''
        }

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: "text-danger form-error"
        });
    }

    handleTextInputChange(event, property) {
        this.setState({
            [property]: event.target.value
        });
    }

    static getDerivedStateFromProps(newProps, oldState) {
        var newValue = newProps.value;
        var obj = oldState;

        if(newValue !== oldState.value) {
            obj.value = newValue;
        }

        return obj;
    }
    
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {
        const serial = this.props.serial;
        const type = this.props.type;
        const label = this.capitalizeFirstLetter(type);
        const isPrimary = this.props.isPrimary;
        const propertyNameInParentState = `${type}_${isPrimary ? 'primary' : 'secondary_'}${isPrimary ? '' : serial}`;
        const elementName = `${isPrimary ? 'primary-' : 'secondary-'}${type}${isPrimary ? '' : '-'+serial}`;
        
        var actionSpan = null;

        if(isPrimary && this.props.countSecondaryContact <= 1) {
            actionSpan =                     
                <span 
                    className="icon-plus"
                    onClick={ event => { this.props.displaySecondaryContactHandler(type); }}
                >
                </span>
        } else if(!isPrimary) {
            actionSpan =                     
                <span 
                    className="icon-minus"
                    onClick={ event => {
                        this.props.handleTextInputChange(event, propertyNameInParentState, null); }}
                >
                </span>
        }
        return (
            <>
                <div className="d-flex input has-icon align-items-center border-dark">
                    <input
                        className="form-control"
                        name={elementName}
                        type="text"
                        value={this.props.value || ""}
                        onChange={event => { this.props.handleTextInputChange(event, propertyNameInParentState)} }
                    />
                    {actionSpan}
                </div>

                {/* validation of field goes here */}
                {this.props.validator && this.props.validator.message(elementName, this.props.value, this.props.rules)}
            </>
        );
    }
}

export default TextInputContactComponent;



