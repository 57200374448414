import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { axios } from "../axios"; 

class BasicUserResponseModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const modalId = this.props.modalId || 'confirmContactDelete';
        const title = this.props.title || 'Confirmation';
        const bodyText = this.props.bodyText || `Are you sure you want to archive this item?
            Once archived, this action can not be undone.`;
        return (
            <Modal
                show={this.props.showModal}
                id={modalId}
                centered>
                    <Modal.Header>
                        <div className="d-flex align-items-center">
                            <Modal.Title>{title}</Modal.Title>
                            <span className="icon icon-cross-icons" onClick={this.props.closeModalHandler}></span>                        
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{bodyText}</p>
                        <div className="d-flex justify-content-between">
                            <button className="custom-confirm" onClick={this.props.confirmActionHandler}>Confirm</button>
                            <button className="custom-cancel" onClick={this.props.closeModalHandler}>Cancel</button>
                        </div>
                    </Modal.Body>
            </Modal>
        )
    }
}

export default BasicUserResponseModal;