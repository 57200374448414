const initialState = {
    items: []
};

export function alerts(state = initialState, action) {
    switch (action.type) {
        case "ALERTS_ADD":
            return {
                ...state,
                items: state.items.concat(action.payload)
            }
        case "ALERTS_DISMISS":
            return {
                ...state,
                items: state.items.filter(item => action.payload !== item)
            }
        case "ALERTS_DISMISS_ALL":
            return {
                ...state,
                items: []
            }
        default:
            return state
    }
}