import { axios } from "../axios"
import qs from 'qs'

const URLS = {
    ORG: '/fms',
    SUBORG: '/suborgs/fms/:id',
    SITE: '/site/fms/:id',
}

/**
 * Fetch FMS based on given id and entity types.
 * 
 * If fetching as `ORG` you can supply a `null` ID. If that's the case, 
 * the ID will be determned from server-side. 
 * Otherwise you must provide the `id` param.
 * 
 * @param {number|null} id 
 * @param {keyof URLS} entity 
 * @param {Record<string, any>} params 
 */
const fetchFMS = async (id, entity, params) => {
    const url = URLS[entity]
    const urlWithID = url.replace(':id', id)

    return axios.get(`${urlWithID}?${qs.stringify(params)}`); 
}

export default {
    fetchFMS
}