import { createReducerSlice } from "../helpers/createReducer"

/**
 * @typedef { typeof slice.ActionTypes } ActionTypes
 * @typedef { typeof initialState } State
 */
const initialState = {
    loading: false,
    errors: [],
    fetched_at: null,
    value: null,
}

const slice = createReducerSlice(initialState, {
    'FETCH_ORG_DETAILS_LOADING': (state, action) => ({
        ...state,
        loading: true
    }),
    'FETCH_ORG_DETAILS_SUCCESS': (state, action) => ({
        ...state,
        loading: false,
        value: (action.response.data['data'] || []),
        fetched_at: Date.now()
    }),
    'FETCH_ORG_DETAILS_FAILED': (state, action) => ({
        ...state,
        loading: false,
        fetched_at: null,
        errors: [action.error]
    })
})

export const orgDetails = slice.reducer