import React from "react"
import LoginHeader from "../components/LoginHeader"
import LoginFooter from "../components/LoginFooter"
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom'
import { history } from "../history"
import doctitles from "../helpers/doctitles"
import { DEFAULT_DOCUMENT_TITLE } from "../config"

/**
 * @typedef {{className: string}} LoginLayoutProps
 * @typedef {LoginLayoutProps & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 * 
 * 
 * @extends {React.Component<Props, {}>}
 */
class LoginLayout extends React.Component {
    static defaultProps = {
        className: '',
    }

    /**
     * Updates the document title when one of the authentication related pages was loaded
     */
    componentDidMount() {
        const { path } = this.props.match
        document.title = this.determineDocumentTitle(path)

        this.unlisten = history.listen(this.onRouteChange)
    }

    /**
     * Cleanup no-longer-needed event handlers (esp. the registered onRouteChange handler)
     */
    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten()
        }

    }

    /**
     * Handler to update doc title on route change
     */
    onRouteChange = (location) => {
        const { path } = this.props.match
        document.title = this.determineDocumentTitle(path)
    }

    /**
     * Finds document title by given key (usually this is retrieved from `props.match.path`) 
     * or else fallback to default doc title w/c is `Org portal` (see `src/config.js`)
     * 
     * @param {number|null|undefined} k 
     */
    determineDocumentTitle(k) {
        if (k in doctitles) {
            return doctitles[k]
        }

        return DEFAULT_DOCUMENT_TITLE
    }



    render() {
        const {className, children} = this.props
        
        return (
            <>
                <div className={`site-wrapper page ${className}`}>
                    <LoginHeader />
                    <div className="site-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoginFooter />
                </div>
            </>
        )
    }
}

export default withRouter(connect()(LoginLayout))