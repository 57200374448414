import createReducer from '../helpers/createReducer'

/**
 * @typedef {keyof types} ActionTypes
 * @typedef {typeof initialState} State
 */

const initialState = {
    loading: false,
    items: [],
    errors: [],
    fetched_at: null
}

export const types = {
    FETCH_SITES_LOADING: 'FETCH_SITES_LOADING',
    FETCH_SITES_SUCCESS: 'FETCH_SITES_SUCCESS',
    FETCH_SITES_FAILED: 'FETCH_SITES_FAILED',
}

export const sites = createReducer(initialState, {
    [types.FETCH_SITES_LOADING]: (state, action) => ({
        ...state,
        loading: true,
    }),
    [types.FETCH_SITES_SUCCESS]: (state, action) => ({
        ...state,
        loading: false,
        items: (action.response.data['data'] || []),
        fetched_at: Date.now(),
        errors: [],
    }),
    [types.FETCH_SITES_FAILED]: (state, action) => ({
        ...state,
        loading: false,
        fetched_at: null,
        errors: ["Could not fetch sites"]
    })
})
