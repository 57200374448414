import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import SimpleReactValidator from 'simple-react-validator';
import jwt from "jsonwebtoken"
import objectToFormData from "object-to-formdata";
import GooglePlacesAutocomplete from 'react-google-autocomplete'
import { Breadcrumb } from 'react-bootstrap'

import { CustomSelect } from "../../components/CustomSelect"
import CustomCheckbox from '../../components/CustomCheckbox';
import { AddPrimarySecondaryContact } from '../../components/AddPrimarySecondaryContact'
import { findAddressComponent } from '../../helpers/google-places'
import OrgInfoSummary from '../../components/OrgInfoSummary'
import { axios } from '../../axios'
import {history} from "../../history"
import { fetchSites } from '../../actions/commonActions'

import 'react-select-plus/dist/react-select-plus.css';
import "../../stylesheets/pages/sites/CreatePage.scss"
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs';

/** 
 * Adds red star to labels
 * 
 * @param {React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>} props 
 */
const RequiredLabel = (props) => (
    <label htmlFor={props.htmlFor}>{props.children} <span className="required">*</span></label>
)


/**
 * @typedef {ReturnType<typeof mapStateToProps> & import('react-router-dom').RouteComponentProps} Props
 * @extends {React.Component<Props>}
 */
class CreatePage extends React.Component {
	
	state = {
		_debug: false,
		_loading: false,
		_loadingRequirements: false,
		_displayAttachToParentOrgModal: false,
		_displayAddSitesModal: false,
		states: [
			{value: "", label: "Loading...", selected: true, disabled: true},
		],
		whoToBillOptions: [
            {value: "", label: "Select", selected: true, disabled: true},
            {value: '0', label: "No", title: 'You will have to provide your own billing contact details'},
            {value: '1', label: "Yes", title: 'The billing contact of the parent organisation will be billed instead'},
        ],
		org_requirements: [],
		selectedLogo: null,
		orgDetail: null,
	}

	constructor(props) {
		super(props);

		this.validator = new SimpleReactValidator({
			validators: {
				valid_phone: {
					message: "Phone numbers should be 8-10 digits",
					rule: (val, params, validator) => {
						return val.length >= 8 && val.length <= 10
					},
				},
				abn_min: {
					message: "Must be exactly 11 digits",
					rule: (val, params, validator) => {
						return val.length == 11
					},
				}
			},
			messages: {
				required: 'This field is required',
				phone: "Not a valid phone number",
				email: "Not a valid email address",
				min: "Must be at least 8 digits",
			},
			element: (message, className) => {
				return (
					<div className={`tooltip fade top in`} role="tooltip" style={{transform: "translateY(-100%)"}}>
						<div className="tooltip-arrow" style={{left: "50%"}}></div>
						<div className="tooltip-inner">
							{message}
						</div>
					</div>
				)
			}
		})
	}

	/**
	 * Fetch states, and org details when this page (or component) is loaded
	 */
	async componentDidMount() {
		this.setState({ _loadingRequirements: true })

		const getStateResponse = await axios.get("/common/get_state");
		const { data: getStateResponseData } = getStateResponse.data
		
		this.setState({
			_loadingRequirements: false,
			states: getStateResponseData
		})
	}

	/**
     * Displays customized validation error message
     * 
     * @param {string} errorMessage
     */
    renderValidationError = errorMessage => {
        return (
            <span className="text-danger">
                <small>{errorMessage}</small>
            </span>
        )
    }


    /** 
     * Runs when the form has been submitted.
     * Will call `POST: suborgs/create` (Note: endpoint does not have dash)
     * 
     * @type {(values: any, formikHelpers: import('formik').FormikHelpers<any>) => void)} 
     */
    handleOnSubmit = async (values, formikHelpers) => {

		// do not include empty secondary phones and emails in submission 
		let newValues = {
			...values,
			SiteDetails: {
				...values.SiteDetails,
				phones: values.SiteDetails.phones.filter(p => p.is_primary || (!p.is_primary && p.value)),
				emails: values.SiteDetails.emails.filter(e => e.is_primary || (!e.is_primary && e.value))
			},
			KeyContactDetails: {
				...values.KeyContactDetails,
				phones: values.KeyContactDetails.phones.filter(p => p.is_primary || (!p.is_primary && p.value)),
				emails: values.KeyContactDetails.emails.filter(e => e.is_primary || (!e.is_primary && e.value))
			},
			BillingContactDetails: {
				...values.BillingContactDetails,
				phones: values.BillingContactDetails.phones.filter(p => p.is_primary || (!p.is_primary && p.value)),
				emails: values.BillingContactDetails.emails.filter(e => e.is_primary || (!e.is_primary && e.value))
			},
		}

		// Before submitting, copy values from key to billing contact 
		// if "Billing contact same as Key contact" is checked
		if (this.isTruthy(values.BillingContactDetails.is_billing_same_as_key_contact)) {
			newValues = {
				...newValues,
				BillingContactDetails: {
					...newValues.BillingContactDetails,
					...({
						first_name: newValues.KeyContactDetails.first_name,
						last_name: newValues.KeyContactDetails.last_name,
						position: newValues.KeyContactDetails.position,
						department: newValues.KeyContactDetails.department,
						phones: [...newValues.KeyContactDetails.phones],
						emails: [...newValues.KeyContactDetails.emails],
					})
				}
			}
		}

		// do_you_want_to_bill_parent_org will always clone the details of parent org to 
		// billing details, even if is_billing_same_as_key_contact is checked
		if (this.isTruthy(values.BillingContactDetails.do_you_want_to_bill_parent_org)) {
			const { orgDetails } = this.props
			const { value: orgDetail } = orgDetails
			const parentOrgBillingContactDetails = determineParentOrgBillingContactDetails(orgDetail)
			
            newValues = {
				...values,
				// Note: GST and Payroll tax is under the 'BillingContactDetails' prop when creating suborg
				SiteDetails: {
					...values.SiteDetails,
					gst: parentOrgBillingContactDetails.gst,
					pay_roll_tax: parentOrgBillingContactDetails.pay_roll_tax,
				},
                BillingContactDetails: {
                    ...values.BillingContactDetails,
                    ...parentOrgBillingContactDetails,
                }
            }
		}

		formikHelpers.setSubmitting(true);

		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate()
			return;
		}

		// server side validation
		try {
			await axios.post("/site/create", objectToFormData({data:JSON.stringify(newValues, null, 4)}));
			
			// update list of sites on success
			const { dispatch } = this.props
			dispatch(fetchSites())
		} catch(e) {
			const {errors} = e.response.data;

			for (let field in errors) {
				formikHelpers.setFieldError(field, errors[field])
			}
		}
	}

	isTruthy(value) {
        return [1, '1', 'true', true].indexOf(value) >= 0
    }

	/**
	 * Render breadcrumbs
	 */
	renderBreadcrumbs() {
		return <CustomBreadcrumbs />
	}

	/**
	 * Renders organisation information
	 */
	renderOrgSummary() {
		return (
			<div className="site-information">
				<OrgInfoSummary />
			</div>
		)
	}

	renderForm() {
		const { states, whoToBillOptions } = this.state

		// NOTE: all validation happens on server-side
		return (
			<Formik
				initialValues={{
					SiteDetails: {
						title: '',
						phones: [
							{ value: "", is_primary: true, required: true }
						],
						emails: [
							{ value: "", is_primary: true, required: true }
						],
						abn: '',
						site_address: '',
						city: '', // aka suburb
						state: '',
						postcode: '',
						pay_roll_tax: '',
						gst: ''
					},
					KeyContactDetails: {
						first_name: '',
						last_name: '',
						position: '',
						department: '',
						phones: [
							{ value: "", is_primary: true, required: true }
						],
						emails: [
							{ value: "", is_primary: true, required: true }
						],
					},
					BillingContactDetails: {
						do_you_want_to_bill_parent_org: false,
						is_billing_same_as_key_contact: false,
						first_name: '',
						last_name: '',
						position: '',
						department: '',
						phones: [
							{ value: "", is_primary: true, required: true }
						],
						emails: [
							{ value: "", is_primary: true, required: true }
						]
					}
				}}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={this.handleOnSubmit}
				enableReinitialize={true}
				>
				{({values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, initialValues}) => {
	
				const { orgDetails } = this.props
				const { value: orgDetail } = orgDetails || {}
				const do_you_want_to_bill_parent_org = this.isTruthy(values.BillingContactDetails.do_you_want_to_bill_parent_org)
				const is_billing_same_as_key_contact = this.isTruthy(values.BillingContactDetails.is_billing_same_as_key_contact)
				
				return (
					<Form encType="multipart/form-data" method="POST" id="addsite">
						<div className="entry-header">
							<h2><b>Create New Site</b></h2>
						</div>

						<div className="row mt-5 mx-0">
							<h3 className="color">Site Details</h3>
						</div>

						<div className="row" >
							<div className="col-md-6">
								<RequiredLabel htmlFor="title">Title: </RequiredLabel>
								{this.validator.message("SiteDetails[title]", values.SiteDetails.title, 'required')}
								<Field name="SiteDetails[title]" id="SiteDetails-title" className="form-control border-dark" required/>
								<ErrorMessage name="SiteDetails[title]" render={this.renderValidationError}/>
							</div>

							<div className="col-12 col-md-3">
								<AddPrimarySecondaryContact
									type="tel"
									required placeholder="Can Include Area Code" 
									data-rule-notequaltogroup='[".distinctPh"]' 
									data-rule-phonenumber 
									data-msg-notequaltogroup="Please enter a unique contact number" 
									errors={errors}
									label='Phone'
									values={values.SiteDetails.phones}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
									renderValidationError={this.renderValidationError}
									idPrefix="SiteDetails-phones"
									namePrefix="SiteDetails[phones]"
									renderBeforeInput={({ value, name, isPrimary }) => {
										if (isPrimary) {
											return this.validator.message(name, value, 'required|phone|valid_phone')
										}

										return this.validator.message(name, value, 'phone', {
											messages: {
												phone: "Not a valid secondary phone"
											}
										})
										
									}}
								/>
							</div>

							<div className="col-12 col-md-3">
								<AddPrimarySecondaryContact
									type="email"
									errors={errors}
									label='Email'
									values={values.SiteDetails.emails}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
									renderValidationError={this.renderValidationError}
									idPrefix="SiteDetails-emails"
									namePrefix="SiteDetails[emails]"
									renderBeforeInput={({ value, name, isPrimary }) => {
										if (isPrimary) {
											return this.validator.message(name, value, 'required|email')
										}
										
										return this.validator.message(name, value, 'email', {
											messages: {
												email: "Not a valid secondary email"
											}
										})
									}}
								/>
							</div>
						</div>

						<div className="row mt-3">
							<div className="col-12 col-md-4">
								<RequiredLabel htmlFor="SiteDetails-site_address">Site Address:</RequiredLabel>
								{this.validator.message('SiteDetails[site_address]', values.SiteDetails.site_address, 'required')}
								<GooglePlacesAutocomplete required 
									name="SiteDetails[site_address]"
									id="SiteDetails-site_address" 
									className="form-control border-dark"
									value={values.SiteDetails.site_address}
									onPlaceSelected={/** @param {google.maps.places.PlaceResult} place */ (place) => {
										const address = {
											state: findAddressComponent(place, "administrative_area_level_1"),
											postcode: findAddressComponent(place, "postal_code"),
											city: findAddressComponent(place, "locality"),
											street_number: findAddressComponent(place, "street_number"),
											route: findAddressComponent(place, "route", true),
											address: document.getElementById("SiteDetails-site_address").value
										}

										const state = (address.state || "").toLowerCase();
										const foundState = (this.state.states || []).find(s => s.label.toLowerCase() == state)
										
										setFieldValue("SiteDetails[state]", (foundState || {}).value)
										setFieldValue("SiteDetails[site_address]", [address.street_number, address.route].join(" "))
										setFieldValue("SiteDetails[postcode]", address.postcode)
										setFieldValue("SiteDetails[city]", address.city)
									}}
									types={['address']}
									componentRestrictions={{country: "au"}}
									onChange={handleChange}
								/>
								<ErrorMessage name="SiteDetails[site_address]" render={this.renderValidationError}/>
							</div>
							<div className="col-md-2">
								<RequiredLabel htmlFor="SiteDetails-state">State</RequiredLabel>
								{this.validator.message('SiteDetails[state]', values.SiteDetails.state, 'required')}
								<CustomSelect required 
									variant="light"
									name="SiteDetails[state]"
									id="SiteDetails-state"
									className="form-control"
									value={values.SiteDetails.state}
									options={states}
									clearable={false}
									onChange={(newValue) => newValue && setFieldValue("SiteDetails[state]", newValue.value)}
								/>
								<ErrorMessage name="SiteDetails[state]" render={this.renderValidationError}/>
							</div>
							
							<div className="col-12 col-md-2">
								<RequiredLabel htmlFor="SiteDetails-city">Suburb:</RequiredLabel>
								{this.validator.message('SiteDetails[city]', values.SiteDetails.city, 'required')}
								<Field required name="SiteDetails[city]" id="SiteDetails-city" className="form-control border-dark"/>
								<ErrorMessage name="SiteDetails[city]" render={this.renderValidationError}/>
							</div>

							<div className="col-12 col-md-2">
								<RequiredLabel htmlFor="SiteDetails-postcode">Postcode</RequiredLabel>
								{this.validator.message('SiteDetails[postcode]', values.SiteDetails.postcode, 'required')}
								<Field name="SiteDetails[postcode]" id="SiteDetails-postcode" className="form-control border-dark" required data-rule-number="true" minLength="4" maxLength="4" data-rule-postcodecheck="true"/>
								<ErrorMessage name="SiteDetails[postcode]" render={this.renderValidationError}/>
							</div>
						
							<div className="col-md-2">
								<RequiredLabel htmlFor="SiteDetails-abn">ABN:</RequiredLabel>
								{this.validator.message('SiteDetails[abn]', values.SiteDetails.abn, 'required|abn_min')}
								<Field name="SiteDetails[abn]" id="SiteDetails-abn" className="form-control border-dark"/>
								<ErrorMessage name="SiteDetails[abn]" render={this.renderValidationError}/>
							</div>
						</div>
					
						<div className="row mt-5 mx-0">
							<h3 className="color">Key Contact Details</h3>
						</div>

						<div className="row">
							<div className="col-12 col-md-3">
								<RequiredLabel htmlFor="KeyContactDetails-first_name">First name</RequiredLabel>
								{this.validator.message('KeyContactDetails[first_name]', values.KeyContactDetails.first_name, 'required')}
								<Field name="KeyContactDetails[first_name]" id="KeyContactDetails-first_name" className="form-control border-dark" required/>
								<ErrorMessage name="KeyContactDetails[first_name]" render={this.renderValidationError}/>
							</div>
							<div className="col-12 col-md-3">
								<RequiredLabel htmlFor="KeyContactDetails-last_name">Last name</RequiredLabel>
								{this.validator.message('KeyContactDetails[last_name]', values.KeyContactDetails.last_name, 'required')}
								<Field name="KeyContactDetails[last_name]" id="KeyContactDetails-last_name" className="form-control border-dark" required/>
								<ErrorMessage name="KeyContactDetails[last_name]" render={this.renderValidationError}/>
							</div>
							<div className="col-12 col-md-3">
								<RequiredLabel htmlFor="KeyContactDetails-position">Position</RequiredLabel>
								{this.validator.message('KeyContactDetails[position]', values.KeyContactDetails.position, 'required')}
								<Field name="KeyContactDetails[position]" id="KeyContactDetails-position" className="form-control border-dark" required/>
								<ErrorMessage name="KeyContactDetails[position]" render={this.renderValidationError}/>
							</div>
							<div className="col-12 col-md-3">
								<label htmlFor="KeyContactDetails-department">Department</label>
								<Field name="KeyContactDetails[department]" id="KeyContactDetails-department" className="form-control border-dark"/>
								<ErrorMessage name="KeyContactDetails[department]" render={this.renderValidationError}/>
							</div>
						</div>
					
						<div className="row mt-3">
							<div className="col-12 col-md-3">
								<AddPrimarySecondaryContact
									type="tel"
									errors={errors}
									label='Phone Contact'
									values={values.KeyContactDetails.phones}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
									renderValidationError={this.renderValidationError}
									idPrefix="KeyContactDetails-phones"
									namePrefix="KeyContactDetails[phones]"
									renderBeforeInput={({ value, name, isPrimary }) => {
										if (isPrimary) {
											return this.validator.message(name, value, 'required|phone|valid_phone')
										}
										
										return this.validator.message(name, value, 'phone', {
											messages: {
												phone: "Not a valid secondary phone"
											}
										})
									}}
								/>
							</div>
							<div className="col-12 col-md-3">
								<AddPrimarySecondaryContact
									type="email"
									errors={errors}
									label='Email Contact'
									values={values.KeyContactDetails.emails}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
									renderValidationError={this.renderValidationError}
									idPrefix="KeyContactDetails-emails"
									namePrefix="KeyContactDetails[emails]"
									renderBeforeInput={({ value, name, isPrimary }) => {
										if (isPrimary) {
											return this.validator.message(name, value, 'required|email')
										}
										
										return this.validator.message(name, value, 'email', {
											messages: {
												email: "Not a valid secondary email"
											}
										})
									}}
								/>
							</div>
						</div>
					
						<div className="row mt-5 mx-0">
							<h3 className="color">Billing Contact Details</h3>
						</div>

						<div className="row">
							<div className="col-12 col-md-6">
								<RequiredLabel htmlFor="BillingContactDetails-do_you_want_to_bill_parent_org">Do you want to bill the parent org?</RequiredLabel>
								<CustomSelect
									variant="light"
									name="BillingContactDetails[do_you_want_to_bill_parent_org]"
									id="BillingContactDetails-do_you_want_to_bill_parent_org"
									className="form-control"
									value={values.BillingContactDetails.do_you_want_to_bill_parent_org}
									options={whoToBillOptions}
									clearable={false}
									onChange={(newValue) => newValue && setFieldValue("BillingContactDetails[do_you_want_to_bill_parent_org]", newValue.value)}
									isLoading={this.props.orgDetails.loading}
									disabled={this.props.orgDetails.loading}
								/>
								<ErrorMessage name="BillingContactDetails[do_you_want_to_bill_parent_org]" render={this.renderValidationError}/>
							</div>
						</div>

						<div className="row">
							<div className="col-12" style={{display: "flex", marginTop:15}}>
								<CustomCheckbox 
									label={`Billing contact same as Key Contact`}
									name="BillingContactDetails[is_billing_same_as_key_contact]" 
									id="BillingContactDetails-is_billing_same_as_key_contact"
									value={values.BillingContactDetails.is_billing_same_as_key_contact}
									onChange={e => {
										const checked = !!e.currentTarget.checked
										
										if (checked) {
											setFieldValue('BillingContactDetails[first_name]', values.KeyContactDetails.first_name)
											setFieldValue('BillingContactDetails[last_name]', values.KeyContactDetails.last_name)
											setFieldValue('BillingContactDetails[position]', values.KeyContactDetails.position)
											setFieldValue('BillingContactDetails[department]', values.KeyContactDetails.department)
											setFieldValue('BillingContactDetails[phones]', (values.KeyContactDetails.phones || []).map(p => ({...p})))
											setFieldValue('BillingContactDetails[emails]', (values.KeyContactDetails.emails || []).map(e => ({...e})))
										} else {
											setFieldValue('BillingContactDetails[first_name]', initialValues.KeyContactDetails.first_name)
											setFieldValue('BillingContactDetails[last_name]', initialValues.KeyContactDetails.last_name)
											setFieldValue('BillingContactDetails[position]', initialValues.KeyContactDetails.position)
											setFieldValue('BillingContactDetails[department]', initialValues.KeyContactDetails.department)
											setFieldValue('BillingContactDetails[phones]', (initialValues.KeyContactDetails.phones || []).map(p => ({...p})))
											setFieldValue('BillingContactDetails[emails]', (initialValues.KeyContactDetails.emails || []).map(e => ({...e})))
										}
										handleChange(e)
									}}
									disabled={do_you_want_to_bill_parent_org}
								/>
								<ErrorMessage name="BillingContactDetails[is_billing_same_as_key_contact]" render={this.renderValidationError}/>
							</div>
						</div>

						<div className="row mt-3">
							<div className="col-12 col-md-3">
								<RequiredLabel htmlFor="BillingContactDetails-first_name">First name</RequiredLabel>
								{
									!this.isTruthy(do_you_want_to_bill_parent_org) && (
										this.validator.message('BillingContactDetails[first_name]', values.BillingContactDetails.first_name, 'required')
									)
								}
								{
									(() => {                                            
										if (do_you_want_to_bill_parent_org) {
											const { first_name } = determineParentOrgBillingContactDetails(orgDetail)
											return <Field name="BillingContactDetails[first_name]" id="BillingContactDetails-first_name" className="form-control border-dark" value={first_name} disabled readOnly/>
										} else if (is_billing_same_as_key_contact) {
											return <Field name="BillingContactDetails[first_name]" id="BillingContactDetails-first_name" className="form-control border-dark" value={values.KeyContactDetails.first_name} disabled readOnly/>
										} else {
											return <Field name="BillingContactDetails[first_name]" id="BillingContactDetails-first_name" className="form-control border-dark" required/>
										}
									})()
								}
								<ErrorMessage name="BillingContactDetails[first_name]" render={this.renderValidationError}/>
							</div>
							<div className="col-12 col-md-3">
								<RequiredLabel htmlFor="BillingContactDetails-last_name">Last name</RequiredLabel>
								{
									!this.isTruthy(do_you_want_to_bill_parent_org) && (
										this.validator.message('BillingContactDetails[last_name]', values.BillingContactDetails.last_name, 'required')
									)
								}
								{
									(() => {
										if (do_you_want_to_bill_parent_org) {
											const { last_name } = determineParentOrgBillingContactDetails(orgDetail)
											return <Field name="BillingContactDetails[last_name]" id="BillingContactDetails-last_name" className="form-control border-dark" value={last_name} disabled readOnly/>
										} else if (is_billing_same_as_key_contact) {
											return <Field name="BillingContactDetails[last_name]" id="BillingContactDetails-last_name" className="form-control border-dark" value={values.KeyContactDetails.last_name} disabled readOnly/>
										} else {
											return <Field name="BillingContactDetails[last_name]" id="BillingContactDetails-last_name" className="form-control border-dark"/>
										}
									})()
								}
								
								<ErrorMessage name="BillingContactDetails[last_name]" render={this.renderValidationError}/>
							</div>
							<div className="col-12 col-md-3">
								<RequiredLabel htmlFor="KeyContactDetails-position">Position</RequiredLabel>
								{
									!this.isTruthy(do_you_want_to_bill_parent_org) && (
										this.validator.message('BillingContactDetails[position]', values.BillingContactDetails.position, 'required')
									)
								}
								{
									(() => {
										if (do_you_want_to_bill_parent_org) {
											const { position } = determineParentOrgBillingContactDetails(orgDetail)
											return <Field name="BillingContactDetails[position]" id="BillingContactDetails-position" className="form-control border-dark" value={position} disabled readOnly/>
										} else if (is_billing_same_as_key_contact) {
											return <Field name="BillingContactDetails[position]" id="BillingContactDetails-position" className="form-control border-dark" value={values.KeyContactDetails.position} disabled readOnly/>
										} else {
											return <Field name="BillingContactDetails[position]" id="BillingContactDetails-position" className="form-control border-dark"/>
										}
									})()
								}
								<ErrorMessage name="BillingContactDetails[position]" render={this.renderValidationError}/>
							</div>
							<div className="col-12 col-md-3">
								<label htmlFor="BillingContactDetails-department">Department</label>
								{
									(() => {
										if (do_you_want_to_bill_parent_org) {
											const { department } = determineParentOrgBillingContactDetails(orgDetail)
											return <Field name="BillingContactDetails[department]" id="BillingContactDetails-department" className="form-control border-dark" value={department} disabled readOnly/>
										} else if (is_billing_same_as_key_contact) {
											return <Field name="BillingContactDetails[department]" id="BillingContactDetails-department" className="form-control border-dark" value={values.KeyContactDetails.department} disabled readOnly/>
										} else {
											return <Field name="BillingContactDetails[department]" id="BillingContactDetails-department" className="form-control border-dark"/>
										}
									})()
								}
								<ErrorMessage name="BillingContactDetails[department]" render={this.renderValidationError}/>
							</div>
						</div>
						
						<div className="row mt-3">
							<div className="col-12 col-md-3">
								<AddPrimarySecondaryContact
									type="tel"
									errors={errors}
									label='Phone Contact'
									values={(() => {
										if (do_you_want_to_bill_parent_org) {
											const { phones } = determineParentOrgBillingContactDetails(orgDetail)
											return phones
										} else if (is_billing_same_as_key_contact) {
											return values.KeyContactDetails.phones
										} else {
											return values.BillingContactDetails.phones
										}
									})()}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
									renderValidationError={this.renderValidationError}
									idPrefix="BillingContactDetails-phones"
									namePrefix="BillingContactDetails[phones]"
									renderBeforeInput={({ value, name, isPrimary }) => {
										if (isPrimary) {
											return this.validator.message(name, value, 'required|phone|valid_phone')
										}

										return this.validator.message(name, value, 'phone', {
											messages: {
												phone: "Not a valid secondary phone"
											}
										})
									}}
									readOnly={do_you_want_to_bill_parent_org || is_billing_same_as_key_contact}
								/>
							</div>
							<div className="col-12 col-md-3">
								<AddPrimarySecondaryContact
									type="email"
									errors={errors}
									label='Email Contact'
									values={(() => {
										if (do_you_want_to_bill_parent_org) {
											const { emails } = determineParentOrgBillingContactDetails(orgDetail)
											return emails
										} else if (is_billing_same_as_key_contact ) {
											return values.KeyContactDetails.emails
										} else {
											return values.BillingContactDetails.emails
										}
									})()}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
									renderValidationError={this.renderValidationError}
									idPrefix="BillingContactDetails-emails"
									namePrefix="BillingContactDetails[emails]"
									renderBeforeInput={({ value, name, isPrimary }) => {
										if (isPrimary) {
											return this.validator.message(name, value, 'required|email')
										}
										
										return this.validator.message(name, value, 'email', {
											messages: {
												email: "Not a valid secondary email"
											}
										})
									}}
									readOnly={do_you_want_to_bill_parent_org || is_billing_same_as_key_contact}
								/>
							</div>

							<div className="col-12 col-md-3">
								<RequiredLabel htmlFor="SiteDetails-pay_roll_tax">Pay roll tax</RequiredLabel>
								{
									do_you_want_to_bill_parent_org ? 
									this.validator.message('SiteDetails[pay_roll_tax]', determineParentOrgBillingContactDetails(orgDetail).pay_roll_tax, 'required')
									: this.validator.message('SiteDetails[pay_roll_tax]', values.SiteDetails.pay_roll_tax, 'required')
								}
								<CustomSelect required 
									variant="light"
									name="SiteDetails[pay_roll_tax]"
									id="SiteDetails-pay_roll_tax"
									className="form-control"
									value={(() => {
										if (do_you_want_to_bill_parent_org) {
											const { pay_roll_tax } = determineParentOrgBillingContactDetails(orgDetail)
											return pay_roll_tax
										}

										return values.SiteDetails.pay_roll_tax
									})()}
									options={[
										{value: null, label: "Select option", selected: true, disabled: true}, 
										{value: '1', label: "Yes"}, 
										{value: '0', label: "No"}
									]}
									clearable={false}
									onChange={(newValue) => newValue && setFieldValue("SiteDetails[pay_roll_tax]", newValue.value)}
								/>
								<ErrorMessage name="SiteDetails[pay_roll_tax]" render={this.renderValidationError}/>
							</div>
							<div className="col-12 col-md-3">
								<RequiredLabel htmlFor="SiteDetails-gst">GST</RequiredLabel>
								{
									do_you_want_to_bill_parent_org ? 
									this.validator.message('SiteDetails[gst]', determineParentOrgBillingContactDetails(orgDetail).gst, 'required')
									: this.validator.message('SiteDetails[gst]', values.SiteDetails.gst, 'required')
								}
								<CustomSelect required 
									variant="light"
									name="SiteDetails[gst]"
									id="SiteDetails-gst"
									className="form-control"
									value={(() => {
										if (do_you_want_to_bill_parent_org) {
											const { gst } = determineParentOrgBillingContactDetails(orgDetail)
											return gst
										}
										return values.SiteDetails.gst
									})()}
									// value={values.SiteDetails.gst}
									options={[
										{value: null, label: "Select option", selected: true, disabled: true}, 
										{value: '1', label: "Yes"}, 
										{value: '0', label: "No"}
									]}
									clearable={false}
									onChange={(newValue) => newValue && setFieldValue("SiteDetails[gst]", newValue.value)}
								/>
								<ErrorMessage name="SiteDetails[gst]" render={this.renderValidationError}/>
							</div>
						</div>

						<div className="form-group mt-5 text-right">
							<button type="submit" 
								className="btn btn-dark"
								disabled={isSubmitting} 
								onClick={e => {
									setFieldValue("publish", true);
									handleSubmit(e);
								}} 
							>
								{
									isSubmitting ? "Creating site..." : "Create site"
								}
							</button>
						</div>

						<div className="form-group">
							<br/>
							<br/>
						</div>
					</Form>
						)
					}
				}
			</Formik>
		)
	}

	render() {
		return (
			<div className="container-fluid">
				<div className="content-block">
					{this.renderBreadcrumbs()}
				</div>
				<div className="content-block">
					{this.renderOrgSummary()}
				</div>
				<div className="content-block">
					{this.renderForm()}
				</div>
			</div>
		)
	}
}

/**
 * Determine the billing contact based on provided org details (usually details of the parent org)
 * 
 * @param {{basic_detail: any, org_contacts: any[], contact_types: any}} orgDetail 
 */
function determineParentOrgBillingContactDetails(orgDetail) {
    const { basic_detail, org_contacts } = orgDetail || {}

    const TYPE_BILLING_CONTACT = 4

    const parentOrgBillingContact = (org_contacts || []).find(c => {
        const { value } = c.contact_type || {}
        return parseInt(value) === TYPE_BILLING_CONTACT
    })

    const { phone_primary, phone_secondary_1, phone_secondary_2 } = parentOrgBillingContact || {}
    const phones = [
        { is_primary: true, value: phone_primary, required: true },
        { is_primary: false, value: phone_secondary_1, required: false },
        { is_primary: false, value: phone_secondary_2, required: false },
    ]
    
    const { email_primary, email_secondary_1, email_secondary_2 } = parentOrgBillingContact || {}
    const emails = [
        { is_primary: true, value: email_primary, required: true },
        { is_primary: false, value: email_secondary_1, required: false },
        { is_primary: false, value: email_secondary_2, required: false },
    ]

    return {
        first_name: parentOrgBillingContact.first_name,
        last_name: parentOrgBillingContact.last_name,
        position: parentOrgBillingContact.position,
        department: parentOrgBillingContact.department,
        phones: phones.filter(p => !!p.is_primary || !!p.value),
        emails: emails.filter(p => !!p.is_primary || !!p.value),
        pay_roll_tax: (basic_detail || {}).payroll_tax,
        gst: (basic_detail || {}).gst,
    }
}


/**
 * 
 * @param {import('../../reducers').RootState} state 
 * @param {import('react-router-dom').RouteComponentProps} ownProps 
 */
const mapStateToProps = (state, ownProps) => {
	const { auth, orgDetails } = state
	const { user } = auth || {}
	const { token } = user || {}

	let claims = null;
	if (token) {
		claims = jwt.decode(token)
	}

	return {
		orgDetails,
		organisationName: (claims || {})['name']
	}
}

export default connect(mapStateToProps)(CreatePage)
