import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/index';
import callAPIMiddleware from './middlewares/callAPIMiddleware';
import { USE_REDUX_LOGGER } from './config';

/**
 * @typedef {import('./reducers').RootState} RootState
 */

let middlewares = []

middlewares.push(thunkMiddleware)
if (USE_REDUX_LOGGER) {
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware)
}

middlewares.push(callAPIMiddleware)

export const store = createStore(rootReducer, applyMiddleware(...middlewares));