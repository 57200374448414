import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import { LOCALSTORAGE_KEY, LOGIN_URL } from '../constants';
import DashboardLayout from '../layouts/DashboardLayout';


const PrivateRoute = ({component: Component, layout: Layout = DashboardLayout, layoutClassName = null, ...rest}) => (
    <Route {...rest} render={props => {
        let destination = LOGIN_URL

        const userJSON = localStorage.getItem(LOCALSTORAGE_KEY);
        const user = JSON.parse(userJSON);

        if (!userJSON) {
            return <Redirect to={{
                pathname: destination, 
                state: {from: props.location}}} 
            />
        }

        if (user['is_first_time_login']) {
            destination = user['temporary_password_reset_url'] || '/temp-password-reset'
            return <Redirect to={{
                pathname: destination, 
                state: {from: props.location}}} 
            />
        }

        if (Layout) {
            return (
                <Layout className={layoutClassName}>
                    <Component {...props} />
                </Layout>
            )
        }

        return <Component {...props} />
    }}/>
)

export default PrivateRoute