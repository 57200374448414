import React from 'react'
import { css } from '../helpers/common'


/**
 * Determine shift address by concatinating prop values from each item in `locations`
 * 
 * @param {object} param
 * @param {{address: string, suburb: string, state: number, postal: number}[]} param.locations
 * @param {{label: string, value: number|string}[]} param.states
 */
function computeShiftAddress({ locations = [], states = [] }) {
    const location = locations[0] || {}
    const mappedStates = states.reduce((p, c) => (p[c.value] = c.label, p), {}) // eg [{label: 'VIC', value: 3},{label: 'ACT', value: 2}, ...] => { '3': 'VIC', '2': 'ACT', ... }

    let result = [location.address, location.suburb, location.postal]
    if (location.state in mappedStates) {
        result = result.concat(mappedStates[location.state])
    }

    return result.filter(s => s).join(` `)
}


/**
 * Component to render shift details. 
 * 
 * Used exclusively by `shifts/IndexPage.jsx`
 * 
 * @param {{[key:string]: any, states: any[], original: {[key: string]: any, id: number|string}}} props 
 */
const ShiftDetails = props => {
    const { original: shift, states } = props

    const styles = css({
        root: {

        },
        table: {
            maxWidth: 1074,
        },
        cellLabel: {
            width: 300,
        },
        cellValue: {
            borderBottom: "1px solid black", 
            borderTop: "none"
        },
        historyLog: {
            fontSize: 20, 
            color: "#006166"
        },
        mapMarker: {
            fontSize: 20, 
            color: "#006166"
        }
    })

    /** @type {{label: string, value: React.ReactChild, icon?: React.ReactChild }[]} */
    const details = [
        { 
            label: 'Shift start date', 
            value: shift.start_time 
        },
        { 
            label: 'Shift end date', 
            value: shift.end_time
        },
        { 
            label: 'Shift duration', 
            value: shift.duration
        },
        { 
            label: 'Shift address', 
            value: computeShiftAddress({ locations: shift.locations || [], states })
        },
        {
            label: 'Completed by',
            value: (shift.completed_by || {}).name
        }
    ]


    return (
        <div className="row fms-details" style={styles.root}>
            <table className="table" style={styles.table}>
                <tbody>
                    {
                        details.map((detail, i) => (
                            <tr key={i}>
                                <td className="border-0 text-right" style={styles.cellLabel}>
                                    <b>{detail.label}</b>
                                </td>
                                <td className="border-0">
                                    <div className="w-100 d-flex justify-content-between" style={styles.cellValue}>
                                        {
                                            detail.value ? <span className="text-left">{detail.value}</span> : <span>&nbsp;</span>
                                        }
                                        {
                                            detail.icon && (detail.icon)
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ShiftDetails