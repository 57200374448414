import React from 'react'
import { connect } from "react-redux"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import GooglePlacesAutocomplete from 'react-google-autocomplete'
import jwt from "jsonwebtoken"

import { subOrgsService } from '../../services/subOrgsService'
import OrgInfoSummary from '../../components/OrgInfoSummary'
import AddSitesModal from "../../components/AddSitesModal"
import { findAddressComponent } from '../../helpers/google-places'
import { AddPrimarySecondaryContact } from '../../components/AddPrimarySecondaryContact'
import CustomCheckbox from '../../components/CustomCheckbox';
import { CustomSelect } from "../../components/CustomSelect"

import "../../stylesheets/pages/sub-orgs/CreatePage.scss"
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs'
import { fetchSubOrgs } from '../../actions/commonActions'

/**
 * @typedef {{value: number|string|boolean, label: string, selected?: boolean, disabled?: boolean}} DropdownOption
 * @typedef {ReturnType<typeof mapStateToProps> & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 * 
 * @typedef {object} State
 * @property {boolean} _debug
 * @property {boolean} _loading
 * @property {boolean} _loadingRequirements
 * @property {boolean} _displayAttachToParentOrgModal
 * @property {boolean} _displayAddSitesModal
 * @property {boolean} _displayAddSitesModal
 * @property {DropdownOption[]} states
 * @property {DropdownOption[]} addressCategories
 * @property {DropdownOption[]} whoToBillOptions
 * @property {DropdownOption[]} org_requirements
 * @property {string|null} selectedLogo
 * @property {any} orgDetail
 */



/** 
 * Adds red star to labels
 * 
 * @param {React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>} props 
 */
const RequiredLabel = (props) => (
    <label htmlFor={props.htmlFor}>{props.children} <span className="required">*</span></label>
)


/**
 * Component that renders the `sub-orgs/create` page
 * 
 * @extends {React.Component<Props, State>}
 */
class CreatePage extends React.Component {

    state = {
        _debug: false,
        _loadingRequirements: false,
        _displayAttachToParentOrgModal: false,
        _displayAddSitesModal: false,
        states: [
            {value: "", label: "Loading...", selected: true, disabled: true},
        ],
        addressCategories: [
            {value: "", label: "Select option", selected: true, disabled: true},
            {value: "head", label: "Head"},
            {value: "billing", label: "Billing"},
            {value: "other", label: "Other"},
        ],
        whoToBillOptions: [
            {value: "", label: "Select", selected: true, disabled: true},
            {value: '0', label: "No", title: 'You will have to provide your own billing contact details'},
            {value: '1', label: "Yes", title: 'The billing contact of the parent organisation will be billed instead'},
        ],
        org_requirements: [],
        selectedLogo: null,
        orgDetail: null,
    }

    /**
     * Fetch states, and org details when this page (or component) is loaded
     */
    async componentDidMount() {
        const { dispatch } = this.props

        this.setState({ _loadingRequirements: true })

        try {
            const response = await subOrgsService.getCreate();
            const data = response.data;

            dispatch({ type: "SUBORG_CREATED" })
    
            this.setState({
                states: [
                    {value: "", label: "Select states", selected: true, disabled: true},
                    ...data.states
                ],
                org_requirements: data.orgRequirements,
                _loadingRequirements: false,
            })
        } catch (e) {

        }
    }

    /**
     * Displays customized validation error message
     * 
     * @param {string} errorMessage
     */
    renderValidationError = errorMessage => {
        return (
            <span className="text-danger">
                <small>{errorMessage}</small>
            </span>
        )
    }


    /** 
     * Runs when the form has been submitted.
     * Will call `POST: suborgs/create` (Note: endpoint does not have dash)
     * 
     * @type {(values: any, formikHelpers: import('formik').FormikHelpers<any>) => void)} 
     */
    handleOnSubmit = async (values, formikHelpers) => {

        let newValues = { ...values }

        if (this.isTruthy(values.BillingContactDetails.is_billing_same_as_key_contact)) {
            newValues = {
                ...values,
                BillingContactDetails: {
                    ...values.BillingContactDetails,
                    ...({
                        first_name: values.KeyContactDetails.first_name,
                        last_name: values.KeyContactDetails.last_name,
                        position: values.KeyContactDetails.position,
                        department: values.KeyContactDetails.department,
                        phones: [...values.KeyContactDetails.phones],
                        emails: [...values.KeyContactDetails.emails],
                    })
                }
            }
        }
        if (this.isTruthy(values.BillingContactDetails.do_you_want_to_bill_parent_org)) {
            const { orgDetails } = this.props
            const { value: orgDetail } = orgDetails
            newValues = {
                ...values,
                BillingContactDetails: {
                    ...values.BillingContactDetails,
                    ...determineParentOrgBillingContactDetails(orgDetail)
                }
            }
        }

        formikHelpers.setSubmitting(true)
        try {
            await subOrgsService.postCreate(newValues);
            this.props.dispatch(fetchSubOrgs())

        } catch(e) {
            const {errors} = e.response.data;
            for (let field in errors) {
                formikHelpers.setFieldError(field, errors[field])
            }
        } finally {
            formikHelpers.setSubmitting(false)
        }  
    }

    /**
     * Renders values and errors as json. 
     * This is just for convenient of watching the form values before form is submitted
     * 
     * @param {object} params 
     * @param {any} params.values 
     * @param {Record<string, string>} params.errors 
     */
    renderDebug({values, errors}) {
        const debugStyle = {
            position: 'fixed',
            bottom: 0,
            right: 0,
            zIndex: 9999,
            backgroundColor: '#FFFF',
            border: '1px solid #DDD',
            overflowY: 'auto',
            height: this.state._debug ? `calc(100% - 200px)` : 0,
            width: this.state._debug ? `auto` : 0,

        }
        
        const preStyle = {
            fontSize: 12, 
            padding: 15, 
            minWidth: 600,
            overflowY: 'auto',
            display: this.state._debug ? 'block' : 'none'
        }

        return (
            <div className="form-group" style={debugStyle}>
                <pre style={preStyle}>
                    {JSON.stringify({values, errors}, null, 4)}
                </pre>
                <div className="text-right" style={{position: 'fixed', bottom: 20, right: 20}}>
                    <button type="button" onClick={e => this.setState(prevState => ({ _debug: !prevState._debug }))}>Toggle debug</button>
                </div>
            </div>
        )
    }

    /** 
     * Renders custom upload button
     * 
     * @param {object} param
     * @param {(field: string, value: any, shouldValidate?: boolean) => void} param.setFieldValue 
     */
    renderUploadButton({ setFieldValue }) {
        const { selectedLogo } = this.state;

        /** @type {Record<'button'|'input', React.CSSProperties>} */
        const styles = {
            input: {
                cursor: "pointer",
                position: "absolute",
                opacity: 0,
                right: 0,
                top: 0,
            },
            button: {
                position: "relative", 
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }
        }

        return (
            <button 
                type="button" 
                className="btn btn-dark btn-block" 
                style={styles.button}
            >
                {selectedLogo || 'Upload logo'}
                <input 
                    type="file" 
                    accept=".png,.jpeg,.jpg" 
                    name="logo" 
                    id="logo" 
                    className="form-control input-block"
                    style={styles.input} 
                    onChange={e => {
                        const selectedFile = e.currentTarget.files[0];
                        if (selectedFile) {
                            this.setState({ selectedLogo: selectedFile.name })
                        }
                        setFieldValue("logo", selectedFile)
                    }} 
                />
            </button>
        )
        
    }

    isTruthy(value) {
        return [1, '1', 'true', true].indexOf(value) >= 0
    }


    /**
     * Renders the form. Uses `formik` form helper library
     */
    renderForm() {

        const { states, addressCategories, whoToBillOptions } = this.state

        // NOTE: all validation happens on server-side

        return (
            <Formik
                initialValues={{
                    publish: true,
                    logo: null,
                    OrgLegalDetails: {
                        sub_org_legal_name: '',
                        abn: ''
                    },
                    ContactDetails: {
                        sub_org_address: '',
                        city: '', // aka suburb
                        state: '',
                        postcode: '',
                        address_category: '',
                        phones: [
                            { value: "", is_primary: true, required: true }
                        ],
                        emails: [
                            { value: "", is_primary: true, required: true }
                        ],
                        website: ''
                    },
                    KeyContactDetails: {
                        first_name: '',
                        last_name: '',
                        position: '',
                        department: '',
                        phones: [
                            { value: "", is_primary: true, required: true }
                        ],
                        emails: [
                            { value: "", is_primary: true, required: true }
                        ],
                    },
                    BillingContactDetails: {
                        is_billing_same_as_key_contact: false,
                        do_you_want_to_bill_parent_org: false,
                        first_name: '',
                        last_name: '',
                        position: '',
                        department: '',
                        phones: [
                            { value: "", is_primary: true, required: true }
                        ],
                        emails: [
                            { value: "", is_primary: true, required: true }
                        ],
                        pay_roll_tax: '',
                        gst: '',
                    },
                    SubOrgRequirements: this.state.org_requirements.reduce((prev, curr) => {
                        prev[curr.value] = curr.checked
                        return prev
                    }, {}),
                    AttachAndAdd: {
                        parent_org: null,
                        sites: null
                    }
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={this.handleOnSubmit}
                enableReinitialize={true}
            >
                {({values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, initialValues}) => {

                    const requirementLabels = this.state.org_requirements.reduce((prev, curr) => {
                        prev[curr.value] = curr.label
                        return prev;
                    }, {})

                    const do_you_want_to_bill_parent_org = this.isTruthy(values.BillingContactDetails.do_you_want_to_bill_parent_org)
                    const is_billing_same_as_key_contact = this.isTruthy(values.BillingContactDetails.is_billing_same_as_key_contact)

                    // parent org billing contact
                    const { orgDetails } = this.props
                    const { value: orgDetail } = orgDetails

                    return (
                        <Form encType="multipart/form-data" method="POST">
                            {/* this.renderDebug({values, errors}) */}
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <h4><b>Org Legal Details</b></h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <RequiredLabel htmlFor="OrgLegalDetails-sub_org_legal_name">Sub-org's name (legal)</RequiredLabel>
                                        <Field name="OrgLegalDetails[sub_org_legal_name]" id="OrgLegalDetails-sub_org_legal_name" className="form-control border-dark"/>
                                        <ErrorMessage name="OrgLegalDetails[sub_org_legal_name]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <RequiredLabel htmlFor="OrgLegalDetails-abn">ABN</RequiredLabel>
                                        <Field name="OrgLegalDetails[abn]" id="OrgLegalDetails-abn" className="form-control input-block border-dark"/>
                                        <ErrorMessage name="OrgLegalDetails[abn]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <RequiredLabel htmlFor="logo">Upload the sub-orgs logo</RequiredLabel>
                                        {this.renderUploadButton({ setFieldValue })}
                                        <ErrorMessage name="logo" render={this.renderValidationError}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <h4><b>Contact Details</b></h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <RequiredLabel htmlFor="ContactDetails-sub_org_address">Sub-org's address</RequiredLabel>
                                        <GooglePlacesAutocomplete 
                                            name="ContactDetails[sub_org_address]"
                                            id="ContactDetails-sub_org_address" 
                                            className="form-control border-dark"
                                            value={values.ContactDetails.sub_org_address}
                                            onPlaceSelected={/** @param {google.maps.places.PlaceResult} place */ (place) => {
                                                const address = {
                                                    state: findAddressComponent(place, "administrative_area_level_1"),
                                                    postcode: findAddressComponent(place, "postal_code"),
                                                    city: findAddressComponent(place, "locality"),
                                                    street_number: findAddressComponent(place, "street_number"),
                                                    route: findAddressComponent(place, "route", true),
                                                    address: document.getElementById("ContactDetails-sub_org_address").value
                                                }

                                                const state = (address.state || "").toLowerCase();
                                                const foundState = (this.state.states || []).find(s => s.label.toLowerCase() === state)
                                                
                                                setFieldValue("ContactDetails[state]", (foundState || {}).value)
                                                setFieldValue("ContactDetails[sub_org_address]", [address.street_number, address.route].join(" "))
                                                setFieldValue("ContactDetails[postcode]", address.postcode)
                                                setFieldValue("ContactDetails[city]", address.city)
                                            }}
                                            types={['address']}
                                            componentRestrictions={{country: "au"}}
                                            onChange={handleChange}
                                        />
                                        {/* <Field name="ContactDetails[sub_org_address]" id="ContactDetails-sub_org_address" className="form-control"/> */}
                                        <ErrorMessage name="ContactDetails[sub_org_address]" render={this.renderValidationError}/>
                                        <ErrorMessage name="ContactDetails[city]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-md-2">
                                        <RequiredLabel htmlFor="ContactDetails-state">State</RequiredLabel>
                                        <CustomSelect
                                            variant="light"
                                            name="ContactDetails[state]"
                                            id="ContactDetails-state"
                                            className="form-control"
                                            value={values.ContactDetails.state}
                                            options={states}
                                            clearable={false}
                                            onChange={(newValue) => newValue && setFieldValue("ContactDetails[state]", newValue.value)}
                                        />
                                        <ErrorMessage name="ContactDetails[state]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <RequiredLabel htmlFor="ContactDetails-postcode">Postcode</RequiredLabel>
                                        <Field name="ContactDetails[postcode]" id="ContactDetails-postcode" className="form-control border-dark"/>
                                        <ErrorMessage name="ContactDetails[postcode]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <RequiredLabel htmlFor="ContactDetails-address_category">Address category</RequiredLabel>
                                        <CustomSelect
                                            variant="light"
                                            name="ContactDetails[address_category]"
                                            id="ContactDetails-address_category"
                                            className="form-control"
                                            value={values.ContactDetails.address_category}
                                            options={addressCategories}
                                            clearable={false}
                                            onChange={(newValue) => newValue && setFieldValue("ContactDetails[address_category]", newValue.value)}
                                        />
                                        <ErrorMessage name="ContactDetails[address_category]" render={this.renderValidationError}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <AddPrimarySecondaryContact
                                            type="tel"
                                            errors={errors}
                                            label='Sub-orgs phones'
                                            values={values.ContactDetails.phones}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            renderValidationError={this.renderValidationError}
                                            idPrefix="ContactDetails-phones"
                                            namePrefix="ContactDetails[phones]"
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <AddPrimarySecondaryContact
                                            type="email"
                                            errors={errors}
                                            label='Sub-orgs emails'
                                            values={values.ContactDetails.emails}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            renderValidationError={this.renderValidationError}
                                            idPrefix="ContactDetails-emails"
                                            namePrefix="ContactDetails[emails]"
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="ContactDetails-website">Sub-org's website</RequiredLabel>
                                        <Field name="ContactDetails[website]" id="ContactDetails-website" className="form-control border-dark"/>
                                        <ErrorMessage name="ContactDetails[website]" render={this.renderValidationError}/>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>
                                            <b>Key Contact Details</b>
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="KeyContactDetails-first_name">First name</RequiredLabel>
                                        <Field name="KeyContactDetails[first_name]" id="KeyContactDetails-first_name" className="form-control border-dark"/>
                                        <ErrorMessage name="KeyContactDetails[first_name]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="KeyContactDetails-last_name">Last name</RequiredLabel>
                                        <Field name="KeyContactDetails[last_name]" id="KeyContactDetails-last_name" className="form-control border-dark"/>
                                        <ErrorMessage name="KeyContactDetails[last_name]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="KeyContactDetails-position">Position <span className="required">*</span></label>
                                        <Field name="KeyContactDetails[position]" id="KeyContactDetails-position" className="form-control border-dark"/>
                                        <ErrorMessage name="KeyContactDetails[position]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="KeyContactDetails-department">Department</label>
                                        <Field name="KeyContactDetails[department]" id="KeyContactDetails-department" className="form-control border-dark"/>
                                        <ErrorMessage name="KeyContactDetails[department]" render={this.renderValidationError}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <AddPrimarySecondaryContact
                                            type="tel"
                                            errors={errors}
                                            label='Phone Contact'
                                            values={values.KeyContactDetails.phones}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            renderValidationError={this.renderValidationError}
                                            idPrefix="KeyContactDetails-phones"
                                            namePrefix="KeyContactDetails[phones]"
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <AddPrimarySecondaryContact
                                            type="email"
                                            errors={errors}
                                            label='Email Contact'
                                            values={values.KeyContactDetails.emails}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            renderValidationError={this.renderValidationError}
                                            idPrefix="KeyContactDetails-emails"
                                            namePrefix="KeyContactDetails[emails]"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>
                                            <b>Billing Contact Details</b>
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <RequiredLabel htmlFor="BillingContactDetails-do_you_want_to_bill_parent_org">Do you want to bill the parent org?</RequiredLabel>
                                        <CustomSelect
                                            variant="light"
                                            name="BillingContactDetails[do_you_want_to_bill_parent_org]"
                                            id="BillingContactDetails-do_you_want_to_bill_parent_org"
                                            className="form-control"
                                            value={values.BillingContactDetails.do_you_want_to_bill_parent_org}
                                            options={whoToBillOptions}
                                            clearable={false}
                                            onChange={(newValue) => newValue && setFieldValue("BillingContactDetails[do_you_want_to_bill_parent_org]", newValue.value)}
                                            isLoading={this.props.orgDetails.loading}
                                            disabled={this.props.orgDetails.loading}
                                        />
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-12 col-md-3" style={{display: "flex"}}>
                                        <CustomCheckbox 
                                            label={`Billing contact same as Key Contact`}
                                            name="BillingContactDetails[is_billing_same_as_key_contact]" 
                                            id="BillingContactDetails-is_billing_same_as_key_contact"
                                            value={values.BillingContactDetails.is_billing_same_as_key_contact}
                                            onChange={e => {
                                                const checked = !!e.currentTarget.checked
                                                
                                                if (checked) {
                                                    setFieldValue('BillingContactDetails[first_name]', values.KeyContactDetails.first_name)
                                                    setFieldValue('BillingContactDetails[last_name]', values.KeyContactDetails.last_name)
                                                    setFieldValue('BillingContactDetails[position]', values.KeyContactDetails.position)
                                                    setFieldValue('BillingContactDetails[department]', values.KeyContactDetails.department)
                                                    setFieldValue('BillingContactDetails[phones]', (values.KeyContactDetails.phones || []).map(p => ({...p})))
                                                    setFieldValue('BillingContactDetails[emails]', (values.KeyContactDetails.emails || []).map(e => ({...e})))
                                                } else {
                                                    setFieldValue('BillingContactDetails[first_name]', initialValues.KeyContactDetails.first_name)
                                                    setFieldValue('BillingContactDetails[last_name]', initialValues.KeyContactDetails.last_name)
                                                    setFieldValue('BillingContactDetails[position]', initialValues.KeyContactDetails.position)
                                                    setFieldValue('BillingContactDetails[department]', initialValues.KeyContactDetails.department)
                                                    setFieldValue('BillingContactDetails[phones]', (initialValues.KeyContactDetails.phones || []).map(p => ({...p})))
                                                    setFieldValue('BillingContactDetails[emails]', (initialValues.KeyContactDetails.emails || []).map(e => ({...e})))
                                                }
                                                handleChange(e)
                                            }}
                                            disabled={do_you_want_to_bill_parent_org}
                                        />
                                        <ErrorMessage name="BillingContactDetails[is_billing_same_as_key_contact]" render={this.renderValidationError}/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="BillingContactDetails-first_name">First name</RequiredLabel>
                                        {
                                            (() => {                                            
                                                if (do_you_want_to_bill_parent_org) {
                                                    const { first_name } = determineParentOrgBillingContactDetails(orgDetail)
                                                    return <Field name="BillingContactDetails[first_name]" id="BillingContactDetails-first_name" className="form-control border-dark" value={first_name} disabled readOnly/>
                                                } else if (is_billing_same_as_key_contact) {
                                                    return <Field name="BillingContactDetails[first_name]" id="BillingContactDetails-first_name" className="form-control border-dark" value={values.KeyContactDetails.first_name} disabled readOnly/>
                                                } else {
                                                    return <Field name="BillingContactDetails[first_name]" id="BillingContactDetails-first_name" className="form-control border-dark" />
                                                }
                                            })()
                                        }
                                        <ErrorMessage name="BillingContactDetails[first_name]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="BillingContactDetails-last_name">Last name</RequiredLabel>
                                        {
                                            (() => {
                                                if (do_you_want_to_bill_parent_org) {
                                                    const { last_name } = determineParentOrgBillingContactDetails(orgDetail)
                                                    return <Field name="BillingContactDetails[last_name]" id="BillingContactDetails-last_name" className="form-control border-dark" value={last_name} disabled readOnly/>
                                                } else if (is_billing_same_as_key_contact) {
                                                    return <Field name="BillingContactDetails[last_name]" id="BillingContactDetails-last_name" className="form-control border-dark" value={values.KeyContactDetails.last_name} disabled readOnly/>
                                                } else {
                                                    return <Field name="BillingContactDetails[last_name]" id="BillingContactDetails-last_name" className="form-control border-dark"/>
                                                }
                                            })()
                                        }
                                        <ErrorMessage name="BillingContactDetails[last_name]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="BillingContactDetails-position">Position</RequiredLabel>
                                        {
                                            (() => {
                                                if (do_you_want_to_bill_parent_org) {
                                                    const { position } = determineParentOrgBillingContactDetails(orgDetail)
                                                    return <Field name="BillingContactDetails[position]" id="BillingContactDetails-position" className="form-control border-dark" value={position} disabled readOnly/>
                                                } else if (is_billing_same_as_key_contact) {
                                                    return <Field name="BillingContactDetails[position]" id="BillingContactDetails-position" className="form-control border-dark" value={values.KeyContactDetails.position} disabled readOnly/>
                                                } else {
                                                    return <Field name="BillingContactDetails[position]" id="BillingContactDetails-position" className="form-control border-dark"/>
                                                }
                                            })()
                                        }
                                        <ErrorMessage name="BillingContactDetails[position]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="BillingContactDetails-department">Department</label>
                                        {
                                            (() => {
                                                if (do_you_want_to_bill_parent_org) {
                                                    const { department } = determineParentOrgBillingContactDetails(orgDetail)
                                                    return <Field name="BillingContactDetails[department]" id="BillingContactDetails-department" className="form-control border-dark" value={department} disabled readOnly/>
                                                } else if (is_billing_same_as_key_contact) {
                                                    return <Field name="BillingContactDetails[department]" id="BillingContactDetails-department" className="form-control border-dark" value={values.KeyContactDetails.department} disabled readOnly/>
                                                } else {
                                                    return <Field name="BillingContactDetails[department]" id="BillingContactDetails-department" className="form-control border-dark"/>
                                                }
                                            })()
                                        }
                                        <ErrorMessage name="BillingContactDetails[department]" render={this.renderValidationError}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <AddPrimarySecondaryContact
                                            type="tel"
                                            errors={errors}
                                            label='Phone Contact'
                                            values={(() => {
                                                if (do_you_want_to_bill_parent_org) {
                                                    const { phones } = determineParentOrgBillingContactDetails(orgDetail)
                                                    return phones
                                                } else if (is_billing_same_as_key_contact ) {
                                                    return values.KeyContactDetails.phones
                                                } else {
                                                    return values.BillingContactDetails.phones
                                                }
                                            })()}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            renderValidationError={this.renderValidationError}
                                            idPrefix="BillingContactDetails-phones"
                                            namePrefix="BillingContactDetails[phones]"
                                            readOnly={do_you_want_to_bill_parent_org || is_billing_same_as_key_contact}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <AddPrimarySecondaryContact
                                            type="email"
                                            errors={errors}
                                            label='Email Contact'
                                            values={(() => {
                                                if (do_you_want_to_bill_parent_org) {
                                                    const { emails } = determineParentOrgBillingContactDetails(orgDetail)
                                                    return emails
                                                } else if (is_billing_same_as_key_contact ) {
                                                    return values.KeyContactDetails.emails
                                                } else {
                                                    return values.BillingContactDetails.emails
                                                }
                                            })()}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            renderValidationError={this.renderValidationError}
                                            idPrefix="BillingContactDetails-emails"
                                            namePrefix="BillingContactDetails[emails]"
                                            readOnly={do_you_want_to_bill_parent_org || is_billing_same_as_key_contact}
                                        />
                                    </div>

                                    <div className="col-12 col-md-2">
                                        <RequiredLabel htmlFor="BillingContactDetails-pay_roll_tax">Pay roll tax</RequiredLabel>
                                        <CustomSelect
                                            variant="light"
                                            name="BillingContactDetails[pay_roll_tax]"
                                            id="BillingContactDetails-pay_roll_tax"
                                            className="form-control"
                                            value={(() => {
                                                if (do_you_want_to_bill_parent_org) {
                                                    const { pay_roll_tax } = determineParentOrgBillingContactDetails(orgDetail)
                                                    return pay_roll_tax
                                                }
                                                return values.BillingContactDetails.pay_roll_tax
                                            })()}
                                            options={[
                                                {value: null, label: "Select option", selected: true, disabled: true}, 
                                                {value: '1', label: "Yes"}, 
                                                {value: '0', label: "No"}
                                            ]}
                                            clearable={false}
                                            onChange={(newValue) => newValue && setFieldValue("BillingContactDetails[pay_roll_tax]", newValue.value)}
                                            disabled={do_you_want_to_bill_parent_org}
                                        />
                                        <ErrorMessage name="BillingContactDetails[pay_roll_tax]" render={this.renderValidationError}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <RequiredLabel htmlFor="BillingContactDetails-gst">GST</RequiredLabel>
                                        <CustomSelect
                                            variant="light"
                                            name="BillingContactDetails[gst]"
                                            id="BillingContactDetails-gst"
                                            className="form-control"
                                            value={(() => {
                                                if (do_you_want_to_bill_parent_org) {
                                                    const { gst } = determineParentOrgBillingContactDetails(orgDetail)
                                                    return gst
                                                }
                                                return values.BillingContactDetails.gst
                                            })()}
                                            options={[
                                                {value: null, label: "Select option", selected: true, disabled: true}, 
                                                {value: '1', label: "Yes"}, 
                                                {value: '0', label: "No"}
                                            ]}
                                            clearable={false}
                                            onChange={(newValue) => newValue && setFieldValue("BillingContactDetails[gst]", newValue.value)}
                                            disabled={do_you_want_to_bill_parent_org}
                                        />
                                        <ErrorMessage name="BillingContactDetails[gst]" render={this.renderValidationError}/>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>
                                            <b>Sub-org requirements</b>
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{marginBottom: 0}}>
                                        <span>Sub-Org Requirements</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex flex-wrap bg-white border border-dark rounded-sm" style={{ padding: "15px 0"}}>
                                        {
                                            this.state._loadingRequirements && (
                                                <div className="col-12">
                                                    <p>Loading requirements...</p>
                                                </div>
                                            )
                                        }
                                        {
                                            Object.keys(values.SubOrgRequirements).map((k, i) => (
                                                <div key={i} className="col-12 col-md-3">
                                                    <CustomCheckbox 
                                                        name={`SubOrgRequirements[${k}]`}
                                                        id={`SubOrgRequirements-${k}`}
                                                        value={values.SubOrgRequirements[k]}
                                                        onChange={e => setFieldValue(`SubOrgRequirements['${k}']`, !!e.target.checked)}
                                                        label={requirementLabels[k]}
                                                    />
                                                </div>
                                            ))
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                            </div>


                            <div className="form-group" style={{verticalAlign: "top"}} id="AttachAndAdd-buttons">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>
                                            <b>Attach and add</b>
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{margin: 0}}>
                                        <div className="d-inline-block add-sites">
                                            <button type="button" className="btn btn-block btn-dark" onClick={() => this.setState({ _displayAddSitesModal: true })}>
                                                Add Sites
                                            </button>
                                            {
                                                values.AttachAndAdd.sites && (
                                                    <span className="btn btn-outline-dark btn-block text-center">
                                                        {values.AttachAndAdd.sites.label}
                                                    </span>
                                                )
                                            }
                                            <ErrorMessage name="AttachAndAdd[sites][value]" render={this.renderValidationError} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group m-0">
                                <hr style={{borderTopWidth: 2}} className="border-dark"/>
                            </div>

                            <div className="form-group text-right m-0">
                                <button type="submit" className="btn btn-dark" style={{minWidth: 257, marginTop: 24}} disabled={isSubmitting || this.state._loadingRequirements } onClick={e => {
                                    setFieldValue("publish", true);
                                    handleSubmit(e);
                                }}>{values.publish === true && isSubmitting ? `Saving...` : 'Save sub-org'}</button>
                            </div>
                            {this.renderAddSitesModal({setFieldValue, values})}

                            <div className="form-group">
                                <br/>
                                <br/>
                            </div>

                        </Form>
                    )
                }}
            </Formik>
        )
    }

    /**
     * Renders breadcrumbs at the top of the page
     */
    renderBreadcrumbs() {
        return <CustomBreadcrumbs />
    }

    /**
     * Renders organisation information
     */
    renderOrgSummary() {
        return (
            <div className="site-information">
                <OrgInfoSummary />
            </div>
        )
    }

    /**
     * Renders the title of the page
     */
    renderEntryHeader() {
        return (
            <div className="entry-header">
                <h2 style={{margin: 0}}>
                    <b>Create new Sub-org</b>
                </h2>
            </div>
        )
    }


    /**
     * Renders 'Attach site' modal
     * 
     * @param {object} props
     * @param {(field: string, value: any, shouldValidate?: boolean) => void} props.setFieldValue 
     * @param {any} props.values
     */
    renderAddSitesModal({ setFieldValue, values }) {

        return (
            <AddSitesModal
                value={values.AttachAndAdd.sites}
                show={this.state._displayAddSitesModal}
                onAdd={(selectedOption) => {
                    setFieldValue(`AttachAndAdd[sites]`, selectedOption)
                    this.setState({_displayAddSitesModal: false})
                }}
                onCancel={() => this.setState({_displayAddSitesModal: false})}
            />
        )
    }


    render() {
        return (
            <div className="container-fluid" style={{maxWidth: 1347, marginLeft: 0}}>
                <div className="content-block">
                    {this.renderBreadcrumbs()}
                </div>
                <div className="content-block">
                    {this.renderOrgSummary()}
                </div>
                <div className="content-block">
                    {this.renderEntryHeader()}
                </div>
                <div className="content-block">
                    {this.renderForm()}
                </div>
            </div>
        )
    }
}


function determineParentOrgBillingContactDetails(orgDetail) {
    const { basic_detail, org_contacts } = orgDetail || {}

    const TYPE_BILLING_CONTACT = 4

    const parentOrgBillingContact = (org_contacts || []).find(c => {
        const { value } = c.contact_type || {}
        return parseInt(value) === TYPE_BILLING_CONTACT
    })

    const { phone_primary, phone_secondary_1, phone_secondary_2 } = parentOrgBillingContact || {}
    const phones = [
        { is_primary: true, value: phone_primary, required: true },
        { is_primary: false, value: phone_secondary_1, required: false },
        { is_primary: false, value: phone_secondary_2, required: false },
    ]
    
    const { email_primary, email_secondary_1, email_secondary_2 } = parentOrgBillingContact || {}
    const emails = [
        { is_primary: true, value: email_primary, required: true },
        { is_primary: false, value: email_secondary_1, required: false },
        { is_primary: false, value: email_secondary_2, required: false },
    ]

    return {
        first_name: parentOrgBillingContact.first_name,
        last_name: parentOrgBillingContact.last_name,
        position: parentOrgBillingContact.position,
        department: parentOrgBillingContact.department,
        phones: phones.filter(p => !!p.is_primary || !!p.value),
        emails: emails.filter(p => !!p.is_primary || !!p.value),
        pay_roll_tax: (basic_detail || {}).payroll_tax,
        gst: (basic_detail || {}).gst,
    }
}


/**
 * Passes the name of current logged in organisation to this page
 * 
 * @param {import('../../reducers').RootState} state 
 */
const mapStateToProps = state => {
    const { orgDetails } = state
    const { value } = orgDetails
    const { org_contacts } = value || {}

    const TYPE_BILLING_CONTACT = 4

    const parentOrgBillingContact = (org_contacts || []).find(c => {
        const { value } = c.contact_type || {}
        return parseInt(value) === TYPE_BILLING_CONTACT
    })

    const {token} = state.auth.user
    let decoded = null;
    let organisationName = null
    if (token) {
        decoded = jwt.decode(token)
        organisationName = decoded['name'];
        if (organisationName) {
            organisationName = organisationName + ' (Parent org)'
        }
    }


    return {
        organisationName,
        orgDetails,
        parentOrgBillingContact,
    }
}


export default connect(mapStateToProps)(CreatePage)