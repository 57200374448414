import React from 'react';
import ReactDOM from 'react-dom';
import "../stylesheets/components/ContactDisplay.scss";
import "../stylesheets/icons.css";
import EditIconBlack from "../icons/Edit-Icon-Black.svg";
import BasicUserResponseModal from '../components/BasicUserResponseModal';
// import { confirmAlert as ReactConfirmAlert }  from 'react-confirm-alert';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { axios } from '../axios';
import objectToFormData from "object-to-formdata";
import {history} from "../history"
import { css } from '../helpers/common';
import _ from 'lodash'

/**
 * Component to render each contact's information for the organization in label:value format
 */
class ContactDisplayComponent extends React.Component {
	constructor(props) {
        super(props);
        this.httpConfig = {
            delete: {
                url: '/ParentOrgController/archiveOrganisationContact',
                data: {}
            }
        };

        this.state = {
            showCofirmArchive: false
        };
	}

    toggleDisplay = () => {
        const { contact, toggleContactDisplay } = this.props
        toggleContactDisplay(contact)
    }
   

    handleOnClickConfirmDelete = (e) => {
        e.preventDefault();
        this.setState({ showCofirmArchive: true });
    }


    deleteContact = async () => {
        const { modalRequestFrom, contact } = this.props
        const { id, siteId } = contact || {}

        if (modalRequestFrom == 'site') {
            try {
                const postData = JSON.stringify({ id, siteId })
                const formData = objectToFormData({ data: postData })
                const response = await axios.post("/site/delete_contact", formData);
                if (response.data) {
                    this.setState({ showCofirmArchive: false })
                    this.props.contactWasUpdatedCallback(response);
                }
            } catch (e) {
                console.error(e)
            }
        } else {
            const { contact_id, organisation_id } = contact || {}
            try {
                const res = await axios.post(`/ParentOrgController/archiveOrganisationContact`, { id: contact_id, org_id: organisation_id })
                this.setState({ showCofirmArchive: false })
                if (!!(res.data || {}).status) {
                    toast('Contact was archived successfully', {
                        toastId: 'archive_org_contact',
                        containerId: 'success_toast_container'
                    });
                    this.props.contactWasUpdatedCallback(res);
                }
            } catch (e) {
                console.error(e)
            }
        }
    }


    renderContactType(contact = {}) {
        const styles = css({
            label: {
                whiteSpace: "nowrap",
                fontSize: 22,
                width: "100%",
                textAlign: "left",
                paddingLeft: "calc(5% + 15px)"
            },
        })

        const label = (contact.contact_type || {}).label || 'Contact'

        return (
            <div className="row data-row d-flex">
                <div className="font-weight-bold label" style={styles.label}>
                    {label}
                </div>
            </div>
        )
    }


    /**
     * Display list of contact information details as label-values
     * 
     * @param {{label: string, value: JSX.Element | string }[]} details 
     */
    renderAllContactDetailItems(details = []) {
        return details.map((detail, i) => (
            <div className="row data-row d-flex" key={i}>
                <div className="font-weight-bold label">{detail.label} :</div>
                <div className="value">&nbsp;
                    { detail.value }
                </div>
            </div>
        ))
    }


    /**
     * Render heading text. 
     * Heading text must be provided as props of this component
     */
    renderHeader() {
        const { expanded, headerText } = this.props

        const styles = css({
            toggleParent: {
                textAlign: 'end',
                cursor: 'pointer',
                padding: 0,
                alignItems: 'center'
            }
        })

        return (
            <div className="row d-flex contact-header justify-content-between">
                <div className="col-sm-6 col-md-6 font-weight-bold contact-label">
                    {headerText}
                </div>
                <div className={`${expanded ? 'show' : 'hide'} `} style={styles.toggleParent} onClick={this.toggleDisplay}>
                    <span className="caret-action">
                        {expanded ? 'CLOSE' : 'OPEN'}
                    </span>
                    <div className="contact-caret"></div>
                </div>
            </div>
        )
    }


    /**
     * Handler when 'Save contact' was clicked
     * 
     * @param {React.MouseEvent<HTMLElement>} e
     */
    handleOnClickUpdateContact = e => {
        const { contact } = this.props
        this.props.updateButtonHandler(contact)
    }


    /**
     * Displays list of action buttons (there's 1 atm)
     */
    renderActions() {
        const { contact } = this.props
        const { contact_type } = contact || {}
        const { value } = contact_type || {}

        return (
            <div className="contact-actions d-flex justify-content-end align-items-center">
                <img className="icon icon-update" src={EditIconBlack} onClick={this.handleOnClickUpdateContact}/>
                {
                    !['3', '4'].includes(value) && (
                        <a className="icon icon-email-pending" onClick={this.handleOnClickConfirmDelete}></a>
                    )
                }
            </div>
        )
    }
    

	render() {
        const { contact, expanded, details } = this.props

        const styles = css({
            root: {
                display: expanded ? 'block' : 'none'
            },
            inner: {
                display: expanded ? 'block' : 'none'
            }
        })

        return (
            <div>
                <div className="contact-data" styles={styles.root}>
                    { this.renderHeader() }
                    <div className={expanded ? 'd-flex flex-column contact-details' : ''} style={styles.inner}>
                        { this.renderContactType(contact) }
                        { this.renderAllContactDetailItems(details) }
                        { this.renderActions() }
                    </div>
                </div>

                <BasicUserResponseModal
                    showModal={this.state.showCofirmArchive}
                    confirmActionHandler={this.deleteContact}
                    closeModalHandler={() => {this.setState({ showCofirmArchive: false })}}
                ></BasicUserResponseModal>
            </div>
        );
    }
}

export default ContactDisplayComponent;