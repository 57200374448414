import { LOCALSTORAGE_KEY } from "../constants";

let user = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY))

const initialState = user 
        ? { loggedIn: true, user: user, passwordResetLoading: false, isSubmittingTempPasswordReset: false,  }
        : { passwordResetLoading: false, isSubmittingTempPasswordReset: false,  }

export function auth(state = initialState, action) {
    switch (action.type) {
        case "LOGIN_REQUEST":
            return {
                loggingIn: true,
                user: action.payload
            }
        case "LOGIN_SUCCESS":
            return {
                loggingIn: false,
                user: action.payload
            }
        case "LOGIN_FAILURE":
            return {}
        case "LOGOUT":
            return {}
        case "SEND_PASSWORD_RESET_EMAIL_REQUESTED":
            return {
                ...state,
                passwordResetLoading: true
            }
        case "SEND_PASSWORD_RESET_EMAIL_SUCCESS":
            return {
                ...state,
                passwordResetLoading: false,
            }
        case "SEND_PASSWORD_RESET_EMAIL_FAILURE":
            return {
                ...state,
                passwordResetLoading: false,
            }
        case "TEMPORARY_PASSWORD_RESET_REQUESTED":
            return {
                ...state,
                isSubmittingTempPasswordReset: true,
            }
        case "TEMPORARY_PASSWORD_RESET_SUCCESS":
        case "TEMPORARY_PASSWORD_RESET_FAILURE": 
            return {
                ...state,
                isSubmittingTempPasswordReset: false,
            }
        default:
            return state
    }
}