import React, { Component } from 'react';
import { connect } from 'react-redux'
// import Select from 'react-select-plus';
import Modal from 'react-bootstrap/Modal'
import { axios } from "../axios";
import SimpleReactValidator from 'simple-react-validator';
import TextInputContactComponent from './TextInputContactComponent';
import {organisatiionTaxList, organisatiionGstList} from '../services/organisationService.js';
import {organisationService} from '../services/organisationService.js';
import GooglePlacesAutocomplete from 'react-google-autocomplete'
import { findAddressComponent } from '../helpers/google-places'
import {CustomSelect, CustomAsyncSelect} from '../components/CustomSelect';
import RequiredLabel from './RequiredLabel';

import 'react-select-plus/dist/react-select-plus.css';

/**
 * Modal component to udpate organization detail
 */
class UpdateOrgDetailModalComponent extends Component {
    constructor(props) {
        super(props);

        // Initialize simple-react validator
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: 'text-danger form-error'
        });

        this.state = {
            address: {
                street: '',
                state: {
                    value: null,
                    label: null
                },
                suburb: {
                    value: null,
                    label: null
                },
                postcode: null,
            },
            httpConfig: {
                fetchSuburbsUrl: 'CommonController/getSuburbList'
            },        
            tax: {
                value: null,
                label: null
            },
            gst: {
                value: null,
                label: null
            },
            website: '',
            phone_primary: '',
			phone_secondary_1: null,
			phone_secondary_2: null,
			email_primary: '',
			email_secondary_1: null,
			email_secondary_2: null,
            show_me: false,
            orgBasicDetails: null,
            filename: null
        }
    }

    /**
     * Function to handle form submission
     * @param {Event} event 
     */
    submitForm(event) {
        const updateOrgDetailUrl = '/ParentOrgController/updateOrgDetails';
        event.preventDefault();

        if (this.validator.allValid()) {
            var formData = new FormData(event.target);

            formData.append('suburb', this.state.address.suburb.label);
            formData.append('is_upload_img', true);
            formData.append('ocs_id', this.props.orgDetails && this.props.orgDetails.basic_detail && this.props.orgDetails.basic_detail.ocs_id || null);

            formData = organisationService.nomralizeSecondaryContacts(formData);

            axios.post(updateOrgDetailUrl, formData).then((response) => {
                this.props.afterUpdatingOrgDetail(response);
                this.setState({
                    selectedFile: null, 
                });
            })
        } else {
            this.validator.showMessages();
        }
    }

    /**
     * This function determines whether maximum secondary phone/email input has been reached
     * @param {string} type - type of the secondary contact(email, phone or all)
     */
    decideSecondaryContactComponentIsAllowed = (type) => {
        var countSecondaryPhn = type === 'all' ? 0 : this.state.countSecondaryPhn;
        var countSecondaryEmail = type === 'all' ? 0 : this.state.countSecondaryEmail;

        if(type === 'all') {
            for(const [prop, val] of Object.entries(this.state)) {
                if(prop.includes('phone_secondary_') && val !== null) {
                    countSecondaryPhn = countSecondaryPhn + 1;
                } else if(prop.includes('email_secondary_') && val !== null) {
                    countSecondaryEmail = countSecondaryEmail + 1;
                }
            }
        } else if(type === 'phone') {
            countSecondaryPhn++;
        } else if(type === 'email') {
            countSecondaryEmail++;
        }

        this.setState({
            countSecondaryPhn,
            countSecondaryEmail,
            acceptSecondaryPhn: countSecondaryPhn <= 2,
            acceptSecondaryEmail: countSecondaryEmail <= 2,
        });
    }

    /**
     * Function to handle file inuput
     * @param {Event} event 
     */
    fileChangedHandler(event) {
        this.setState({
            selectedFile: event.target.files[0], 
            filename: event.target.files[0].name
        });
    }

    /**
     * Function that fetches suburbs based on user's typed string
     * @param {string} userInput 
     * @param {function} callback 
     */
    fetchSuburbs(userInput, callback) {
        // Dont start fetching until user has types at least two letters
        if(userInput.length >= 2) {
            axios.post(this.state.httpConfig.fetchSuburbsUrl, {
                str: userInput,
                state: this.state.address.state.value
            }).then(response => {
                if(response.data.data != null && typeof response.data.data !== 'undefined') {
                    callback(
                        null, 
                        {
                            options: response.data.data,
                            complete: true
                        },
                    );
                }
            });
        }
    }
    
    // on-change handler for text inputs
    handleTextInputChange = (event, property, indirectlyChangedValue) => {
        var nestedProps = property.split('.');

        if(nestedProps.length == 2) {
            var level1Prop = nestedProps[0];
            var level2Prop = nestedProps[1];
            var current = {...this.state[nestedProps[0]]};

            // This IF check is required to make sure that typing in the street input box will not set it's value to the google's autocomplete api input object. 
            if(typeof event.target.value !== 'object') {
                this.setState({
                    [level1Prop]: { ...current, [level2Prop]: event.target.value}
                });
            }
        } else if(nestedProps.length == 1) {
            var obj = {
                [property]: indirectlyChangedValue !== undefined ? indirectlyChangedValue : event.target.value
            };
    
            if(indirectlyChangedValue !== undefined) {
                if(property.includes('phone_secondary_')) {
                    obj.countSecondaryPhn = this.state.countSecondaryPhn - 1;
                } else if(property.includes('email_secondary_')) {
                    obj.countSecondaryEmail = this.state.countSecondaryEmail - 1;
                }
            }

            this.setState({
                ...obj
            });
        }
    }

    // on-change handler for react-select-plus inputs
    handleSelectChange(selectedOption, property) {
        var nestedProps = property.split('.');

        if(nestedProps.length == 2) {
            var level1Prop = nestedProps[0];
            var level2Prop = nestedProps[1];
            var current = {...this.state[nestedProps[0]]};

            this.setState({
                [level1Prop]: { ...current, [level2Prop]: selectedOption}
            });
        } else if(nestedProps.length == 1) {
            var prop = nestedProps[0];
            this.setState({
                [prop]: selectedOption
            });
        }
    }

    // populating component state from props. Since props are passed from parent and some of those prop's values depends on an async network call, the child will re-render when it received the new values from the parent
    static getDerivedStateFromProps(props, oldState) {
        var orgBasicDetails = (props.orgDetails && props.orgDetails.basic_detail) || null;
        var obj = oldState;

        // This will ensure that state change by onChange handlers are not cancelled out by getDerivedStateFromProps function call
        if(orgBasicDetails !== oldState.orgBasicDetails) {
            if(orgBasicDetails && orgBasicDetails.street != oldState.address.street) {
                obj.address.street = orgBasicDetails.street;
            }

            if(orgBasicDetails && orgBasicDetails.state != oldState.address.state.value) {
                obj.address.state = {
                    value: orgBasicDetails.state,
                    label: orgBasicDetails.statename
                }
            }

            if(orgBasicDetails && orgBasicDetails.city && orgBasicDetails.city.value != oldState.address.suburb.value) {
                obj.address.suburb = {
                    value: orgBasicDetails.city.value,
                    label: orgBasicDetails.city.label
                }
            }

            if(orgBasicDetails && orgBasicDetails.postal != oldState.address.postcode) {
                obj.address.postcode = orgBasicDetails.postal
            }

            if(orgBasicDetails && orgBasicDetails.payroll_tax != oldState.tax.value) {
                obj.tax = { 
                    label: orgBasicDetails.payroll_tax === "1" ? 'Yes' : 'No',
                    value: orgBasicDetails.payroll_tax
                }
            }

            if(orgBasicDetails && orgBasicDetails.gst != oldState.gst.value) {
                obj.gst = { 
                    label: orgBasicDetails.gst === "1" ? 'Yes' : 'No',
                    value: orgBasicDetails.gst
                }
            }

            if(orgBasicDetails && orgBasicDetails.website != oldState.website) {
                obj.website = orgBasicDetails.website;
            }

            if(orgBasicDetails && orgBasicDetails.OrganisationPh && orgBasicDetails.OrganisationPh != oldState.phone) {
                var primaryPhone = orgBasicDetails.OrganisationPh.filter(el => {
                    return el.primary_phone === "1";
                })[0];

                obj.phone_primary = primaryPhone && primaryPhone.phone;

                var secondaryPhones = orgBasicDetails.OrganisationPh.filter(el => {
                    return el.primary_phone === "2";
                });

                if(secondaryPhones.length) {
                    secondaryPhones.forEach((elem, ind) => {
                        var tmp = ind + 1;
                        obj[`phone_secondary_${tmp}`] = elem.phone;
                    });
                } else {
                    obj.phone_secondary_1 = null;
                    obj.phone_secondary_2 = null;
                }
            }

            if(orgBasicDetails && orgBasicDetails.OrganisationEmail && orgBasicDetails.OrganisationEmail != oldState.email) {
                var primaryEmail = orgBasicDetails.OrganisationEmail.filter(el => {
                    return el.primary_email === "1";
                })[0];

                obj.email_primary = primaryEmail && primaryEmail.email;

                var secondaryEmails = orgBasicDetails.OrganisationEmail.filter(el => {
                    return el.primary_email === "2";
                });

                if(secondaryEmails.length) {
                    secondaryEmails.forEach((elem, ind) => {
                        var tmp = ind + 1;
                        obj[`email_secondary_${tmp}`] = elem.email;
                    });
                } else {
                    obj.secondary_email_1 = null;
                    obj.secondary_email_2 = null;
                }
            }

            if(orgBasicDetails && orgBasicDetails.logo_file_name != oldState.filename) {
                obj.filename = orgBasicDetails.logo_file_name;
            }

            // Important to place this here. Otherwise any changes to the component state will be cancelled out by the code above
            obj.orgBasicDetails = orgBasicDetails;

            return obj;
        }
        
        return null;
    }

    componentDidMount() {
        this.decideSecondaryContactComponentIsAllowed('all');
    }

    componentDidUpdate(prevProps) {
        if(this.props.orgDetails !== prevProps.orgDetails) {
            this.decideSecondaryContactComponentIsAllowed('all');
        }
    }

    /**
     * Function that extract individual address elemetns from google's atuto complete address api's result and set's the component state for 'address'
     * @param {object} googlePlacesResult
     */
    autoCompleteAddressComponents = (googlePlacesResult) => {
        const { stateList } = this.props

        const address = {
            state: findAddressComponent(googlePlacesResult, "administrative_area_level_1"),
            orgPostcode: findAddressComponent(googlePlacesResult, "postal_code"),
            orgSuburb: findAddressComponent(googlePlacesResult, "locality"),
            orgStreetNo: findAddressComponent(googlePlacesResult, "street_number"),
            orgStreetName: findAddressComponent(googlePlacesResult, "route", true),
        }

        let { state, orgStreetNo, orgStreetName, orgPostcode, orgSuburb } = address;

        let obj = {
            street: [orgStreetNo, orgStreetName].filter(_ => _).join(' '), // in case street no cannot be extracted
            postcode: orgPostcode,
            suburb: {
                label: orgSuburb.replace(/\b(\w+)\b/, w => w.charAt(0).toUpperCase() + w.substring(1)),
                value: orgSuburb.replace(/\b(\w+)\b/, w => w.charAt(0).toUpperCase() + w.substring(1))
            },
            state: state && (stateList || []).find( elem => elem.label.toLowerCase() == state.toLowerCase())
        }

        this.setState(prevState => ({
            address: { 
                ...prevState.address, 
                ...obj 
            }
        }))
        
    }

    render() {
        var showMe = this.props.showPopup;
        var modalId = this.props.modalId || '';
        var stateList = this.props.stateList || [];

        const { orgDetails } = this.props
        const { basic_detail } = orgDetails || {}
        const { name } = basic_detail || {}
        
        return (
            <Modal 
                show={showMe}
                aria-labelledby="contained-modal-title"
                size="lg"
                id={`${modalId}`}
                centered>

                <Modal.Header>
                    <div className="d-flex align-items-center">
                        <Modal.Title>Update '{name}':</Modal.Title>
                        <span className="icon icon-cross-icons" onClick={this.props.closeModal}></span>                        
                    </div>

                </Modal.Header>

                <Modal.Body>
                    <form id="updateOrgDetail" encType="multipart/form-data" onSubmit={event => { this.submitForm(event) }}>
                        <div className="form-row" >                  
                            <div className="form-group col-md-4">
                                <RequiredLabel>Street: </RequiredLabel>
                                <GooglePlacesAutocomplete 
                                    name="street"
                                    className="form-control border-dark"
                                    value={this.state.address.street}
                                    onChange={event => { this.handleTextInputChange(event, 'address.street') } } 
                                    onPlaceSelected={(place) => this.autoCompleteAddressComponents(place)}
                                    types={['address']}
                                    componentRestrictions={{country: "au"}}
                                />
                            </div>

                            <div className="form-group col-md-2">
                                <RequiredLabel>State: </RequiredLabel>
                                <CustomSelect className="border-dark small"
                                    name="state"
                                    required={true}
                                    simpleValue={false}
                                    clearable={false}
                                    cache={false}
                                    value={this.state.address.state.value}  
                                    onChange={selectedVal => { this.handleSelectChange(selectedVal, 'address.state') } } 
                                    options={stateList} 
                                    placeholder="Please Select" />
                                {this.validator.message('state', this.state.address.state.value, 'required')}
                            </div>

                            <div className="form-group col-md-4">
                                <RequiredLabel>Suburb:</RequiredLabel>
                                <CustomAsyncSelect className="border-dark"
                                    name="suburb"
                                    required={true}
                                    simpleValue={false}
                                    clearable={false}
                                    cache={false}
                                    value={this.state.address.suburb || ''} 
                                    disabled={false}
                                    loading={false}
                                    autoload={false}
                                    loadOptions={(val, callback) => { this.fetchSuburbs(val, callback) }}
                                    onSelectResetsInput={false}
                                    onBlurResetsInput={false}
                                    onChange={selectedVal => this.handleSelectChange(selectedVal, 'address.suburb')} 
                                    placeholder="Please Select" required />
                                {this.validator.message('suburb', this.state.address.suburb.value, 'required')}
                            </div>

                            <div className="form-group col-md-2">
                                <RequiredLabel>Postcode: </RequiredLabel>
                                <input className="form-control border-dark" 
                                    name="postcode"
                                    type="text"
                                    value={this.state.address.postcode || ''} 
                                    onChange={event => this.handleTextInputChange(event, 'address.postcode')}
                                    minLength="4" maxLength="4"
                                />
                                {this.validator.message('postcode', this.state.address.postcode, 'min:4|max:4|required')}
                            </div>
                        </div>

                        <div className="form-row" >                  
                            <div className="form-group col-md-4">
                                <RequiredLabel>Payroll Tax: </RequiredLabel>
                                <CustomSelect clearable={false} className="border-dark" 
                                    name="tax"
                                    simpleValue={false}
                                    required={true}
                                    value={this.state.tax.value}  
                                    onChange={selectedVal => this.handleSelectChange(selectedVal, 'tax')} 
                                    options={organisatiionTaxList}
                                    placeholder="Please Select" />
                                {this.validator.message('tax', this.state.tax.value, ['required',{in: ['0','1']}])}
                            </div>
                            <div className="form-group col-md-4">
                                <RequiredLabel>GST: </RequiredLabel>
                                <CustomSelect clearable={false} className="border-dark"
                                    name="gst"
                                    simpleValue={false}
                                    required={true}
                                    value={this.state.gst}  
                                    onChange={selectedVal => this.handleSelectChange(selectedVal, 'gst')} 
                                    options={organisatiionGstList}
                                    placeholder="Please Select" />
                                {this.validator.message('gst', this.state.gst.value, ['required',{in: ['0','1']}])}
                            </div>

                            <div className="form-group col-md-4">
                                <RequiredLabel>Website: </RequiredLabel>
                                <input className="form-control border-dark" type="text"
                                    name="website"
                                    value={this.state.website} 
                                    onChange={event => this.handleTextInputChange(event, 'website')}  
                                />
                                {this.validator.message('website', this.state.website, 'required')}
                            </div>
                        </div>

                        <div className={`form-row ${this.state.filename ? 'mb-n-16' : ''}`}>
                            <div className="form-group col-md-4">
                                <RequiredLabel>Phone(Primary): </RequiredLabel>
                                <TextInputContactComponent
                                    type="phone"
                                    value={this.state.phone_primary}
                                    isPrimary={true}
                                    displaySecondaryContactHandler={this.decideSecondaryContactComponentIsAllowed}
                                    handleTextInputChange={this.handleTextInputChange}
                                    countSecondaryContact={this.state.countSecondaryPhn}
                                    validator={this.validator}
                                    rules="required|phone|size:10"
                                ></TextInputContactComponent>
                            </div>
                            <div className="form-group col-md-4">
                                <RequiredLabel>Email(Primary): </RequiredLabel>
                                <TextInputContactComponent
                                    type="email"
                                    value={this.state.email_primary}
                                    isPrimary={true}
                                    displaySecondaryContactHandler={this.decideSecondaryContactComponentIsAllowed}
                                    handleTextInputChange={this.handleTextInputChange}
                                    countSecondaryContact={this.state.countSecondaryEmail}
                                    validator={this.validator}
                                    rules="required|email"
                                ></TextInputContactComponent>
                            </div>
                            <div className="form-group col-md-4">
                                <label>Logo: </label>
                                <label className="btn label-on-top upload btn-dark">
                                    <span aria-hidden="true">Upload logo</span>
                                    <input className="custom-file-input"
                                        name="org-logo"
                                        type="file"
                                        id="org_logo"
                                        onChange={ event => { this.fileChangedHandler(event) } } 
                                    />
                                </label>
                                {this.state.filename ? <span className='file-name'>File Name: <small>{this.state.filename}</small></span>: ''}
                            </div>
                        </div>

                        {/************************************** secondary components start here ********************************/}
                        <div className="form-row">
                            <div className="form-group col-md-4">
                            { (this.state.phone_secondary_1 || (this.state.acceptSecondaryPhn && this.state.countSecondaryPhn >= 1)) &&
                                <>
                                    <label>Phone(Secondary)</label>
                                    <TextInputContactComponent
                                        type="phone"
                                        serial="1"
                                        value={this.state.phone_secondary_1}
                                        isPrimary={false}
                                        handleTextInputChange={this.handleTextInputChange}
                                        validator={this.validator}
                                        rules="required|phone|size:10"
                                    ></TextInputContactComponent>
                                </>
                            }
                            </div>

                            <div className="form-group col-md-4">
                            { (this.state.email_secondary_1 || (this.state.acceptSecondaryEmail && this.state.countSecondaryEmail >= 1)) &&
                                <>
                                    <label>Email(Secondary)</label>
                                    <TextInputContactComponent
                                        type="email"
                                        serial="1"
                                        value={this.state.email_secondary_1}
                                        isPrimary={false}
                                        handleTextInputChange={this.handleTextInputChange}
                                        validator={this.validator}
                                        rules="required|email"
                                    ></TextInputContactComponent>
                                </>
                            }
                            </div> 
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                            { (this.state.phone_secondary_2 || (this.state.acceptSecondaryPhn && this.state.countSecondaryPhn == 2)) &&
                                <>
                                    <label>Phone(Secondary)</label>
                                    <TextInputContactComponent
                                        type="phone"
                                        serial="2"
                                        value={this.state.phone_secondary_2}
                                        isPrimary={false}
                                        handleTextInputChange={this.handleTextInputChange}
                                        validator={this.validator}
                                        rules="required|phone|size:10"
                                    ></TextInputContactComponent>
                                </>
                            }
                            </div>

                            <div className="form-group col-md-4">
                            { (this.state.email_secondary_2 || (this.state.acceptSecondaryEmail && this.state.countSecondaryEmail == 2)) &&
                                <>
                                    <label>Email(Secondary)</label>
                                    <TextInputContactComponent
                                        type="email"
                                        serial="2"
                                        value={this.state.email_secondary_2}
                                        isPrimary={false}
                                        handleTextInputChange={this.handleTextInputChange}
                                        validator={this.validator}
                                        rules="required|email"
                                    ></TextInputContactComponent>
                                </>
                            }
                            </div> 
                        </div>

                        <div className="form-row row d-flex justify-content-end">
                            <button className="btn btn-dark" type="submit">Save Changes</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

/**
 * 
 * @param {import('../reducers').RootState} state 
 */
const mapStateToProps = state => {
    const { australianStates } = state
    const { items: stateList } = australianStates

    return {
        stateList
    }
}


export default connect(mapStateToProps)(UpdateOrgDetailModalComponent);
