import React from 'react'
import { connect } from 'react-redux'
import jwt from "jsonwebtoken"
import 'react-select-plus/dist/react-select-plus.css';

/**
 * layout page for privacy policy
 */
class PrivacyPolicy extends React.Component {

	constructor(props) {
		super(props);
	}

	renderPage() {
		return (
			<div>
			<div className="entry-header mt-5">
				<h2><b>Privacy Policy</b></h2>
			</div>
			<div className="content mt-4">
				<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum egestas velit nibh, id mattis massa accumsan id. Aenean ut erat in nunc feugiat lobortis. Vivamus elementum neque arcu, sit amet dignissim mauris consectetur posuere. Maecenas urna elit, molestie in urna quis, ultricies sollicitudin nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis efficitur euismod sagittis. Sed lacinia ornare pretium.
				</p>
				<p>
				Nunc viverra eget augue ut rhoncus. Nulla sed nibh at nunc egestas molestie. Suspendisse lobortis libero ut ex elementum, in sollicitudin dui sollicitudin. In aliquam, elit et lobortis porta, eros ligula aliquam ligula, ac accumsan ipsum magna non ex. Donec iaculis, arcu eu dignissim feugiat, lacus dui viverra elit, a hendrerit mauris nisl nec dui. Duis scelerisque in mi ac facilisis. Vestibulum vestibulum urna sed luctus finibus. Duis ut congue dolor. Nulla facilisi. In fermentum tincidunt augue, et aliquam augue ultricies imperdiet. Curabitur in risus non orci sodales sagittis eget quis elit. Aenean arcu mauris, vehicula non imperdiet in, vestibulum nec sem. Suspendisse nec porta eros. Donec eleifend ipsum in lectus posuere malesuada. Praesent venenatis quam non dolor viverra, non efficitur quam porta.
				</p>
				<p>
				Proin accumsan dignissim nibh, ac egestas nisi efficitur pellentesque. Fusce at ante ut nibh aliquam condimentum. Nulla interdum pretium nunc quis suscipit. Pellentesque tincidunt purus nisl, sit amet eleifend tellus blandit nec. Ut finibus ligula nunc, sed tempor quam gravida sed. Fusce congue ante non neque bibendum tristique vel a enim. Pellentesque blandit vulputate turpis eu feugiat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum ornare augue nec condimentum varius. Nullam neque eros, dictum quis dignissim vitae, cursus quis elit. Vestibulum et tristique nunc, vitae placerat dolor. Curabitur interdum purus vel leo porttitor, vitae venenatis orci luctus. Cras id massa eget justo venenatis fringilla. Nam ornare neque ac eros elementum, vestibulum sodales sem molestie.
				</p>
				<p>
				Curabitur luctus felis sem, eget cursus orci ornare at. Vestibulum pellentesque vel risus quis eleifend. Integer facilisis rhoncus dui nec pellentesque. Aenean non laoreet tortor, et rutrum ligula. Integer semper ut lorem id congue. Pellentesque eget congue mauris. Ut sapien velit, semper posuere imperdiet vitae, finibus sed tellus. Nunc auctor arcu consequat felis eleifend gravida. Maecenas tristique ligula cursus eleifend tristique. Suspendisse ipsum tortor, vulputate vitae feugiat varius, sagittis eget neque. 	
				</p>
			</div>
			</div>
		)
	}

	render() {
		return (
			<div className="container-fluid">
				<div className="content-block">
					{this.renderPage()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {token} = state.auth.user

	let claims = null;
	if (token) {
		claims = jwt.decode(token)
	}

	return {
		organisationName: (claims || {})['name']
	}
}

export default connect(mapStateToProps)(PrivacyPolicy)
