import { axios } from "../axios";
import { LOCALSTORAGE_LOGIN_TIME_KEY, LOGIN_URL } from "../constants";
import { logout } from "../actions/userActions";
import { LOGIN_DIFFERENCE } from "../config";
import moment from "moment";
import _ from 'lodash'

export const queryOptions = async ({ inputValue, url, data, maxLength = 0, stringify = 0}) => {
    if (!inputValue || inputValue.length < parseInt(maxLength)) {
        return Promise.resolve({ options: [] }).then(res => res);
    }

    const req = parseInt(stringify) == 1 ? data : JSON.stringify(data);
    return await axios.post(url, req).then(r => r.data)
}

/** 
 * This is just to make strongly typed inline styling. 
 * Note: Don't use nested styling
 * @template K
 * @param {Record<keyof K, CSSStyleDeclaration>} s
 * @return {Record<keyof K, CSSStyleDeclaration>}
 */
export const css = s => s


// Accessor and mutator to login time key
export const setLoginTime = (token) => localStorage.setItem(LOCALSTORAGE_LOGIN_TIME_KEY, token);
export const getLoginTime = () => localStorage.getItem(LOCALSTORAGE_LOGIN_TIME_KEY);


/**
 * Helper function to clear session and logout
 * 
 * @param {string} queryString 
 */
export const logoutAndRedirect = (queryString = null) => {
    logout();
    window.location = LOGIN_URL + queryString ? `?${queryString}` : ''
    return;
}


/**
 * Routine to check for user inactivity. User is considered inactive if the 
 * user did not make requests to the server within next 60 minutes. 
 * If that's the case, therefore the user is logged out.
 * 
 * @param {() => void} clearIntervalCallback 
 */
export const checkLoginTime = (clearIntervalCallback) => {
    const DATE_TIME = getLoginTime();
    const server = moment(DATE_TIME)
    const currentDateTime = moment()
    const diff = currentDateTime.diff(server);
    const diffDuration = moment.duration(diff);

    if (diffDuration.days() > 0) {
        clearIntervalCallback()
        return logoutAndRedirect()
    }

    if (diffDuration.hours() > 0) {
        clearIntervalCallback()
        return logoutAndRedirect()
    }

    if (diffDuration.minutes() > LOGIN_DIFFERENCE) {
        clearIntervalCallback()
        return logoutAndRedirect()
    }
}


/**
 * Determine entity type from routes
 * 
 * @param {string} path You can get this from `props.match.path`
 */
export const determineEntityType = (path) => {
    if (_.startsWith(path, '/sub-orgs/:id')) {
        return 'SUBORG'
    } else if (_.startsWith(path, '/site/:id') || _.startsWith(path, '/sites/:id')) {
        return 'SITE'
    }

    return 'ORG'
}

/**
 * Prevents non-number keys from being entered
 * @param {KeyboardEvent} e 
 */
export function ensureOnlyNumericKeypressAllowed(e) {
    if (!(/[0-9]/.test(e.key))) {
        e.preventDefault()
    }
}

export function removeTrailingSlashes(str) {
    return str.replace(/\/+$/, '')
}