import React, { Component } from "react";
import Select from "react-select-plus";
import Modal from "react-bootstrap/Modal";
import { axios } from "../axios";
import SimpleReactValidator from "simple-react-validator";
import TextInputContactComponent from './TextInputContactComponent';
import {organisationService} from '../services/organisationService.js';
import {prestineContactState} from '../services/contactService.js';
import RequiredLabel from './RequiredLabel';
import "react-select-plus/dist/react-select-plus.css";

/**
 * Modal component to udpate organization detail
 */
class UpdateContactDetailModalComponent extends Component {
	constructor(props) {
		super(props);

		// Initialize simple-react validator
		this.validator = new SimpleReactValidator({
			autoForceUpdate: this,
			className: "text-danger form-error"
		});
       
        this.state = prestineContactState;
	}

    static getDerivedStateFromProps(props, oldState) {
        var contactDetails = (props.loadedContact) || null;
        var obj = oldState;

        // This will ensure that state change by onChange handlers are not cancelled out by getDerivedStateFromProps function call
        // if(contactDetails == null) {
        //     return prestineContactState;
        // } else 
        if(contactDetails !== oldState.contactDetails) {
            if(contactDetails && contactDetails.contact_type && contactDetails.contact_type.value != oldState.contact_type.value) {
                obj.contact_type = contactDetails.contact_type;
            }

            if(contactDetails && contactDetails.first_name != oldState.first_name) {
                obj.first_name = contactDetails.first_name;
            }

            if(contactDetails && contactDetails.last_name != oldState.last_name) {
                obj.last_name = contactDetails.last_name;
            }

            if(contactDetails && contactDetails.position != oldState.position) {
                obj.position = contactDetails.position;
            }

            if(contactDetails && contactDetails.department != oldState.department) {
                obj.department = contactDetails.department;
            }

            if(contactDetails && contactDetails.phone_primary != oldState.phone_primary) {
                obj.phone_primary = contactDetails.phone_primary;
            }

            if(contactDetails && contactDetails.email_primary != oldState.email_primary) {
                obj.email_primary = contactDetails.email_primary;
            }

            if(contactDetails && contactDetails.phone_secondary_1 != oldState.phone_secondary_1) {
                obj.phone_secondary_1 = contactDetails.phone_secondary_1;
			}
			
			if(contactDetails && contactDetails.phone_secondary_2 != oldState.phone_secondary_2) {
                obj.phone_secondary_2 = contactDetails.phone_secondary_2;
            }

            if(contactDetails && contactDetails.email_secondary_1 != oldState.email_secondary_1) {
                obj.email_secondary_1 = contactDetails.email_secondary_1;
			}
			
			if(contactDetails && contactDetails.email_secondary_2 != oldState.email_secondary_2) {
                obj.email_secondary_2 = contactDetails.email_secondary_2;
            }

            // Important to place this here. Otherwise any changes to the component state will be cancelled out by the code above
            obj.contactDetails = contactDetails;

            return obj;
        }
        
        return null;
    }
    
    /**
     * Function to handle form submission
     * @param {Event} event 
     */
	submitForm(event, actionMode) {
		const updateContactDetailUrl = "/ParentOrgController/updateContactDetails";
		event.preventDefault();

		if (this.validator.allValid()) {
            var secondaryPhones = [];
            var secondaryEmails = [];

            var formData = new FormData(event.target);

            if(actionMode === 'update') {
                formData.append("edit_mode", '1');
                formData.append('contact-type', this.props.loadedContact.contact_type.value);
                formData.append("org_id", this.props.loadedContact.organisation_id);
                formData.append("id", this.props.loadedContact.contact_id);
            } else {
                formData.append('contact-type', this.props.contactTypes.find(elem => {
                    return elem.label.toLowerCase() === 'other' 
                }).value);

                formData.append("org_id", this.props.orgId);
            }

            formData = organisationService.nomralizeSecondaryContacts(formData);

			axios.post(updateContactDetailUrl, formData).then(response => {
                this.props.contactWasUpdatedCallback(response);
			}).finally(() => {
            });
		} else {
			this.validator.showMessages();
		}
	}

	// on-change handler for text inputs
	handleTextInputChange = (event, property, indirectlyChangedValue) => {
        var obj = {
            [property]: indirectlyChangedValue !== undefined ? indirectlyChangedValue : event.target.value
        };

        if(indirectlyChangedValue !== undefined) {
            if(property.includes('phone_secondary_')) {
                obj.countSecondaryPhn = this.state.countSecondaryPhn - 1;
            } else if(property.includes('email_secondary_')) {
                obj.countSecondaryEmail = this.state.countSecondaryEmail - 1;
            }
        }

		this.setState({
            ...obj
		});
	}

	// on-change handler for react-select-plus inputs
	handleSelectChange(selectedOption, property) {
		this.setState({
			[property]: selectedOption
		});
    }
    
    /**
     * Function that evaluates whether more secondary contact should be allowed in the form
     */
    decideSecondaryContactComponentIsAllowed = (type) => {
        var countSecondaryPhn = type === 'all' ? 0 : this.state.countSecondaryPhn;
        var countSecondaryEmail = type === 'all' ? 0 : this.state.countSecondaryEmail;

        if(type === 'all') {
            for(const [prop, val] of Object.entries(this.state)) {
                if(prop.includes('phone_secondary_') && val !== null) {
                    countSecondaryPhn = countSecondaryPhn + 1;
                } else if(prop.includes('email_secondary_') && val !== null) {
                    countSecondaryEmail = countSecondaryEmail + 1;
                }
            }
        } else if(type === 'phone') {
            countSecondaryPhn++;
        } else if(type === 'email') {
            countSecondaryEmail++;
        }

        this.setState({
            countSecondaryPhn,
            countSecondaryEmail,
            acceptSecondaryPhn: countSecondaryPhn <= 2,
            acceptSecondaryEmail: countSecondaryEmail <= 2,
        });
    }

    componentDidMount() {
        this.decideSecondaryContactComponentIsAllowed('all');
    }

    componentDidUpdate(prevProps) {
        if(this.props.loadedContact !== prevProps.loadedContact) {
            this.decideSecondaryContactComponentIsAllowed('all');
        }
    }

	render() {
		var showMe = this.props.updateModalShouldLanuch;
		var modalId = this.props.modalId || "";
        const actionMode = this.props.mode;
		// var contactDetails = this.props.contactDetail;

		return (
			<Modal
				show={showMe}
				aria-labelledby="contained-modal-title"
				size="lg"
				id={`${modalId}`}
				centered
			>
				<Modal.Header>
					<div className="d-flex align-items-center">
						<Modal.Title>{actionMode == 'create' ? 'Create Contact' : 'Edit Contact'}</Modal.Title>
						<span className="icon icon-cross-icons"
							onClick={this.props.updateContactModalCloseBtnHandler}
						></span>
					</div>
				</Modal.Header>

				<Modal.Body>
					<form id="updateContactDetails" onSubmit={event => {
                           this.submitForm(event, actionMode);
                        }}>

						<div className="form-row">
                            <div className="form-group col-md-4">
								{actionMode === 'update' ? (
                                        <>
                                            <RequiredLabel>Contact type: </RequiredLabel>
                                            <div className="form-control input border-dark disabled">
                                                {this.state.contact_type.label}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <label>Contact type: </label>
                                            <div className="form-control input border-dark disabled">
                                                Other
                                            </div>
                                        </>
                                    )}
							</div>

							<div className="form-group col-md-4">
								<RequiredLabel>First Name: </RequiredLabel>
								<input
									className="form-control required border-dark"
									name="first-name"
									type="text"
									value={this.state.first_name}
									onChange={e =>this.handleTextInputChange(e, "first_name")}
								/>
								{this.validator.message("first-name", this.state.first_name, "required")}
							</div>

                            <div className="form-group col-md-4">
								<RequiredLabel>Last Name: </RequiredLabel>
								<input
									className="form-control required border-dark"
									name="last-name"
									type="text"
									value={this.state.last_name}
									onChange={e =>this.handleTextInputChange(e, "last_name")}
								/>
								{this.validator.message("last-name", this.state.last_name, "required")}
							</div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
								<RequiredLabel>Position: </RequiredLabel>
								<input
									className="form-control required border-dark"
									name="position"
									type="text"
									value={this.state.position}
									onChange={e =>this.handleTextInputChange(e, "position")}
								/>
								{this.validator.message("position", this.state.position, "required")}
							</div>

                            <div className="form-group col-md-4">
								<RequiredLabel>Department: </RequiredLabel>
								<input
									className="form-control required border-dark"
									name="department"
									type="text"
									value={this.state.department}
									onChange={e =>this.handleTextInputChange(e, "department")}
								/>
								{this.validator.message("department", this.state.department, "required")}
							</div>

						</div>

						<div className="form-row">
							<div className="form-group col-md-4">
                                <RequiredLabel>Phone(Primary)</RequiredLabel>
                                <TextInputContactComponent
                                    type="phone"
                                    value={this.state.phone_primary}
                                    isPrimary={true}
                                    displaySecondaryContactHandler={this.decideSecondaryContactComponentIsAllowed}
                                    handleTextInputChange={this.handleTextInputChange}
                                    countSecondaryContact={this.state.countSecondaryPhn}
                                    validator={this.validator}
                                    rules="required|phone|size:10"
                                ></TextInputContactComponent>
							</div>

							<div className="form-group col-md-4">
                                <RequiredLabel>Email(Primary)</RequiredLabel>
                                <TextInputContactComponent
                                    type="email"
                                    value={this.state.email_primary}
                                    isPrimary={true}
                                    displaySecondaryContactHandler={this.decideSecondaryContactComponentIsAllowed}
                                    handleTextInputChange={this.handleTextInputChange}
                                    countSecondaryContact={this.state.countSecondaryEmail}
                                    validator={this.validator}
                                    rules="required|email"
                                ></TextInputContactComponent>
							</div>
						</div>

                        {/************************************** secondary components start here ********************************/}
                        <div className="form-row">
                            <div className="form-group col-md-4">
                            { (this.state.phone_secondary_1 || (this.state.acceptSecondaryPhn && this.state.countSecondaryPhn >= 1)) &&
                                <>
                                    <label>Phone(Secondary)</label>
                                    <TextInputContactComponent
                                        type="phone"
                                        serial="1"
                                        value={this.state.phone_secondary_1}
                                        isPrimary={false}
                                        handleTextInputChange={this.handleTextInputChange}
                                        validator={this.validator}
                                        rules="required|phone|size:10"
                                    ></TextInputContactComponent>
                                </>
							}
							</div>

                            <div className="form-group col-md-4">
                            { (this.state.email_secondary_1 || (this.state.acceptSecondaryEmail && this.state.countSecondaryEmail >= 1)) &&
                                <>
                                    <label>Email(Secondary)</label>
                                    <TextInputContactComponent
                                        type="email"
                                        serial="1"
                                        value={this.state.email_secondary_1}
                                        isPrimary={false}
                                        handleTextInputChange={this.handleTextInputChange}
                                        validator={this.validator}
                                        rules="required|email"
                                    ></TextInputContactComponent>
                                </>
							}
							</div> 
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                            { (this.state.phone_secondary_2 || (this.state.acceptSecondaryPhn && this.state.countSecondaryPhn == 2)) &&
                                <>
                                    <label>Phone(Secondary)</label>
                                    <TextInputContactComponent
                                        type="phone"
                                        serial="2"
                                        value={this.state.phone_secondary_2}
                                        isPrimary={false}
                                        handleTextInputChange={this.handleTextInputChange}
                                        validator={this.validator}
                                        rules="required|phone|size:10"
                                    ></TextInputContactComponent>
                                </>
							}
							</div>

                            <div className="form-group col-md-4">
                            { (this.state.email_secondary_2 || (this.state.acceptSecondaryEmail && this.state.countSecondaryEmail == 2)) &&
                                <>
                                    <label>Email(Secondary)</label>
                                    <TextInputContactComponent
                                        type="email"
                                        serial="2"
                                        value={this.state.email_secondary_2}
                                        isPrimary={false}
                                        handleTextInputChange={this.handleTextInputChange}
                                        validator={this.validator}
                                        rules="required|email"
                                    ></TextInputContactComponent>
                                </>
							}
							</div> 
                        </div>

						<div className="form-row row d-flex justify-content-end">
							<button className="btn btn-dark" type="submit">
								Save Changes
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		);
	}
}

export default UpdateContactDetailModalComponent;
