import React, { Component } from 'react';
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import { axios } from "../axios";
import SimpleReactValidator from 'simple-react-validator';
import GooglePlacesAutocomplete from 'react-google-autocomplete'
import { findAddressComponent } from '../helpers/google-places'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { CustomSelect } from "./CustomSelect"
import { AddPrimarySecondaryContact } from './AddPrimarySecondaryContact'
import objectToFormData from "object-to-formdata";
import { history } from "../history"
import 'react-select-plus/dist/react-select-plus.css';
import RequiredLabel from './RequiredLabel'

/**
 * 
 * @typedef {{} & ReturnType<typeof mapStateToProps>} UpdateSiteDetailsModalProps
 * @typedef {UpdateSiteDetailsModalProps} Props
 * 
 * 
 * Modal component to udpate organization detail
 * 
 * @extends {React.Component<Props, {}>}
 */
class UpdateSiteDetailsModal extends Component {

    state = {
        states: [],
    }

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({

            autoForceUpdate: this,
            className: 'text-danger form-error',

            validators: {
                valid_phone: {
                    message: "Phone numbers should be 8-10 digits",
                    rule: (val, params, validator) => {
                        return val.length >= 8 && val.length <= 10
                    },
                },
                abn_min: {
                    message: "Must be exactly 11 digits",
                    rule: (val, params, validator) => {
                        return val.length == 11
                    },
                }
            },
            messages: {
                required: 'This field is required',
                phone: "Not a valid phone number",
                email: "Not a valid email address",
                min: "Must be at least 8 digits",
            },
            element: (message, className) => {
                return (
                    <div className={`tooltip fade top in`} role="tooltip" style={{ transform: "translateY(-100%)" }}>
                        <div className="tooltip-arrow" style={{ left: "50%" }}></div>
                        <div className="tooltip-inner">
                            {message}
                        </div>
                    </div>
                )
            }
        })
    }

	/**
	 * used in pre filling form fiels using props/siteDetails/basic_detail
	 * passed using the initiator component page (modal opener)
	 */
    preFillData() {
        const { siteDetails } = this.props
        const { basic_detail } = siteDetails || {}

        if (!basic_detail) {
            return
        }

        this.state.basic_detail = {
            name: basic_detail.name,
            site_address: basic_detail.street,
            state: basic_detail.state,
            city: basic_detail.city.label,
            postcode: basic_detail.postal,
            id: basic_detail.ocs_id,
            payroll_tax: basic_detail.payroll_tax,
            gst: basic_detail.gst,
            abn: basic_detail.abn,
            phones: basic_detail.site_phones,
            emails: basic_detail.site_emails,
        }
    }

	/**
	 * Displays customized validation error message
	 * 
	 * @param {string} errorMessage
	 */
    renderValidationError = errorMessage => {
        return (
            <span className="text-danger">
                <small>{errorMessage}</small>
            </span>
        )
    }

	/**
	 * Function to handle form submission
	 */
    handleOnSubmit = async (values, formikHelpers) => {

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate()
            return;
        }

        // do not include empty secondary phones and emails in submission 
        const newValues = {
            ...values,
            SiteDetails: {
                ...values.SiteDetails,
                phones: values.SiteDetails.phones.filter(p => p.is_primary || (!p.is_primary && p.value)),
                emails: values.SiteDetails.emails.filter(e => e.is_primary || (!e.is_primary && e.value))
            }
        }

        // server side validation
        try {
            const response = await axios.post("/site/update?id=" + this.state.basic_detail.id, objectToFormData({ data: JSON.stringify(newValues) }));
            if (response.data) {
                let destination_url = response.data['destination_url'] || '/';
                this.props.closeModal();
                history.push(destination_url);
            }
        } catch (e) {
            const { errors } = e.response.data;

            for (let field in errors) {
                formikHelpers.setFieldError(field, errors[field])
            }
        }
    }

    async componentDidMount() {
        const { items } = this.props.australianStates
        this.setState({ states: items })
        this.preFillData();
    }

	/**
	 * Function that extract individual address elemetns from google's atuto complete address api's result and set's the component state for 'address'
	 * @param {object} googlePlacesResult
	 */
    autoCompleteAddressComponents = (googlePlacesResult) => {
        const address = {
            state: findAddressComponent(googlePlacesResult, "administrative_area_level_1"),
            orgPostcode: findAddressComponent(googlePlacesResult, "postal_code"),
            orgSuburb: findAddressComponent(googlePlacesResult, "locality"),
            orgStreetNo: findAddressComponent(googlePlacesResult, "street_number"),
            orgStreetName: findAddressComponent(googlePlacesResult, "route", true),
        }

        var hcmStateObject = {};
        var obj = {};
        var { state, orgStreetNo, orgStreetName, orgPostcode, orgSuburb } = address;
        var tmpSuburbName = null;

        if (state) {
            hcmStateObject = (this.props.stateList || []).find(elem => {
                return elem.label.toLowerCase() == state.toLowerCase();
            });
        }

        obj.street = `${orgStreetNo}, ${orgStreetName}`;
        obj.postcode = orgPostcode;

        if (orgSuburb) {
            tmpSuburbName = orgSuburb.replace(/\b(\w+)\b/, w => w.charAt(0).toUpperCase() + w.substring(1));
        }

        obj.suburb = {
            label: orgSuburb.replace(/\b(\w+)\b/, w => w.charAt(0).toUpperCase() + w.substring(1)),
            value: orgSuburb.replace(/\b(\w+)\b/, w => w.charAt(0).toUpperCase() + w.substring(1))
        };

        obj.state = hcmStateObject;

        this.setState({
            address: {
                ...obj
            }
        });
    }

	/**
	 * displays the update site details form
	 * passes the prefetched form data to form fields also
	 */
    renderForm() {
        const { items: states } = this.props.australianStates

        const defaultPhones = [
            { value: "", is_primary: true, required: true }
        ]

        const defaultEmails = [
            { value: "", is_primary: true, required: true }
        ]


        let { basic_detail } = this.state
        let { phones, emails } = basic_detail || {}

        if (!basic_detail) {
            return
        }

        basic_detail = basic_detail || {}

        var phones_init = [];
        var emails_init = [];
        for (const [index, com_row] of (phones || defaultPhones).entries()) {
            var is_primary = true;
            var is_required = true;
            if (index > 0) {
                is_primary = false;
                is_required = false;
            }
            phones_init.push({ value: com_row.phone, is_primary: is_primary, required: is_required })
        }

        for (const [index, com_row] of (emails || defaultEmails).entries()) {
            var is_primary = true;
            var is_required = true;
            if (index > 0) {
                is_primary = false;
                is_required = false;
            }
            emails_init.push({ value: com_row.email, is_primary: is_primary, required: is_required })
        }

        return (
            <Formik
                initialValues={{
                    SiteDetails: {
                        phones: phones_init,
                        emails: emails_init,
                        abn: basic_detail.abn,
                        title: basic_detail.name,
                        site_address: basic_detail.site_address,
                        city: basic_detail.city, // aka suburb
                        state: basic_detail.state,
                        postcode: basic_detail.postcode,
                        pay_roll_tax: basic_detail.payroll_tax,
                        gst: basic_detail.gst
                    }
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={this.handleOnSubmit}
                enableReinitialize={true}
            >
                {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, initialValues }) => {
                    return (
                        <Form encType="multipart/form-data" method="POST" id="updateOrgDetail">
                            <div className="row" >
                                <div className="col-md-5">
                                    <RequiredLabel htmlFor="title">Title: </RequiredLabel>
                                    {this.validator.message("SiteDetails[title]", values.SiteDetails.title, 'required')}
                                    <Field name="SiteDetails[title]" id="SiteDetails-title" className="form-control border-dark" required />
                                    <ErrorMessage name="SiteDetails[title]" render={this.renderValidationError} />
                                </div>
                                <div className="col-12 col-md-3">
                                    <AddPrimarySecondaryContact
                                        type="tel"
                                        required placeholder="Can Include Area Code"
                                        data-rule-notequaltogroup='[".distinctPh"]'
                                        data-rule-phonenumber
                                        data-msg-notequaltogroup="Please enter a unique contact number"
                                        errors={errors}
                                        label='Phone'
                                        values={values.SiteDetails.phones}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        renderValidationError={this.renderValidationError}
                                        idPrefix="SiteDetails-phones"
                                        namePrefix="SiteDetails[phones]"
                                        renderBeforeInput={({ value, name, isPrimary }) => {
                                            if (isPrimary) {
                                                return this.validator.message(name, value, 'required|phone|valid_phone')
                                            }

                                            return this.validator.message(name, value, 'phone', {
                                                messages: {
                                                    phone: "Not a valid secondary phone"
                                                }
                                            })

                                        }}
                                    />
                                </div>

                                <div className="col-12 col-md-4">
                                    <AddPrimarySecondaryContact
                                        type="email"
                                        errors={errors}
                                        label='Email'
                                        values={values.SiteDetails.emails}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        renderValidationError={this.renderValidationError}
                                        idPrefix="SiteDetails-emails"
                                        namePrefix="SiteDetails[emails]"
                                        renderBeforeInput={({ value, name, isPrimary }) => {
                                            if (isPrimary) {
                                                return this.validator.message(name, value, 'required|email')
                                            }

                                            return this.validator.message(name, value, 'email', {
                                                messages: {
                                                    email: "Not a valid secondary email"
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-row mt-3" >
                                <div className="col-12 col-md-4">
                                    <RequiredLabel htmlFor="SiteDetails-site_address">Site Address:</RequiredLabel>
                                    {this.validator.message('SiteDetails[site_address]', values.SiteDetails.site_address, 'required')}
                                    <GooglePlacesAutocomplete required
                                        name="SiteDetails[site_address]"
                                        id="SiteDetails-site_address"
                                        className="form-control border-dark"
                                        value={values.SiteDetails.site_address}
                                        onPlaceSelected={/** @param {google.maps.places.PlaceResult} place */ (place) => {
                                            const address = {
                                                state: findAddressComponent(place, "administrative_area_level_1"),
                                                postcode: findAddressComponent(place, "postal_code"),
                                                city: findAddressComponent(place, "locality"),
                                                street_number: findAddressComponent(place, "street_number"),
                                                route: findAddressComponent(place, "route", true),
                                                address: document.getElementById("SiteDetails-site_address").value
                                            }

                                            const state = (address.state || "").toLowerCase();
                                            const foundState = (states || []).find(s => s.label.toLowerCase() == state)

                                            setFieldValue("SiteDetails[state]", (foundState || {}).value)
                                            setFieldValue("SiteDetails[site_address]", [address.street_number, address.route].join(" "))
                                            setFieldValue("SiteDetails[postcode]", address.postcode)
                                            setFieldValue("SiteDetails[city]", address.city)
                                        }}
                                        types={['address']}
                                        componentRestrictions={{ country: "au" }}
                                        onChange={handleChange}
                                    />
                                    <ErrorMessage name="SiteDetails[site_address]" render={this.renderValidationError} />
                                </div>

                                <div className="col-md-3">
                                    <RequiredLabel htmlFor="SiteDetails-state">State</RequiredLabel>
                                    {this.validator.message('SiteDetails[state]', values.SiteDetails.state, 'required')}
                                    <CustomSelect required
                                        variant="light"
                                        name="SiteDetails[state]"
                                        id="SiteDetails-state"
                                        className="form-control"
                                        value={values.SiteDetails.state}
                                        options={states}
                                        clearable={false}
                                        onChange={(newValue) => newValue && setFieldValue("SiteDetails[state]", newValue.value)}
                                    />
                                    <ErrorMessage name="SiteDetails[state]" render={this.renderValidationError} />
                                </div>

                                <div className="col-12 col-md-3">
                                    <RequiredLabel htmlFor="SiteDetails-city">Suburb:</RequiredLabel>
                                    {this.validator.message('SiteDetails[city]', values.SiteDetails.city, 'required')}
                                    <Field required name="SiteDetails[city]" id="SiteDetails-city" className="form-control border-dark" />
                                    <ErrorMessage name="SiteDetails[city]" render={this.renderValidationError} />
                                </div>

                                <div className="col-12 col-md-2">
                                    <RequiredLabel htmlFor="SiteDetails-postcode">Postcode</RequiredLabel>
                                    {this.validator.message('SiteDetails[postcode]', values.SiteDetails.postcode, 'required')}
                                    <Field name="SiteDetails[postcode]" id="SiteDetails-postcode" className="form-control border-dark" required data-rule-number="true" minLength="4" maxLength="4" data-rule-postcodecheck="true" />
                                    <ErrorMessage name="SiteDetails[postcode]" render={this.renderValidationError} />
                                </div>
                            </div>

                            <div className="form-row mt-3" >
                                <div className="col-12 col-md-4">
                                    <RequiredLabel htmlFor="SiteDetails-pay_roll_tax">Pay roll tax</RequiredLabel>
                                    {this.validator.message('SiteDetails[pay_roll_tax]', values.SiteDetails.pay_roll_tax, 'required')}
                                    <CustomSelect required
                                        variant="light"
                                        name="SiteDetails[pay_roll_tax]"
                                        id="SiteDetails-pay_roll_tax"
                                        className="form-control"
                                        value={values.SiteDetails.pay_roll_tax}
                                        options={[
                                            { value: null, label: "Select option", selected: true, disabled: true },
                                            { value: '1', label: "Yes" },
                                            { value: '0', label: "No" }
                                        ]}
                                        clearable={false}
                                        onChange={(newValue) => newValue && setFieldValue("SiteDetails[pay_roll_tax]", newValue.value)}
                                    />
                                    <ErrorMessage name="SiteDetails[pay_roll_tax]" render={this.renderValidationError} />
                                </div>
                                <div className="col-12 col-md-4">
                                    <RequiredLabel htmlFor="SiteDetails-gst">GST</RequiredLabel>
                                    {this.validator.message('SiteDetails[gst]', values.SiteDetails.gst, 'required')}
                                    <CustomSelect required
                                        variant="light"
                                        name="SiteDetails[gst]"
                                        id="SiteDetails-gst"
                                        className="form-control"
                                        value={values.SiteDetails.gst}
                                        options={[
                                            { value: null, label: "Select option", selected: true, disabled: true },
                                            { value: '1', label: "Yes" },
                                            { value: '0', label: "No" }
                                        ]}
                                        clearable={false}
                                        onChange={(newValue) => newValue && setFieldValue("SiteDetails[gst]", newValue.value)}
                                    />
                                    <ErrorMessage name="SiteDetails[gst]" render={this.renderValidationError} />
                                </div>

                                <div className="col-md-4">
                                    <RequiredLabel htmlFor="SiteDetails-abn">ABN:</RequiredLabel>
                                    {this.validator.message('SiteDetails[abn]', values.SiteDetails.abn, 'required|abn_min')}
                                    <Field name="SiteDetails[abn]" id="SiteDetails-abn" className="form-control border-dark" />
                                    <ErrorMessage name="SiteDetails[abn]" render={this.renderValidationError} />
                                </div>
                            </div>

                            <div className="form-row mt-3">

                            </div>


                            <div className="form-row row d-flex justify-content-end">
                                <button type="submit" disabled={this.state.loading} onClick={e => {
                                    setFieldValue("publish", true);
                                    handleSubmit(e);
                                }} className="btn btn-dark">Save Changes</button>
                            </div>
                        </Form>
                    )
                }
                }
            </Formik>
        )
    }

	/**
	 * renders the main modal
	 */
    render() {
        this.preFillData();

        var showMe = this.props.showPopup;
        var modalId = this.props.modalId || '';
        var site_title = (this.props.siteDetails && this.props.siteDetails.basic_detail) ? this.props.siteDetails.basic_detail.name : '';

        return (
            <Modal show={showMe} aria-labelledby="contained-modal-title" size="lg" id={`${modalId}`} centered>
                <Modal.Header>
                    <div className="d-flex align-items-center">
                        <Modal.Title>{site_title}</Modal.Title>
                        <span className="icon icon-cross-icons" onClick={this.props.closeModal}></span>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    {this.renderForm()}
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        australianStates: state.australianStates
    }
}

export default connect(mapStateToProps)(UpdateSiteDetailsModal);
