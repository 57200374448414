import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import LoginWelcomeArea from "../components/LoginWelcomeArea"
import { sendPasswordResetEmail } from "../actions/passwordResetActions"

import "../stylesheets/pages/ForgotPasswordPage.scss"

class ForgotPasswordPage extends React.Component {

    state = {
        email: "",
        submitted: false,
    }

    handleOnChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    handleOnSubmit = (e) => {
        e.preventDefault();

        this.setState({ submitted: true })
        const { email } = this.state
        const { dispatch } = this.props
        
        if (email) {
            dispatch(sendPasswordResetEmail(email))
        }
    }

    renderForm() {
        const { email, submitted } = this.state
        const { isLoggedIn, passwordResetLoading } = this.props

        return (
            <form action="#" id="ForgotPasswordForm" className="text-primary" onSubmit={this.handleOnSubmit}>
                <div className={'form-group m-auto' + (submitted && !email ? ' has-error' : '')}>
                    <label htmlFor="ForgotPasswordForm-email">Email</label>
                    <input type="email" className="form-control input-primary" name="email" id="ForgotPasswordForm-email" onChange={this.handleOnChange} />
                    {submitted && !email && (
                        <div className="help-block text-danger">Email is required</div>
                    )}
                </div>
                <br />
                <div className="form-group m-auto text-center">
                    <button type="submit" 
                        className="btn bt-lgn btn-primary btn-block m-auto" 
                        id="ForgotPasswordForm-submit" 
                        disabled={!!passwordResetLoading}
                        title={'Send password reset email'}
                    >
                        {!!passwordResetLoading ? 'Sending...' : 'Send password reset email'}
                    </button>
                </div>
                <div className="form-group m-auto">
                    {!isLoggedIn && (
                        <Link to="/login"><small>Go back to login</small></Link>
                    )}
                </div>
            </form>
        )
    }

    render() {
        return (
            <>
                <LoginWelcomeArea description="Reset your password" />
                {this.renderForm()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        passwordResetLoading: state.auth.passwordResetLoading
    }
}


export default connect(mapStateToProps, null)(ForgotPasswordPage)