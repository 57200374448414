import React from "react";
import { connect } from 'react-redux'
import _ from 'lodash'
import OrgInfoSummary from '../components/OrgInfoSummary';
import BasicDisplayForm from '../components/BasicDisplayFormComponent';
import ContactDisplayForm from '../components/ContactDisplayComponent';
import UpdateOrgDetailModal from '../components/UpdateOrgDetailModalComponent';
import UpdateContactDetailModalComponent from '../components/updateContactDetailModalComponent';
import {prestineContactState} from '../services/contactService.js';
import { toast } from 'react-toastify';
import { axios } from "../axios";

import EditIconWhite from "../icons/Edit-Icon-White.svg";

import "../stylesheets/pages/OrgReviewPage.scss";
import 'react-select-plus/dist/react-select-plus.css';
import CustomBreadcrumbs from "../components/CustomBreadcrumbs";
import BlockUi from "react-block-ui";
import { css } from "../helpers/common";


/**
 * @typedef {ReturnType<typeof mapStateToProps>} OrgReviewPageProps
 * @typedef {OrgReviewPageProps & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 * @typedef {any} State
 */

/**
 * Component that renders the main page of an organization
 * 
 * @extends {React.Component<Props, State>}
*/
class OrgReviewPage extends React.Component {

    state = {
        orgDetail: null,
        orgContacts: null,
        contactTypes: [],
        showOrgUpdateModal: false,
        showUpdateContactModal: false,
        contactToLoadInUpdateModal: prestineContactState,
        contact: {
            actionType: ''
        },
        basic_detail: null,
        org_contacts: null,
        contact_types: null,
        loading: false,
        orgId: null
    }

    httpConfig = {
        orgDataUrl: '/ParentOrgController/getOrgDetails',
        stateListUrl: '/CommonController/getStateList'
    };

    /**
     * Fetch parent org or suborg details on load
     */
    async componentDidMount() {
        const { match, entityTypes } = this.props
        const { type: entityType } = entityTypes
        const { params } = match
        const { id } = params

        let useId = null
        if (entityType === 'SUBORG') {
            useId = id
        }

        await this.fetchOrgDetails(useId)
    }

    /**
     * Determine if given value equates to 'Yes' or 'No'.
     * If it cannot be determined, just will return blank text
     * 
     * @param {any} value 
     */
    isYesOrNo(value) {
        if (['1', 1, true, 'true', 'yes', 'YES'].indexOf(value) >= 0) {
            return 'Yes'
        } else if (['0', 0, false, 'false', 'no', 'NO'].indexOf(value) >= 0) {
            return 'No'
        } else {
            return ''
        }
    }


    /**
     * Convert provided org details into information into an array containing label and value info
     * 
     * @param {any} d 
     */
    mapBasicDetailToOrgDetails(d) {
        const basic_detail = d || {}

        return [
            {
                label: 'Name',
                value: _.get(basic_detail, 'name'),
            },
            {
                label: 'Address',
                value: _.get(basic_detail, 'primary_address')
            },
            {
                label: 'Office Phone',
                value: (basic_detail.OrganisationPh || []).map(p => p.phone).filter(_ => _).join(', ')
            },
            {
                label: 'Office Email',
                value: (basic_detail.OrganisationEmail || [])
                    .map(p => p.email)
                    .filter(_ => _)
                    .map((email, i, readonlyEmails) => {
                        return (
                            <React.Fragment key={i}>
                                <a href={`mailTo: ${email}`}>{email}</a>{i < readonlyEmails.length - 1 ? ', ' : ' '}
                            </React.Fragment>
                        )
                    })
            },
            {
                label: 'ABN NO',
                value: _.get(basic_detail, 'abn')
            },
            {
                label: 'Payroll Tax',
                value: this.isYesOrNo(_.get(basic_detail, 'payroll_tax'))
            },
            {
                label: 'GST',
                value: this.isYesOrNo(_.get(basic_detail, 'gst'))
            },
            {
                label: 'Booking Status',
                value: this.isYesOrNo(_.get(basic_detail, 'booking_status'))
            },
        ]
    }


    /**
     * Map org/suborg contact info as array of details containing label and value attrs
     * 
     * @param {any} c 
     */
    mapOrgContactToContactDetails(c) {
        const org_contact = c || {}

        return [
            {
                label: 'First Name',
                value: _.get(org_contact, 'first_name'),
            },
            {
                label: 'Last Name',
                value: _.get(org_contact, 'last_name'),
            },
            {
                label: 'Position',
                value: _.get(org_contact, 'position'),
            },
            {
                label: 'Email',
                value: [
                    { email: _.get(org_contact, 'email_primary'), is_primary: true, },
                    { email: _.get(org_contact, 'email_secondary_1'), is_primary: false, },
                    { email: _.get(org_contact, 'email_secondary_2'), is_primary: false, },
                ]
                .filter(e => !!e.is_primary)
                .map((e, i)=> <span key={i}>{e.email}</span>)
            },
            {
                label: 'Phone',
                value: [
                    { phone: _.get(org_contact, 'phone_primary'), is_primary: true, },
                    { phone: _.get(org_contact, 'phone_secondary_1'), is_primary: false, },
                    { phone: _.get(org_contact, 'phone_secondary_2'), is_primary: false, },
                ]
                .filter(p => !!p.is_primary)
                .map((p, i)=> <span key={i}>{p.phone}</span>)
            },
        ]
    }


    /**
     * Render rows of org/suborg information
     */
    renderDetails() {
        const { basic_detail } = this.state
        const details = this.mapBasicDetailToOrgDetails(basic_detail)
        const header = `Your Org - Details`

        return (
            <div>
				<div className="entry-header">
                    <h2><b>{header}</b></h2>
				</div>
                <div className="d-flex flex-column">
                    {
                        details.map((kv, i) => (
                            <div className="row data-row d-flex" key={i}>
                                <div className="font-weight-bold label">{kv.label} :</div>
                                <div className="value">&nbsp;{kv.value}</div>
                            </div>
                        ))
                    }
                </div>
                {this.renderUpdateOrgDetailsBtn()}
            </div>
        )
    }

    /**
     * Render rows of contact detail items
     * 
     * @param {{label: string, value: any}[]} details 
     */
    renderAllContactDetailItems(details = []) {
        return details.map((detail, i) => (
            <div className="row data-row d-flex" key={i}>
                <div className="font-weight-bold label">{detail.label} :</div>
                <div className="value">&nbsp;
                    { detail.value }
                </div>
            </div>
        ))
    }

    /**
     * Handler to display modal to update org/suborg info
     */
    handleOnClickUpdateOrgDetailsBtn = e => {
        this.setState({
            showOrgUpdateModal: true
        })
    }


    /**
     * Render update org/suborg details button
     */
    renderUpdateOrgDetailsBtn() {
        const { basic_detail } = this.state
        const disabled = !basic_detail

        const styles = css({
            button: {
                whiteSpace: "nowrap",
                paddingLeft: 20,
                paddingRight: 20,
            },
            text: {
                margin: 0,
            },
            icon: {
                margin: 0
            }
        })

        return (
            <div className="d-flex justify-content-end entity-detail-action">
                <button className="d-flex justify-content-between align-items-center update-entity-detail btn btn-dark" 
                    style={styles.button} 
                    onClick={this.handleOnClickUpdateOrgDetailsBtn}
                    disabled={disabled}
                >
                    <span className="text" style={styles.text}>Update Org Details</span>
                    <img className="icon icon-update" src={EditIconWhite} style={styles.icon} />
                </button>
            </div>
        )
    }

    
    toggleContactDisplay = (toggledOrgContact) => {
        const { org_contacts } = this.state 

        const newOrgContacts = (org_contacts || []).map((orgContact, i) => {
            if (orgContact === toggledOrgContact) {
                return {
                    ...orgContact,
                    expanded: !orgContact.expanded
                }
            }

            return orgContact;
        })

        this.setState({
            org_contacts: newOrgContacts
        })
    }

    updateContactButtonHandler = (organizationContact) => {
        this.setState({
            showUpdateContactModal: true,
            contactToLoadInUpdateModal: organizationContact,
            contact: {
                ...this.state.contact,
                actionType: 'update'
            }
        });
    }

    modalCloseHandler = () => {
        this.setState({
            showOrgUpdateModal: false
        });

        const { basic_detail } = this.state
        const { ocs_id } = basic_detail || {}

        this.fetchOrgDetails(ocs_id);
    }

    updateContactModalCloseHandler = () => {
        this.setState({
            showUpdateContactModal: false,
            contactToLoadInUpdateModal: prestineContactState
        });
    }

    displayModalHandler = () => {
        this.setState({
            showOrgUpdateModal: true
        });
    }

    orgDetailUpdated = (response) => {
        this.setState({
            showOrgUpdateModal: false
        });

        if(response.data.status === true) {
            toast(response.data.msg, {
                toastId: 'updated_org_detail',
                containerId: 'success_toast_container'
            });
        }

        const { basic_detail } = this.state
        const { ocs_id } = basic_detail || {}

        this.fetchOrgDetails(ocs_id);
    }

    contactWasUpdated = (response) => {
        this.updateContactModalCloseHandler();

        if (response.data.status === true) {
            toast(response.data.msg, {
                toastId: 'updated_contact_detail',
                containerId: 'success_toast_container'
            });
        }

        const { basic_detail } = this.state
        const { ocs_id } = basic_detail || {}

        this.fetchOrgDetails(ocs_id);
    }
    

    /**
     * Determine what should be display in the header 
     * section of each row under 'Your Org - Contacts' section
     * 
     * @param {any} contact 
     */
    determineContactHeaderText(contact) {
        const fullname = [ _.get(contact, 'first_name'), _.get(contact, 'last_name')]
            .filter(_ => _)
            .join(' ')

        const text = [fullname, _.get(contact, 'position')].filter(_ => _).join(' - ')
        return text
    }


    /**
     * Render list of suborgs contacts
     */
    renderSuborgContacts() {
        const { org_contacts } = this.state

        return (org_contacts || []).map((contact, i) => {
            const details = this.mapOrgContactToContactDetails(contact)
            const headerText = this.determineContactHeaderText(contact)

            return (
                <div className="org-contacts" key={i}>
                    <ContactDisplayForm 
                        expanded={contact.expanded}
                        toggleContactDisplay={this.toggleContactDisplay}
                        contact={contact}
                        headerText={headerText}
                        updateButtonHandler={this.updateContactButtonHandler}
                        contactWasUpdatedCallback={this.contactWasUpdated}
                        details={details}
                    />
                </div>
            )
        })
    }

    /**
     * Render list of parent org contacts
     */
    renderContacts() {
        const { org_contacts } = this.state

        return (org_contacts || []).map((contact, i) => {
            const details = this.mapOrgContactToContactDetails(contact)
            const headerText = this.determineContactHeaderText(contact)

            return (
                <div className="parent-org-details org-contacts" key={i}>
                    <ContactDisplayForm 
                        expanded={!!contact.expanded}
                        toggleContactDisplay={this.toggleContactDisplay}
                        contact={contact}
                        details={details}
                        headerText={headerText}
                        updateButtonHandler={this.updateContactButtonHandler}
                        contactWasUpdatedCallback={this.contactWasUpdated}
                    />
                </div>
            )
        })

    }

	render() {
        const { orgDetails, suborgs, entityTypes } = this.props
        const { type } = entityTypes || {}
        const entityType = type || 'ORG'
        const { basic_detail, org_contacts, contact_types } = this.state

        const loading = orgDetails.loading || suborgs.loading

        return(
            <BlockUi blocking={loading}>
                <div className="container-fluid">
                    <div className="content-block">
                        <CustomBreadcrumbs />
                    </div>

                    <div className="content-block">
                        <div className="site-information">
                            <OrgInfoSummary />
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="entity-details">
                            { this.renderDetails() }

                            {/* { entityType === 'SUBORG' ? this.renderYourSubOrgDetails() : this.renderYourOrgDetails() } */}
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="entry-header entity-contact-header">
                            <h2><b>Your Org - Contacts</b></h2>
                        </div>
                    </div>

                    <div className="content-block">
                        { entityType === 'SUBORG' ? this.renderSuborgContacts() : this.renderContacts() }
                    </div>

                    <div className="content-block">
                        <div className="d-flex justify-content-end org-page-action" onClick={(event) => { this.setState({ showUpdateContactModal: true, contact: { ...this.state.contact, actionType: 'create'} })}}>
                            <div className="d-flex justify-content-end align-items-center add-contact">
                                <span className="text">Add Contact</span>
                                <img className="icon icon-update" src={EditIconWhite} />
                            </div>
                        </div>
                    </div>
                    
                </div>

                {
                    this.state.basic_detail && (
                        <UpdateOrgDetailModal modalId="updateOrgDetailModal" 
                            showPopup={this.state.showOrgUpdateModal}
                            closeModal={this.modalCloseHandler}
                            stateList={this.state.stateList}
                            orgDetails={{ basic_detail, org_contacts, contact_types }}
                            afterUpdatingOrgDetail={this.orgDetailUpdated}
                        />
                    )
                }

                {
                    this.state.org_contacts && (
                        <UpdateContactDetailModalComponent modalId="updateContactDeatilModal" /*key={(this.state.contactToLoadInUpdateModal && this.state.contactToLoadInUpdateModal.contact_id) || 'none'}*/
                            updateModalShouldLanuch={this.state.showUpdateContactModal}
                            updateButtonHandler={this.updateContactButtonHandler}
                            updateContactModalCloseBtnHandler={this.updateContactModalCloseHandler}
                            contactWasUpdatedCallback={this.contactWasUpdated}
                            loadedContact={this.state.contactToLoadInUpdateModal}
                            contactTypes={this.state.contactTypes}
                            mode={this.state.contact.actionType}
                            orgId={this.state.orgDetail && this.state.orgDetail.basic_detail.ocs_id}
                        />
                    )
                }



            </BlockUi>
        );
    }

    async fetchOrgDetails(org_id) {
        const { orgDataUrl  } = this.httpConfig

        this.setState({ loading: true })
        try {
            const { data: d } = await axios.post(orgDataUrl,{ org_id })
            const { data } = d || {}
            const { basic_detail, org_contacts, contact_types } = data || {}
            this.setState({ basic_detail, org_contacts, contact_types, loading: false })

        } catch (e) {
            this.setState({ loading: false })
        }
    }
}

/**
 * 
 * @param {import("../reducers").RootState} state 
 * @param {import("react-router-dom").RouteComponentProps} ownProps 
 */
const mapStateToProps = (state, ownProps) => {
    const { entityTypes, suborgs, orgDetails, australianStates } = state

    return {
        entityTypes,
        suborgs,
        orgDetails,
        australianStates,
    }
}

export default connect(mapStateToProps)(OrgReviewPage);