import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import LoginWelcomeArea from "../components/LoginWelcomeArea";
import {login} from '../actions/userActions';

import "../stylesheets/pages/LoginPage.scss";
import { store } from "../store";
import qs from "qs"

/**
 * @typedef {{ username: string, password: string, submitted: boolean }} State
 * @typedef {{}} Props

 * @extends {React.Component<Props, State>}
 * 
 * Component that renders the login page. `/login`
 */
class LoginPage extends React.Component {

    static URL_FORGOT_PASSWORD = "/forgot-password"

    constructor(props) {
        super(props)

        this.state = {
            username: "",
            password: "",
            submitted: false
        }
    }

    handleOnChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }


    /** @param {React.FormEvent<HTMLFormElement>} e  */
    handleOnSubmit = e => {
        e.preventDefault();

        this.setState({ submitted: true })
        const { username, password } = this.state
        const { dispatch } = this.props;

        if (username && password) {
            dispatch(login(username, password));
        }
    }

    renderWelcomeArea() {
        return <LoginWelcomeArea />
    }

    renderForm() {
        const { loggingIn } = this.props;
        const { username, password, submitted } = this.state;

        return (
            <form action="#" id="LoginForm" className="text-primary" onSubmit={this.handleOnSubmit}>
                <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                    <label htmlFor="LoginForm-username">User name</label>
                    <input type="text" className="form-control input-primary" name="username" id="LoginForm-username" value={username} onChange={this.handleOnChange}/>
                    {submitted && !username && (
                        <div className="help-block text-danger">Username is required</div>
                    )}
                </div>
                <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                    <label htmlFor="LoginForm-password">Password</label>
                    <input type="password" className="form-control input-primary" name="password" id="LoginForm-password" value={password} onChange={this.handleOnChange}/>
                    {submitted && !password && (
                        <div className="help-block text-danger">Password is required</div>
                    )}
                </div>
                <div className="form-group text-center">
                    <button type="submit" className="btn bt-lgn btn-primary btn-block m-auto" id="LoginForm-submit" disabled={loggingIn}>
                        {loggingIn ? 'Logging in...' : 'Log In'}
                    </button>
                    {
                        /* loading spinner */
                        loggingIn && <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                </div>
                <div className="form-group text-center">
                    <Link to={LoginPage.URL_FORGOT_PASSWORD} id="LoginForm-forgotpassword" className="text-primary">
                        Forgot Password
                    </Link>
                </div>
            </form>
        )
        
    }
    

    render() {
        return (
            <>
                {this.renderWelcomeArea()}
                {this.renderForm()}
            </>
        )
    }
}

const mapStateToProps = state => {
    const { loggingIn } = state.auth
    return {
        loggingIn
    }
}

export default connect(mapStateToProps, null)(LoginPage);