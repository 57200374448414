import { createReducerSlice } from '../helpers/createReducer'

/**
 * @typedef {typeof slice.ActionTypes} ActionTypes
 * @typedef {typeof initialState} State
 */

const initialState = {
    loading: false,
    items: [],
    errors: [],
    fetched_at: null
}

export const slice = createReducerSlice(initialState, {
    'FETCH_SUBORGS_LOADING': (state, action) => ({
        ...state,
        loading: true,
    }),
    'FETCH_SUBORGS_SUCCESS': (state, action) => ({
        ...state,
        loading: false,
        items: (action.response.data['data'] || []),
        fetched_at: Date.now(),
        errors: [],
    }),
    'FETCH_SUBORGS_FAILED': (state, action) => ({
        ...state,
        loading: false,
        fetched_at: null,
        errors: ["Could not fetch sub-organisations"]
    }),
    // force API calls on next calls on fetchSuborgs()
    // @see /src/actions/commonActions.js and /src/middleware/callAPIMiddleware.js
    'SUBORG_CREATED': (state, action) => ({
        ...state,
        fetched_at: null,
    })
})

export const suborgs = slice.reducer