import React from 'react';

/**
 * A reusable component comprised of basic html header element
 */
class SimpleHeaderComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var headerSize = this.props.headerSize;
        return (
            <div className="entry-header">
                {headerSize == '1' && <h1><b>{this.props.text}</b></h1>}
                {headerSize == '2' && <h2><b>{this.props.text}</b></h2>}
                {headerSize == '3' && <h3><b>{this.props.text}</b></h3>}
                {headerSize == '4' && <h4><b>{this.props.text}</b></h4>}
                {headerSize == '5' && <h5><b>{this.props.text}</b></h5>}
                {headerSize == '6' && <h6><b>{this.props.text}</b></h6>}
            </div>
        )
    }
}

export default SimpleHeaderComponent;