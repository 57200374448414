/**
 * 
 * @param {google.maps.places.PlaceResult} places 
 * @param {string} component
 * @return {string|null}
 */
export function findAddressComponent(places, component, isLongName = false) {
    const addressComponent = places.address_components.find(address_component => 
        address_component.types.indexOf(component) >= 0
    )

    if (addressComponent) {
        return isLongName ? addressComponent.long_name : addressComponent.short_name;
    }

    return null; 
}