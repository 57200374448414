import { axios } from "../axios";
import qs from "qs"

export const organisationService = {
    dashboard,
    searchParentOrganisation,
    searchSites,
    nomralizeSecondaryContacts
}

export const organisatiionTaxList = [
    { value:'1', label:'Yes' },
    { value:'0', label:'No' }
]

export const organisatiionGstList = [
    { value:'1', label:'Yes' },
    { value:'0', label:'No' }
]


const URL_DASHBOARD = "/dashboard/index";
const URL_ORGANISATIONS_SEARCH = "/dashboard/get_parent_org_name";
const URL_SITES_SEARCH = "/dashboard/get_site_org";

// Hypertext Application Language format
// https://en.wikipedia.org/wiki/Hypertext_Application_Language
const URL_DASHBOARD_FAKE_RESPONSE = {
    latest_updates: {
        title: "Latest updates",
        data: [
            { 
                update: "Yoorella Altona North - Phone number updated", 
                date: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                update: "Yoorella Altona North - Phone number updated", 
                date: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                update: "Yoorella Altona North - Phone number updated", 
                date: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                update: "Yoorella Altona North - Phone number updated", 
                date: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                update: "Yoorella Altona North - Phone number updated", 
                date: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                update: "Yoorella Altona North - Phone number updated", 
                date: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
        ],
        _links: {
            view_all: { href: "/", text: "View all" }, 
        }
    },
    invoices: {
        title: "Invoices Feedback",
        data: [
            { 
                invoiceName: "Hutley St (House) - Energy Bill", 
                dueDate: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                invoiceName: "Hutley St (House) - Energy Bill", 
                dueDate: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                invoiceName: "Hutley St (House) - Energy Bill", 
                dueDate: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                invoiceName: "Hutley St (House) - Energy Bill", 
                dueDate: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                invoiceName: "Hutley St (House) - Energy Bill", 
                dueDate: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
            { 
                invoiceName: "Hutley St (House) - Energy Bill", 
                dueDate: "01/01/2020",
                _links: {
                    self: { href: "/" }
                }
            },
        ],
        _links: {
            view_all: { href: "/", text: "View all" }, 
        }
    },
    fms_feedback: {
        title: "FMS Feedback",
        data: [
            { feedback: "[Feedback by a Participant here]” - Lorna Marsh, 01/01/01" },
            { feedback: "[Feedback by a Participant here, Feedback by a Participant here, Feedbac" }
        ],
    },
    _links: {
        create_new_site: { href: "/" },
        create_new_sub_org: { href: "/" },
    }
}

const URL_ORGANISATIONS_SEARCH_FAKE_RESPONSE = {
    data: [
        { value: 1, label: "TTTT Pty Ltd" },
        { value: 2, label: "GGGG Real State" },
        { value: 3, label: "The Trustee" },
    ]
}

const URL_SITES_SEARCH_FAKE_RESPONSE = {
    data: [
        { value: 1, label: "Site 1" },
        { value: 2, label: "Site 2" },
        { value: 3, label: "Site 3" },
    ]
}

const URL_DASHBOARD_GET_PARENT_ORG_BILLING_DETAILS_FAKE_RESPONSE = {
    first_name: "Joshua",
    last_name: "Orozco",
    position: "Software developer",
    department: "Sample department",
    phones: [
        { value: "0444 444 444", is_primary: true },
        { value: "0444 444 555", is_primary: false },
        { value: "0444 444 666" },
    ],
    emails: [
        { value: "joshua@ydt.com.au", is_primary: true },
        { value: "joshua2@ydt.com.au", is_primary: false },
        { value: "joshua3@ydt.com.au" },
    ],
    pay_roll_tax: true,
    gst: true,
}



/**
 * @return {Promise<{data: typeof URL_DASHBOARD_FAKE_RESPONSE}>}
 */
function dashboard() {
    return axios.get(URL_DASHBOARD);

}

/** @returns {Promise<{data: typeof URL_ORGANISATIONS_SEARCH_FAKE_RESPONSE}} */
function searchParentOrganisation(keyword) {
    return axios.get(URL_ORGANISATIONS_SEARCH + "?" + qs.stringify({ query: keyword }));
    // return new Promise((resolve, reject) => setTimeout(() => resolve({ data: URL_ORGANISATIONS_SEARCH_FAKE_RESPONSE }), 1000))
}

/** @returns {Promise<{data: typeof URL_SITES_SEARCH_FAKE_RESPONSE}} */
function searchSites(keyword) {
    return axios.get(URL_SITES_SEARCH + `?${qs.stringify({ query: keyword })}`);
    // return new Promise((resolve, reject) => setTimeout(() => resolve({ data: URL_SITES_SEARCH_FAKE_RESPONSE }), 1000))
}

function fetchParentOrgDetails() {
    return new Promise((resolve, reject) => setTimeout(() => resolve({ data: URL_DASHBOARD_GET_PARENT_ORG_BILLING_DETAILS_FAKE_RESPONSE}), 1000))
}

function nomralizeSecondaryContacts(formData) {
    var secondaryPhones = [];
    var secondaryEmails = [];

    for(let pair of formData.entries()) {
        if(['secondary-email-1','secondary-email-2','secondary-phone-1','secondary-phone-2'].indexOf(pair[0]) >= 0) {
            if(pair[1] !== null && pair[1] !== '') {
                pair[0].includes('secondary-phone') ? secondaryPhones.push(pair[1]) : secondaryEmails.push(pair[1]);
            }
        }
    }

    ['secondary-email-1','secondary-email-2','secondary-phone-1','secondary-phone-2'].forEach(elem => {
        formData.delete(elem);
    });

    formData.append('secondary-phones', JSON.stringify(secondaryPhones));
    formData.append('secondary-emails',  JSON.stringify(secondaryEmails));

    return formData;
}