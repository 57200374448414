import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BlockUi from 'react-block-ui'

import '../stylesheets/components/HelpSidebar.scss'
import { css } from '../helpers/common'
import { fetchInfographics } from '../actions/commonActions'

const styles = css({
    blockUi: {
        height: '100%',
    },
    iconInfo: {
        fontSize: 24,
        verticalAlign: 'middle',
        marginLeft: 20,
    },
})

/**
 * @typedef {{} & ReturnType<typeof mapStateToProps>} HelpSidebarProps
 * @typedef {HelpSidebarProps & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 */



/**
 * Renders series of help blocks in order to display 
 * an entire help article in right sidebar. 
 * 
 * Note: The data for the help blocks will come from redux store.
 * 
 * @extends {React.Component<Props, {}>}
 */
class HelpSidebar extends React.Component {

    static defaultProps = {
        onClose: () => {}
    }

    state = {
        open: true
    }

    /**
     * Fetch infographic when this component is mounted. 
     * 
     * This component SHOULD BE mounted once.
     */
    componentDidMount() {
        const { dispatch, match } = this.props
        const { path } = match

        dispatch(fetchInfographics(path))
    }



    render() {
        const { infographics, loading } = this.props


        return (
            <BlockUi blocking={loading} tag="div" style={styles.blockUi}>
                <div className="help-sidebar">
                    <div className="help-sidebar-header">
                        <h3 className="d-inline-block">
                            Page information
                        </h3>
                        <span className="icon icon-info d-inline-block" style={styles.iconInfo}></span>
                        <span className="icon-right-arrow d-inline-block" onClick={this.props.onClose}>
                            <span style={{ display: 'inline-block', transform: 'rotate(180deg)'}}>
                                <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" tabIndex="-1" title="ArrowBack" width="33">
                                    <path d="
                                    M20 11
                                    H7.83
                                    l5.59-5.59
                                    L12 4
                                    l-8 8 8 8 1.41-1.41
                                    L7.83 13
                                    H20v-2z" fill="white" stroke="transparent"></path>
                                </svg>
                            </span>
                        </span>
                    </div>
                    <div className="help-sidebar-list">
                        {
                            !loading && (infographics || []).length === 0 && (
                                <div className="no-infographics">
                                    <h4>There are no infographics available</h4>
                                </div>
                            )
                        }
                        {
                            (infographics || []).length > 0 && infographics.map((helpBlock, i) => {

                                const infographicsBaseUrl = `${process.env.REACT_APP_ADMIN_URL}uploads/infographics` // from admin portal

                                let src = 'http://via.placeholder.com/583x307.png'
                                if (helpBlock.block_image) {
                                    src = `${infographicsBaseUrl}/${helpBlock.block_image}`
                                }
        
                                return (
                                    <React.Fragment key={i}>
                                        {i > 0 && <hr/>}
                                        <div className="help-sidebar-block">
                                            <img src={src}/>
                                            <h4>
                                                <b>{helpBlock.block_title}</b>
                                            </h4>
                                            <p>{helpBlock.block_desc}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                    <div className="help-sidebar-footer text-center">
                        <h4>
                            <b>Can't find what you're looking for?</b>
                        </h4>
                        <a href="#" target="_blank" className="btn btn-primary">
                            <b>
                                Click here to access the Help Desk
                            </b>
                        </a>
                    </div>
                </div>

            </BlockUi>
        )
    }

}


const mapStateToProps = (state, ownProps) => {
    const { match } = ownProps
    const { path } = match || {}
    const { data, loading } = state.infographics 

    return {
        infographics: (path in data) ? data[path].items : [],
        loading
    }
}



export default withRouter(connect(mapStateToProps)(HelpSidebar))