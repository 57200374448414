import React from 'react';
// import FileIcon from '../icons/icon-file.svg';
import moment from 'moment';

import '../stylesheets/components/GalleryIconComponent.scss';

/**
 * A component that can be used as a gallery item
 */
class GalleryIconComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.classNames + " d-flex flex-column justify-content-between align-items-center doc"} 
                style={this.props.style}
                onClick={() => this.props.docSelectedHandler(this.props.relatedObject)}>
                <div className="title font-weight-bold">
                    {this.props.relatedObject.title}
                </div>
                {this.props.iconToRender && 
                    <div className="file-icon">
                        <img src={this.props.iconToRender} alt="file" width="52px" height="52px"/>
                    </div>
                }
                <div className="file-name">
                    {this.props.relatedObject.filename}
                </div>

                <div className="date">
                    Upload date:
                    <br />
                    {this.props.relatedObject && moment(this.props.relatedObject.created, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')}
                </div>
            </div>
        )
    }
}

export default GalleryIconComponent;