import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import SimpleReactValidator from 'simple-react-validator';
import jwt from "jsonwebtoken"
import objectToFormData from "object-to-formdata";
import { Breadcrumb } from 'react-bootstrap'
import OrgInfoSummary from '../components/OrgInfoSummary'
import { axios } from '../axios'
import {history} from "../history"

import 'react-select-plus/dist/react-select-plus.css';
import RequiredLabel from '../components/RequiredLabel';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';

/**
 * layout page for changing the parent org's password
 */
class ChangePassword extends React.Component {
	
	state = {
		oldpassword: "",
		newpassword: "",
		repeatpassword: "",
	}

	constructor(props) {
		super(props);

		this.validator = new SimpleReactValidator({
			validators: {
				/*check_oldpass: {
					message: "Old password doesn't match",
					rule: async (oldpassword, params, validator) => {
						console.log(validator);
						console.log(params);
						var valid_pass;
						const response = await axios.post("/account/check_oldpassword", objectToFormData({data:JSON.stringify(oldpassword)}))
						if(response.data) {
							return true;
						}
						else {
							return false;
						}
					}
				},*/
				confirm_pass: {
					message: "Passwords don't match",
					rule: (val, params, validator) => {
						if(val != this.state.newpassword) {
							return false;
						} else {
							return true;
						}
					},
				},
				pass_min: {
					message: "New password must be at least 6 characters",
					rule: (val, params, validator) => {
						return val.length >= 6
					},
				}
			},
			messages: {
				required: 'This field is required'
			},
			element: (message, className) => {
				return (
					<div className={`tooltip fade top in`} role="tooltip" style={{transform: "translateY(-100%)"}}>
						<div className="tooltip-arrow" style={{left: "50%"}}></div>
						<div className="tooltip-inner">
							{message}
						</div>
					</div>
				)
			}
		})
	}


	/**
	 * Displays customized validation error message
	 * 
	 * @param {string} errorMessage
	 */
	renderValidationError = errorMessage => {
		return (
			<span className="text-danger">
				<small>{errorMessage}</small>
			</span>
		)
	}

	/** 
	 * Runs when the form has been submitted.
	 * Will call `POST: account/portal_reset_password` 
	 * 
	 * @type {(values: any, formikHelpers: import('formik').FormikHelpers<any>) => void)}
	 */
	handleOnSubmit = async (values, formikHelpers) => {

		formikHelpers.setSubmitting(true);
		this.state.oldpassword = values.oldpassword;
		this.state.newpassword = values.newpassword;
		this.state.repeatpassword = values.repeatpassword;

		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate()
			return;
		}

		// server side validation
		try {
			const response = await axios.post("/account/portal_reset_password", objectToFormData({data:JSON.stringify(values)}));
			if (response.data) {
				let destination_url = response.data['destination_url'] || '/';
				history.push(destination_url);
			}
		} catch(e) {
			const {errors} = e.response.data;
			for (let field in errors) {
				formikHelpers.setFieldError(field, errors[field])
			}
		}
	}

	/**
	 * Render breadcrumbs
	 */
	renderBreadcrumbs() {
		return <CustomBreadcrumbs />
	}

	/**
	 * Renders organisation information
	 */
	renderOrgSummary() {
		return (
			<div className="site-information">
				<OrgInfoSummary />
			</div>
		)
	}

	renderForm() {
		// NOTE: all validation happens on server-side
		return (
			<Formik
				initialValues={{
					oldpassword: this.state.oldpassword,
					newpassword: this.state.newpassword,
					repeatpassword: this.state.repeatpassword
				}}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={this.handleOnSubmit}
				enableReinitialize={true}
				>
				{({values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, initialValues}) => {
				return (
					<Form encType="multipart/form-data" method="POST" id="addsite">
						<div className="entry-header">
							<h2><b>Change Password</b></h2>
						</div>

						<div className="row mt-4" >
							<div className="col-md-4">
								<RequiredLabel htmlFor="oldpassword">Old Password: </RequiredLabel>
								{this.validator.message("oldpassword", values.oldpassword, 'required')}
								<Field name="oldpassword" id="oldpassword" className="form-control border-dark" type="password" required/>
								<ErrorMessage name="oldpassword" render={this.renderValidationError}/>
							</div>
						
							<div className="col-md-4">
								<RequiredLabel htmlFor="newpassword">New Password: </RequiredLabel>
								{this.validator.message("newpassword", values.newpassword, 'required|pass_min')}
								<Field name="newpassword" id="newpassword" className="form-control border-dark" type="password" required/>
								<ErrorMessage name="newpassword" render={this.renderValidationError}/>
							</div>
						
							<div className="col-md-4">
								<RequiredLabel htmlFor="repeatpassword">Repeat Password: </RequiredLabel>
								{this.validator.message("repeatpassword", values.repeatpassword, 'required|confirm_pass')}
								<Field name="repeatpassword" id="repeatpassword" className="form-control border-dark" type="password" required/>
								<ErrorMessage name="repeatpassword" render={this.renderValidationError}/>
							</div>
						</div>

						<div className="form-group mt-5 text-right">
							<button type="submit" disabled={this.state.loading} onClick={e => {
								setFieldValue("publish", true);
								handleSubmit(e);
							}} className="btn btn-dark">Save Changes</button>
						</div>

						<div className="form-group">
							<br/>
							<br/>
						</div>
					</Form>
						)
					}
				}
			</Formik>
		)
	}

	render() {
		return (
			<div className="container-fluid">
				<div className="content-block">
					{this.renderBreadcrumbs()}
				</div>
				<div className="content-block">
					{this.renderOrgSummary()}
				</div>
				<div className="content-block">
					{this.renderForm()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {token} = state.auth.user

	let claims = null;
	if (token) {
		claims = jwt.decode(token)
	}

	return {
		organisationName: (claims || {})['name']
	}
}

export default connect(mapStateToProps)(ChangePassword)
