import React from 'react';
import ReactDOM from 'react-dom';
import EditIconWhite from '../icons/Edit-Icon-White.svg';
import UpdateOrgDetailModal from '../components/UpdateOrgDetailModalComponent';

/**
 * Component to display basic information for the organization in label:value format
 */

class BasicDisplayFormComponent extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            showUpdateModal: false
        }
	}

	renderLabelValueMapping(mappings = []) {
		return mappings.map((kv, i) => (
			<div className="row data-row d-flex" key={i}>
				<div className="font-weight-bold label">{kv.label} :</div>
				<div className="value">&nbsp;{kv.value}</div>
			</div>
		))
	}

	render() {
		const { details } = this.props

        const digitToTextMapping = {
            "1" : 'Yes',
            "0" : 'No'
        };
        const labelsAndColumns = this.props.labelAndColumnMapping;
        const organizationDetail = this.props.organizationDetail;
        const organizationBasicDetail = (organizationDetail && organizationDetail.basic_detail) || null;
        var rows = [];

        // Build the component that needs to be rendered
        for(let label in labelsAndColumns) {
            var i = 1;

            if(labelsAndColumns.hasOwnProperty(label)) {
                var renderedValue = '';

                if(label === 'Office Phone' && organizationBasicDetail && organizationBasicDetail[labelsAndColumns[label]]) {
					for (const [index, com_row] of organizationBasicDetail[labelsAndColumns[label]].entries()) {
						if(renderedValue)
							renderedValue += ", " + com_row.phone
						else
							renderedValue += com_row.phone
					}
                } else if(label === 'Office Email' && organizationBasicDetail && organizationBasicDetail[labelsAndColumns[label]]) {

					renderedValue = []
					for (const [index, com_row] of organizationBasicDetail[labelsAndColumns[label]].entries()) {
						renderedValue.push(
							<a href={`mailTo: ${com_row.email}`}>{com_row.email}</a>
						)
					}
					renderedValue = renderedValue.map((r, i, readonlyArray) => (
						<React.Fragment key={i}>{r}{ (i < readonlyArray.length - 1 ? ', ' : '' ) }</React.Fragment>
					))
                } else if((organizationBasicDetail && typeof organizationBasicDetail[labelsAndColumns[label]]) == "boolean") {
					renderedValue = organizationBasicDetail[labelsAndColumns[label]] === true ? 'Open' : 'Closed';
				} else if(organizationBasicDetail && organizationBasicDetail[labelsAndColumns[label]]) {
					var val = organizationBasicDetail && organizationBasicDetail[labelsAndColumns[label]];

					renderedValue = val;

					if(val === "1" || val === "0") {
						renderedValue = digitToTextMapping[val];
					}
				}

				rows.push(
					<div className="row data-row d-flex" key={`${i}-${label}`}>
						<div className="font-weight-bold label">{label} :</div>
						<div className="value">&nbsp;
							{ renderedValue }
						</div>
					</div>
				);

				i++;
			}
		}

		return(
			<div>
				<div className="entry-header">
				    <h2><b>{this.props.header}</b></h2>
				</div>
                <div className="d-flex flex-column">
					{details ? this.renderLabelValueMapping(details) : rows }
                </div>
                <div className="d-flex justify-content-end entity-detail-action" onClick={this.props.showModal}>
                    <div className="d-flex justify-content-end align-items-center update-entity-detail">
                        <span className="text">{this.props.button_title}</span>
                        <img className="icon icon-update" src={EditIconWhite} />
                    </div>
                </div>

            </div>
        );
    }
}

export default BasicDisplayFormComponent;