import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import { ToastContainer, toast } from 'react-toastify';
import NotificationBoard from 'react-sidebar';
import Notification from '../components/NotificationComponent';
import HelpSidebarParent from 'react-sidebar'

import Logo from '../images/placeholder-logo-200x100.png'
import AlertIcon from '../images/Alert-Icon-White.svg'
import InformationIcon from '../images/info-overlay-Icon-White.svg'
import MessagesIcon from '../images/Messages.png'
import SettingsIcon from '../images/Settings-Icon-White.svg'
import LogoutIcon from '../images/LogOut-Icon-White.svg'

import "../stylesheets/layouts/DashboardLayout.scss";
import jwt from "jsonwebtoken"
import { fetchAustralianStates, fetchOrgDetails, fetchSubOrgs, fetchSites, fetchInfographics } from '../actions/commonActions';
import { useState } from 'react'
import { history } from '../history'

import { checkLoginTime, css, determineEntityType, removeTrailingSlashes } from '../helpers/common'
import "../stylesheets/layouts/DashboardLayout.scss";
import 'react-toastify/dist/ReactToastify.css';
import { axios } from "../axios";
import doctitles from '../helpers/doctitles';
import { DEFAULT_DOCUMENT_TITLE } from '../config';
import HelpSidebar from '../components/HelpSidebar';

/**
 * @typedef {object} StandardJWTClaimSet
 * @property {string|null} iss
 * @property {string|null} aud
 * @property {string|null} jti
 * @property {string|null} iat
 * @property {int|null} bnf
 * @property {int|null} exp
 * 
 * 
 * @typedef {object} OrganisationClaimSet
 * @property {string|null} id
 * @property {string|null} companyId
 * @property {string|null} name
 * @property {string|null} abn
 * @property {string|null} logo_file
 * @property {string|int|null} parent_org
 * @property {string|null} website
 * @property {'0'|'1'|null} payroll_tax
 * @property {'0'|'1'|null} gst
 * @property {'0'|'1'|null} enabled_portal_access
 * @property {'0'|'1'|null} status
 * @property {string|null} booking_date
 * @property {'0'|'1'|null} archive
 * @property {string|null} username
 * @property {'0'|'1'|null} is_first_time_login
 * 
 * @typedef {import('react-redux').DispatchProp & {className: string, claims?: (StandardJWTClaimSet & OrganisationClaimSet) | null} & import('react-router-dom').RouteComponentProps} Props
 * @typedef {{ href: string, name?: string, description?: string, title?: string, is_active?: boolean }} SidebarLink
 * @typedef {SidebarLink & {_links?: Record<string, SidebarLink>}} Menu
 */

const SidebarMenu = ({ sidebarMenu }) => {
    const [open, setOpen] = useState(true)

    const styles = css({
        toggle: {
            position: "absolute",
            right: 15,
            top: 15,
            transform: open ? 'rotate(90deg)' : undefined
        },
        iconArrow: { 
            display: open ? 'block' : 'none' 
        }

    })


    return (
        <li className="position-relative">
            {
                sidebarMenu.href ? 
                (
                    <Link to={sidebarMenu.href} onClick={e => sidebarMenu.toggleable ? setOpen(!open) : false} title={sidebarMenu.description} className={sidebarMenu.is_active && 'active'}>
                        {sidebarMenu.title}
                        {
                            sidebarMenu.toggleable && sidebarMenu._links && Object.keys(sidebarMenu._links).length > 0 && (
                                <span className="icon icon-arrow-right" style={styles.toggle}></span>
                            )
                        }
                    </Link>
                ) 
                : 
                (
                    <span title={sidebarMenu.description} onClick={e => sidebarMenu.toggleable ? setOpen(!open) : false} className={sidebarMenu.is_active && 'active'}>
                        {sidebarMenu.title}
                        {
                            sidebarMenu.toggleable && sidebarMenu._links && Object.keys(sidebarMenu._links).length > 0 && (
                                <span className="icon icon-arrow-right" style={styles.toggle}></span>
                            )
                        }
                    </span>
                )
            }
            {(sidebarMenu._links && Object.keys(sidebarMenu._links).length > 0) && (
                <ul style={styles.iconArrow}>
                    {
                        Object.keys(sidebarMenu._links).map((key, i) => {
                            const menu = sidebarMenu._links[key];
                            if (!menu) {
                                return null
                            }
                            return (
                                <li key={i}>
                                    <Link to={menu.href} title={menu.description} className={menu.is_active && 'active'}>
                                        {menu.title}
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            )}
        </li>
    )
}

/**
 * @extends {React.Component<Props, null>}
 */
class DashboardLayout extends React.Component {

    static defaultProps = {
        className: ""
    }

    constructor(props) {
        super(props)

        this.checkLoginRoutine = null
        this.state = {
            notificationBoardIsOpen: false,
            notificationBoardStyles: {
                root: {
                    zIndex: -1
                },
                sidebar: {
                    backgroundColor: "#545454",
                    width: "245px",
                },
            },
            helpSidebarOpen: false,
        }

        // determine ORG, SUBORG or SITE immediately because
        // entityType is set to ORG before DashboardLayout is mounted
        this.determineEntityType()
    }

    componentDidMount() {
        const { dispatch } = this.props
        const { path } = this.props.match
        document.title = this.determineDocumentTitle(path)
        this.unlisten = history.listen(this.onRouteChanged)


        // determine entity type immediately on page load
        // or else entity types will be set as ORG when loading one of the sites' page
        this.determineEntityType()

        // check login status every 60 seconds
        const SIXTY_SECONDS = 1000 * 60;
        this.checkLoginRoutine = setInterval(() => checkLoginTime(() => clearInterval(this.checkLoginRoutine)), SIXTY_SECONDS);

  
		dispatch(fetchSites());
        dispatch(fetchSubOrgs());
        dispatch(fetchAustralianStates());
        dispatch(fetchOrgDetails());

        this.fetchNotifications();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.onRouteChanged()
        }
    }

    onRouteChanged = () => {
        this.determineEntityType()

        const { path } = this.props.match
        document.title = this.determineDocumentTitle(path)

        const { dispatch } = this.props
        dispatch(fetchInfographics(path))
    }

    determineDocumentTitle(k) {
        if (k in doctitles) {
            return doctitles[k]
        }

        return DEFAULT_DOCUMENT_TITLE
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten()
        }
    }

    
    determineEntityType() {
        const { dispatch, match } = this.props
        const { path, params } = match || {}
        const { id } = params || {}

        dispatch({ type: 'SET_ENTITY', payload: { id }})
    }


    /**
     * Make a network call to fetch notifications
     */
    fetchNotifications = () => {
        axios.get('/ParentOrgController/get_notifications').then(response => {
            var {data} = response;

            if(data.status) {
                this.setState({
                    notifications: data.data || []
                });
            }
        });
    }

    createSidebarUrls() {
        const { claims, suborgs, match, sites } = this.props;
        const { path } = match
		const { items, loading } = suborgs;
		const { siteitems, siteloading } = sites;
		const organisationName = claims && claims.name ? claims.name : "- na -"
        
        // Trying to follow HAL specification
        // http://stateless.co/hal_specification.html
        /** @type {Menu[]} */
        let sidebarMenus = [
            {
                name : "dashboard",
                title: "Dashboard",
                description: "Dashboard page",
                is_active: path === '/',
                href: "/",
                _links: {
                    "your_organisation": { href: "/org-review", title: "Your Organisation", is_active: path === '/org-review' },
                    "invoice_tracker": {href: "/invoices", title: "Invoice Tracker", is_active: path === '/invoices' },
                    "fms": {href: "/fms", title: "FMS", description: "Feedback Management System", is_active: path === '/fms'},
                    "shifts": { href: "/shifts", title: "Shifts", is_active: path === '/shifts'},
                    "request_staff": { href: "/shifts/create", title: "Request staff", is_active: path === '/shifts/create'},
                }
            },
            {
                name : "suborgs",
                title: "Sub-orgs",
                description: "Suborganisation",
                href: "/sub-orgs",
                is_active: path === '/sub-orgs',
                _links: {}
            },
        ];

        let suborgLinks = []

        if (['/sub-orgs/:id'].indexOf(path) >= 0 || ['/sub-orgs/:id'].some(suborgUrl => _.startsWith(path, suborgUrl))) {
            const { id } = match.params
            suborgLinks = (items || []).filter(s => s.id === id).map(suborg => ({
                title: suborg.name,
                description: suborg.name,
                toggleable: true,
                is_active: path === '/sub-orgs/:id',
                _links: {
                    "your_organisation": { href: `/sub-orgs/${suborg.id}`, title: "Your Sub-Org", description: `Contains details about sub-organisation '${suborg.name}'` , is_active: path === '/sub-orgs/:id'},
                    "invoice_tracker": {href: `/sub-orgs/${suborg.id}/invoices`, title: "Invoice Tracker", description: `Contains list of invoices for '${suborg.name}'` , is_active: path === '/sub-orgs/:id/invoices'},
                    "fms": {href:`/sub-orgs/${suborg.id}/fms`, title: "FMS", description: `Manage feedback and cases`, is_active: path === '/sub-orgs/:id/fms'},
                    "shifts": { href: `/sub-orgs/${suborg.id}/shifts/`, title: "Shifts", description: `Request staff for ${suborg.name}`, is_active: path === '/sub-orgs/:id/shifts'},
                    "request_staff": { href: `/sub-orgs/${suborg.id}/shifts/create`, title: "Request staff", description: `Request staff for ${suborg.name}`, is_active: path === '/sub-orgs/:id/shifts/create'},
                }
            }))
        }

        let siteLinks = []

        if (['/sites/:id'].indexOf(path) >= 0 || ['/sites/:id'].some(siteUrl => _.startsWith(path, siteUrl))) {
			const { id } = match.params
            siteLinks = (siteitems || []).filter(s => s.site_id === id).map(site => ({
                title: site.title,
                description: site.title,
                toggleable: true,
                is_active: _.startsWith(path, '/sites/:id'),
                _links: {
                    "your_site": { href: `/sites/${site.site_id}/detail`, title: "Your Site", description: `Contains details about site '${site.title}'` , is_active: ['/sites/:id', '/sites/:id/detail'].indexOf(path) >= 0},
                    "invoice_tracker": {href: `/sites/${site.site_id}/invoices`, title: "Invoice Tracker", description: `Contains list of invoices for '${site.title}'` , is_active: path === '/sites/:id/invoices'},
                    "fms": {href:`/sites/${site.site_id}/fms`, title: "FMS", description: `Manage feedback and cases`, is_active: path === '/sites/:id/fms'},
					"shifts": { href: `/sites/${site.site_id}/shifts`, title: "Shifts", description: `Shifts for ${site.title}`, is_active: path === '/sites/:id/shifts'},
					"request_staff": { href: `/sites/${site.site_id}/shifts/create`, title: "Request staff", description: `Request staff for ${site.title}`, is_active: path === '/sites/:id/shifts/create'},
					"site_docs": { href: `/sites/${site.site_id}/docs`, title: "Site docs", description: `Manage site docs for ${site.title}`, is_active: path === '/sites/:id/docs'},
                }
            }))
		}
		
        return [
            ...sidebarMenus, 
            ...suborgLinks,
            {
                name: "sites_house",
                title: "Sites/House",
                href: "/sites",
                is_active: path === '/sites'
			},
			...siteLinks
        ]
    }

    /** 
     * @param {Menu[]} sidebarMenus 
     */
    renderSidebarMenus(sidebarMenus) {
        return (
            <ul>
                {
                    sidebarMenus.map((sidebarMenu, i) => {
                        return <SidebarMenu key={i} sidebarMenu={sidebarMenu} />
                    })
                }
            </ul>
        )
    }

    renderSidebarMenusOld() {
        return (
            <ul>
                <li>
                    <Link to="/" className="active">Dashboard</Link>
                    <ul>
                        <li><Link to="/">Your Organisation</Link></li>
                        <li><Link to="/">Invoice Tracker</Link></li>
                        <li><Link to="/">FMS</Link></li>
                        <li><Link to="/">Request Staff</Link></li>
                    </ul>
                </li>
                <li><Link to="/">Sub-orgs</Link></li>
                <li>
                    <Link to="/">Yooralla - Altona East</Link>
                    <ul>
                        <li><Link to="/">Your Sub-Org</Link></li>
                        <li><Link to="/">Invoice Tracker</Link></li>
                        <li><Link to="/">FMS</Link></li>
                        <li><Link to="/">Request Staff</Link></li>
                    </ul>
                </li>
                <li><Link to="/">Houses</Link></li>
                <li><Link to="/">Sites</Link></li>
            </ul>
        )
    }

    onOpeningNotificationBar = () => {
        // This might seem counter intuitive, but what we are doing here is setting the z-index of the root(of the react-sidebar component) to what it should be when the value of the 'notificationBoardIsOpen' is inverted.
        // So if the notification bar is currently open, it will be closed below, and the z-index of the root should be -1 when it is closed, and vice-versa. that is what is happening here.
        var rootStyle = {
            zIndex: '-1'
        }
        this.setState(
            {
                notificationBoardIsOpen: !this.state.notificationBoardIsOpen,
            }
        );

        // After the notification panel goes in/hidden, set the z-index of the root to be lower as explained above. Otherwise the page becomes unclickable as the z-index of the root is high and it sits on top all elements on the page
        setTimeout(() => {
            this.setState({ notificationBoardStyles: {...this.state.notificationBoardStyles, root: rootStyle } });
        }, 500);
    }

    /**
     * Handle display of notification bar
     */
    showNotificationBar = () => {
        var rootStyle = {
            zIndex: 'auto'
        }

        this.setState({ 
            notificationBoardIsOpen: true ,
            notificationBoardStyles: {...this.state.notificationBoardStyles, root: rootStyle }
        }) 
    }


    handleOnClickClearNotification = () => {
        this.setState({
            notifications: [],
        })
    }

    /**
     * Handler to toggle help sidebar component
     * @param {React.MouseEvent<HTMLAnchorElement>} e
     */
    handleOnClickToggleHelpSidebar = e => {
        e.preventDefault()
        this.toggleHelpSidebar(!this.state.helpSidebarOpen)
    }

    /**
     * @param {boolean} open
     */
    toggleHelpSidebar = open => {
        this.setState({ helpSidebarOpen: open })
    }



    createNotificationBoardContent = () => {
        return (
            <>
                <div className="notifications-header d-flex justify-content-start align-items-center">
                    <span className="text font-weight-bold"> Your Notification </span>
                    <i className="icon icon-notification-icons"></i>
                    {this.state.notifications && <span className="notification-count mr-auto">{this.state.notifications.length}</span>}
                    <i className="icon icon-back-arrow close-notifications" onClick={this.onOpeningNotificationBar}></i>
                </div>

                <div className="notifications-body">
                    {
                        this.state.notifications && this.state.notifications.map((notification, ind) => (
                            <Notification key={notification.id}
                                labelsAndColumns={
                                    [
                                        { label: 'Title', column: 'title'},
                                        { label: 'Date', column: 'created'}, 
                                        { label: 'Des', column: 'shortdescription'}
                                    ]
                                }
                                relatedObject={notification}
                            />
                        ))
                    }
                </div>

                <div className="notification-footer d-flex justify-content-around">
                    <span className="footer-item font-weight-bold text-center" onClick={this.handleOnClickClearNotification}>
                        <span>Clear Notifications</span>
                    </span>
                    <Link to="/notifications" className="footer-item font-weight-bold text-center">
                        <span>Open Notifications</span>
                    </Link>
                </div>
            </>
        )
    }

    /**
     * Renders the right sidebar containing help articles/blocks
     */
    renderHelpSidebar() {
        const { helpSidebarOpen } = this.state

        const styles = {
            root: {
                zIndex: helpSidebarOpen ? 'auto' : -1,
            },
            sidebar: {
                backgroundColor: "#404040",
                width: 666,
            },
        }

        return (
            <HelpSidebarParent
                pullRight
                sidebarId="HelpSidebar"
                open={helpSidebarOpen}
                onSetOpen={this.toggleHelpSidebar}
                sidebar={<HelpSidebar onClose={() => this.setState({ helpSidebarOpen: false })}/>}
                styles={styles}
            />
        )
    }


    render() {
        const {className, children, alerts, claims} = this.props
        const siteHeading = claims && claims.name ? claims.name : "- na -"
        const sidebarMenus = this.createSidebarUrls()
        const notificationBoardContents = this.createNotificationBoardContent();
        const urlPrefix = removeTrailingSlashes(process.env.REACT_APP_ADMIN_URL)
        const imgSrc = `${urlPrefix}/uploads/organisation/${claims.id}/${claims.logo_file}`

        return (
            <>
            <ToastContainer
                enableMultiContainer
                containerId={'error_toast_container'}
                className='toast-container'
                toastClassName="red-toast"
                hideProgressBar={true}
            />
            <ToastContainer
                enableMultiContainer
                containerId={'success_toast_container'}
                className='toast_container'
                toastClassName="green-toast"
                hideProgressBar={true}
            />
            <div className={`layout-dashboard site-wrapper page ${className}`}>
                <aside className="sidebar sidebar-left">
                    <header className="sidebar-header bg-primary d-flex org-logo">
                        <div className="align-self-center w-100 text-center">
                            <img src={imgSrc} alt="Organisation logo"/>
                        </div>
                    </header>
                    <nav className="sidebar-navigation">
                        {this.renderSidebarMenus(sidebarMenus)}
                        {/* {this.renderSidebarMenusOld()} */}
                    </nav>
                    <footer className="sidebar-footer bg-primary">
                        <ul className="sidebar-footer-links">
                            <li><Link to="/privacy-policy">Privacy policy</Link></li>
                            <li><Link to="/about-hcm">About Healthcare Manager</Link></li>
                            <li><Link to="/staff-policy">Staff policies</Link></li>
                            <li><Link to="/terms-conditions">Terms &amp; conditions</Link></li>
                        </ul>
                        <div className="copyright text-light">
                            &copy; Healthcare Manager 2019 - All Rights Reserved
                        </div>
                    </footer>
                </aside>
                <div className="site-body">
                    <header className="site-header bg-primary text-light">
                        <div className="container-fluid d-flex align-items-center h-100 w-100 site-header-inner">
                            <div className="w-100">
                                <div className="nav-icons clearfix">
                                    <nav className="d-inline-block">
                                        <ul>
                                            <li>
                                                <span onClick={() => history.goBack()}>
                                                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" tabIndex="-1" title="ArrowBack" width="33">
                                                        <path d="
                                                        M20 11
                                                        H7.83
                                                        l5.59-5.59
                                                        L12 4
                                                        l-8 8 8 8 1.41-1.41
                                                        L7.83 13
                                                        H20v-2z" fill="white" stroke="transparent"></path>
                                                    </svg>
                                                </span>
                                            </li>
                                        </ul>
                                    </nav>
                                    <nav className="d-inline-block text-right float-right">
                                        <ul>
											<li>
												<Link title="Help" to="/" onClick={this.handleOnClickToggleHelpSidebar}>
													<img src={InformationIcon} alt="Icons"/>
												</Link>
											</li>
											<li>
												<Link title="change password" to="/change-password">
													<img width="20" src={SettingsIcon} alt="Icons"/>
												</Link>
											</li>
											<li>
												<Link to='#'>
                                                    <img src={AlertIcon} alt="Icons" 
                                                        onClick={this.showNotificationBar}
                                                    />
                                                    {this.state.notifications && <span className="notification-count">{this.state.notifications.length}</span>}
												</Link>
											</li>
											<li>
                                                {/* Use regular links to force cancel all pending network request when logging out */}
                                                <a href="/logout" title="Logout">
													<img width="20" src={LogoutIcon} alt="Icons"/>
                                                </a>
											</li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="site-heading-parent w-100">
                                    <h1 className="site-heading">{siteHeading}</h1>
                                </div>
                            </div>
                        </div>

                    </header>
                    <main className="site-content">
                        <div className="site-content-inner">
                            {children}
                        </div>
                            <NotificationBoard
                                pullRight={true}
                                rootId="notifications_panel"
                                open={this.state.notificationBoardIsOpen}
                                onSetOpen={this.onOpeningNotificationBar}
                                sidebar={
                                    notificationBoardContents
                                }
                                styles={this.state.notificationBoardStyles}
                            >
                            </NotificationBoard>
                        {this.renderHelpSidebar()}
                    </main>
                    <footer className="site-footer">

                    </footer>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {

    const {token} = state.auth.user
    let decoded = null;
    if (token) {
        decoded = jwt.decode(token)
    }

    return {
        alerts: state.alerts.items,
        claims: decoded,
        suborgs: {
            items: state.suborgs.items || [],
            loading: !!state.suborgs.loading
		},
		sites: {
            siteitems: state.sites.items || [],
            siteloading: !!state.sites.loading
        }
    }
}


export default withRouter(connect(mapStateToProps, null)(DashboardLayout))