import React from 'react'
import { connect } from 'react-redux'
import OrgInfoSummary from '../../components/OrgInfoSummary'
import BasicDisplayForm from '../../components/BasicDisplayFormComponent';
import ContactDisplayForm from '../../components/ContactDisplayComponent';
import UpdateDetailModal from '../../components/UpdateSiteDetailsModal';
import UpdateSiteContactModalComponent from '../../components/UpdateSiteContactModalComponent';
import { Breadcrumb } from 'react-bootstrap'
import { axios } from '../../axios'
import jwt from "jsonwebtoken"
import EditIconWhite from "../../icons/Edit-Icon-White.svg";
import 'react-select-plus/dist/react-select-plus.css';
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs';
import SiteInfoSummary from '../../components/site/SiteInfoSummary'
import _ from 'lodash'
import { css } from '../../helpers/common';

const siteContactTypes = {
    "1": "Support Co-ordinator",
    "2": "Member",
    "3": "Key Contact",
    "4": "Billing Contact",
    "5": "Other Contact",
}

const DefaultContactData = {
    'contactTypeLabel': "Other Contact",
    '​department': '',
    '​emails': null,
    'firstname': "",
    'id': "",
    'key_contact': "",
    'key_contact_label': "",
    'lastname': "",
    'phones': null,
    'position': "",
    'siteId': "",
    'type': "5"
}

/**
 * Renders page to display and search list of sites
 * 
 * @extends {React.Component<Props, State>}
 */
class IndexPage extends React.Component {

    /** @type {State} */
    state = {
        orgDetail: null,
        siteDetails: null,
        siteContacts: null,
        siteContactTypes: siteContactTypes,
        showUpdateModal: false,
        showUpdateContactModal: false,
        contactToLoadInUpdateModal: DefaultContactData,
        sites: [],
    }

	/**
	 * fetches site information through Ajax
	 */
    async fetchSiteDetails() {
        const getSiteResponse = await axios.get("/site/get_site_details?id=" + this.props.match.params.id);
        const { data: getSiteResponseData } = getSiteResponse.data;
        this.setState({
            siteDetails: getSiteResponseData,
            siteContacts: getSiteResponseData.contact_detail,
            siteContactTypes: getSiteResponseData.contact_types,
        })
    }


	/**
	 * Fetch org details and sites when this page has loaded
	 */
    async componentDidMount() {
        this.setState({ loadingSites: true })
        await this.fetchSiteDetails()
        this.setState({
            loadingSites: false,
        })
    }

	/**
	 * Render org details
	 */
    renderSiteSummary() {
        return (
            <div className="site-information">
                <SiteInfoSummary />
            </div>
        )
    }

	/**
	 * Render breadcrumbs
	 */
    renderBreadcrumbs() {
        return <CustomBreadcrumbs />
    }

    updateContactButtonHandler = (siteContact) => {
        this.setState({
            showUpdateContactModal: true,
            contactToLoadInUpdateModal: siteContact,
            actionType: 'update'
        });
    }

    addContactButtonHandler = async () => {

        DefaultContactData.siteId = this.props.match.params.id;
        this.setState({
            showUpdateContactModal: true,
            contactToLoadInUpdateModal: DefaultContactData,
            actionType: 'create'
        });
        await this.fetchSiteDetails();
    }

    updateContactModalCloseHandler = async () => {
        this.setState({
            showUpdateContactModal: false
        });
        await this.fetchSiteDetails();
    }

    toggleContactDisplay = (toggledOrgContact) => {
        const siteContacts = this.state.siteContacts;
        const newSiteContacts = siteContacts.map((orgContact, i) => {
            if (orgContact === toggledOrgContact) {
                return {
                    ...orgContact,
                    expanded: !orgContact.expanded
                }
            }

            return orgContact;
        })

        this.setState({
            siteContacts: newSiteContacts
        })
    }

    contactWasUpdated = (response) => {
        this.updateContactModalCloseHandler();
    }

    modalCloseHandler = async () => {
        this.setState({
            showUpdateModal: false
        });
        await this.fetchSiteDetails();
    }

    displayModalHandler = () => {
        this.setState({
            showUpdateModal: true
        });
    }


    /**
     * Determine what to display for each row under contact section
     * @param {any} contact 
     */
    determineContactHeaderText(contact) {
        const fullname = [ _.get(contact, 'firstname'), _.get(contact, 'lastname')]
            .filter(_ => _)
            .join(' ')

        const text = [fullname, _.get(contact, 'position')].filter(_ => _).join(' - ')
        return text
    }


    /**
     * Convert given value to 'yes' or 'no'
     * @param {any} value 
     */
    isYesOrNo(value) {
        if (['1', 1, true, 'true', 'yes', 'YES'].indexOf(value) >= 0) {
            return 'Yes'
        } else if (['0', 0, false, 'false', 'no', 'NO'].indexOf(value) >= 0) {
            return 'No'
        } else {
            return ''
        }
    }

    /**
     * Map details of the site into array of items containing label and value
     * @param {any} c 
     * @return {{[key: string]?: any, label: string, value: string | number | JSX.Element}[]}
     */
    mapSiteContactToContactDetails(c) {
        const site_contact = c || {}

        return [
            {
                label: 'First Name',
                value: _.get(site_contact, 'firstname'),
            },
            {
                label: 'Last Name',
                value: _.get(site_contact, 'lastname'),
            },
            {
                label: 'Position',
                value: _.get(site_contact, 'position'),
            },
            {
                label: 'Email',
                value: (site_contact.emails || [])
                    .filter(e => parseInt(e.primary_email) === 1)
                    .map((e, i)=> <span key={i}>{e.email}</span>)
            },
            {
                label: 'Phone',
                value: (site_contact.phones || [])
                    .filter(p => parseInt(p.primary_phone) === 1)
                    .map((p, i)=> <span key={i}>{p.phone}</span>)
            },
        ]
    }
    

    /**
     * Render list of contacts
     * 
     * @param {any[]} contacts 
     */
    renderContacts(contacts = []) {
        return contacts.map((contact, i) => {
            const details = this.mapSiteContactToContactDetails(contact)
            const headerText = this.determineContactHeaderText(contact)
            
            return (
                <div className="org-contacts" key={i}>
                    <ContactDisplayForm 
                        expanded={contact.expanded}
                        toggleContactDisplay={this.toggleContactDisplay}
                        updateButtonHandler={this.updateContactButtonHandler}
                        contact={contact}
                        headerText={headerText}
                        contactWasUpdatedCallback={this.contactWasUpdated}
                        details={details}
                        modalRequestFrom="site"
                    />
                </div>
            )
        })
    }


    /**
     * Convert site details to array of items containing label-value
     * 
     * @param {any} d 
     */
    mapDetails(d) {
        const details = d || {}

        return [
            {
                label: 'Name',
                value: _.get(details, 'name'),
            },
            {
                label: 'Address',
                value: _.get(details, 'primary_address')
            },
            {
                label: 'Office Phone',
                value: (details.site_phones || []).map(p => p.phone).filter(_ => _).join(', ')
            },
            {
                label: 'Office Email',
                value: (details.site_emails || [])
                    .map(p => p.email)
                    .filter(_ => _)
                    .map((email, i, readonlyEmails) => {
                        return (
                            <React.Fragment key={i}>
                                <a href={`mailTo: ${email}`}>{email}</a>{i < readonlyEmails.length - 1 ? ', ' : ' '}
                            </React.Fragment>
                        )
                    })
            },
            {
                label: 'ABN NO',
                value: _.get(details, 'abn')
            },
            {
                label: 'Payroll Tax',
                value: this.isYesOrNo(_.get(details, 'payroll_tax'))
            },
            {
                label: 'GST',
                value: this.isYesOrNo(_.get(details, 'gst'))
            },
        ]
    }


    /**
     * Render site details
     */
    renderDetails() {
        const { siteDetails } = this.state
        const { basic_detail } = siteDetails || {}
        const details = this.mapDetails(basic_detail)

        return (
            <div>
				<div className="entry-header">
                    <h2><b>Site Details</b></h2>
				</div>
                <div className="d-flex flex-column">
                    {
                        details.map((kv, i) => (
                            <div className="row data-row d-flex" key={i}>
                                <div className="font-weight-bold label">{kv.label} :</div>
                                <div className="value">&nbsp;{kv.value}</div>
                            </div>
                        ))
                    }
                </div>
                {this.renderUpdateSiteDetailsBtn()}
            </div>
        )
    }


    /**
     * Render button to update site details
     */
    renderUpdateSiteDetailsBtn() {
        const { siteDetails } = this.state
        const { basic_detail } = siteDetails || {}
        const disabled = !basic_detail

        const styles = css({
            button: {
                whiteSpace: "nowrap",
                paddingLeft: 20,
                paddingRight: 20,
            },
            text: {
                margin: 0,
            },
            icon: {
                margin: 0
            }
        })

        return (
            <div className="d-flex justify-content-end entity-detail-action">
                <button className="d-flex justify-content-between align-items-center update-entity-detail btn btn-dark" 
                    style={styles.button} 
                    onClick={this.displayModalHandler}
                    disabled={disabled}
                >
                    <span className="text" style={styles.text}>Update Org Details</span>
                    <img className="icon icon-update" src={EditIconWhite} style={styles.icon} />
                </button>
            </div>
        )
    }


    render() {
        const { siteContacts } = this.state 

        return (
            <div className="container-fluid">
                <div className="content-block">
                    {this.renderBreadcrumbs()}
                </div>
                <div className="content-block">
                    {this.renderSiteSummary()}
                </div>
                <div className="content-block">
                    <div className="entity-details">
                        { this.renderDetails() }
                    </div>
                </div>
                <UpdateDetailModal modalId="updateOrgDetailModal"
                    showPopup={this.state.showUpdateModal}
                    closeModal={this.modalCloseHandler}
                    stateList={this.state.stateList}
                    siteDetails={this.state.siteDetails}
                    afterUpdatingOrgDetail={this.orgDetailUpdated}>
                </UpdateDetailModal>
                <div className="content-block">
                    <div className="entry-header entity-contact-header">
                        <h2><b>Site - Contacts</b></h2>
                    </div>
                </div>

                <div className="content-block">
                    {this.renderContacts(siteContacts || [])}
                </div>

                <div className="content-block">
                    <div className="d-flex justify-content-end org-page-action" onClick={this.addContactButtonHandler}>
                        <div className="d-flex justify-content-end align-items-center add-contact">
                            <span className="text">Add Contact</span>
                            <img className="icon icon-update" src={EditIconWhite} />
                        </div>
                    </div>
                </div>


                <UpdateSiteContactModalComponent modalId="updateContactDeatilModal"
                    updateModalShouldLanuch={this.state.showUpdateContactModal}
                    updateButtonHandler={this.updateContactButtonHandler}
                    updateContactModalCloseBtnHandler={this.updateContactModalCloseHandler}
                    contactWasUpdatedCallback={this.contactWasUpdated}
                    loadedContact={this.state.contactToLoadInUpdateModal}
                    mode={this.state.actionType}
                    siteId={this.props.match.params.id}
                    contactTypes={this.state.siteContactTypes}>
                </UpdateSiteContactModalComponent>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { token } = state.auth.user
    const { type: entityType } = state.entityTypes

    let claims = null;
    if (token) {
        claims = jwt.decode(token)
    }

    return {
        entityType,
        organisationName: (claims || {})['name']
    }
}

export default connect(mapStateToProps)(IndexPage);