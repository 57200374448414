import { axios } from "../axios"
import qs from "qs"

const httpConfig = {
    orgDataUrl: '/ParentOrgController/getOrgDetails',
    siteDetailsUrl: '/site/get_site_details?id='
};

const search_admins = async (q) => {
    return await axios.get("/common/search_admins?" + qs.stringify({ q }))
}

const search_organisations = async (q) => {
    return await axios.get("/common/search_organisations?" + qs.stringify({ q }))
}

const search_members = async (q) => {
    return await axios.get("/common/search_members?" + qs.stringify({ q }))
}

const search_house_or_site = async (q) => {
    return await axios.get("/common/search_site?" + qs.stringify({ q }))
}

const fetchOrgDetails = async(orgId) => {
    const data = {};

    if(typeof orgId !== undefined) {
        data.org_id = orgId;
    }

    return await axios.post(httpConfig.orgDataUrl, data);
}

const fetchSiteDetails = async (siteId) => {
    return await axios.get(httpConfig.siteDetailsUrl + siteId);

}

export default {
    search_admins,
    search_organisations,
    search_members,
    search_house_or_site,
    fetchOrgDetails,
    fetchSiteDetails
}