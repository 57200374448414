import React from 'react'
import { connect } from 'react-redux'

/**
 * Displays org details
 * 
 * @typedef {{} & import('react-router-dom').RouteComponentProps & import('react-redux').DispatchProp} Props
 * @typedef {{}} State
 * @extends {React.Component<Props, State>}
 */
class DetailsPage extends React.Component {
    render() {
        const { params } = this.props.match
        const { id } = params

        return (
            <div className="container-fluid">
                <h3>Suborg details</h3>
                <div>
                    <code>this.props.match.params.id: {id}</code><br/>
                    Props:
                    <pre>
                        {JSON.stringify(this.props, null, 4)}
                    </pre>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

    }
}

export default connect(mapStateToProps, null)(DetailsPage)