import { axios } from "../axios"

/**
 * 
 * @param {string} claims 
 * @param {string} new_password 
 * @param {string} password_repeat 
 */
function temporaryPasswordReset(claims, new_password, password_repeat) {
    const formData = new FormData();
    formData.set("new_password", new_password);
    formData.set("password_repeat", password_repeat);

    return axios.post("/account/temp_password_reset", formData )
}

/**
 * 
 * @param {string} email 
 */
function sendPasswordResetEmail(email) {
    const formData = new FormData();
    formData.set("email", email);

    return axios.post("/account/request_reset_password", formData)
}

/**
 * @typedef {{email: string, id: string, token: string, new_password: string, password_repeat: string}} ResetPasswordParams
 * @type {(params: ResetPasswordParams) => Promise}
 */
function resetPassword({email, id, token, new_password, password_repeat}) {
    const formData = new FormData();
    formData.set("email", email);
    formData.set("id", id);
    formData.set("token", token);
    formData.set("new_password", new_password);
    formData.set("password_repeat", password_repeat);

    return axios.post("/account/reset_password", formData);
}


function verifyPasswordResetToken(token) {
    return axios.post("/account/verify_reset_password_token?token=" + token);
}

export const passwordResetService = {
    temporaryPasswordReset,
    sendPasswordResetEmail,
    verifyPasswordResetToken,
    resetPassword,
}