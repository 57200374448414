import { createReducerSlice } from '../helpers/createReducer'

/**
 * @typedef { typeof slice.ActionTypes } ActionTypes
 * @typedef { typeof initialState } State
 */

const initialState = {
    loading: false,

    /** @type {{ [key: string]: any, value: number, label: string }[]} */
    items: [],

    /** @type {number|null} */
    fetched_at: null,

    /** @type {string[]} */
    errors: [],
}


const slice = createReducerSlice(initialState, {
    "FETCH_SHIFT_REQUIREMENTS_LOADING": (state, action) => ({
        ...state,
        loading: true,
    }),
    "FETCH_SHIFT_REQUIREMENTS_SUCCESS": (state, action) => ({
        ...state,
        loading: false,
        items: (action.response.data['data'] || []),
        fetched_at: Date.now(),
        errors: [],
    }),
    "FETCH_SHIFT_REQUIREMENTS_FAILED": (state, action) => ({
        ...state,
        loading: false,
        fetched_at: null,
        errors: ["Could not fetch shift requirements"]
    })
})


export const shiftRequirements = slice.reducer;
