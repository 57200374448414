import _ from 'lodash'

/**
 * @typedef {'ORG' | 'SUBORG' | 'SITE'} EntityTypes
 * @typedef { typeof initialState & { type: EntityTypes} } State
 */


/**
 * Determine entity type based on url
 * 
 * @todo Maybe a better way is to determine this within each 'Page' components 
 * using react-router instead of using the global window object
 * 
 * @param {Location} param0
 */
function determineEntityTypeFromLocation({ pathname: path }) {
    let entityType = 'ORG'
    if (/\/?sub-orgs(\/\d+){1}(\/.*)?$/gm.test(path)) {
        entityType = 'SUBORG'
    }

    if (/\/?sites(\/\d+){1}(\/.*)?$/gm.test(path)) {
        entityType = 'SITE'
    }

    return entityType
}

const initialState = {
    /** @type {number|null} */
    id: null,
    
    // @todo: Refactor this in the future to remove dependency to `window.location` object
    // (in Redux terms, this is considered as side-effect, w/c is an anti-pattern) 
    // Was implemented like this to avoid determining entity type on every 'Page' component (writing extra code)
    get type() {
        return determineEntityTypeFromLocation(window.location)
    }
}

export function entityTypes(state = initialState, { type, payload }) {
    switch (type) {
        case 'SET_ENTITY':
            return {
                ...state,
                id: payload.id,
                get type() {
                    return determineEntityTypeFromLocation(window.location)
                }
            }
        default: 
            return {
                ...state,
                get type() {
                    return determineEntityTypeFromLocation(window.location)
                }
            }
    }
}
