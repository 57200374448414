import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { axios } from "../axios";
import SimpleReactValidator from "simple-react-validator";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { AddPrimarySecondaryContact } from './AddPrimarySecondaryContact'
import "react-select-plus/dist/react-select-plus.css";

import RequiredLabel from './RequiredLabel'
import objectToFormData from "object-to-formdata";
import {history} from "../history"

/**
 * Modal component to udpate organization detail
 */
class UpdateSiteContactModalComponent extends Component {

	state = {
		loadedContact : {
			type : 5,
		}
	};

	constructor(props) {
		super(props);

		this.validator = new SimpleReactValidator({

			autoForceUpdate: this,
			className: 'text-danger form-error',
			
			validators: {
				valid_phone: {
					message: "Phone numbers should be 8-10 digits",
					rule: (val, params, validator) => {
						return val.length >= 8 && val.length <= 10
					},
				},
			},
			messages: {
				required: 'This field is required',
				phone: "Not a valid phone number",
				email: "Not a valid email address",
				min: "Must be at least 8 digits",
			},
			element: (message, className) => {
				return (
					<div className={`tooltip fade top in`} role="tooltip" style={{transform: "translateY(-100%)"}}>
						<div className="tooltip-arrow" style={{left: "50%"}}></div>
						<div className="tooltip-inner">
							{message}
						</div>
					</div>
				)
			}
		})
	}
	
	/**
	 * Displays customized validation error message
	 * 
	 * @param {string} errorMessage
	 */
	renderValidationError = errorMessage => {
		return (
			<span className="text-danger">
				<small>{errorMessage}</small>
			</span>
		)
	}

	/**
	 * handles the form submission
	 */
	handleOnSubmit = async (values, formikHelpers) => {

		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate()
			return;
		}

		// do not include empty secondary phones and emails in submission 
		const newValues = {
			...values,
			contactDetails: {
				...values.contactDetails,
				phones: values.contactDetails.phones.filter(p => p.is_primary || (!p.is_primary && p.value)),
				emails: values.contactDetails.emails.filter(e => e.is_primary || (!e.is_primary && e.value))
			}
		}

		// server side validation
		try {
			var response = null;
			if(this.props.mode == "create") {
				response = await axios.post("/site/create_contact?siteid="+this.props.siteId, objectToFormData({data:JSON.stringify(newValues)}));
			}
			else {
				response = await axios.post("/site/update_contact?id="+this.props.loadedContact.id, objectToFormData({data:JSON.stringify(newValues)}));
			}
			
			if (response.data) {
				let destination_url = response.data['destination_url'] || '/';
				this.props.updateContactModalCloseBtnHandler();
				history.push(destination_url);
			}
		} catch(e) {
			const {errors} = e.response.data;

			for (let field in errors) {
				formikHelpers.setFieldError(field, errors[field])
			}
		}
	}
	
	/**
	 * renders the main form to update contact details
	 */
	renderForm() {
		if(!this.state) {
			return
		}

		var contactDetails = this.props.loadedContact;
		var contactTypes = this.props.contactTypes;
		var phones_init_contact = [];
		var emails_init_contact = [];

		if(contactDetails && contactDetails.phones) {
			for (const [index, com_row] of contactDetails.phones.entries()) {
				var is_primary = true;
				var is_required = true;
				if(index > 0) {
					is_primary = false;
					is_required = false;
				}
				phones_init_contact.push({ value: com_row.phone, is_primary: is_primary, required: is_required })
			}
		}
		else {
			phones_init_contact.push({ value: "", is_primary: true, required: true })
		}

		if(contactDetails && contactDetails.emails) {
			for (const [index, com_row] of contactDetails.emails.entries()) {
				var is_primary = true;
				var is_required = true;
				if(index > 0) {
					is_primary = false;
					is_required = false;
				}
				emails_init_contact.push({ value: com_row.email, is_primary: is_primary, required: is_required })
			}
		}
		else {
			emails_init_contact.push({ value: "", is_primary: true, required: true })
		}
		contactDetails.phones = phones_init_contact;
		contactDetails.emails = emails_init_contact;
		contactDetails.contactTypeLabel = contactTypes[contactDetails.type];

		return (
			<Formik
				initialValues={{
					contactDetails: contactDetails
				}}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={this.handleOnSubmit}
				enableReinitialize={true}
				>
			{({values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting, initialValues}) => {
				return (
		<form id="updateOrgDetail" method="post">

		 <div className="form-row">
			 <div className="col-12 col-md-4">
				 <label>Contact type: </label>
				 <div className="form-control input border-dark disabled">
					 {contactDetails.contactTypeLabel}
				 </div>
			 </div>

			 <div className="col-12 col-md-4">
				 <RequiredLabel htmlFor="contactDetails-firstname">First name</RequiredLabel>
				 {this.validator.message('contactDetails[firstname]', values.contactDetails.firstname, 'required')}
				 <Field name="contactDetails[firstname]" id="contactDetails-firstname" className="form-control border-dark" required/>
				 <ErrorMessage name="contactDetails[firstname]" render={this.renderValidationError}/>
			 </div>

			 <div className="col-12 col-md-4">
				 <RequiredLabel htmlFor="contactDetails-lastname">Last name</RequiredLabel>
				 {this.validator.message('contactDetails[lastname]', values.contactDetails.lastname, 'required')}
				 <Field name="contactDetails[lastname]" id="contactDetails-lastname" className="form-control border-dark" required/>
				 <ErrorMessage name="contactDetails[lastname]" render={this.renderValidationError}/>
			 </div>
		 </div>

		 <div className="form-row mt-3">
			<div className="col-12 col-md-4">
				<RequiredLabel htmlFor="contactDetails-position">Position</RequiredLabel>
				{this.validator.message('contactDetails[position]', values.contactDetails.position, 'required')}
				<Field name="contactDetails[position]" id="contactDetails-position" className="form-control border-dark" required/>
				<ErrorMessage name="contactDetails[position]" render={this.renderValidationError}/>
			</div>
			<div className="col-12 col-md-4">
				<label htmlFor="contactDetails-department">Department</label>
				<Field name="contactDetails[department]" id="contactDetails-department" className="form-control border-dark"/>
				<ErrorMessage name="contactDetails[department]" render={this.renderValidationError}/>
			</div>
		 </div>

		 <div className="form-row mt-3">
		 	<div className="col-12 col-md-4">
				<AddPrimarySecondaryContact
					required placeholder="Can Include Area Code" 
					data-rule-notequaltogroup='[".distinctPh"]' 
					data-rule-phonenumber 
					data-msg-notequaltogroup="Please enter a unique contact number" 
					errors={errors}
					label='Phone'
					values={values.contactDetails.phones}
					handleChange={handleChange}
					setFieldValue={setFieldValue}
					renderValidationError={this.renderValidationError}
					idPrefix="contactDetails-phones"
					namePrefix="contactDetails[phones]"
					renderBeforeInput={({ value, name, isPrimary }) => {
						if (isPrimary) {
							return this.validator.message(name, value, 'required|phone|valid_phone')
						}

						return this.validator.message(name, value, 'phone', {
							messages: {
								phone: "Not a valid secondary phone"
							}
						})
						
					}}
				/>
			</div>

			<div className="col-12 col-md-4">
				<AddPrimarySecondaryContact
					errors={errors}
					label='Email'
					values={values.contactDetails.emails}
					handleChange={handleChange}
					setFieldValue={setFieldValue}
					renderValidationError={this.renderValidationError}
					idPrefix="contactDetails-emails"
					namePrefix="contactDetails[emails]"
					renderBeforeInput={({ value, name, isPrimary }) => {
						if (isPrimary) {
							return this.validator.message(name, value, 'required|email')
						}
						
						return this.validator.message(name, value, 'email', {
							messages: {
								email: "Not a valid secondary email"
							}
						})
					}}
				/>
			</div>
		 </div>

		 <div className="form-row row d-flex justify-content-end">
			<button type="submit" disabled={this.state.loading} onClick={e => {
					setFieldValue("publish", true);
					handleSubmit(e);
			}} className="btn btn-dark">Save Changes</button>
		</div>
	 </form>
					)
				}
			}
			</Formik>
		)	
	}

	/**
	 * main render function
	 */
	render() {
		var showMe = this.props.updateModalShouldLanuch;
		var modalId = this.props.modalId || "";
		const actionMode = this.props.mode;

		return (
			<Modal
				show={showMe}
				aria-labelledby="contained-modal-title"
				size="lg"
				id="updateOrgDetailModal"
				centered
			>
				<Modal.Header>
					<div className="d-flex align-items-center">
						<Modal.Title>{actionMode == 'create' ? 'Create Contact' : 'Edit Contact'}</Modal.Title>
						<span className="icon icon-cross-icons"
							onClick={this.props.updateContactModalCloseBtnHandler}
						></span>
					</div>
				</Modal.Header>

				<Modal.Body>
				{this.renderForm()}
				</Modal.Body>
			</Modal>
		);
	}
}

export default UpdateSiteContactModalComponent;
