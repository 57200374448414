import React from "react"
import { connect } from "react-redux"
import { Breadcrumb  } from 'react-bootstrap'
import { Link } from "react-router-dom"
import jwt from "jsonwebtoken"
import qs from 'qs'
import _ from 'lodash'

import OrgInfoSummary from '../../components/OrgInfoSummary'
import SiteInfoSummary from '../../components/site/SiteInfoSummary'
import { axios } from '../../axios'

import ViewIconWhite from "../../images/View-Icon-White.svg"

import { invoiceService } from "../../services/invoiceService"
import ReactTable from "react-table"
import { css } from "../../helpers/common"

import PreviousPageIcon from "../../images/Previous-Page-Icon.svg"
import NextPageIcon from "../../images/Next-Page-Icon.svg"

import "../../stylesheets/pages/invoices/IndexPage.scss"
import 'react-table/react-table.css'
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"


/**
 * @typedef {'NDIS' | 'Private' | 'Welfare'} FundingType NOTE: Unused atm. Check out `ydtwebstaging.com/admin/finance/CreateManualInvoice/` for funding types (aka invoice types)
 * 
 * @typedef {ReturnType<typeof mapStateToProps> & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 * @typedef {{}} State
 */


/**
 * Renders the invoice tracker page.
 * 
 * Note: This component is shared among these routes: 
 * - `/invoices` - Renders invoices of currently logged in org
 * - `/sub-orgs/:id/invoices` - Renders invoices by given suborg ID from the URL 
 * - `/sites/:id/invoices` - Renders invoices by given site ID from the URL
 * 
 * @extends {React.Component<Props, State>}
 */
class IndexPage extends React.Component {

    /** @type {State} */
    state = {
        loadingInvoices: false,
        invoices: [],
        booked_by: 'ORG',
        pageSize: 10,
        search: ''
    }

    /**
     * Fetch org details and invoices when this page has loaded
     */
    async componentDidMount() {
        const { match } = this.props
        const { path, params } = match
        
        let booked_by = 'ORG'
        let id = params.id
        
        switch (path) {
            case '/sub-orgs/:id/invoices':
                booked_by = 'SUBORG'
                break
            case '/sites/:id/invoices':
                booked_by = 'SITE'
                break
            default:
                break
        }
        
        this.setState({ loadingOrgDetail: true, loadingInvoices: true, booked_by: booked_by })
        try {
            const fetchAllInvoicesResponse = await invoiceService.fetchAllInvoices(id, booked_by, { pageSize: 10, page: 0, sorted: [], filtered: [] });
            const { data: fetchAllInvoicesData } = fetchAllInvoicesResponse.data;

            this.setState({
                loadingInvoices: false,
                invoices: fetchAllInvoicesData,
            })


        } catch (e) {
            this.setState({ 
                loadingOrgDetail: false, 
                loadingInvoices: false 
            })
        }


    }


    /**
     * Render breadcrumbs
     */
    renderBreadcrumbs() {
        return <CustomBreadcrumbs />
    }

    /**
     * Render org details
     */
    renderOrgSummary() {
        return (
            <div className="site-information">
                <OrgInfoSummary />
            </div>
        )
    }

    /**
     * Render org details
     */
    renderSiteSummary() {
        return (
            <div className="site-information">
                <SiteInfoSummary />
            </div>
        )
    }




    /**
     * Render page title
     */
    renderEntryHeader() {
        const { path } = this.props.match

        let header = 'Invoice Tracker'
        if (_.startsWith(path, '/sub-orgs')) {
            header = 'Sub-orgs - Invoice Tracker'
        } else if (_.startsWith(path, '/site') || _.startsWith(path, '/sites')) {
            header = 'Sites - Invoice Tracker'
        }

        return (
            <div className="entry-header">
                <h2>
                    <b>{header}</b>
                </h2>
            </div>
        )
    }


    /**
     * Search form submit handler
     */
    handleOnSubmitSearch = async e => {
        e.preventDefault();

        const { value: search } = e.target['search'] || {} // e.target['search'] came from `form input[name=search]` element
        await this.doSearch(search)
    }


    /**
     * Handler when search input is clicked (ie. 'X' button is clicked)
     */
    handleOnClickClearSearch = async e => {
        e.preventDefault()

        const { search } = this.state

        // don't run ajax again when search field is already empty
        if (search) {
            await this.doSearch('')
        }
        
    }


    /**
     * Calls the backend to search invoices
     */
    doSearch = async search => {

        const { params } = this.props.match
        const { id } = params
        const { booked_by } = this.state

        this.setState({ loadingInvoices: true, search })
        try {
            const response = await invoiceService.fetchAllInvoices(id, booked_by, { search })
            const { data } = response.data
            this.setState({
                loadingInvoices: false,
                invoices: data
            })
        } catch (e) {
            this.setState({ loadingInvoices: false })
        }
    }


    /**
     * Section for top part of the invoice listing
     */
    renderListHeader() {
        const { search } = this.state

        return (
            <div className="row mt-4">
                <div className="col-12 col-sm-9">
                    <form action="#" method="GET" className="search-form" onSubmit={this.handleOnSubmitSearch}>
                        <input type="text" name="search" value={search} placeholder="Search" onChange={e => this.setState({ search: e.target.value })}/>
                        <button className="btn" type="button" onClick={this.handleOnClickClearSearch}>
                            <span className="close-icon"></span>
                        </button>
                    </form>
                </div>
            </div>
        )
    }


    /**
     * Renders invoice details when a single row 
     * in the table of invoices have been expanded
     * 
     * @param {any} invoice 
     */
    renderInvoiceDetails(invoice) {
        const styles = css({
            root: {

            },
            table: {
                maxWidth: 1074,
            },
            cellLabel: {
                width: 300,
            },
            cellValue: {
                borderBottom: "1px solid black", 
                borderTop: "none"
            },
            historyLog: {
                fontSize: 20, 
                color: "#006166"
            }
        })


        const details = [
            // { label: "Invoice from", value: " - (TODO) -" },
            // { label: "Invoice type", value: item.fund_type },
            { label: "Billing contact", value: invoice.billing_contact},
            { label: "Billing phone", value: invoice.billing_phone, has_history_log: true },
            { label: "Billing email", value: invoice.billing_email, has_history_log: true },
            { label: "Invoice amount", value: `$ ${invoice.amount}` },
            { label: "Invoice due date", value: invoice.pay_by },
        ]

        return (
            <div className="row invoice-details" style={styles.root}>
                <table className="table" style={styles.table}>
                    <tbody>
                        {
                            details.map((detail, i) => (
                                <tr key={i}>
                                    <td className="border-0 text-right" style={styles.cellLabel}>
                                        <b>{detail.label}</b>
                                    </td>
                                    <td className="border-0">
                                        <div className="w-100 d-flex justify-content-between" style={styles.cellValue}>
                                            {
                                                detail.value ? <span>{detail.value}</span> : <span>&nbsp;</span>
                                            }
                                            {
                                                detail.has_history_log && (
                                                    <span className="icon icon-pending-icons" style={styles.historyLog} title="History log"></span>
                                                )
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    /**
     * Determines the path which the 'Create new invoice' button goes to
     * 
     * @param {object} param 
     * @param {number} param.id
     */
    viewInvoiceLink({ id }) {
        const { path, params } = this.props.match
        if (path === '/invoices') {
            return `/invoices/${id}`
        } else if (path === '/sub-orgs/:id/invoices') {
            return `/sub-orgs/:id/invoices/`.replace(':id', params.id) + id
        } else if (path === '/sites/:id/invoices') {
            return `/sites/:id/invoices/`.replace(':id', params.id) + id
        }

        return '#'
    }

    determineBilledTo = (invoice) => {
        let { name, booked_by, bill_pay_by } = invoice

        const styles = css({
            label: {
                overflowX: "hidden",
                textOverflow: "ellipsis",
            },
        })

        const BILL_PAY_BY_PARENT_ORG = 1
        const BILL_PAY_BY_SUB_ORG = 2

        // This 'reason' variable is to tell why 'billed to' will be attributed to the owner organisation 
        // (parent or suborg) instead of the type of current site/org 
        // eg. invoice is for current site/org but the one that will be billed will be parent/sub org
        let reason = '' 

        let booked_by_label = ""
        if (bill_pay_by == BILL_PAY_BY_PARENT_ORG) {
            booked_by_label = "Parent Org"
            reason = ' (Parent org is billed instead)'
        } else if (bill_pay_by == BILL_PAY_BY_SUB_ORG) {
            booked_by_label = "Sub Org"
            reason = ' (Sub org is billed instead)'
        } else if (booked_by == 4) {
            booked_by_label = "Parent Org"
        } else if (booked_by == 5) {
            booked_by_label = "Sub Org"
        } else if (booked_by == 1) {
            booked_by_label = "Site"
        }

        let label = [name, booked_by_label].filter(t => !!t).join(' - ')
        let tooltipLabel = [label, reason].join(' ')
        return (
            <div className="text-center" title={tooltipLabel} style={styles.label}>
                {label}
            </div>
        )
    }


    /**
     * Renders table of invoices using <ReactTable /> plugin
     */
    renderTable() {
        const { loadingInvoices, invoices, pageSize } = this.state

        /** @type {import("react-table").Column<any>[]} */
        const columns = [
            {
                Header: "Billed to",
                headerClassName: "col-sm-3 has-vertical-divider",
                className: "has-vertical-divider",
                Cell: ({ original: invoice }) => this.determineBilledTo(invoice)
            },
            {
                accessor: "invoice_id",
                Header: "Invoice No.",
                headerClassName: "col-sm-3 has-vertical-divider",
                className: "has-vertical-divider",
            },
            {
                accessor: "invoice_date",
                Header: "Date:",
                headerClassName: "col-sm-3 has-vertical-divider",
                className: "has-vertical-divider",
            },
            {
                Header: "Action",
                headerClassName: "col-sm-3",
                Cell: ({ original: invoice }) => {
                    const viewInvoiceLink = this.viewInvoiceLink(invoice)

                    return (
                        <div className="d-flex align-items-center justify-content-center w-100">
                            <Link to={viewInvoiceLink} className="btn btn-sm btn-dark">
                                View invoice &nbsp; &nbsp; &nbsp;<img src={ViewIconWhite} alt=""/>
                            </Link>
                        </div>
                    )
                }
            }
        ]

        return (
            <ReactTable
                columns={columns}
                data={invoices}
                minRows={0}
                loading={loadingInvoices}
                SubComponent={({ original: invoice }) => this.renderInvoiceDetails(invoice)}

                className="hcm-list border-0"
                TheadComponent={(props) => <section {...props}/>}
                TrGroupComponent={(props) => <section {...props}/>}
                getTheadTrProps={() => ({ className: 'row row-header w-100' })}
                getTheadProps={() => ({ className: 'bg-dark text-white text-center' })}
                getTrProps={() => ({ className: 'row w-100'})}
                getTdProps={() => ({ className: 'col-12 align-self-center' })}
                getTrGroupProps={() => ({ className: 'text-center'})}

                // pagination
                pageSize={pageSize}
                pageSizeOptions={[10, 20, 50]}
                onPageSizeChange={(pageSize) => this.setState({ pageSize })}
                PreviousComponent={({disabled, onClick}) => (
                    <button type="button" className="btn" onClick={onClick} disabled={disabled} >
                        <img src={PreviousPageIcon} />
                    </button>
                )}
                NextComponent={({disabled, onClick}) => (
                    <button type="button" className="btn" onClick={onClick} disabled={disabled} >
                        <img src={NextPageIcon} />
                    </button>
                )}
                getPaginationProps={() => ({ className: 'd-flex ml-auto mr-auto justify-content-between align-items-center' })}
            />
        )
    }



    render() {
        const { entityType } = this.props

        return (
            <div className="container-fluid">
                <div className="content-block">
                    {this.renderBreadcrumbs()}
                </div>
                <div className="content-block">
                    {entityType === 'SITE' ? this.renderSiteSummary() : this.renderOrgSummary()}
                </div>
                <div className="content-block">
                    {this.renderEntryHeader()}
                </div>
                <div className="content-block">
                    {this.renderListHeader()}
                </div>
                <div className="content-block">
                    {this.renderTable()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {token} = state.auth.user
    const { type: entityType } = state.entityTypes

    let claims = null;
    if (token) {
        claims = jwt.decode(token)
    }

    return {
        organisationName: (claims || {})['name'],
        entityType,
    }
}

export default connect(mapStateToProps)(IndexPage)