import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import OrgInfoSummary from '../../components/OrgInfoSummary'
import { Breadcrumb } from 'react-bootstrap'
import { axios } from '../../axios'
import jwt from "jsonwebtoken"
import qs from "qs"
import AddIconWhite from "../../images/Add-Icon-White.svg"
import LoadingIndicator from "../../images/download.gif"
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs'

/**
 * Renders page to display and search list of sites
 * 
 * @extends {React.Component<Props, State>}
 */
class IndexPage extends React.Component {

    /** @type {State} */
    state = {
        loadingSites: false,
        sites: [],
    }

	/**
	 * Fetch org details and sites when this page has loaded
	 */
    async componentDidMount() {
        this.setState({ loadingSites: true })
        const getSiteResponse = await axios.get("/site/get_sites");
        const { data: getSiteResponseData } = getSiteResponse.data;

        this.setState({
            sites: getSiteResponseData,
            loadingSites: false,
        })
    }

	/**
	 * Search sites when form is submitted
	 * 
	 * @param {React.FormEvent | React.MouseEvent} e
	 */
    handleSubmitSearch = async (e) => {
        e.preventDefault();
        const { keyword } = this.state;
        try {
            let endpoint = `/site/get_sites`
            if (keyword.length > 0) {
                endpoint = endpoint + `?` + qs.stringify({ search: keyword });
            }

            const getSiteResponse = await axios.get(endpoint);
            const { data: getSiteResponseData } = getSiteResponse.data;

            this.setState({
                sites: getSiteResponseData,
                loadingSites: false,
            })
        } catch (e) {
            this.setState({ loadingSites: false })
        }
    }

	/**
	 * @param {React.MouseEvent<HTMLButtonElement>} e
	 */
    handleClickClearSearch = async (e) => {
        try {
            let endpoint = `/site/get_sites`
            const getSiteResponse = await axios.get(endpoint);
            const { data: getSiteResponseData } = getSiteResponse.data;

            this.setState({
                sites: getSiteResponseData,
                loadingSites: false,
            })
        } catch (e) {
            this.setState({ loadingSites: false })
        }
    }

	/**
	 * Render org details
	 */
    renderOrgSummary() {
        return (
            <div className="site-information">
                <OrgInfoSummary />
            </div>
        )
    }

	/**
	 * Render page title
	 */
    renderEntryHeader() {
        return (
            <div className="entry-header">
                <h2>
                    <b>Sites List</b>
                </h2>
            </div>
        )
    }

	/**
	 * Render breadcrumbs
	 */
    renderBreadcrumbs() {
        return <CustomBreadcrumbs />
    }

	/**
	 * Render number of results and link to create site
	 */
    renderListActions() {
        const { sites } = this.state
        const totalSites = sites.length
        const styles = {
            root: {
                marginTop: 24,
                marginBottom: 24,
            },
            createSiteBtn: {
                fontSize: 20,
                width: 300,
                paddingTop: 4,
                paddingBottom: 4,
            },
            addIcon: {
                marginLeft: 70
            }
        }

        return (
            <div className="sites-list-header" style={styles.root}>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="d-flex h-100">
                            <div className="align-self-center">
                                Total Sites / Houses - {totalSites}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 text-right">
                        <Link to="/sites/create" className="btn btn-lg btn-dark" style={styles.createSiteBtn}>
                            <span style={{ marginLeft: 30 }}>
                                <span>Create new Site</span>
                                <img src={AddIconWhite} alt="Add sites" style={styles.addIcon} />
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

	/**
	 * Renders search form
	 */
    renderSearchForm() {
        const { keyword } = this.state

        return (
            <div className="col-12 col-sm-4">
                <div className="row">
                    <form action="#" onSubmit={this.handleSubmitSearch} method="GET" id="SubOrgsIndexForm" className="search-form">
                        <input type="text"
                            value={keyword}
                            className="form-control"
                            name="search"
                            id="SubOrgsIndexForm-search"
                            placeholder="Search"
                            onChange={e => this.setState({ keyword: e.target.value })}
                        />
                        <button type="button" className="btn" onClick={this.handleClickClearSearch}>
                            <span className="close-icon"></span>
                        </button>
                    </form>
                </div>
            </div>
        )
    }

	/**
	 * Renders list of sites
	 */
    renderList() {
        const { loadingSites, sites, searchingSites } = this.state


        /** @type {Record<'loading' | 'sites' | 'name' | 'site' | 'button', React.CSSProperties>} */
        const styles = {
            loading: {
                marginTop: 15,
                marginBottom: 15,
            },
            sites: {
                backgroundColor: "#e6e6e6",
                padding: "10px 24px",
            },
            name: {
                fontSize: 20,
                fontWeight: "bold",
            },
            site: {
                fontSize: 20,
                fontWeight: "bold",
            },
            button: {
                fontSize: 14,
                width: 260,
                paddingTop: 0,
                paddingBottom: 0,
                lineHeight: "26px",
            }
        }

        const allsites = sites.map((site_row) => {
            return {
                type: "Site",
                title: site_row['title'],
                site: site_row['city']['value'] + ", " + site_row['statename'],
                _links: {
                    "details": {
                        href: `/sites/${site_row['ocs_id']}/detail`,
                        text: "View site details"
                    }
                }
            }
        })

        if (loadingSites) {
            return (
                <div className="text-center" style={styles.loading}>
                    Loading sites<br />
                    <img src={LoadingIndicator} alt="Loading..." />
                </div>
            )
        }

        return (
            <div className={`sub-orgs` + (searchingSites ? ' is-searching' : '') + (allsites.length === 0 ? ' has-no-results' : '')}>
                <div className="front-layer"></div>
                <div className="hcm-list">
                    {
                        allsites.length === 0 && (
                            <div className="row">
                                <div className="col-12">
                                    <p className="text-center" style={{ marginTop: 24, padding: 24, backgroundColor: "#E6E6E6" }}>
                                        {searchingSites ? <span>&nbsp;</span> : `No results found`}
                                    </p>
                                </div>
                            </div>
                        )
                    }
                    {
                        allsites.length > 0 && allsites.map((site_row, i) => (
                            <section key={i}>
                                <div className="row">
                                    <div className="col-12 col-sm-3 d-flex has-vertical-divider">
                                        <div className="d-flex align-self-center">
                                            <span style={styles.name}>{site_row.type}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-9">
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex align-self-center">
                                                <span style={styles.site}>{site_row.title} - {site_row.site}</span>
                                            </div>
                                            <span className="buttons">
                                                {
                                                    Object.keys(site_row._links).map((rel, i) => (
                                                        <Link className="btn btn-dark btn-block" style={styles.button} key={i} to={site_row._links[rel].href}>{site_row._links[rel].text}</Link>
                                                    ))
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        ))
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="content-block">
                    {this.renderBreadcrumbs()}
                </div>
                <div className="content-block">
                    {this.renderOrgSummary()}
                </div>
                <div className="content-block">
                    {this.renderEntryHeader()}
                </div>
                <div className="content-block">
                    {this.renderListActions()}
                </div>
                <div className="content-block">
                    {this.renderSearchForm()}
                </div>
                <div className="content-block">
                    {this.renderList()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { token } = state.auth.user

    let claims = null;
    if (token) {
        claims = jwt.decode(token)
    }

    return {
        organisationName: (claims || {})['name']
    }
}

export default connect(mapStateToProps)(IndexPage);