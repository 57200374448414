import React, { useState, useEffect } from 'react';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import BlockUi from 'react-block-ui';
import _ from 'lodash'

import { axios } from '../../axios';
import OrgInfoSummary from '../../components/OrgInfoSummary';
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs';
import fmsService from '../../services/fmsService'
import { css } from '../../helpers/common';

import PreviousPageIcon from "../../images/Previous-Page-Icon.svg"
import NextPageIcon from "../../images/Next-Page-Icon.svg"

import 'react-table/react-table.css'
import 'react-block-ui/style.css';
import '../../stylesheets/pages/fms/IndexPage.scss'
import FMSCaseDetails from '../../components/FMSCaseDetails';
import SiteInfoSummary from '../../components/site/SiteInfoSummary';

/**
 * @typedef {'ORG' | 'SUBORG' | 'SITE'} EntityTypes
 * @typedef {ReturnType<typeof mapStateToProps> & {}} OrganisationFmsProps
 * @typedef {OrganisationFmsProps & typeof mapStateToProps & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 * @typedef {{loading: boolean, pageSize: number, fms: any[], search: string}} State
 */


/**
 * @extends {React.Component<Props, State}
 */
class IndexPage extends React.Component {

    /** @type {State} */
    state = {
        loading: true,
        pageSize: 10,
        fms: [],
        expanded: {},
        search: '',
    }

    async componentDidMount() {        
        await this.doSearch('')
    }


    renderBreadCrumbs() {
        return <CustomBreadcrumbs />
    }

    /**
     * Render org details
     */
    renderOrgSummary() {
        return (
            <div className="site-information">
                <OrgInfoSummary />
            </div>
        )
    }

    renderSiteSummary() {
        return (
            <div className="site-information">
                <SiteInfoSummary />
            </div>
        )
    }


    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    handleOnSubmitSearch = async e => {
        e.preventDefault()

        const { value } = e.target['search'] || {}
        await this.doSearch(value)
    }


    /**
     * Handler when 'X' button in search field is clicked
     * @param {React.MouseEvent<HTMLButtonElement>} e
     */
    handleOnClickClearSearch = async e => {
        e.preventDefault()

        const { search } = this.state

        // dont run ajax again if search field is empty
        if (search) {
            await this.doSearch('')
        }
    }

    onExpandedChange = (newExpanded, index, event) => {
        this.setState(prevState => ({
            expanded: {
                ...prevState.expanded,
                [index]: !prevState.expanded[index]
            }
        }))
    }


    /**
     * Calls the backend to search FMS
     * @param {string} search
     */
    doSearch = async search => {
        const { entityType, match } = this.props
        const { id } = match.params || {}

        this.setState({ loading: true, search })
        try {
            const response = await fmsService.fetchFMS(id, entityType, { search: !!search ? search : undefined })
            const { data } = response.data
            this.setState({ fms: data, loading: false })
        } catch (e) {
            this.setState({ loading: false })
        }
    }



    /**
     * Render page title
     */
    renderEntryHeader() {
        const { headerText } = this.props

        return (
            <div className="entry-header">
                <h2>
                    <b>{headerText}</b>
                </h2>
            </div>
        )
    }

    /**
     * Renders search form
     */
    renderSearchForm() {
        const { search } = this.state
        const { createLink } = this.props

        const styles = css({
            root: {
                marginTop: 24,
                marginBottom: 12,
            },
            button: {
                minWidth: 257, 
                paddingLeft: 7, 
                paddingRight: 7
            } 
        })

        return (
            <div className="row" style={styles.root}>
                <div className="col-12 col-sm-4">
                    <form action="#" onSubmit={this.handleOnSubmitSearch} method="GET" id="FMSIndexForm" className="search-form">
                        <input type="text" 
                            value={search}
                            className="form-control" 
                            name="search" 
                            id="FMSIndexForm-search" 
                            placeholder="Search"
                            onChange={e => this.setState({ search: e.target.value })}
                        />
                        <button type="button" className="btn" onClick={this.handleOnClickClearSearch}>
                            <span className="close-icon"></span>
                        </button>
                    </form>
                </div>
                <div className="col-12 col-sm-8 text-right">
                    <Link to={createLink} className='btn btn-dark' style={styles.button}>Create new FMS</Link>
                </div>
            </div>
        )
    }

    /**
     * Sets up and renders <ReactTable />
     */
    renderTable() {
        const { loading, pageSize, fms: data, expanded } = this.state


        /** @type { import('react-table').Column<any>[] } */
        const columns = [
            {
                Header: 'ID',
                accessor: 'id',
                filterable: false,
                headerClassName: 'col-12 col-sm-2_4 has-vertical-divider position-relative'
            },
            {
                Header: 'Category',
                accessor: 'case_category',
                filterable: false,
                sortable: false,
                headerClassName: 'col-12 col-sm-2_4 has-vertical-divider position-relative'
            },
            {
                Header: 'Initiated By',
                accessor: 'initiated_by',
                sortable: false,
                headerClassName: 'col-12 col-sm-2_4 has-vertical-divider position-relative'
            },
            {
                Header: 'Date of Event',
                accessor: 'event_date',
                filterable: false,
                headerClassName: 'col-12 col-sm-2_4 has-vertical-divider position-relative'
            },
            {
                Header: 'Status',
                accessor: 'case_status',
                sortable: false,
                Cell: ({ original: fms }) => {
                    if (fms.case_status === 'closed') {
                        return <span className="badge badge-danger">{fms.case_status}</span>
                    }
                    if (fms.case_status === 'open') {
                        return <span className="badge badge-success">{fms.case_status}</span>
                    }

                    return fms.case_status
                }
            },
        ];

        return (
            <div className="row">
                <div className="col-12">
                    <ReactTable manual
                        columns={columns}
                        data={data}
                        loading={loading}
                        defaultPageSize={10}
                        className="hcm-list border-0"
                        minRows={0}
                        expanded={expanded}
                        onExpandedChange={this.onExpandedChange}
                    
                        SubComponent={(props) => <FMSCaseDetails {...props} />}
                        resizable={false}

                        className="hcm-list border-0"
                        TheadComponent={(props) => <section {...props}/>}
                        TrGroupComponent={(props) => <section {...props}/>}
                        getTheadTrProps={() => ({ className: 'row row-header w-100' })}
                        getTheadProps={() => ({ className: 'bg-dark text-white text-center' })}
                        getTrProps={() => ({ className: 'row w-100'})}
                        getTdProps={() => ({ className: 'col-12 align-self-center' })}
                        getTrGroupProps={() => ({ className: 'text-center'})}
        
                        // pagination
                        pageSize={pageSize}
                        pageSizeOptions={[10, 20, 50]}
                        onPageSizeChange={(pageSize) => this.setState({ pageSize })}
                        PreviousComponent={({disabled, onClick}) => (
                            <button type="button" className="btn" onClick={onClick} disabled={disabled} >
                                <img src={PreviousPageIcon} />
                            </button>
                        )}
                        NextComponent={({disabled, onClick}) => (
                            <button type="button" className="btn" onClick={onClick} disabled={disabled} >
                                <img src={NextPageIcon} />
                            </button>
                        )}
                        getPaginationProps={() => ({ className: 'd-flex ml-auto mr-auto justify-content-between align-items-center' })}
                    />
                </div>
            </div>
        )
    }




    render() {
        const { OrganisationProfile, entityType } = this.props


        return (
            <div className="container-fluid">
                <div className="content-block">
                    {this.renderBreadCrumbs()}
                </div>
                <div className="content-block">
                    {entityType === 'SITE' ? this.renderSiteSummary() : this.renderOrgSummary()}
                </div>
                <div className="content-block">
                    {this.renderEntryHeader()}
                </div>
                <div className="content-block">
                    {this.renderSearchForm()}
                </div>
                <div className="content-block">
                    {OrganisationProfile ? this.renderTable() : null}
                </div>
            </div>
        )
    }
}


/**
 * Determine header text from given entity type.
 * Entity type is previously determined from routes (see: `determineEntityType()`)
 * 
 * @param {EntityTypes} entityType 
 */
function determineHeaderText(entityType) {
    if (entityType === 'SUBORG') {
        return  "Sub-orgs - FMS Cases"
    }
    
    if (entityType === 'SITE') {
        return 'Sites -  FMS Cases'
    }
    
    return 'FMS Cases'
}

/**
 * Dtermine link to FMS create page from given entity type.
 * Entity type is previously determined from routes (see: `determineEntityType()`)
 * 
 * @param {EntityTypes} entityType
 * @param {import('react-router-dom').match<{id?: number}>} param 
 */
function determineFMSCreateLink(entityType, { params }) {
    if (entityType === 'SUBORG') {
        const { id } = params
        return `/sub-orgs/${id}/fms/create`
    }
    
    if (entityType === 'SITE') {
        const { id } = params
        return `/sites/${id}/fms/create`
    }
    
    return '/fms/create'
}




/**
 * 
 * @param {any} state 
 * @param {import('react-router-dom').RouteComponentProps} ownProps 
 */
const mapStateToProps = (state, ownProps) => {
    const { match } = ownProps
    const { type: entityType } = state.entityTypes

    return {
        entityType,
        headerText: determineHeaderText(entityType),
        createLink: determineFMSCreateLink(entityType, match),
        OrganisationProfile: (state.orgDetails.value || {}).basic_detail || {},
    }
}


export default connect(mapStateToProps)(IndexPage)