import { passwordResetService } from "../services/passwordResetService"
import { history } from "../history"

/**
 * 
 * @param {{[key: string]: any}} claims 
 * @param {string} password 
 * @param {string} reenteredPassword 
 * @return {(dispatch: import("redux-thunk").ThunkDispatch) => void}
 */
export function resetTemporaryPassword(claims, password, reenteredPassword) {
    return dispatch => {
        dispatch({ type: "TEMPORARY_PASSWORD_RESET_REQUESTED" })
        passwordResetService.temporaryPasswordReset(claims, password, reenteredPassword)
            .then(response => {
                dispatch({ type: "TEMPORARY_PASSWORD_RESET_SUCCESS", payload: response })
                dispatch({ type: "ALERTS_ADD", payload: { message: response.data.message, variant: "success" } })
                const destination_url = response.data['destination_url'] || "/logout"
                history.push(destination_url)
                setTimeout(() => dispatch({ type: "ALERTS_DISMISS_ALL" }), 3000)
            })
            .catch(error => {
                if (error.response.status !== 401) {
                    const { errors } = error.response.data
                    const firstErrorMessage = Object.values(errors || {})[0];
    
                    dispatch({ type: "TEMPORARY_PASSWORD_RESET_FAILURE", payload: error })
                    dispatch({ type: "ALERTS_ADD", payload: { message: firstErrorMessage, variant: "danger" } })
                    setTimeout(() => dispatch({ type: "ALERTS_DISMISS_ALL" }), 3000);
                }
            })
    }
}


/**
 * 
 * @param {string} email 
 * @return {(dispatch: import("redux-thunk").ThunkDispatch) => void}
 */
export function sendPasswordResetEmail(email) {
    return dispatch => {
        dispatch({ type: "SEND_PASSWORD_RESET_EMAIL_REQUESTED" });
        passwordResetService.sendPasswordResetEmail(email)
            .then(response => dispatch({ type: "SEND_PASSWORD_RESET_EMAIL_SUCCESS", payload: response }))
            .catch(error => dispatch({ type: "SEND_PASSWORD_RESET_EMAIL_FAILURE", payload: error }))
    }
}

/**
 * @typedef {{email: string, id: string, token: string, new_password: string, password_repeat: string}} ResetPasswordParams
 * @typedef {(dispatch: import("redux-thunk").ThunkDispatch) => void} ThunkAction
 * 
 * @type {(params: ResetPasswordParams) => ThunkAction}
 */
export function resetPassword({ email, id, token, new_password, password_repeat }) {
    return dispatch => {
        dispatch({ type: "RESET_PASSWORD_REQUESTED"})
        passwordResetService.resetPassword({ email, id, token, new_password, password_repeat })
            .then(response => {
                dispatch({ type: "RESET_PASSWORD_SUCCESS", payload: response })
                dispatch({ type: "ALERTS_ADD", payload: { message: response.data.message, variant: "success" } })
                setTimeout(() => {
                    dispatch({ type: "ALERTS_DISMISS_ALL" })
                    history.push("/login")
                }, 3000)
            })
            .catch(error => {
                if (error.response.status !== 401) {
                    const { errors } = error.response.data
                    const firstErrorMessage = Object.values(errors || {})[0];
                    dispatch({ type: "RESET_PASSWORD_FAILURE", payload: error })
                    dispatch({ type: "ALERTS_ADD", payload: { message: firstErrorMessage, variant: "danger" } })
                    setTimeout(() => dispatch({ type: "ALERTS_DISMISS_ALL" }), 3000);
                }
            })
    }
}


/**
 * 
 * @param {string} token 
 */
export function verifyPasswordResetToken(token) {
    return dispatch => {
        dispatch({ type: "VERIFY_PASSWORD_RESET_TOKEN_REQUESTED" })
        passwordResetService.verifyPasswordResetToken(token)
            .then(response => dispatch({ type: "VERIFY_PASSWORD_RESET_TOKEN_SUCCESS", payload: response }))
            .catch(error => dispatch({ type: "VERIFY_PASSWORD_RESET_TOKEN_FAILURE", payload: error.response.data }))
    }
}