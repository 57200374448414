import { createReducerSlice } from "../helpers/createReducer"

/**
 * @typedef { typeof initialState } State
 * @typedef { typeof sidebarReducer.ActionTypes } ActionTypes
 */

const initialState = {
    loading: false,
    items: [
        {
            name : "dashboard",
            is_active: true,
            title: "Dashboard",
            description: "Dashboard page",
            href: "/",
            expanded: true,
            _links: {
                "your_organisation": { href: "/org-review", title: "Your Organisation" },
                "invoice_tracker": {href: "/invoices", title: "Invoice Tracker" },
                "fms": {href: "/fms/create", title: "FMS", description: "Feedback Management System" },
                "request_staff": { href: "/", title: "Request staff"}
            }
        },
        {
            name : "suborgs",
            title: "Sub-orgs",
            description: "Suborganisation",
            href: "/sub-orgs",
            expanded: true,
            _links: {}
        },
        {
            name: "sites_house",
            title: "Sites/House",
            href: "/sites",
			expanded: true,
			_links: {}
        }
    ],

    errors: [],
    fetched_at: null
}


const sidebarReducer = createReducerSlice(initialState, {
    "SET_SIDEBAR_LINKS": (state, action) => ({
        ...state,
        items: action.payload
    }),
    "TOGGLE_SIDEBAR_LINK": (state, action) => ({
        ...state,
        items: state.items.map((link, i) => action.payload === link ? ({...link, expanded: !link.expanded}) : link)
    }),
    "ADD_SUBORGS_TO_SIDEBAR_LINKS": (state, action) => {
		const { data: items } = action.response.data
        const originalLinks = state.items.filter(l => ['dashboard', 'suborgs'].indexOf(l.name) >= 0)
        const suborgLinks = (items || []).map(suborg => ({
            title: suborg.name,
            description: suborg.name,
            href: `/sub-orgs/${suborg.id}`,
            expanded: true,
            _links: {
                "your_organisation": { href: `/sub-orgs/${suborg.id}`, title: "Your Sub-Org", description: `Contains details about sub-organisation '${suborg.name}'` },
                "invoice_tracker": {href: `/sub-orgs/${suborg.id}/invoices`, title: "Invoice Tracker", description: `Contains list of invoices for '${suborg.name}'` },
                "fms": {href:`/sub-orgs/${suborg.id}/fms`, title: "FMS", description: `Manage feedback and cases`},
                "request_staff": { href: `/sub-orgs/${suborg.id}/request-staff`, title: "Request staff", description: `Request staff for ${suborg.name}`},
            }
		})) 

        const newLinks = [
            ...originalLinks,
            ...suborgLinks
        ];

        return {
            ...state,
            items: newLinks
        }
	},
	
	"ADD_SITES_TO_SIDEBAR_LINKS": (state, action) => {
        const { data: items } = action.response.data
		
		const sitesLinks = (items || []).map(site => ({
            title: site.title,
            description: site.title,
            href: `/sites/${site.site_id}`,
            expanded: true,
            _links: {
                "your_organisation": { href: `/sites/${site.site_id}`, title: "Your Site", description: `Contains details about site '${site.title}'` },
                "invoice_tracker": {href: `/sites/${site.site_id}/invoices`, title: "Invoice Tracker", description: `Contains list of invoices for '${site.title}'` },
                "fms": {href:`/sites/${site.site_id}/fms`, title: "FMS", description: `Manage feedback and cases`},
                "request_staff": { href: `/sites/${site.site_id}/request-staff`, title: "Request staff", description: `Request staff for ${site.title}`},
            }
		})) 

        return {
            ...state,
            siteitems: sitesLinks
        }
    }
})

export const sidebar = sidebarReducer.reducer

