
export const ROUTER_PATH = '/';
export const LOGIN_DIFFERENCE = 60; // 60 minutes;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_OCS_GOOGLE_MAP_KEY;

export const WS_URL = process.env.REACT_APP_OCS_WS_URL;
export const PIN_DATA = {'fms':'1','admin':'2','incident':'3'};
export const PAGINATION_SHOW = 0;
export const BASE_URL = process.env.REACT_APP_OCS_API_HOST;
export const UPLOAD_MAX_SIZE_IN_MB = 5; //1 Megabyte = 1048576 Bytes.
export const UPLOAD_MAX_SIZE = (parseInt(1048576) * parseInt(UPLOAD_MAX_SIZE_IN_MB)); //1 Megabyte = 1048576 Bytes.
export const UPLOAD_MAX_SIZE_ERROR = 'The file you are attempting to upload is larger than the permitted size ('+UPLOAD_MAX_SIZE_IN_MB+'MB).';

//export const WS_URL = 'wss://dev.ydtwebstaging.com/wss';

export const DATA_CONSTANTS = {
    FILTER_WAIT_INTERVAL : 1000,
    FILTER_ENTER_KEY : 13
};

export const REGULAR_EXPRESSION_FOR_NUMBERS  = /^(\+|-)?(\d*\.?\d*)$/;
export const USE_REDUX_LOGGER = process.env.REACT_APP_REDUX_LOGGER === 'true'

export const DEFAULT_DOCUMENT_TITLE = 'Org portal'
