import React from 'react'
import { getIn } from 'formik'
import { ensureOnlyNumericKeypressAllowed, css } from '../helpers/common'
// import AddIconBlack from "../images/Add-Icon-Black.svg"
// import RemoveIconBlack from "../images/Add-Icon-Black.svg"

const styles = css({
    icon: {
        display: "inline-block",
        position: "absolute",
        top: 4,
        right: 4,
        cursor: "pointer"
    },
    inputBoxesParent: {
        position: 'relative'
    },
    addIcon: {
        fontSize: 30
    },
    removeIcon: {
        fontSize: 22
    },
    input: {
        paddingRight: 36
    }
})

/**
 * Custom component to display inputs that allow adding primary/secondary phone/email contact
 * 
 * @param {object} props
 * @param {{value: any, is_primary?: boolean}[]} props.values
 * @param {(field: string, value: any, shouldValidate?: boolean) => void} props.setFieldValue
 * @param {(e: React.ChangeEvent<any>) => void} props.handleChange
 * @param {string} props.label
 * @param {string} props.idPrefix
 * @param {string} props.namePrefix
 * @param {string} [props.primaryText]
 * @param {string} [props.secondaryText]
 * @param {'text' | 'tel' | 'email'} [props.type]
 * @param {number} [props.maxItems]
 * @param {string} [props.addIcon]
 * @param {string} [props.removeIcon]
 * @param {(data: { value: any, name: string, isPrimary: boolean }) => JSX.Element} [props.renderBeforeInput]
 * @param {(data: { value: any, name: string, isPrimary: boolean }) => JSX.Element} [props.renderAfterInput]
 * @param {boolean} [props.readOnly]
 */
export const AddPrimarySecondaryContact = ({
    values,
    setFieldValue,
    handleChange,
    renderValidationError,
    label,
    idPrefix,
    namePrefix,
    errors,
    // addIcon = AddIconBlack,
    // removeIcon = RemoveIconBlack,
    primaryText = ' (Primary)',
    secondaryText = ' (Secondary)',
    type = 'text',
    maxItems = 3,
    renderBeforeInput = () => null,
    renderAfterInput = () => null,
    readOnly = false,
}) => {

    return values.map((value, i, readonlyValues) => {

        const required = !!value.required
        const isPrimary = !!value.is_primary
        let displayAddIcon = isPrimary && readonlyValues.length < 3 && !readOnly

        const name = `${namePrefix}[${i}][value]`;
        const errorMessage = getIn(errors, name);

        let display = "block";
        if (readOnly && i > 0) {
            display = "none"
        }

        // for now assume readonly will disable all inputs
        let disabled = !!readOnly

        return (
            <div key={i} style={{ display }}>
                <label htmlFor={`${idPrefix}-${i}-value`}>
                    {label} {isPrimary ? primaryText : secondaryText} &nbsp;
                    {required ? <span className="required">*</span> : null}
                </label>
                {renderBeforeInput({ value: value.value, name, isPrimary })}
                <div style={styles.inputBoxesParent}>
                    <input type="hidden" name={`${namePrefix}[${i}][is_primary]`} value={readonlyValues[i].is_primary}/>
                    <input type={type}
                        className="form-control border-dark" 
                        id={`${idPrefix}-${i}-value`}
                        name={name} 
                        value={readonlyValues[i].value} 
                        onChange={handleChange}
                        maxLength={type === 'tel' ? 10 : undefined}
                        style={styles.input}
                        onKeyPress={e => {
                            if (['tel'].indexOf(type) >= 0) {
                                ensureOnlyNumericKeypressAllowed(e)
                            }
                        }}
                        disabled={disabled}
                        readOnly={readOnly}
                    />
                    {
                        displayAddIcon && (
                            <span style={styles.icon} onClick={e => {
                                const vals = readonlyValues || [];
                                if (vals.length < maxItems) {
                                    const newVals = vals.concat({value: ""})
                                    setFieldValue(namePrefix, newVals)
                                }
                            }}>
                                {/* <span className="icon-decrease-icon" style={{fontSize: 22}}></span> */}
                                <span className="icon-add-icons" style={styles.addIcon}></span>
                            </span>
                        )
                    }
                    {
                        !isPrimary && (
                            <span style={{...styles.icon, top: 9, right: 6}} onClick={e => {
                                const vals = readonlyValues || [];
                                const newVals = vals.filter(p => p !== value);
                                setFieldValue(namePrefix, newVals)
                            }}>

                                {/* <img src={removeIcon} alt="Remove secondary contact"/> */}
                                <span className="icon-decrease-icon" style={styles.removeIcon}></span>
                            </span>
                        )
                    }
                </div>
                {renderAfterInput({ value: value.value, name, isPrimary })}
                {
                    // <ErrorMessage ... /> will not work!
                    errorMessage && renderValidationError(getIn(errors, name))
                }
            </div>
        )
    })
}