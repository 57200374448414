import React from "react"
import { connect } from "react-redux"
import jwt from "jsonwebtoken"
import _ from 'lodash'
import { axios } from '../axios'
import ReactTable from "react-table"
import PreviousPageIcon from "../images/Previous-Page-Icon.svg"
import NextPageIcon from "../images/Next-Page-Icon.svg"
import 'react-table/react-table.css'


/**
 * Renders the notifications page
 * 
 */
class NotificationsPage extends React.Component {

	/** @type {State} */
	state = {
		loadingNotifications: false,
		notifications: [],
		pageSize: 10,
	}

	/**
	 * Fetch notifications when this page has loaded
	 */
	async componentDidMount() {
		const { match } = this.props
		
		this.setState({ loadingNotifications: true})
		try {
			const fetchAllLogsResponse = await axios.get("/ParentOrgController/get_notifications");
			const { data: LogsData } = fetchAllLogsResponse.data;

			this.setState({
				loadingNotifications: false,
				notifications: LogsData,
			})
		} catch (e) {
			this.setState({ 
				loadingNotifications: false 
			})
		}
	}

	/**
	 * Render page title
	 */
	renderEntryHeader() {
		return (
			<div className="entry-header">
				<h2>
					<b>Notifications</b>
				</h2>
			</div>
		)
	}

	/**
	 * Renders table of notifications using <ReactTable /> plugin
	 */
	renderTable() {
		const { loadingNotifications, notifications, pageSize } = this.state

		/** @type {import("react-table").Column<any>[]} */
		const columns = [
			{
				accessor: "created",
				Header: "Date & time",
				headerClassName: "col-sm-3 has-vertical-divider",
				className: "col-sm-3 has-vertical-divider",
			},
			{
				accessor: "title",
				Header: "Log title",
				headerClassName: "col-sm-4 has-vertical-divider",
				className: "col-sm-4 has-vertical-divider",
			},
			{
				accessor: "shortdescription",
				Header: "Description",
				headerClassName: "col-sm-5 has-vertical-divider",
				className: "col-sm-5",
			},
		]

		return (
			<ReactTable
				columns={columns}
				data={notifications}
				minRows={0}
				loading={loadingNotifications}

				className="hcm-list border-0"
				TheadComponent={(props) => <section {...props}/>}
				TrGroupComponent={(props) => <section {...props}/>}
				getTheadTrProps={() => ({ className: 'row row-header w-100' })}
				getTheadProps={() => ({ className: 'bg-dark text-white text-center' })}
				getTrProps={() => ({ className: 'row w-100'})}
				getTdProps={() => ({ className: 'col-12 align-self-center' })}
				getTrGroupProps={() => ({ className: 'text-center'})}

				// pagination
				pageSize={pageSize}
				pageSizeOptions={[10, 20, 50]}
				onPageSizeChange={(pageSize) => this.setState({ pageSize })}
				PreviousComponent={({disabled, onClick}) => (
					<button type="button" className="btn" onClick={onClick} disabled={disabled} >
						<img src={PreviousPageIcon} />
					</button>
				)}
				NextComponent={({disabled, onClick}) => (
					<button type="button" className="btn" onClick={onClick} disabled={disabled} >
						<img src={NextPageIcon} />
					</button>
				)}
				getPaginationProps={() => ({ className: 'd-flex ml-auto mr-auto justify-content-between align-items-center' })}
			/>
		)
	}

	render() {
		return (
			<div className="container-fluid">
				<div className="content-block mt-5">
					{this.renderEntryHeader()}
				</div>
				<div className="content-block mt-4">
					{this.renderTable()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	const {token} = state.auth.user

	let claims = null;
	if (token) {
		claims = jwt.decode(token)
	}

	return {
		organisationName: (claims || {})['name']
	}
}

export default connect(mapStateToProps)(NotificationsPage)