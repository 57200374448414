import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal'
import SimpleReactValidator from 'simple-react-validator';

import { axios } from "../axios";
import RequiredLabel from './RequiredLabel';

import "react-datepicker/dist/react-datepicker.css";

/**
 * Modal component to upload file
 */
class UploadDocumentModalComponent extends Component {
    constructor(props) {
        super(props);

        // Initialize simple-react validator
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: 'text-danger form-error',
        });

        this.state = {
        }
    }

    /**
     * Function to handle form submission
     * @param {Event} event 
     */
    submitForm(event) {
        const uploadDocumentUrl = '/Site/uploadDocument';
        event.preventDefault();

        if (this.validator.allValid()) {
            var formData = new FormData(event.target);

            formData.append('site_id', this.props.siteDetail && this.props.siteDetail.ocs_id);

            axios.post(uploadDocumentUrl, formData).then((response) => {
                this.props.afterUploadingDocument(response);
                this.setState({
                    selected_file: null,
                    selected_file_name: null,
                });
            })
        } else {
            this.validator.showMessages();
        }
    }

    /**
     * Function to handle file inuput
     * @param {Event} event 
     */
    fileChangedHandler(event) {
        this.setState({
            selected_file: event.target.files[0], 
            selected_file_name: event.target.files[0].name
        });
    }

    // on-change handler for text inputs
    handleTextInputChange = (event, property, indirectlyChangedValue) => {
        var nestedProps = property.split('.');

        if(nestedProps.length == 2) {
            var level1Prop = nestedProps[0];
            var level2Prop = nestedProps[1];
            var current = {...this.state[nestedProps[0]]};

            this.setState({
                [level1Prop]: { ...current, [level2Prop]: event.target.value}
            });

        } else if(nestedProps.length == 1) {
            var obj = {
                [property]: indirectlyChangedValue !== undefined ? indirectlyChangedValue : event.target.value
            };
    
            this.setState({
                ...obj
            });
        }
    }

    handleDateChange = (momentDateInstance) => {
        this.setState({
            expiry_date: momentDateInstance
        });
    }

    render() {
        var showMe = this.props.showModal;
        var modalId = this.props.modalId || '';
        
        return (
            <Modal 
                show={showMe}
                aria-labelledby="contained-modal-title"
                size="lg"
                id={`${modalId}`}
                centered>

                <Modal.Header>
                    <div className="d-flex align-items-center">
                        <Modal.Title>Upload New Docs:</Modal.Title>
                        <span className="icon icon-cross-icons" onClick={this.props.closeModal}></span>                        
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <form encType="multipart/form-data" onSubmit={event => { this.submitForm(event) }}>
                        <div className="form-row" >                  
                            <div className="form-group col-md-4">
                                <RequiredLabel>Title: </RequiredLabel>
                                <input className="form-control border-dark" 
                                    name="title"
                                    type="text"
                                    value={this.state.title || ''} 
                                    onChange={event => this.handleTextInputChange(event, 'title')}
                                />
                                {this.validator.message('title', this.state.title, 'max: 264|required')}
                            </div>

                            <div className="form-group col-md-3">
                                <RequiredLabel>Expiry date: </RequiredLabel>
                                <DatePicker
                                    name="expiry-date"
                                    autoComplete="off"
                                    selected={this.state.expiry_date}
                                    className="form-control border-dark"
                                    onChange={this.handleDateChange}>                                    
                                </DatePicker>
                                {this.validator.message('expiry-date', this.state.expiry_date, 'required')}
                            </div>
                        </div>

                        <div className={`form-row ${this.state.filename ? 'mb-n-16' : ''}`}>
                            <div className="form-group col-md-4">
                                <RequiredLabel>Select file: </RequiredLabel>
                                <label className="btn label-on-top upload btn-dark">
                                    <span aria-hidden="true">Upload new doc</span>
                                    <input className="custom-file-input"
                                        name="document"
                                        type="file"
                                        onChange={ event => { this.fileChangedHandler(event) } } />
                                </label>
                                {this.state.selected_file_name ? <span className='file-name'>File Name: <small>{this.state.selected_file_name}</small></span>: ''}
                                {this.validator.message('document', this.state.selected_file, 'required')}
                            </div>
                        </div>

                        <div className="form-row row d-flex justify-content-end">
                            <button className="btn btn-dark" type="submit">Save</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

export default UploadDocumentModalComponent;
