import Axios from "axios"
import { logout } from "./actions/userActions";
import { LOGIN_URL, LOCALSTORAGE_KEY } from "./constants";
import qs from "qs"
import { store } from "./store"
import { history } from "./history";
import { setLoginTime } from "./helpers/common";
import moment from "moment";
import { BASE_URL } from "./config";

// creates instance of axios
export const axios = Axios.create({
    baseURL: BASE_URL
})

// for every 401 responses, log the user out immediately (ie. redirect to login page)
// and display reason (eg 'User session has expired')
axios.interceptors.response.use(res => res, error => {
    if (error.response.status === 401) {
        logout();
        window.location = LOGIN_URL // + "?" + qs.stringify({ error_code: 1 })
        return;
    }
    
    return Promise.reject(error);
})


// redirection issued by server
axios.interceptors.response.use(res => {
    const { _redirect } = res.data

    if (_redirect) {
        history.push(_redirect)
    }

    return res
})

/**
 * @param {import("axios").AxiosResponse<any>} res 
 */
function handleAxiosResponse(res) {
    const { _flash } = res.data;
    if (_flash) {
        Object.keys(_flash).forEach(key => {
            const variant = key;
            const message = _flash[key];
            store.dispatch({ type: "ALERTS_ADD", payload: { message, variant } })
        })

        setTimeout(() => store.dispatch({ type: "ALERTS_DISMISS_ALL" }), 2000)
    }

    return res
}

// flash messages
axios.interceptors.response.use(response => handleAxiosResponse(response), error => {
    handleAxiosResponse(error.response)
    return Promise.reject(error)
})

// attach Authorization on every request
// NOTE: Axios.create({ headers: authHeader() }) will not work
axios.interceptors.request.use(config => {
    let user = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
    if (user && user.token) {
        config.headers['Authorization'] = `Bearer ${user.token}`
    }

    return config;
}, error => Promise.reject(error))


// update login time on every response received (even if receiving error responses)
axios.interceptors.response.use(res => {
    setLoginTime(moment().format());

    return res
}, error => Promise.reject(error))