import React from "react";

import "../stylesheets/components/LoginWelcomeArea.scss";

export default function LoginWelcomeArea({
    description = "Login below using your allocated Username and Password"
}) {

    return (
        <div className="welcome-area text-center">
            <div className="welcome-icon-parent">
                <span className="welcome-icon icon icon-userm1-ie logie_ic text-primary"></span>
            </div>
            <div className="welcome-text">
                <h4 className="welcome-text-header text-primary">Hello, Welcome to the Orgs Portal</h4>
                <p className="welcome-text-description">{description}</p>
            </div>
        </div>
    )
}