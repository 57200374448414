import React from 'react'
import { connect } from 'react-redux'

import { css } from '../helpers/common'

/**
 * Renders 404 page
 */
class NotFoundPage extends React.Component {

    render() {
        const styles = css({
            headingParent: {
                marginTop: 100,
            },
            heading: {
                fontSize: 48,
                fontWeight: 'bold'
            }
        })

        return (
            <div className="container-fluid">
                <div className="content-block">
                    <div style={styles.headingParent}>
                        <h2 className="text-dark" style={styles.heading}>Uh oh.</h2>
                        <h4>The page you requested could not be found</h4>
                    </div>
                </div>
            </div>
        )
    }

}

export default connect()(NotFoundPage)