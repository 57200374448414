import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'

/**
 * @typedef {{} & import('react-router-dom').RouteComponentProps & import('react-redux').DispatchProp} Props
 * @typedef {object} State
 */


/**
 * @extends {React.Component<Props, State>}
 */
class DetailsPage extends React.Component {

    render() {
        const { id } = this.props.match.params

        return (
            <div className="container-fluid">
                <div className="content-block">
                    <h2>This page has yet to be implemented.</h2>
                </div>
            </div>
        )
    }
}

export default DetailsPage;