import React from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route, withRouter } from 'react-router-dom'
import '../stylesheets/components/CustomBreadcrumbs.scss'
import { css } from '../helpers/common'
import _ from 'lodash'

// @todo: This component needs more working

const styles = css({
    root: {
        minHeight: 24,
        marginTop: 24,
        marginBottom: 24,
    }
})


/**
 * Determines breadcrumbs links and renders it to page
 * 
 * @typedef {{} & ReturnType<typeof mapStateToProps> & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 * 
 * @param {Props} props 
 */
const CustomBreadcrumbs = (props) => {
    const { organisationName, orgDetails, currentSite, currentSubOrg, match } = props
    const { loading } = orgDetails
    const { name: suborgName } = currentSubOrg || {}
    const { title: siteName } = currentSite || {}
    const { params, path } = match || {}
    const { id } = params || {}

    return (
        <nav aria-label="breadcrumb" className="site-breadcrumbs" style={styles.root}>
            {
                !loading && (
                    <ol className="breadcrumb">
                        {
                            _.startsWith(path, '/') && (
                                <li className="breadcrumb-item"><Link to="/org-review">{organisationName} (Parent Org)</Link></li>
                            )
                        }
                        {
                            _.startsWith(path, '/sub-orgs/:id') && (
                                <li className="breadcrumb-item"><Link to={id ? `/sub-orgs/${id}` : `/`}>{suborgName} (Sub org)</Link></li>
                            )
                        }
                        {
                            _.startsWith(path, '/sites/:id') && (
                                <li className="breadcrumb-item"><Link to={id ? `/sites/${id}/detail` : `/`}>{siteName} (Site)</Link></li>
                            )
                        }
                    </ol>
                )
            }
        </nav>
    )
}

/**
 * Determine entity type from routes
 * 
 * @param {import('react-router-dom').match<{id?: number}>} param
 * @return {EntityTypes}
 */
function determineEntityType({ path }) {
    if (_.startsWith(path, '/sub-orgs')) {
        return 'SUBORG'
    } else if (_.startsWith(path, '/site') || _.startsWith(path, '/sites')) {
        return 'SITE'
    } else {
        return 'ORG'
    }
}


const mapStateToProps = (state, ownProps) => {
    const { value: orgDetail } = state.orgDetails
    const { match } = ownProps
    const { params } = match

    const entityType = determineEntityType(match)

    return {
        entityType,
        orgDetails: state.orgDetails,
        currentSubOrg: entityType === 'SUBORG' ? (state.suborgs.items || []).find(s => s.id == params.id) : null,
        currentSite: entityType === 'SITE' ? (state.sites.items || []).find(s => s.site_id == params.id) : null,
        organisationName: ((orgDetail || {}).basic_detail || {}).name || ''
    }
}


export default withRouter(connect(mapStateToProps)(CustomBreadcrumbs))