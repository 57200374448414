import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import DefaultSiteLogo from '../../images/Org-icon-trimmed.png'

import '../../stylesheets/components/SiteInfoSummary.scss'

/**
 * @typedef {{} & ReturnType<typeof mapStateToProps>} SiteInfoSummaryProps
 * @typedef {SiteInfoSummaryProps & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 */

/**
 * Component to render summary information of a site
 * @extends {React.Component<Props, {}>}
 */
class SiteInfoSummary extends React.Component {

    determineKeyContact(currentSite = {}) {
        const { contacts } = currentSite
        const key_contact = (contacts || []).find(c => (c.contact_type || {}).value === '3') || {}
        const { firstname, lastname } = key_contact
        
        return [firstname, lastname].filter(f => f).join(' ')
    }

    determineAddress(currentSite = {}) {
        const { address } = currentSite || {}
        return address
    }

    determineIconSrc(currentSite = {}) {
        return DefaultSiteLogo
    }

    determineStatus(currentSite = {}) {
        const { status } = currentSite
        if (status === "0") {
            return "Inactive"
        } else if (status === "1") {
            return "Active"
        }

        return ""
    }


    render() {
        const { currentSite, orgDetails } = this.props
        const { ocs_id } = currentSite || {}

        const logosrc = this.determineIconSrc(currentSite)
        const address = this.determineAddress(currentSite)
        const keyContact = this.determineKeyContact(currentSite)
        const currentStatus = this.determineStatus(currentSite)

        return (
            <div id="SiteInfoSummary" className="d-flex">
                <div className="site-logo">
                    <img src={logosrc} alt="Logo" width="100" height="100" />
                </div>
                <div className="site-info">
                    <div>
                        <b>OCS ID: </b> {ocs_id}
                    </div>
                    <div>
                        <b>Address: </b> {address}
                    </div>
                    <div>
                        <b>Key contact: </b> {keyContact}
                    </div>
                    <div>
                        {/* @todo: How can you determine status of a site? */}
                        <b>Status: </b> {currentStatus}
                    </div>
                </div>
            </div>
        )
    }

}

/**
 * 
 * @param {import('../../reducers').RootState} state 
 * @param {import('react-router-dom').RouteComponentProps} ownProps 
 */
const mapStateToProps = (state, ownProps) => {
    const { sites, entityTypes, orgDetails } = state
    const { items } = sites || {}
    const { params } = ownProps.match
    const { type } = entityTypes
    const { id } = params || {}
    
    const currentSite = type === 'SITE' ? (items || []).find(s => s.ocs_id == id) : undefined

    return {
        currentSite,
        orgDetails,
    }
}


export default withRouter(connect(mapStateToProps)(SiteInfoSummary));