import { axios } from "../axios"
import { store } from "../store"
import objectToFormData from "object-to-formdata"

/**
 * @template T, S, P
 * @typedef {{types: [T, T, T], shouldCallAPI: (state: S) => boolean, callAPI: () => Promise, payload: P}} CallAPIAction
 */

// Based on https://redux.js.org/recipes/reducing-boilerplate/

/**
 * @returns {CallAPIAction<import("../reducers/australianStates").ActionTypes, {australianStates: import('../reducers/australianStates').State}, any>}
 */
export function fetchAustralianStates() {
    return {
        types: ['AUSTRALIAN_STATES_LOADING', 'AUSTRALIAN_STATES_SUCCESS', 'AUSTRALIAN_STATES_FAILED'],
        shouldCallAPI: ({ australianStates: s }) => !s.fetched_at || s.fetched_at + (1000 * 60 * 60 * 24) < Date.now(),
        callAPI: () => axios.post(`/CommonController/getStateList`),
        payload: {}
    }
}


/**
 * @returns {CallAPIAction<import("../reducers/orgDetails").ActionTypes, {orgDetails: import('../reducers/orgDetails').State}, any>}
 */
export function fetchOrgDetails() {
    return {
        types: ['FETCH_ORG_DETAILS_LOADING', 'FETCH_ORG_DETAILS_SUCCESS', 'FETCH_ORG_DETAILS_FAILED'],
        shouldCallAPI: ({ orgDetails: o }) => true, // !o.fetched_at || o.fetched_at + (1000 * 60 * 60) < Date.now(),
        callAPI: () => axios.post(`/ParentOrgController/getOrgDetails`),
        payload: {}
    }
}

/**
 * @returns {CallAPIAction<import("../reducers/suborgs").ActionTypes, {suborgs: import('../reducers/suborgs').State}, any>}
 */
export function fetchSubOrgs() {
    return {
        types: ['FETCH_SUBORGS_LOADING', 'FETCH_SUBORGS_SUCCESS', 'FETCH_SUBORGS_FAILED'],
        shouldCallAPI: ({ suborgs }) => suborgs.fetched_at || (suborgs.fetched_at + (1000 * 60 * 60)) < Date.now(),
        callAPI: () => axios.get('/suborgs').then(response => {
            store.dispatch({ type: "ADD_SUBORGS_TO_SIDEBAR_LINKS", response })
            return response
        }),
        payload: {},
    }
}


/**
 * @returns {CallAPIAction<import("../reducers/shiftTimeRequirements").ActionTypes, {shiftTimeRequirements: import('../reducers/shiftTimeRequirements').State}, any>}
 */
export function fetchShiftTimeRequirements() {
    return {
        types: ['FETCH_SHIFT_TIME_REQUIREMENTS_LOADING', 'FETCH_SHIFT_TIME_REQUIREMENTS_SUCCESS', 'FETCH_SHIFT_TIME_REQUIREMENTS_FAILED'],
        shouldCallAPI: ({ shiftTimeRequirements }) => shiftTimeRequirements.fetched_at || (shiftTimeRequirements.fetched_at + (1000 * 60 * 60)) < Date.now(),
        callAPI: () => axios.get('/shifts/time_requirements'),
        payload: {}
    }
}

/**
 * @returns {CallAPIAction<import("../reducers/shiftRequirements").ActionTypes, {shiftRequirements: import('../reducers/shiftRequirements').State}, any>}
 */
export function fetchShiftRequirements() {
    return {
        types: ['FETCH_SHIFT_REQUIREMENTS_LOADING', 'FETCH_SHIFT_REQUIREMENTS_SUCCESS', 'FETCH_SHIFT_REQUIREMENTS_FAILED'],
        shouldCallAPI: ({ shiftRequirements }) => shiftRequirements.fetched_at || (shiftRequirements.fetched_at + (1000 * 60 * 60)) < Date.now(),
        callAPI: () => axios.get('/shifts/requirements'),
        payload: {}
    }
}

/**
 * @returns {CallAPIAction<import("../reducers/organisationRequirements").ActionTypes, {organisationRequirements: import('../reducers/organisationRequirements').State}, any>}
 */
export function fetchOrganisationRequirements() {
    return {
        types: ['FETCH_ORGANISATION_REQUIREMENTS_LOADING', 'FETCH_ORGANISATION_REQUIREMENTS_SUCCESS', 'FETCH_ORGANISATION_REQUIREMENTS_FAILED'],
        shouldCallAPI: ({ organisationRequirements }) => organisationRequirements.fetched_at || (organisationRequirements.fetched_at + (1000 * 60 * 60)) < Date.now(),
        callAPI: () => axios.get('/ParentOrgController/requirements'),
        payload: {}
    }
}
/**
 * @returns {CallAPIAction<import("../reducers/sites").ActionTypes, {sites: import('../reducers/sites').State}, any>}
 */
export function fetchSites() {
    return {
        types: ['FETCH_SITES_LOADING', 'FETCH_SITES_SUCCESS', 'FETCH_SITES_FAILED'],
        shouldCallAPI: ({ sites }) => sites.fetched_at || (sites.fetched_at + (1000 * 60 * 60)) < Date.now(),
        callAPI: () => axios.get('/site/get_sites').then(response => {
            store.dispatch({ type: "ADD_SITES_TO_SIDEBAR_LINKS", response })
            return response
        }),
        payload: {},
    }
}

/**
 * @param {string} page_url
 * @returns {CallAPIAction<import("../reducers/infographics").ActionTypes, {infographics: import('../reducers/infographics').State}, any>}
 */
export function fetchInfographics(page_url) {
    return {
        types: ['FETCH_INFOGRAPHICS_LOADING', 'FETCH_INFOGRAPHICS_SUCCESS', 'FETCH_INFOGRAPHICS_FAILED'],
        shouldCallAPI: ({ infographics }) => {
            const { data } = infographics
            if (page_url in data && Array.isArray(data[page_url].items) && data[page_url].items.length > 0) {
                return false;
            }

            return true
        },
        callAPI: () => axios.post('/CommonController/get_infographics', objectToFormData({ module: 'org portal', page_url })),
        payload: { page_url },
    }
}