import React from "react"
import { connect } from "react-redux"
import { Alert } from "react-bootstrap"

/** @type {Record<'root' | 'alert', React.CSSProperties>} */
const styles = {
    root: {
        position: "fixed",
        transform: "translateX(-50%)",
        top: 60,
        left: "50%",
        textAlign: "center",
        height: 0,
    },
    alert: {
        maxWidth: 576,
        minWidth: 320,
        width: "auto",
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    }
}


const Alerts = props => {
    if (!props.alerts || props.alerts.length === 0) {
        return null
    }

    return (
        <div style={styles.root}>
            {
                props.alerts.map((alert, i) => (
                    <Alert key={i} variant={alert.variant} style={styles.alert} onClose={() => props.dispatch({ type: "ALERTS_DISMISS", payload: alert })} dismissible>
                        <div className="text-center w-100">{alert.message}</div>
                    </Alert>
                ))
            }
        </div>
    )
}


const mapStateToProps = state => {
    return {
        alerts: state.alerts.items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Alerts)