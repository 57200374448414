import React from 'react';
import '../../src/stylesheets/components/ActionButtonComponent.scss';

/**
 * A generic button component
 */
class ActionButtonComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <button className="d-flex align-items-center action-btn" 
                disabled={this.props.isDisabled}
                onClick={this.props.clickHandler}
                style={this.props.styles}>
                <span className="text text-center" style={{width: 90 + '%'}}>{this.props.text}</span>
                {this.props.buttonIcon && <img className="icon icon-add" src={this.props.buttonIcon}/>}
            </button>
        )
    }
}

export default ActionButtonComponent;
