import React from 'react'
import { connect } from 'react-redux'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import jwt from "jsonwebtoken"
import _ from "lodash"
import { Formik, ErrorMessage, Field, Form } from 'formik'
import GooglePlacesAutocomplete from 'react-google-autocomplete'
import objectToFormData from 'object-to-formdata'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import qs from 'qs'

import OrgInfoSummary from '../../components/OrgInfoSummary'
import { CustomSelect, CustomAsyncSelect } from "../../components/CustomSelect"
import { findAddressComponent } from '../../helpers/google-places'
import participants from '../../services/participants'
import common from '../../services/common'
import { axios } from "../../axios"
import CustomCheckbox from '../../components/CustomCheckbox';
import CustomRadioButton from '../../components/CustomRadioButton';
import { subOrgsService } from '../../services/subOrgsService'
import RequiredLabel from '../../components/RequiredLabel';

import "react-datepicker/dist/react-datepicker.css";
import "../../stylesheets/pages/shifts/CreatePage.scss"
import { fetchOrganisationRequirements, fetchShiftRequirements, fetchShiftTimeRequirements } from '../../actions/commonActions'
import BlockUi from 'react-block-ui'
import { css, ensureOnlyNumericKeypressAllowed } from '../../helpers/common'
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs'
import SiteInfoSummary from '../../components/site/SiteInfoSummary'


/**
 * @typedef {'ORG' | 'SUBORG' | 'SITE'} EntityTypes
 * @typedef {ReturnType<typeof mapStateToProps> & {}} IndexPageProps
 * @typedef {IndexPageProps & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 * @typedef {{}} State
 */

/**
 * Page component to create shifts. Can create shifts for org, suborgs or sites
 * 
 * @extends {React.Component<Props, State>}
 */
class CreatePage extends React.Component {

    state = {
        org_requirements: [],
        shifttime_requirements: [],
        shift_requirements: [],

        keyContacts: [],
        keyContactsLoading: false,

        billingContacts: [],
        billingContactsLoading: false,

    }

    /**
     * Fetch org, shift and shift time req on mount.
     * Also fetch booker contact details - key contact and billing contact
     */
    async componentDidMount() {
        const { dispatch, entityType } = this.props
        dispatch(fetchOrganisationRequirements());
        dispatch(fetchShiftRequirements());
        dispatch(fetchShiftTimeRequirements());

        if (entityType === 'ORG') {
            await this.fetchBookerForParentOrg()
        } else if (entityType === 'SUBORG') {
            await this.fetchBookerForSuborg()
        } else if (entityType === 'SITE') {
            await this.fetchBookerForSite()
        }
    }

    /**
     * Fetch key and billing contact for suborg
     * For each contact, firstname, lastname, phone, email and id will be mapped
     */
    async fetchBookerForSuborg() {
        const { params } = this.props.match
        const { id } = params

        // dont' use Promise.all([axios1, axios2]) 
        // because if at least one of them fails, 
        // you will never able to autofill contact details
        // We have to fetch them separately, unfortunately

        this.setState({keyContactsLoading: true})
        try {
            const res1 = await axios.get(`/suborgs/key_contacts/${id}`)
            this.setState({
                keyContactsLoading: false,
                keyContacts: (res1.data['data'] || []).map(c => ({
                    id: c.id,
                    firstname: c.name,
                    lastname: c.lastname,
                    phone: _.get(c, 'contact_phones.0.phone', null),
                    email: _.get(c, 'contact_emails.0.email', null),
                })),
            })

        } catch (e) {
            this.setState({keyContactsLoading: false})
        }


        this.setState({billingContactsLoading: true})

        try {
            const res2 = await axios.get(`/suborgs/key_billing_contacts/${id}`)
            this.setState({
                billingContactsLoading: false,
                billingContacts: (res2.data['data'] || []).map(c => ({
                    id: c.id,
                    firstname: c.name,
                    lastname: c.lastname,
                    phone: _.get(c, 'contact_phones.0.phone', null),
                    email: _.get(c, 'contact_emails.0.email', null),
                })),
            })

        } catch (e) {
            this.setState({ billingContactsLoading: false })
        }
    }

    /**
     * Fetch key and billing contact for parent org
     * For each contact, firstname, lastname, phone, email and id will be mapped
     */
    async fetchBookerForParentOrg() {
        this.setState({keyContactsLoading: true})

        // dont' use Promise.all([axios1, axios2]) 
        // because if at least one of them fails, 
        // you will never able to autofill contact details
        // We have to fetch them separately, unfortunately

        try {
            const res1 = await axios.get(`/ParentOrgController/key_contacts`)

            this.setState({
                keyContactsLoading: false,
                keyContacts: (res1.data['data'] || []).map(c => ({
                    id: c.id,
                    firstname: c.name,
                    lastname: c.lastname,
                    phone: _.get(c, 'contact_phones.0.phone', null),
                    email: _.get(c, 'contact_emails.0.email', null),
                })),
            })

        } catch (e) {
            this.setState({keyContactsLoading: false})
        }

        this.setState({ billingContactsLoading: true })

        try {
            const res2 = await axios.get(`/ParentOrgController/key_billing_contacts`)
            this.setState({
                billingContactsLoading: false,
                billingContacts: (res2.data['data'] || []).map(c => ({
                    id: c.id,
                    firstname: c.name,
                    lastname: c.lastname,
                    phone: _.get(c, 'contact_phones.0.phone', null),
                    email: _.get(c, 'contact_emails.0.email', null),
                })),
            })

        } catch (e) {
            this.setState({ billingContactsLoading: false })
        }
    }


    /**
     * Fetch key and billing contact for site
     * For each contact, firstname, lastname, phone, email and id will be mapped
     */
    async fetchBookerForSite() {
        const { params } = this.props.match
        const { id } = params

        // dont' use Promise.all([axios1, axios2]) 
        // because if at least one of them fails, 
        // you will never able to autofill contact details
        // We have to fetch them separately, unfortunately

        this.setState({keyContactsLoading: true })
        try {
            const res1 = await axios.get(`/site/key_contacts/${id}`)
            this.setState({
                keyContactsLoading: false,
                keyContacts: (res1.data['data'] || []).map(c => ({
                    id: c.id,
                    firstname: c.firstname,
                    lastname: c.lastname,
                    phone: _.get(c, 'contact_phones.0.phone', null),
                    email: _.get(c, 'contact_emails.0.email', null),
                })),
            })

        } catch (e) {
            this.setState({ keyContactsLoading: false, })
        }

        this.setState({billingContactsLoading: true })
        try {
            const res2 = await axios.get(`/site/key_billing_contacts/${id}`)
            this.setState({
                billingContactsLoading: false,
                billingContacts: (res2.data['data'] || []).map(c => ({
                    id: c.id,
                    firstname: c.firstname,
                    lastname: c.lastname,
                    phone: _.get(c, 'contact_phones.0.phone', null),
                    email: _.get(c, 'contact_emails.0.email', null),
                })),
            })

        } catch (e) {
            this.setState({ billingContactsLoading: false })
        }
    }




    /**
     * Submit handler
     * 
     * @param {any} values
     * @param {import('formik').FormikHelpers<any>} formikHelpers
     */
    handleSubmit = async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true)

        // use number for memberId and bookerId because they are currently of type object ({label: string, value: number})
        // instead of number. They are initially type of object because of <CustomAsyncSelect />.
        // Server expects these values to be numbers
        const memberId = _.get(values, 'Shift.preferred_member.memberId.value', null);

        // little trick to modify values in a nested obj without mutating original obj. Don't just use _.set
        // If you mutate original, in this case memberId, the selection will be blank upon submission
        // @see https://github.com/lodash/lodash/issues/1696
        let newValues = _.setWith(_.clone(values), 'Shift.preferred_member.memberId', memberId, _.clone); 

        try {
            await axios.post(`/shifts/create`, objectToFormData({ reqData: JSON.stringify(newValues) }))
        } catch (e) {
            const {errors} = e.response.data;
            for (let field in errors) {
                formikHelpers.setFieldError(field, errors[field])
            }
        } finally {
            formikHelpers.setSubmitting(false)
        }
    }

    /**
     * Prevents non-numeric characters to be entered.
     * 
     * @param {React.KeyboardEvent} e
     */
    handleOnKeyPressTelInput = e => {
        if (e.which < 48 || e.which > 57) {
            e.preventDefault()
        }
    }

    /**
     * Displays customized validation error message
     * 
     * @param {string} errorMessage
     */
    renderValidationError = errorMessage => {
        return (
            <span className="text-danger">
                <small>{errorMessage}</small>
            </span>
        )
    }

    /**
     * Render breadcrumbs
     */
    renderBreadcrumbs() {
        return <CustomBreadcrumbs />
    }

    /**
     * Render org details
     */
    renderOrgSummary() {
        return (
            <div className="site-information">
                <OrgInfoSummary />
            </div>
        )
    }

    /**
     * Render site details
     */
    renderSiteSummary() {
        return (
            <div className="site-information">
                <SiteInfoSummary />
            </div>
        )
    }


    /**
     * Render page title
     */
    renderEntryHeader() {
        return (
            <div className="entry-header">
                <h2 style={{ margin: 0 }}>
                    <b>Create new Shift</b>
                </h2>
            </div>
        )
    }


    /**
     * Returns the text that displays on top of the 
     * form saying 'Creating shift for...'
     * 
     * @param {EntityTypes} entityType 
     */
    creatingShiftFor(entityType) {
        if (entityType === 'ORG') {
            const { value, loading } = this.props.orgDetails

            if (loading) {
                return `Creating shift for: ...`
            }

            const { basic_detail } = value || {}
            const { name } = basic_detail || {}
            return (
                <>Creating shift for: <b>{name} (Parent org)</b></>
            )
        }

        if (entityType === 'SUBORG') {
            const { loading } = this.props.suborgs
            if (loading) {
                return `Creating shift for: ...`
            }

            const { name, id } = this.props.currentSubOrg || {}
            return <>Creating shift for: <b>{name} (Sub-org)</b></>
        }

        if (entityType === 'SITE') {
            const { loading } = this.props.sites
            if (loading) {
                return `Creating shift for: ...`
            }

            const { title } = this.props.currentSite || {}
            return <>Creating shift for: <b>{title} (Site)</b></>
        }


        return `Creating shift for: ...`
    }


    /**
     * Options for site booker
     */
    siteBookerOptions() {
        const { keyContacts, billingContacts } = this.state

        return [
            { 
                value: 1, 
                label: 'Key contact', 
                title: `Automatically fills key contact details of the site/suborg/parent org`,
                contact: _.first(keyContacts || [])
            },
            { 
                value: 2, 
                label: 'Billing contact', 
                title: `Automatically fills billing contact details of the site/suborg/parent org`,
                contact: _.first(billingContacts || [])
            },
        ]
    }


    /**
     * Options for "Confirm with" dropdown
     */
    confirmWithOptions() {
        const { keyContacts, billingContacts } = this.state

        return [
            { 
                value: 3, 
                label: 'Key contact', 
                title: `Automatically fills key contact details of the site/suborg/parent org`,
                contact: _.first(keyContacts || [])
            },
            { 
                value: 4, 
                label: 'Billing contact', 
                title: `Automatically fills billing contact details of the site/suborg/parent org`,
                contact: _.first(billingContacts || [])
            },
        ]
    }


    /**
     * Will determine the value of `values.Shift.booked_by` inside Formik form
     */
    determineBookedBy() {
        const { entityType } = this.props

        const entityTypeToBookingBy = {
            ORG: 4,
            SUBORG: 5,
            SITE: 1
        }

        return entityTypeToBookingBy[entityType] || null
    }

    /**
     * Renders formik controller form
     */
    renderForm() {
        const { organisationRequirements, shiftRequirements, shiftTimeRequirements, match, entityType } = this.props
        const { billingContacts, billingContactsLoading, keyContacts, keyContactsLoading } = this.state
        const { params } = match || {}
        const { id } = params || {}

        const booked_by = this.determineBookedBy()

        if (!this.state.org_requirements)
            return;

        return (
            <Formik
                initialValues={{
                    Shift: {
                        booked_by: booked_by,
                        org_or_site_id: id,
                        status: 1,

                        start_time: '', //moment().format('DD-MM-YYYY hh:mm a'),// @todo - Now
                        end_time: '', // moment().add('7', 'hours').format('DD-MM-YYYY hh:mm a'),

                        caller: {
                            firstname: '',
                            lastname: '',
                            email: '',
                            phone: '',
                            booker_id: '',
                            booking_method: '',
                            _site_booker: '',
                        },

                        locations: [
                            {
                                address: '',
                                state: '',
                                suburb: '',
                                postal: ''
                            }
                        ],

                        confirmation: {
                            confirm_with: '',
                            confirm_by: '',
                            firstname: '',
                            lastname: '',
                            email: '',
                            phone: '',
                            _contact_id: null,
                        },

                        preferred_member: {
                            memberId: null, // typeof { value: number, label: string }, @todo: Convert from obj to number before submitting to backend
                            allocate: false,
                            autofill_shift: false,
                        },

                        shift_notes: {
                            notes: ''
                        },
                    },
                    OrgRequirements: {
                        // 1: true,
                        // 2: false,
                        // 5: true,
                        // 6: true,
                        // 7: false,
                        // [id: number]: boolean
                    },
                    ShiftTimeRequirements: {
                        // [id: number]: boolean
                    },
                    ShiftRequirements: {
                        // [id: number]: boolean
                    },
                }}
                onSubmit={this.handleSubmit}
            >
                {({ values, errors, setFieldValue, handleChange, isSubmitting }) => {
                    return (
                        <Form id="CreateShiftForm" method="POST" noValidate autoComplete="off">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <label>{this.creatingShiftFor(entityType)}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row mt-5 mx-0">
                                    <h3 className="color">Booking Details</h3>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-sm-5">
                                        <label htmlFor="Shift-caller-booker_id">Site booker</label>
                                        <CustomSelect 
                                            variant="dark"
                                            placeholder={(keyContactsLoading || billingContactsLoading) ? `Loading...` : `Select...`}
                                            onChange={newValue => {
                                                const { contact, value } = newValue || {}
                                                setFieldValue("Shift[caller][_site_booker]", value)
                                                
                                                if (!newValue) {
                                                    return;
                                                }
                                                
                                                const { firstname, lastname, email, phone, id } = contact || {}
                                                setFieldValue("Shift[caller][booker_id]", id)
                                                setFieldValue("Shift[caller][firstname]", firstname || '')
                                                setFieldValue("Shift[caller][lastname]", lastname || '')
                                                setFieldValue("Shift[caller][email]", email || '')
                                                setFieldValue("Shift[caller][phone]", phone || '')                                                
                                            }}
                                            name="Shift[caller][booker_id]"
                                            id="Shift-caller-booker_id"
                                            clearable={false}
                                            value={values.Shift.caller._site_booker}
                                            className="form-control"
                                            searchable={false}
                                            disabled={keyContactsLoading || billingContactsLoading}
                                            options={this.siteBookerOptions()}
                                        />
                                        <ErrorMessage name="Shift[caller][booker_id]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <RequiredLabel htmlFor="Shift-caller-booking_method-">Booking method</RequiredLabel>
                                        <CustomSelect
                                            inputProps={{
                                                autoComplete: "off"
                                            }}
                                            variant="dark"
                                            name="Shift[caller][booking_method]"
                                            id="Shift-caller-booking_method"
                                            value={values.Shift.caller.booking_method}
                                            clearable={false}
                                            searchable={false}
                                            className="form-control"
                                            options={[
                                                { value: null, label: 'Select...', selected: true, disabled: true },
                                                { value: 1, label: 'Phone' },
                                                { value: 2, label: 'Direct Email' },
                                                // { value: 3, label: 'SMS' },
                                                // { value: 4, label: 'Imail' }
                                            ]}
                                            simpleValue
                                            onChange={v => setFieldValue("Shift[caller][booking_method]", v)}
                                        />
                                        <ErrorMessage name="Shift[caller][booking_method]" render={this.renderValidationError} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-caller-firstname">Caller First name</RequiredLabel>
                                        <Field type="text" className="form-control border-dark" name="Shift[caller][firstname]" id="Shift-caller-firstname" disabled={values.Shift.caller.booker_id}/>
                                        <ErrorMessage name="Shift[caller][firstname]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-caller-lastname">Caller last name</RequiredLabel>
                                        <Field type="text" className="form-control border-dark" name="Shift[caller][lastname]" id="Shift-caller-lastname" disabled={values.Shift.caller.booker_id}/>
                                        <ErrorMessage name="Shift[caller][lastname]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-caller-email">Caller email</RequiredLabel>
                                        <Field type="email" className="form-control border-dark" name="Shift[caller][email]" id="Shift-caller-email" disabled={values.Shift.caller.booker_id }/>
                                        <ErrorMessage name="Shift[caller][email]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-caller-phone">Caller phone</RequiredLabel>
                                        <Field type="tel" className="form-control border-dark" name="Shift[caller][phone]" id="Shift-caller-phone" disabled={values.Shift.caller.booker_id} maxlength="10" onKeyPress={ensureOnlyNumericKeypressAllowed}/>
                                        <ErrorMessage name="Shift[caller][phone]" render={this.renderValidationError} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row mt-5 mx-0">
                                    <h3 className="color">Shift Details</h3>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-start_time">Shift start date &amp; time</RequiredLabel><br />
                                        <DatePicker
                                            autoComplete={'off'} 
                                            // placeholderText={"DD-MM-YYYY hh:mm a"}
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeCaption="time"
                                            name="Shift[start_time]"
                                            id="Shift-start_time"
                                            className="form-control border-dark"
                                            selected={values.Shift.start_time ? moment(values.Shift.start_time, 'DD-MM-YYYY hh:mm a') : null} 
                                            dateFormat="DD-MM-YYYY hh:mm a" 
                                            onChange={/** @param {moment.Moment} momentInstance */ momentInstance => {
                                                let start_time = momentInstance ? momentInstance.format("DD-MM-YYYY hh:mm a") : null
                                                setFieldValue("Shift[start_time]", start_time)
                                            }}
                                        />
                                        <ErrorMessage name="Shift[start_time]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-end_time">Shift end date &amp; time</RequiredLabel><br />
                                        <DatePicker
                                            autoComplete={'off'} 
                                            // placeholderText={"DD-MM-YYYY hh:mm a"} 
                                            showTimeSelect
                                            name="Shift[end_time]"
                                            id="Shift-end_time"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            className="form-control border-dark"
                                            selected={values.Shift.end_time ? moment(values.Shift.end_time, 'DD-MM-YYYY hh:mm a') : null} 
                                            dateFormat="DD-MM-YYYY hh:mm a" 
                                            onChange={/** @param {moment.Moment} momentInstance */ momentInstance => {
                                                let end_time = momentInstance ? momentInstance.format("DD-MM-YYYY hh:mm a") : null
                                                setFieldValue("Shift[end_time]", end_time)
                                            }}
                                        />
                                        <ErrorMessage name="Shift[end_time]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label htmlFor={`ShiftTimeRequirements`}>Shift requirements</label>
                                        <div className="d-flex flex-wrap bg-white border border-dark rounded-sm" style={{ padding: "15px 0" }}>
                                            {
                                                shiftTimeRequirements.loading ?
                                                (
                                                    <div className="col-12">
                                                        <p>Loading requirements...</p>
                                                    </div>
                                                )
                                                :
                                                (
                                                    shiftTimeRequirements.items.map(({ value: id, label }, i) => (
                                                        <div key={i} className="col-12 col-md-4">
                                                            <CustomCheckbox
                                                                name={`ShiftTimeRequirements[${id}]`}
                                                                id={`ShiftTimeRequirements-${id}`}
                                                                value={values.ShiftTimeRequirements[id]}
                                                                onChange={e => setFieldValue(`ShiftTimeRequirements['${id}']`, !!e.target.checked)}
                                                                label={label}
                                                            />
                                                        </div>
                                                    ))
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    values.Shift.locations.map((location, i) => {
                                        return (
                                            <div className="row mt-3" key={i}>
                                                <div className="col-12 col-md-4">
                                                    <RequiredLabel htmlFor={`Shift-locations-${i}-address`}>Shift location</RequiredLabel>
                                                    <GooglePlacesAutocomplete
                                                        name={`Shift[locations][${i}][address]`}
                                                        id={`Shift-locations-${i}-address`}
                                                        className="form-control border-dark"
                                                        value={location.address}
                                                        onPlaceSelected={/** @param {google.maps.places.PlaceResult} place */ (place) => {
                                                            const address = {
                                                                state: findAddressComponent(place, "administrative_area_level_1"),
                                                                suburb: findAddressComponent(place, "administrative_area_level_2"),
                                                                postcode: findAddressComponent(place, "postal_code"),
                                                                city: findAddressComponent(place, "locality"),
                                                                street_number: findAddressComponent(place, "street_number"),
                                                                route: findAddressComponent(place, "route", true),
                                                                address: document.getElementById(`Shift-locations-${i}-address`).value
                                                            }

                                                            const state = (address.state || "").toLowerCase();
                                                            const foundState = (this.props.australianStates.items || []).find(s => s.label.toLowerCase() == state)

                                                            setFieldValue(`Shift[locations][${i}][state]`, (foundState || {}).value)
                                                            setFieldValue(`Shift[locations][${i}][address]`, [address.street_number, address.route].join(" "))
                                                            setFieldValue(`Shift[locations][${i}][postal]`, address.postcode)
                                                            setFieldValue(`Shift[locations][${i}][suburb]`, address.suburb)
                                                            setFieldValue(`Shift[locations][${i}][city]`, address.city)
                                                        }}
                                                        types={['address']}
                                                        componentRestrictions={{ country: "au" }}
                                                        onChange={handleChange}
                                                    />
                                                    <ErrorMessage name={`Shift[locations][${i}][address]`} render={this.renderValidationError} />
                                                </div>
                                                <div className="col-12 col-md-2">
                                                    <RequiredLabel htmlFor={`Shift-locations-${i}-state`}>State</RequiredLabel>
                                                    <CustomSelect
                                                        variant="dark"
                                                        name={`Shift[locations][${i}][state]`}
                                                        id={`Shift-locations-${i}-state`}
                                                        className="form-control"
                                                        value={location.state}
                                                        options={this.props.australianStates.items}
                                                        clearable={false}
                                                        searchable={false}
                                                        onChange={(newValue) => newValue && setFieldValue(`Shift[locations][${i}][state]`, newValue.value)}
                                                    />
                                                    <ErrorMessage name={`Shift[locations][${i}][state]`} render={this.renderValidationError} />
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <RequiredLabel htmlFor={`Shift-locations-${i}-suburb`}>Suburb</RequiredLabel>
                                                    <Field type="text" className="form-control border-dark" name={`Shift[locations][${i}][suburb]`} id={`Shift-locations-${i}-suburb`} />
                                                    <ErrorMessage name={`Shift[locations][${i}][suburb]`} render={this.renderValidationError} />
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <RequiredLabel htmlFor={`Shift-locations-${i}-postal`}>Postcode</RequiredLabel>
                                                    {/* postal field has to be type=text, not type=number so that it wont strip out preceeding zeroes when submitting */}
                                                    <Field type="text" className="form-control border-dark" name={`Shift[locations][${i}][postal]`} id={`Shift-locations-${i}-postal`} />
                                                    <ErrorMessage name={`Shift[locations][${i}][postal]`} render={this.renderValidationError} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                        <label htmlFor={`ShiftRequirements`}>Shift requirements</label>
                                        <div className="d-flex flex-wrap bg-white border border-dark rounded-sm" style={{ padding: "15px 0" }}>
                                            {
                                                shiftRequirements.loading ?
                                                (
                                                    <div className="col-12">
                                                        <p>Loading requirements...</p>
                                                    </div>
                                                )
                                                :
                                                (
                                                    shiftRequirements.items.map(({ value: id, label}, i) => (
                                                        <div key={i} className="col-12 col-md-6">
                                                            <CustomCheckbox
                                                                name={`ShiftRequirements[${id}]`}
                                                                id={`ShiftRequirements-${id}`}
                                                                value={values.ShiftRequirements[id]}
                                                                checked={!!values.ShiftRequirements[id]}
                                                                onChange={e => setFieldValue(`ShiftRequirements['${id}']`, !!e.target.checked)}
                                                                label={label}
                                                            />
                                                        </div>
                                                    ))
                                                )
                                            }
                                        </div>
                                        <ErrorMessage name={`ShiftRequirements`} render={this.renderValidationError} />
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <label htmlFor={`OrgRequirements`}>Organisation requirements</label>
                                        <div className="d-flex flex-wrap bg-white border border-dark rounded-sm" style={{ padding: "15px 0" }}>
                                            {
                                                organisationRequirements.loading ? 
                                                (
                                                    <div className="col-12">
                                                        <p>Loading requirements...</p>
                                                    </div>
                                                )
                                                :
                                                (
                                                    organisationRequirements.items.map(({ value: id, label }, i) => (
                                                        <div key={i} className="col-12 col-md-6">
                                                            <CustomCheckbox
                                                                name={`OrgRequirements[${id}]`}
                                                                id={`OrgRequirements-${id}`}
                                                                value={values.OrgRequirements[id]}
                                                                checked={!!values.OrgRequirements[id]}
                                                                onChange={e => setFieldValue(`OrgRequirements[${id}]`, !!e.target.checked)}
                                                                label={label}
                                                            />
                                                        </div>
                                                    ))
                                                )
                                            }
                                        </div>
                                        <ErrorMessage name={`OrgRequirements`} render={this.renderValidationError} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row mt-5 mx-0">
                                    <h3 className="color">Confirmation Details</h3>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-5">
                                        <RequiredLabel htmlFor="Shift-confirmation-confirm_with">Confirm with</RequiredLabel>
                                        <CustomSelect
                                            variant="dark"
                                            name="Shift[confirmation][confirm_with]"
                                            id="Shift-confirmation-confirm_with"
                                            className="form-control"
                                            placeholder={(keyContactsLoading || billingContactsLoading) ? `Loading...` : `Select...`}
                                            value={values.Shift.confirmation.confirm_with}
                                            options={this.confirmWithOptions()}
                                            clearable={false}
                                            searchable={false}
                                            disabled={keyContactsLoading || billingContactsLoading}
                                            onChange={(newValue) => {
                                                const { contact, value } = newValue || {}
                                                
                                                if (!newValue) {
                                                    return;
                                                }

                                                setFieldValue("Shift[confirmation][confirm_with]", value)

                                                if (!newValue) {
                                                    return;
                                                }
                                                
                                                const { firstname, lastname, email, phone, id } = contact || {}
                                                setFieldValue("Shift[confirmation][_contact_id]",  id)
                                                setFieldValue("Shift[confirmation][firstname]", firstname || '')
                                                setFieldValue("Shift[confirmation][lastname]", lastname || '')
                                                setFieldValue("Shift[confirmation][email]", email || '')
                                                setFieldValue("Shift[confirmation][phone]", phone || '')  

                                            }}
                                        />
                                        <ErrorMessage name="Shift[confirmation][confirm_with]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-confirmation-confirm_by">Confirm by</RequiredLabel>
                                        <CustomSelect
                                            variant="dark"
                                            name="Shift[confirmation][confirm_by]"
                                            id="Shift-confirmation-confirm_by"
                                            className="form-control"
                                            value={values.Shift.confirmation.confirm_by}
                                            options={[
                                                { value: null, label: 'Select...', selected: true, disabled: true },
                                                { value: 1, label: 'Email' },
                                                { value: 2, label: 'Phone' },
                                            ]}
                                            clearable={false}
                                            searchable={false}
                                            onChange={(newValue) => {
                                                setFieldValue("Shift[confirmation][confirm_by]", newValue.value)
                                            }}
                                        />
                                        <ErrorMessage name="Shift[confirmation][confirm_by]" render={this.renderValidationError} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-confirmation-firstname">Contact First name</RequiredLabel>
                                        <Field type="text" className="form-control border-dark" name="Shift[confirmation][firstname]" id="Shift-confirmation-firstname" disabled={values.Shift.confirmation._contact_id} />
                                        <ErrorMessage name="Shift[confirmation][firstname]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-confirmation-lastname">Contact last name</RequiredLabel>
                                        <Field type="text" className="form-control border-dark" name="Shift[confirmation][lastname]" id="Shift-confirmation-lastname" disabled={values.Shift.confirmation._contact_id} />
                                        <ErrorMessage name="Shift[confirmation][lastname]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <RequiredLabel htmlFor="Shift-confirmation-email">Contact email</RequiredLabel>
                                        <Field type="email" className="form-control border-dark" name="Shift[confirmation][email]" id="Shift-confirmation-email" disabled={values.Shift.confirmation._contact_id} />
                                        <ErrorMessage name="Shift[confirmation][email]" render={this.renderValidationError} />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="Shift-confirmation-phone">Contact phone</label>
                                        <Field type="text" className="form-control border-dark" name="Shift[confirmation][phone]" id="Shift-confirmation-phone" maxlength="10" disabled={values.Shift.confirmation._contact_id} onKeyPress={ensureOnlyNumericKeypressAllowed}/>
                                        <ErrorMessage name="Shift[confirmation][phone]" render={this.renderValidationError} />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="color">Miscellaneous shift information</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="Shift-confirmation-phone">Site preferred member</label>
                                                <CustomAsyncSelect
                                                    arrowRenderer={() => <span className="search-icon"></span>}
                                                    placeholder="Search member"
                                                    onChange={(newValue) => setFieldValue("Shift[preferred_member][memberId]", newValue)}
                                                    name="Shift[preferred_member][memberId]"
                                                    id="Shift-preferred_member-memberId"
                                                    value={values.Shift.preferred_member.memberId}
                                                    cache={false}
                                                    clearable={true}
                                                    className="form-control border border-dark"
                                                    loadOptions={async (inputValue, cb) => {
                                                        if (!inputValue) {
                                                            return Promise.resolve({ options: [] })
                                                        }

                                                        const res = await common.search_members(inputValue)
                                                        const { data } = res.data
                                                        return { options: data }
                                                    }}
                                                />
                                                <ErrorMessage name="Shift[preferred_member][memberId]" render={this.renderValidationError} />
                                            </div>
                                            <div className="col-12 d-flex w-100 justify-content-between">
                                                <label>Allocate preferred member</label>
                                                <div className="d-inline-block">
                                                    <CustomRadioButton 
                                                        id="Shift-preferred_member-allocate-yes"
                                                        name="Shift[preferred_member][allocate]"
                                                        value={values.Shift.preferred_member.allocate}
                                                        checked={!!values.Shift.preferred_member.allocate}
                                                        onChange={e => setFieldValue("Shift[preferred_member][allocate]", true)}
                                                        label="Yes"
                                                    />
                                                    <CustomRadioButton 
                                                        id="Shift-preferred_member-allocate-no"
                                                        name="Shift[preferred_member][allocate]"
                                                        value={values.Shift.preferred_member.allocate}
                                                        checked={!values.Shift.preferred_member.allocate}
                                                        onChange={e => setFieldValue("Shift[preferred_member][allocate]", false)}
                                                        label="No"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex w-100 justify-content-between">
                                                <label>Autofill shift</label>
                                                <div className="d-inline-block">
                                                    <CustomRadioButton 
                                                        id="Shift-preferred_member-autofill_shift-yes"
                                                        name="Shift[preferred_member][autofill_shift]"
                                                        value={values.Shift.preferred_member.autofill_shift}
                                                        checked={!!values.Shift.preferred_member.autofill_shift}
                                                        onChange={e => setFieldValue("Shift[preferred_member][autofill_shift]", true)}
                                                        label="Yes"
                                                    />
                                                    <CustomRadioButton 
                                                        id="Shift-preferred_member-autofill_shift-no"
                                                        name="Shift[preferred_member][autofill_shift]"
                                                        value={values.Shift.preferred_member.autofill_shift}
                                                        checked={!values.Shift.preferred_member.autofill_shift}
                                                        onChange={e => setFieldValue("Shift[preferred_member][autofill_shift]", false)}
                                                        label="No"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="row">
                                            <div className="col-12">
                                                <RequiredLabel htmlFor="Shift-shift_notes-notes">Note</RequiredLabel><br/>
                                                <Field
                                                    component="textarea"
                                                    id="Shift-shift_notes-notes"
                                                    name="Shift[shift_notes][notes]"
                                                    className="form-control border-dark"
                                                    rows={5}
                                                    value={values.Shift.shift_notes.notes}
                                                />
                                                <ErrorMessage name="Shift[shift_notes][notes]" render={this.renderValidationError} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-12 text-right">
                                        <button type="submit" className="btn btn-dark" disabled={isSubmitting}>
                                            {isSubmitting ? `Creating...` : 'Create new shift'}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </Form>
                    )
                }}
            </Formik>
        )
    }

    render() {
        const { entityType } = this.props

        return (
            <div className="container-fluid">
                <div className="content-block">
                    {this.renderBreadcrumbs()}
                </div>
                <div className="content-block">
                    {entityType === 'SITE' ? this.renderSiteSummary() : this.renderOrgSummary()}
                </div>
                <div className="content-block">
                    {this.renderEntryHeader()}
                </div>
                <div className="content-block">
                    {this.renderForm()}
                </div>
            </div>
        )
    }
}


/**
 * Determine entity type from routes
 * 
 * @param {import('react-router-dom').match<{id?: number}>} param
 * @return {EntityTypes}
 */
function determineEntityType({ path }) {
    if (_.startsWith(path, '/sub-orgs')) {
        return 'SUBORG'
    } else if (_.startsWith(path, '/site') || _.startsWith(path, '/sites')) {
        return 'SITE'
    } else {
        return 'ORG'
    }
}


const mapStateToProps = (state, ownProps) => {
    const { token } = state.auth.user
    const { params } = ownProps.match

    let claims = null;
    if (token) {
        claims = jwt.decode(token)
    }

    const { type: entityType } = state.entityTypes 


    return {
        organisationName: (claims || {})['name'],
        suborgs: {
            items: state.suborgs.items,
            loading: state.suborgs.loading,
        },
        sites: {
            items: state.sites.items,
            loading: state.sites.loading,
        },
        currentSubOrg: entityType === 'SUBORG' ? (state.suborgs.items || []).find(s => s.id == params.id) : null,
        currentSite: entityType === 'SITE' ? (state.sites.items || []).find(s => s.site_id == params.id) : null,
        australianStates: {
            items: [
                state.australianStates.loading ?
                    { label: "Loading...", value: "", selected: true, disabled: true } :
                    { label: "Select...", value: "", selected: true, disabled: true },
                ...state.australianStates.items
            ],
            loading: state.australianStates.loading
        },
        orgDetails: {
            value: state.orgDetails.value,
            loading: state.orgDetails.loading,
        },
        organisationRequirements: {
            loading: state.organisationRequirements.loading,
            items: state.organisationRequirements.items || [],
        },
        shiftTimeRequirements: {
            loading: state.shiftTimeRequirements.loading,
            items: state.shiftTimeRequirements.items || [],
        },
        shiftRequirements: {
            loading: state.shiftRequirements.loading,
            items: state.shiftRequirements.items || [],
        },
        entityType: entityType
    }
}

export default connect(mapStateToProps)(CreatePage)