import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Breadcrumb, InputGroup, DropdownButton, FormControl, Dropdown } from 'react-bootstrap'
import jwt from "jsonwebtoken"
import qs from "qs"

import OrgInfoSummary from '../../components/OrgInfoSummary'
import { axios } from '../../axios'

import AddIconWhite from "../../images/Add-Icon-White.svg"
import LoadingIndicator from "../../images/download.gif"

import "../../stylesheets/pages/sub-orgs/IndexPage.scss"
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs'


/**
 * @typedef {{organisationName?: string} & import('react-redux').DispatchProp} Props
 * 
 * @typedef {object} State
 * @property {import('../../../global').HCM.SubOrgsIndexResponse.DataItem[]} suborgs
 * @property {boolean} loadingSubOrgs 
 * @property {boolean} searchingSubOrgs 
 * @property {string} keyword
 */


 /**
  * Renders page to display and search list of suborgs
  * 
  * @extends {React.Component<Props, State>}
  */
class IndexPage extends React.Component {

    /** @type {State} */
    state = {
        suborgs: [],
        loadingSubOrgs: false,
        searchingSubOrgs: false,
        search: '',
    }


    /**
     * Fetch org details and suborgs when this page has loaded
     */
    async componentDidMount() {
        this.setState({ loadingSubOrgs: true })

        const getSubOrgsResponse = await axios.get("/suborgs/get_sub_org");
        const { data: getSubOrgsResponseData } = getSubOrgsResponse.data;

        this.setState({
            suborgs: getSubOrgsResponseData,
            loadingSubOrgs: false,
        })
    }

    /**
     * Search suborgs when form is submitted
     * 
     * @param {React.FormEvent | React.MouseEvent} e
     */
    handleSubmitSearch = async (e) => {
        e.preventDefault();


        const { keyword } = this.state;

        try {
            this.setState({ searchingSubOrgs: true })
            let endpoint = `/suborgs/get_sub_org`
            if (keyword.length > 0) {
                endpoint = endpoint + `?` + qs.stringify({ search: keyword });
            }

            const getSubOrgsResponse = await axios.get(endpoint);
            const { data: getSubOrgsResponseData } = getSubOrgsResponse.data;
    
            this.setState({ 
                suborgs: getSubOrgsResponseData,
                searchingSubOrgs: false,
            })
        } catch (e) {
            this.setState({ searchingSubOrgs: false })
        }

    }


    /**
     * @param {React.MouseEvent<HTMLButtonElement>} e
     */
    handleClickClearSearch = async (e) => {
        this.setState({ keyword: '', searchingSubOrgs: true })
        try {

            let endpoint = `/suborgs/get_sub_org`
            const getSubOrgsResponse = await axios.get(endpoint);
            const { data: getSubOrgsResponseData } = getSubOrgsResponse.data;
    
            this.setState({ 
                suborgs: getSubOrgsResponseData,
                searchingSubOrgs: false,
            })
            
        } catch (e) {
            this.setState({ searchingSubOrgs: false })
        }

    }



    /**
     * Render breadcrumbs
     */
    renderBreadcrumbs() {
        return <CustomBreadcrumbs />
    }

    /**
     * Render org details
     */
    renderOrgSummary() {
        return (
            <div className="site-information">
                <OrgInfoSummary />
            </div>
        )
    }

    /**
     * Render page title
     */
    renderEntryHeader() {
        return (
            <div className="entry-header">
                <h2 style={{margin: 0}}>
                    <b>Sub-org List</b>
                </h2>
            </div>
        )
    }

    /**
     * Render number of results and link to create suborg
     */
    renderListActions() {
        const { suborgs } = this.state
        const totalSubOrgs = suborgs.length

        /** @type {Record<'root' | 'createSubOrgBtn' | 'addIcon', React.CSSProperties>} */
        const styles = {
            root: {
                marginTop: 24,
                marginBottom: 24,
            },
            createSubOrgBtn: {
                fontSize: 20,
                width: 394,
                paddingTop: 4,
                paddingBottom: 4,
            },
            addIcon: {
                marginLeft: 70
            }
        }

        return (
            <div className="suborgs-list-header" style={styles.root}>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="d-flex h-100">
                            <div className="align-self-center">
                                Total  Sub-Orgs - {totalSubOrgs}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 text-right">
                        <Link to="/sub-orgs/create" className="btn btn-lg btn-dark" style={styles.createSubOrgBtn}>
                            <span style={{marginLeft: 70}}>
                                <span>Create new Sub-org</span>
                                <img src={AddIconWhite} alt="Add suborgs" style={styles.addIcon} />
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }


    /**
     * Renders search form
     */
    renderSearchForm() {
        const { keyword } = this.state

        return (
            <div className="col-12 col-sm-4">
                <div className="row">
                    <form action="#" onSubmit={this.handleSubmitSearch} method="GET" id="SubOrgsIndexForm" className="search-form">
                        <input type="text" 
                            value={keyword}
                            className="form-control" 
                            name="search" 
                            id="SubOrgsIndexForm-search" 
                            placeholder="Search" 
                            onChange={e => this.setState({ keyword: e.target.value })}
                        />
                        <button type="button" className="btn" onClick={this.handleClickClearSearch}>
                            <span className="close-icon"></span>
                        </button>
                    </form>
                </div>
            </div>
        )
    }


    /**
     * Renders list of suborgs
     */
    renderList() {
        const { loadingSubOrgs, suborgs, searchingSubOrgs } = this.state


        /** @type {Record<'loading' | 'suborgs' | 'name' | 'site' | 'button', React.CSSProperties>} */
        const styles = {
            loading: {
                marginTop: 15,
                marginBottom: 15,
            },
            suborgs: {
                backgroundColor: "#e6e6e6",
                padding: "10px 24px",
            },
            name: {
                fontSize: 20,
                fontWeight: "bold",
            },
            site: {
                fontSize: 20,
                fontWeight: "bold",
            },
            button: {
                fontSize: 14,
                width: 260,
                paddingTop: 0,
                paddingBottom: 0,
                lineHeight: "26px",
            }
        }

        const subOrgs = suborgs.map((suborg) => {
            return { 
                type: "Sub-Org", 
                name: suborg['name'], 
                site: suborg['city']['value'], 
                _links: { 
                    "details": {
                        href: `/sub-orgs/${suborg['ocs_id']}`,
                        text: "View Sub-org details"
                    }
                }
            }
        })

        if (loadingSubOrgs) {
            return (
                <div className="text-center" style={styles.loading}>
                    Loading sub-orgs<br/>
                    <img src={LoadingIndicator} alt="Loading..."/>
                </div>
            )
        }

        return (
            <div className={`sub-orgs` + (searchingSubOrgs ? ' is-searching' : '') + (subOrgs.length === 0 ? ' has-no-results' : '')}>
                <div className="front-layer"></div>
                <div className="hcm-list">
                {
                    subOrgs.length === 0 && (
                        <div className="row">
                            <div className="col-12">
                                <p className="text-center" style={{marginTop: 24, padding: 24, backgroundColor: "#E6E6E6"}}>
                                    { searchingSubOrgs ? <span>&nbsp;</span> : `No results found` }
                                </p>
                            </div>
                        </div>
                    )
                }
                {
                    subOrgs.length > 0 && subOrgs.map((suborg, i) => (
                        <section key={i}>
                            <div className="row">
                                <div className="col-12 col-sm-3 d-flex has-vertical-divider">
                                    <div className="d-flex align-self-center">
                                        <span style={styles.name}>Sub-Org</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-9">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <span style={styles.site}>{suborg.name}{suborg.site && (` - ${suborg.site}`)}</span>
                                        </div>
                                        <span className="buttons">
                                        {
                                            Object.keys(suborg._links).map((rel, i) => (
                                                <Link className="btn btn-dark btn-block" style={styles.button} key={i} to={suborg._links[rel].href}>{suborg._links[rel].text}</Link>
                                            ))
                                        }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ))
                }
                </div>
            </div>
        )
    }


    render() {
        return (
            <div className="container-fluid">
                <div className="content-block">
                    {this.renderBreadcrumbs()}
                </div>
                <div className="content-block">
                    {this.renderOrgSummary()}
                </div>
                <div className="content-block">
                    {this.renderEntryHeader()}
                </div>
                <div className="content-block">
                    {this.renderListActions()}
                </div>
                <div className="content-block">
                    {this.renderSearchForm()}
                </div>
                <div className="content-block">
                    {this.renderList()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {token} = state.auth.user

    let claims = null;
    if (token) {
        claims = jwt.decode(token)
    }

    return {
        organisationName: (claims || {})['name']
    }
}

export default connect(mapStateToProps)(IndexPage);