import React from "react"
import { connect } from "react-redux"
import queryString from "query-string"
import jwt from "jsonwebtoken"

import LoginWelcomeArea from "../components/LoginWelcomeArea";

import "../stylesheets/pages/PasswordResetPage.scss";
import { resetPassword, verifyPasswordResetToken } from "../actions/passwordResetActions"
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";



class PasswordResetPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            new_password: "",
            password_repeat: "",
            id: "",
            token: "",
            submitted: false,
            formErrors: {
                new_password: null,
                password_repeat: null
            }
        }
    }


    componentDidMount() {
        const { dispatch } = this.props;

        const { token } =  queryString.parse(window.location.search)
        const decoded = jwt.decode(token);
        if (!decoded) {
            return
        }

        const { date, email, id } = decoded
        this.setState({ email, id, token })

        dispatch(verifyPasswordResetToken(token))
    }
    

    /**
     * @param {React.FormEvent} e
     */
    handleOnChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    /**
     * @type {((values: any, formikHelpers: FormikHelpers<any>) => void) & ((values: any) => void)}
     */
    handleOnSubmit = (values, formikHelpers) => {
        const { dispatch } = this.props
        dispatch(resetPassword(values))
    }



    renderWelcomeArea() {
        const { email } = this.state

        return <LoginWelcomeArea description={`Reset password for ${email}`}/>
    }

    renderForm() {
        const { isResettingPassword } = this.props;
        const { new_password, password_repeat, id, token, email } = this.state;

        const MESSAGE_PASSWORDS_DO_NOT_MATCH = "Passwords do not match"

        Yup.addMethod(Yup.string, 'equalTo', (ref, msg) => {
            return Yup.mixed().test({
                name: 'equalTo',
                exclusive: false,
                message: msg || '${path} must be the same as ${reference}',
                params: {
                    reference: ref.path,
                },
                test: (value) => {
                    return value === this.resolve(ref)
                }
            })
        })

        const PasswordResetSchema = Yup.object().shape({
            email: Yup.string().required("Email is required"),
            id: Yup.string().required("Organisation ID is required"),
            token: Yup.string().required("Password reset token is required"),
            new_password: Yup
                .string().min(6, "New password must be at least 6 characters")
                .required("New password is required"),
                password_repeat: Yup
                .string().min(6, "This field must be at least 6 characters")
                .oneOf([Yup.ref("new_password"), null], MESSAGE_PASSWORDS_DO_NOT_MATCH)
                .required("Password repeat field is required")
        });

        return (
            <Formik
                initialValues={{
                    email: email,
                    token: token,
                    id: id,
                    new_password: '',
                    password_repeat: '',
                }}
                validationSchema={PasswordResetSchema}
                onSubmit={this.handleOnSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <Form action="#" className="text-primary" id="PasswordResetForm" name="password-reset-form">
                            <Field type='hidden' name='email'/>
                            <Field type='hidden' name='id'/>
                            <Field type='hidden' name='token'/>
                            <div className="form-group">
                                <label htmlFor="PasswordResetForm-new_password">New password</label>
                                <Field type="password" className="form-control input-primary" name="new_password" id="PasswordResetForm-new_password" onChange={handleChange}/>
                                <ErrorMessage name="new_password" render={errorMessage => (
                                    <div className="help-block text-danger">{errorMessage}</div>
                                )}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="PasswordResetForm-password_repeat">Password repeat</label>
                                <Field type="password" className="form-control input-primary" name="password_repeat" id="PasswordResetForm-password_repeat" onChange={handleChange}/>
                                <ErrorMessage name="password_repeat" className="help-block text-danger" render={errorMessage => (
                                    errorMessage !== MESSAGE_PASSWORDS_DO_NOT_MATCH && (<div className="help-block text-danger">{errorMessage}</div>)
                                )}/>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="submit" disabled={isResettingPassword}>
                                    {isResettingPassword ? 'Processing...' : 'Submit'}
                                </button>
                            </div>
                            {
                                errors.password_repeat === MESSAGE_PASSWORDS_DO_NOT_MATCH && (
                                    <div className="form-group text-danger">{MESSAGE_PASSWORDS_DO_NOT_MATCH}</div>
                                )
                            }
                        </Form>
                    )
                }
            </Formik>

        )
    }

    render() {
        const { isVerifyingPasswordResetToken, errors, isResettingPassword } = this.props;

        if (isVerifyingPasswordResetToken) {
            return <p>Verifying token...</p>
        }

        if (errors && errors.length > 0) {
            return <p>{errors[0]}</p>
        }


        return (
            <>
                {this.renderWelcomeArea()}
                {this.renderForm()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isResettingPassword: state.passwordReset.isResettingPassword,
        isVerifyingPasswordResetToken: state.passwordReset.isVerifyingPasswordResetToken,
        errors: state.passwordReset.passwordResetTokenErrors,
    }
}

export default connect(mapStateToProps, null)(PasswordResetPage)