import Axios from 'axios';
import { LOCALSTORAGE_KEY, AUTH_ENDPOINT } from '../constants';
import { BASE_URL } from '../config';

export const userService = {
    login,
    logout,
    isLoggedIn,
    sendPasswordResetEmail,
    isFirstTimeLogIn
};

function login(username, password) {
    const formdata = new FormData();
        formdata.set('username', username);
        formdata.set('password', password);

    const axiosInstance = Axios.create({
        baseURL: BASE_URL
    })


    return axiosInstance.post(AUTH_ENDPOINT, formdata)
        .then(response => {
            const data = response.data
            if ([403, 401].indexOf(response.status) > -1) {
                logout();
                window.location.reload(true);
                const error = data.message || response.statusText
                return Promise.reject(error)
            }

            return response
        })
        .then(response => {
            const user = response.data
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(user))
            return response;
        })

}

function isLoggedIn() {
    return !!localStorage.getItem(LOCALSTORAGE_KEY);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function sendPasswordResetEmail() {
    return new Promise((resolve, reject) => {
        reject("Not yet implemented")
    })
}


function isFirstTimeLogIn() {
    const userJSON = localStorage.getItem(LOCALSTORAGE_KEY);
    const user = JSON.parse(userJSON) || {};
    return !!user['is_first_time_login']
}