// @ts-check

/** 
 * @type {<S, CR extends Record<string, (state: S, action: import("../../global").HCM.Action<keyof CR>) => S>>(initialState: S, handlers: CR) => import("redux").Reducer<S>}
 */
export default function createReducer(initialState, handlers) {
    return function reducer(state = initialState, action) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action)
        } else {
            return state
        }
    }
}

/** 
 * Helper method to help intellisense get possible action types (Will be determined from keys of `handlers`)
 * 
 * @type {<S, CR extends Record<string, (state: S, action: import("../../global").HCM.Action<keyof CR>) => S>>(initialState: S, handlers: CR) => {reducer: import("redux").Reducer<S>, ActionTypes: keyof CR}}
 */
export function createReducerSlice(initialState, handlers) {
    return {
        reducer(state = initialState, action) {
            if (handlers.hasOwnProperty(action.type)) {
                return handlers[action.type](state, action)
            } else {
                return state
            }
        },
        // @ts-ignore
        ActionTypes: null // do not call this directly in your code
    }
}