const doctitles = {
    // Account
    "/forgot-password": "Forgot Password",
    "/login": "Login",
    "/temp-password-reset": "Temporary Password Reset",
    "/password-reset": "Password reset",
    "/logout": "Logging out...",

    // Suborg
    "/sub-orgs/:id/shifts/create": "Create shift",
    "/sub-orgs/:id/shifts": "Shifts",
    "/sub-orgs/:id/invoices": "Invoice tracker",
    "/sub-orgs/:id/fms/create": "Create FMS case",
    "/sub-orgs/:id/fms": "FMS cases",
    "/sub-orgs/:id/shifts/create": "Create new shift",
    "/sub-orgs/:id/shifts": "Create shift",
    "/sub-orgs/:id": "Your Sub-org",

    // Site
    "/sites/:id/shifts/create": "Create shift",
    "/sites/:id/shifts": "Shifts",
    "/sites/:id/invoices": "Invoice tracker",
    "/sites/:id/fms/create": "Create FMS case",
    "/sites/:id/fms": "FMS cases",
    "/sites/:id/shifts/create": "Create new shift",
    "/sites/:id/shifts": "Create shift",
    "/sites/:id/docs": "Site docs",
    "/sites/:id/detail": "Your site",
    "/sites/:id": "Your site",

    // Parent org
    "/shifts/create": "Create shift",
    "/shifts": "Shifts",
    "/sites/create": "Create new site",
    "/sites": "Sites",
    "/sub-orgs/create": "Create new sub-org",
    "/sub-orgs": "Sub-orgs",
    "/org-review": "Your Organisation",
    "/notifications": "Notifications",
    "/change-password": "Change password",
    "/fms/create": "Create FMS Case",
    "/fms": "FMS",
    "/invoices": "Invoice tracker",
    "/privacy-policy": "Privacy Policy",
    "/about-hcm": "About Healthcare Manager",
    "/staff-policy": "Staff Policy",
    "/terms-conditions": "Terms and Conditions",
    "/": "Dashboard",
}

export default doctitles