import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { toast } from 'react-toastify';

import SiteInfoSummary from '../../components/site/SiteInfoSummary';
import SimpleHeader from '../../components/SimpleHeaderComponent';
import ActionButton from '../../components/ActionButtonComponent';
import AddIconWhite from '../../icons/Add-Icon-White.svg';
import UploadFileModal from '../../components/UploadFileModalComponent';
import DocumentGalleryIcon from '../../components/GalleryIconComponent';
import BasicUserResponseModal from '../../components/BasicUserResponseModal';
import FileIcon from '../../icons/icon-file.svg';
import {CustomSelect} from '../../components/CustomSelect';

import { axios } from '../../axios';
import common from '../../services/common';
import {BASE_URL} from '../../config';

import '../../stylesheets/pages/sites/SiteDocsPage.scss';
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs';

/**
 * Renders page to display site document list and corresponding actions
 * 
 * @extends {React.Component<Props, State>}
 */
class SiteDocsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            org_data: {},
            site_data: {},
            site_docs: [],
            show_doc_upload_modal: false,
            show_basic_user_response_modal: false,
            selected_docs: [],
            keyword: '',
            sort: {
                by: null,
                options: [
                    {label: 'Upload date', value: 'upload_date'},
                    {label: 'Title', value: 'title'}
                ]
            }
        }

        this.httpConfig = {
            getSiteDocs: '/Site/getSiteDocuments',
            archiveDocs: '/Site/archiveSiteDocs',
            downloadDocs: '/Site/downloadSiteDocs'
        };
    }

    componentDidMount() {
        var keyContact = null;

        // Fetch the organization details of the corresponding site to which the documents belongs
        common.fetchOrgDetails().then(response => {
            var orgDetail = null;

            if(response.data !== null && typeof response.data !== 'undefined') {
                if(response.data.status == true) {
                    orgDetail = response.data.data;
                    this.setState({
                        org_data: orgDetail,
                    });                    
                }                
            }
        });

        // Fetch the site details to which this documents belongs
        common.fetchSiteDetails(this.props.match.params.id).then(({data}) => {
            if(data.status === true) {
                this.setState({
                    site_data: data.data
                });

                var siteContacts = data.data.contact_detail || [];
                keyContact = siteContacts.find(({contact_type}) => {
                    return contact_type.value == "3"
                });

                if(typeof keyContact !== 'undefined') {
                    this.setState({
                        key_contact: keyContact
                    });
                }
            }
        });

        this.fetchSiteDocs();
    }

    // Fetch the list of documents of the site
    fetchSiteDocs = (searchCriteria) => {
        var searchParams = {
            site_id: this.props.match.params.id,
            view_by: "1"
        }

        if(typeof searchCriteria !== 'undefined') {
            if(searchCriteria.search_string !== '') {
                searchParams.search_by = searchCriteria.search_string
            }

            if(searchCriteria.sort_by !== '') {
                searchParams.order_by = searchCriteria.sort_by
            }
        }

        axios.get(this.httpConfig.getSiteDocs, {
            params: searchParams
        }).then(response => {
            var {data} = response;

            if(data.status) {
                var siteDocs = data.data.map( (doc, ind) => {
                    doc['selected'] = false;                        // By default, no document on the page is on 'selected' state
                    doc['index'] = ind;
                    return doc;
                });
                this.setState({
                    site_docs: siteDocs || [],
                });
            }
        }).finally(() => {
            this.setState({
                selected_docs: []
            });
        });
    }

    // display the modal to upload new documents
    showFileUploadModal = () => {
        this.setState({
            show_doc_upload_modal: true
        });
    }

    // Handler function to hide 'file upload modal'
    fileUploadModalCloseHandler = () => {
        this.setState({
            show_doc_upload_modal: false
        });
    }

    // Function that is called after a new document was uploaded
    documentWasUploaded = (response) => {
        this.setState({
            show_doc_upload_modal: false,
            org_data: {},
            site_data: {}

        });

        if(response.data.status === true) {
            this.showToast('success', 'document uploaded successfully', 'uploaded_site_doc', 'success_toast_container');
            this.fetchSiteDocs();
        } else {
            this.showToast('error', response.data.error, 'uploaded_site_doc', 'error_toast_container');
        }
    }

    // When user click on a document icon on the page
    docSelected = (docObject) => {
        const {index} = docObject;
        var allSiteDocs = this.state.site_docs;

        // If document icon was 'selected' by user before, unselect it and remove from the selected document list
        docObject.selected = !docObject.selected;
        allSiteDocs.splice(index, 1, docObject);
        this.setState({
            site_docs: allSiteDocs
        });

        // push the selected document in the list of selected docs
        if(docObject.selected) {
            this.setState({
                selected_docs: [...this.state.selected_docs, docObject]
            });
        } else {
            let arr = this.state.selected_docs;
            if(arr.length) {
                var ind = arr.findIndex((elem) => {
                    return elem.key === docObject.key;
                });
            }

            if(ind !== -1) {
                arr.splice(ind, 1);
                this.setState({
                    selected_docs: arr
                });
            }
        }
    }

    // Archive the selected documents
    archiveDocuments = () => {
        var selectedDocs = this.state.selected_docs;

        if(selectedDocs.length) {
            axios.post(this.httpConfig.archiveDocs,{
                    selected_docs: this.state.selected_docs
            }).then(response => {
                let {data} = response;

                if(data.status) {
                    this.showToast('success', 'document(s) archived successfully', 'archive_site_doc', 'success_toast_container');

                    this.setState({ show_basic_user_response_modal: false });
                    this.fetchSiteDocs();
                }
            });
        }
    }

    // Function to handle downloading of selected documents
    downloadSelectedDocs = () => {
        var selectedDocs = this.state.selected_docs;

        if(selectedDocs.length) {
            axios.post(this.httpConfig.downloadDocs, {
                site_id: this.state.site_data.basic_detail.ocs_id,
                selected_docs: this.state.selected_docs
            }).then(response => {
                let {data} = response;

                // If file/files were successfully added to a zip file, then download it
                if(data.status) {
                    window.location.href = BASE_URL + "archieve/" + data.zip_name;
                    this.showToast('success', 'Files downloaded successfully', 'download_site_doc', 'success_toast_container');
                    this.unselectSelectedDocs();
                } else {
                    this.showToast('error', 'Unable to download file', 'download_site_doc', 'error_toast_container');
                }
            }).finally(() => {
                this.setState({
                    selected_docs: []
                });
            });
        }
    }

    // Unselect the doc(s) that were selected
    unselectSelectedDocs = () => {
        var arr = this.state.selected_docs.map(elem => {
            if(elem.selected) {
                elem.selected = !elem.selected;
            }

            return elem;
        });
        
        this.setState({
            selected_docs: arr
        });
    }

    // Function to handler user search input
    handleSearch = (event) => {
        if(typeof event !== 'undefined') {
            event.preventDefault();
        }

        var searchCriteria = {
            search_string: '',
            sort_by: ''
        };

        if(this.state.keyword.length) {
            searchCriteria.search_string = this.state.keyword;
        }

        if(this.state.sort.by && (this.state.sort.by.value !== null && typeof this.state.sort.by.value !== 'undefined' )) {
            searchCriteria.sort_by = this.state.sort.by.value;
        }

        this.fetchSiteDocs(searchCriteria);
    }

    // Function to handle clearing of search input box
    clearSearch = () => {
        this.setState(() => {
            return {
                keyword: '',
            }
        }, () => {
            this.handleSearch();
        });
    }

    handleSelectChange(selectedOption, property) {
        var nestedProps = property.split('.');
        if(nestedProps.length == 2) {
            var level1Prop = nestedProps[0];
            var level2Prop = nestedProps[1];
            var current = {...this.state[nestedProps[0]]};

            this.setState(() => {
                return {
                    [level1Prop]: { ...current, [level2Prop]: selectedOption}
                }
                
            }, () => {
                if(this.state.sort.by && this.state.sort.by.value !== null && this.state.sort.by.value !== undefined) {
                    this.handleSearch();
                }
            });
        } else if(nestedProps.length == 1) {
            var prop = nestedProps[0];
            this.setState( () => {
                return {
                    [prop]: selectedOption
                }                
            }, () => {
                if(this.state.sort.by && this.state.sort.by.value !== null && this.state.sort.by.value !== undefined) {
                    this.handleSearch();
                }
            });
        }
    }

    // Function to show a toast message based
    showToast = (type, message, toastId, containerId) => {
        toast(message, {
            toastId: toastId,
            containerId: containerId
        });
    }

    render() {
        var siteDocList = [];
        var galleryIconStyle = {
            width: `120px`,
            height: `120px`,
            margin: `5px`,
            // backgroundColor: '#F6BB42',
            borderRadius: `8px`,
            padding: `5px 10px`
        };

        this.state.site_docs.forEach((elem, index) => {
            siteDocList.push(
                <DocumentGalleryIcon
                    key={elem.id}
                    style={galleryIconStyle}
                    classNames={elem.class_name + (elem.selected ? ' selected' : '') }
                    relatedObject={elem}
                    docSelectedHandler={this.docSelected}
                    iconToRender={FileIcon}
                ></DocumentGalleryIcon>
            );
        })

        return (
            <div className="container-fluid">
                <div className="content-block">
                    <CustomBreadcrumbs />
                </div>
                <div className="content-block">
                    <div className="site-information">
                        <SiteInfoSummary 
                            siteDetail={this.state.site_data.basic_detail}
                            keyContact={this.state.key_contact}>
                        </SiteInfoSummary>
                    </div>
				</div>
                
                <div className="content-block">
                    <div className="entity-details">
                        <SimpleHeader text="Site - Docs"
                            headerSize='2'>
                        </SimpleHeader>

                        <div className="d-flex justify-content-end entity-detail-action">
                            <ActionButton
                                text="Upload new doc"
                                buttonIcon={AddIconWhite}
                                clickHandler={this.showFileUploadModal}>
                            </ActionButton>
                        </div>

                        <div className="row searchbar">
                            <div className="col-md-10 search-text">
                                <form action="#" onSubmit={(event) => this.handleSearch(event)} method="GET" className="search-form">
                                    <input type="text" 
                                        value={this.state.keyword}
                                        name="search" 
                                        placeholder="Search" 
                                        onChange={e => this.setState({ keyword: e.target.value })}
                                    />
                                    <button type="button" className="btn" onClick={this.clearSearch}>
                                        <span className="close-icon"></span>
                                    </button>
                                </form>
                            </div>
                            <div className="col-md-2 sort-selector">
                                <CustomSelect className="border-dark small"
                                    name="state"
                                    simpleValue={false}
                                    cache={false}
                                    clearable={true}
                                    value={this.state.sort.by}  
                                    onChange={selectedVal => { this.handleSelectChange(selectedVal, 'sort.by'); } } 
                                    options={this.state.sort.options} 
                                    placeholder="Sort by" />
                            </div>
                        </div>

                        <div className="doc-lists">
                            <form className="d-flex justify-content-left">
                                {siteDocList}
                            </form>
                        </div>

                        <div className="d-flex justify-content-end">
                            <ActionButton
                                styles={{marginRight: '18px'}}
                                text="Archive Selected"
                                clickHandler={() => {this.setState({ show_basic_user_response_modal: true}) }} 
                                isDisabled={!this.state.selected_docs.length}>
                            </ActionButton>

                            <ActionButton
                                text="Download Selected"
                                clickHandler={this.downloadSelectedDocs}
                                isDisabled={!this.state.selected_docs.length}>
                            </ActionButton>
                        </div>
                    </div>
                </div>

                <UploadFileModal
                    siteDetail={this.state.site_data.basic_detail}
                    showModal={this.state.show_doc_upload_modal}
                    closeModal={this.fileUploadModalCloseHandler}
                    afterUploadingDocument={this.documentWasUploaded}>
                </UploadFileModal>

                <BasicUserResponseModal
                    showModal={this.state.show_basic_user_response_modal}
                    bodyText="Are you sure you want to Archive selected docs?"
                    confirmActionHandler={this.archiveDocuments}
                    closeModalHandler={() => { this.setState({ show_basic_user_response_modal: false}) }}>
                </BasicUserResponseModal>
            </div>
        )
    }
}

export default SiteDocsPage;