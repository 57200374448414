import { axios } from "../axios"
import qs from "qs"


const URLS = {
    'ORG': "/invoices",
    'SUBORG': '/suborgs/invoices/:id',
    'SITE': '/site/invoices/:id'
}


const URL_INVOICES_FAKE_RESPONSE = {
    count: 1,
    data: [
        {
            addressto: "Organisation",
            amount: 0,
            description: "Shift",
            fund_type: null,
            id: "1",
            invoice_date: "0000-00-00",
            invoice_for: "Laverton",
            invoice_number: "100001",
            invoice_shift_notes: "",
            line_item_notes: "",
            manual_invoice_notes: "",
            pay_by: "2019-12-01",
            status: "0",
        }
    ],
    total_count: 1
}


/**
 * Fetches invoice listing
 * 
 * @param {number} id 
 * @param {keyof URLS} booked_by 
 * @param {any} params 
 * @return {import("axios").AxiosResponse<typeof URL_INVOICES_FAKE_RESPONSE}
 */
function fetchAllInvoices(id, booked_by, params = {}) {
    const url = URLS[booked_by]
    const urlWithID = url.replace(':id', id)

    return axios.get(`${urlWithID}?${qs.stringify(params)}`);
}


export const invoiceService = {
    fetchAllInvoices,
    URLS
}