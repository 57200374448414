import React from 'react';
import { history } from './history';
import {
    Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import AboutHcmPage from './pages/AboutHcmPage';
import StaffPolicyPage from './pages/StaffPolicyPage';
import TermsConditionsPage from './pages/TermsConditionsPage';
import NotificationsPage from './pages/NotificationsPage';

import LoginPage from './pages/LoginPage';
import TempPasswordResetPage from './pages/TempPasswordResetPage';
import DashboardPage from './pages/DashboardPage';
import ChangePasswordPage from './pages/ChangePasswordPage';

import ForgotPasswordPage from './pages/ForgotPasswordPage';
import { userService } from './services/userService';
import LogoutPage from './pages/LogoutPage';
import PasswordResetPage from './pages/PasswordResetPage';

import SitesCreatePage from './pages/sites/CreatePage';
import SitesIndexPage from './pages/sites/IndexPage';
import SiteDetailsPage from './pages/sites/DetailsPage';
import SiteDocsPage from './pages/sites/SiteDocsPage';
import ShiftsIndexPage from './pages/shifts/IndexPage';

import ShiftCreatePage from './pages/shifts/CreatePage';

import InvoicesIndexPage from './pages/invoices/IndexPage';
import SubOrgsCreatePage from './pages/sub-orgs/CreatePage';
import FMSCreatePage from './pages/fms/CreatePage';
import FMSDetailsPage from './pages/fms/DetailsPage';
import FMSIndexPage from './pages/fms/IndexPage';

import './stylesheets/App.scss';
import OrgReviewPage from './pages/OrgReviewPage';
import SubOrgsDetailsPage from './pages/sub-orgs/DetailsPage';
import SubOrgsIndexPage from './pages/sub-orgs/IndexPage';
import InvoicesDetailsPage from './pages/invoices/DetailsPage';
import LoginLayout from './layouts/LoginLayout';
import Alerts from "./components/Alerts";
import NotFoundPage from './pages/NotFoundPage';



class App extends React.Component {


    render() {
        return (
            <>
                {/* TODO: Can we use BrowserRouter for this? */}
                <Router history={history}>
                    <Switch>

                        <Route exact path='/login' render={props => {
                            return userService.isLoggedIn()
                                ? <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                                : <LoginLayout className="page-login"><LoginPage {...props} /></LoginLayout>
                        }} />

                        <PublicRoute exact path='/forgot-password' layout={LoginLayout} layoutClassName="page-forgot-password" component={ForgotPasswordPage} />
                        <PublicRoute exact path='/password-reset' layoutClassName="page-password-reset" component={PasswordResetPage} />
                        <PublicRoute exact path='/temp-password-reset' layoutClassName="page-temp-password-reset" component={TempPasswordResetPage} />
                        <PublicRoute exact path='/logout' component={LogoutPage} />

                        {/* Routes for parent org */}
                        <PrivateRoute exact path='/sites/create' layoutClassName="page-sites-create" component={SitesCreatePage} />
                        <PrivateRoute exact path='/sites' layoutClassName="page-sites" component={SitesIndexPage} />
                        <PrivateRoute exact path='/sub-orgs/create' layoutClassName="page-sub-orgs-create" component={SubOrgsCreatePage} />
                        <PrivateRoute exact path='/sub-orgs' layoutClassName="page-sub-orgs-index" component={SubOrgsIndexPage} />
                        <PrivateRoute exact path='/fms/create' layoutClassName="page-fms-create" component={FMSCreatePage} />
                        <PrivateRoute exact path='/fms' layoutClassName="page-fms-index" component={props => <FMSIndexPage {...props} />} />
                        <PrivateRoute exact path='/shifts/create' layoutClassName="page-shifts-create" component={props => <ShiftCreatePage {...props} />} />
                        <PrivateRoute exact path='/shifts' layoutClassName="page-shifts-index" component={props => <ShiftsIndexPage {...props} />} />
                        <PrivateRoute exact path='/invoices' layoutClassName="page-invoices-index" component={InvoicesIndexPage} />
                        <PrivateRoute exact path='/org-review' layoutClassName="page-org-review" component={OrgReviewPage} />
                        <PrivateRoute exact path='/change-password' layoutClassName="page-change-password" component={ChangePasswordPage} />
                        <PrivateRoute exact path='/notifications' layoutClassName="page-notifications" component={NotificationsPage} />
                        <PrivateRoute exact path='/privacy-policy' layoutClassName="page-privacy-policy" component={PrivacyPolicyPage} />
                        <PrivateRoute exact path='/about-hcm' layoutClassName="page-about-hcm" component={AboutHcmPage} />
                        <PrivateRoute exact path='/staff-policy' layoutClassName="page-staff-policy" component={StaffPolicyPage} />
                        <PrivateRoute exact path='/terms-conditions' layoutClassName="page-terms-conditions" component={TermsConditionsPage} />

                        <PrivateRoute exact path='/sites/:id/fms/create' layoutClassName="page-sites-fms-create" component={FMSCreatePage} />
                        <PrivateRoute exact path='/sites/:id/fms' layoutClassName="page-sites-fms" component={props => <FMSIndexPage {...props} />} />
                        <PrivateRoute exact path='/sites/:id/invoices' layoutClassName="page-sites-invoices" component={props => <InvoicesIndexPage {...props} />} />
                        <PrivateRoute exact path='/sites/:id/shifts/create' layoutClassName="page-shifts-create" component={props => <ShiftCreatePage {...props} />} />
                        <PrivateRoute exact path='/sites/:id/shifts' layoutClassName="page-sites-shifts" component={ShiftsIndexPage} />
                        <PrivateRoute exact path='/sites/:id/docs' component={SiteDocsPage} />
                        <PrivateRoute exact path='/sites/:id/detail' layoutClassName="page-sites-create" component={SiteDetailsPage} />
                        
                        <PrivateRoute exact path='/sub-orgs/:id/shifts/create' layoutClassName="page-shifts-create" component={props => <ShiftCreatePage {...props} />} />
                        <PrivateRoute exact path='/sub-orgs/:id/shifts' layoutClassName="page-sub-orgs-shifts" component={props => <ShiftsIndexPage {...props} />} />
                        <PrivateRoute exact path='/sub-orgs/:id/fms/create' layoutClassName="page-sub-orgs-fms-create" component={FMSCreatePage} />
                        <PrivateRoute exact path='/sub-orgs/:id/fms' layoutClassName="page-sub-orgs-fms" component={props => <FMSIndexPage {...props} />} />
                        <PrivateRoute exact path='/sub-orgs/:id/invoices' layoutClassName="page-sub-orgs-invoices" component={props => <InvoicesIndexPage {...props} />} />
                        <PrivateRoute exact path='/sub-orgs/:id' layoutClassName="page-sub-orgs-details" component={(props) => <OrgReviewPage {...props} />} />

                        <PrivateRoute exact path='/' layoutClassName="page-dashboard" component={DashboardPage} />
                        <PrivateRoute path='*' component={NotFoundPage}/>
                    </Switch>
                </Router>
                <Alerts />
            </>
        )
    }
}

export default App;
