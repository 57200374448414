import { combineReducers } from 'redux';
import { auth } from './auth';
import { alerts } from "./alerts";
import { passwordReset } from "./passwordReset"
import { australianStates } from './australianStates'
import { orgDetails } from './orgDetails'
import { suborgs } from './suborgs'
import { sidebar } from './sidebar'
import { organisationRequirements } from './organisationRequirements'
import { shiftRequirements } from './shiftRequirements'
import { shiftTimeRequirements } from './shiftTimeRequirements'
import { sites } from './sites'
import { entityTypes } from './entityTypes'
import { infographics } from './infographics'

/**
 * @typedef {{[K in keyof reducers]: ReturnType<(typeof reducers)[K]> & {[key: string]: any}}} RootState
 */

const reducers = {
    auth,
    alerts,
    passwordReset,
    australianStates,
    orgDetails,
	suborgs,
	sites,
    sidebar,
    organisationRequirements,
    shiftRequirements,
    shiftTimeRequirements,
    entityTypes,
    infographics,
}

const rootReducer = combineReducers(reducers);

export default rootReducer;