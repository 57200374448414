import React from 'react'

/** 
 * Adds red star to labels
 * 
 * @param {React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>} props 
 */
const RequiredLabel = (props) => (
    <label {...props}>{props.children} <span className="required">*</span></label>
)

export default RequiredLabel