import React from 'react';
import moment from 'moment';

/**
 * This is a small component that can be used to display label => value information in general
 */
class NotificationComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="notification">
                {
                    this.props.labelsAndColumns && this.props.labelsAndColumns.map((element) => (
                        <div className="notification-row d-flex" key={element.label}>
                            <span className="label">{element.label}:&nbsp;</span>
                            <span className="value">{
                                element.label === 'Date' ? (
                                    moment(this.props.relatedObject[element.column], 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY h:mm A')
                                )
                                : (this.props.relatedObject[element.column])
                            }
                            </span>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default NotificationComponent;