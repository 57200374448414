import React from 'react'
import LoginLayout from '../layouts/LoginLayout'
import { Route } from 'react-router-dom'
import { LOGIN_URL } from '../constants';
import { userService } from '../services/userService';

const PublicRoute = ({ component: Component, layout: Layout = LoginLayout, layoutClassName = null, ...rest }) => (
    <Route {...rest} 
        render={props => {   

            // special case for temp pw reset page
            if (rest.path === '/temp-password-reset') {
                // only accessible to logged in users (USE HARD REDIRECT)
                const isLoggedin = userService.isLoggedIn();
                if (!isLoggedin) {
                    window.location.href = LOGIN_URL;
                    return;
                }

                // redirect to dashboard if user already logged in and set their own pw (USE HARD REDIRECT)
                if (!userService.isFirstTimeLogIn()) {
                    window.location.href = "/";
                    return;
                }
            }


            if (Layout) {
                return (
                    <Layout className={layoutClassName}>
                        <Component {...props} />
                    </Layout>
                )
            }
            
            return <Component {...props} />

        }}
    />
)

export default PublicRoute