import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom"

import LoginWelcomeArea from "../components/LoginWelcomeArea";
import LoginHeader from "../components/LoginHeader";
import LoginFooter from "../components/LoginFooter";

import "../stylesheets/pages/TempPasswordResetPage.scss";

import { resetTemporaryPassword } from "../actions/passwordResetActions";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";

/**
 * @typedef {{} & ReturnType<typeof mapStateToProps>} TempPasswordResetPageProps
 * @typedef {TempPasswordResetPageProps & import('react-redux').DispatchProp & import('react-router-dom').RouteComponentProps} Props
 * @typedef {{ new_password: string, password_repeat: string }} State
 */


/**
 * @extends {React.Component<Props, State>}
 */
class TempPasswordResetPage extends React.Component {

    state = {
        new_password: '',
        password_repeat: '',
    }

    handleOnSubmit = (values) => {
        const { new_password, password_repeat } = values;
        const { dispatch, claims } = this.props;
        dispatch(resetTemporaryPassword(claims, new_password, password_repeat))
    }

    renderSiteHeader() {
        return <LoginHeader />
    }

    renderSiteFooter() {
        return <LoginFooter />
    }

    renderWelcomeArea() {
        return <LoginWelcomeArea description={`Temporary password reset`} />
    }

    renderForm() {
        const {isResettingPassword} = this.props

        const MESSAGE_PASSWORDS_DO_NOT_MATCH = "Passwords do not match"

        const TempPasswordResetSchema = Yup.object().shape({
            new_password: Yup.string()
                .min(6, "New password must be at least 6 characters")
                .required("New password is required"),
            password_repeat: Yup.string()
                .min(6, "This field must be at least 6 characters")
                .oneOf([Yup.ref("new_password"), null], MESSAGE_PASSWORDS_DO_NOT_MATCH)
                .required("Password repeat field is required")
        });

        return (
            <Formik
                initialValues={{
                    new_password: '',
                    password_repeat: '',
                }}
                validationSchema={TempPasswordResetSchema}
                onSubmit={this.handleOnSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <Form action="#" className="text-primary" id="TempPasswordResetForm" name="temp-password-reset-form">
                            <div className="form-group">
                                <label htmlFor="TempPasswordResetForm-new_password">New password</label>
                                <Field type="password" className="form-control input-primary" name="new_password" id="TempPasswordResetForm-new_password" onChange={handleChange}/>
                                <ErrorMessage name="new_password" render={errorMessage => (
                                    <div className="help-block text-danger">{errorMessage}</div>
                                )}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="TempPasswordResetForm-password_repeat">Password repeat</label>
                                <Field type="password" className="form-control input-primary" name="password_repeat" id="TempPasswordResetForm-password_repeat" onChange={handleChange}/>
                                <ErrorMessage name="password_repeat" className="help-block text-danger" render={errorMessage => (
                                    errorMessage !== MESSAGE_PASSWORDS_DO_NOT_MATCH && (<div className="help-block text-danger">{errorMessage}</div>)
                                )}/>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="submit" disabled={isResettingPassword}>
                                    {isResettingPassword ? 'Processing...' : 'Submit'}
                                </button>
                            </div>
                            <div className="form-group text-center">
                                <Link to="/logout">Logout</Link>
                            </div>
                            {
                                errors.password_repeat === MESSAGE_PASSWORDS_DO_NOT_MATCH && (
                                    <div className="form-group text-danger">{MESSAGE_PASSWORDS_DO_NOT_MATCH}</div>
                                )
                            }
                        </Form>
                    )
                }
            </Formik>

        )
    }

    renderToast() {
        /** @type {React.CSSProperties} */
        const toastStyle = {
            position: "fixed",
            top: 60,
            color: "#FFFFFF",
            backgroundColor: "green"
        }

        return (
            <div className="toast" style={toastStyle}>
                <div className="toast-header">
                <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="toast-body text-center">
                    Password set successfully
                </div>
            </div>
        )
    }


    render() {
        return (
            <>
                {this.renderWelcomeArea()}
                {this.renderForm()}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, isSubmittingTempPasswordReset } = state.auth

    return {
        claims: user,
        isResettingPassword: isSubmittingTempPasswordReset,
    }
}

export default connect(mapStateToProps)(TempPasswordResetPage);